import { useGetUserV1WhoamiQuery } from "../../../app/services/generated-api.js"
import { LegalAgreementEditor, type LegalAgreementEditorDrawerContentProps } from "./LegalAgreementEditor.js"
import LegalAgreementEditorLite from "./LegalAgreementEditorLite.js"

// Conditionally renders the legal agreement editor in full or the LITE version based on the user's organizatio
export default function LegalAgreementEditorContainer({ create }: LegalAgreementEditorDrawerContentProps) {
  const { data: whoami } = useGetUserV1WhoamiQuery()

  if (!whoami) {
    return null
  }
  const isLiteOrganization = whoami.organization.is_lite

  if (isLiteOrganization) {
    return <LegalAgreementEditorLite />
  }

  return <LegalAgreementEditor create={create} />
}
