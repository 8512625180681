// eslint-disable-next-line no-restricted-imports
import { AsyncSelect as ChakraAsyncSelect, type AsyncSelectComponent } from "chakra-react-select"
import { forwardRef } from "react"
import DropdownIndicator from "./DropdownIndicator.js"

const AsyncSelect = forwardRef(function AsyncSelect(props, ref) {
  return (
    <ChakraAsyncSelect
      {...props}
      ref={ref}
      components={{
        ...props.components,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DropdownIndicator,
      }}
    />
  )
}) as AsyncSelectComponent
export default AsyncSelect
