import { Icon } from "@chakra-ui/react"
import { components } from "chakra-react-select"
import { ChevronDownIcon } from "../icons/icons.js"

const DropdownIndicator: (typeof components)["DropdownIndicator"] = (props) => (
  // Without forcing isFocused, the dropdown indicator appears grayed out when not focused which is not consistent with our other dropdown styling.
  <components.DropdownIndicator {...props} isFocused>
    <Icon as={ChevronDownIcon} color="black" />
  </components.DropdownIndicator>
)

export default DropdownIndicator
