import type { EmployeeGroup, WorkflowKind } from "@brm/schema-types/types.js"
import { Box, FormControl, FormErrorMessage, FormLabel, HStack, Icon, Input, Text, Tooltip } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { Controller, type Control } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetEmployeeGroupV1Query } from "../../../../app/services/generated-api.js"
import { DatePickerInput } from "../../../../components/DynamicForm/DatePicker.js"
import OrganizationEntityPicker from "../../../../components/Form/OrganizationEntityPicker.js"
import { ChampIcon } from "../../../../components/icons/icons.js"
import MultiSelect from "../../../../components/Select/Select.js"
import { getDefaultDateShortcuts } from "../../../../util/form.js"
import { validateProseString } from "../../../../util/validation.js"
import { displayWorkflowKind } from "../../util.js"
import type { WorkflowSectionFormState } from "./util.js"

export default function WorkflowSection({
  control,
  workflowKind,
  departments,
}: {
  control: Control<WorkflowSectionFormState>
  workflowKind: WorkflowKind | null
  departments: EmployeeGroup[]
}) {
  const intl = useIntl()

  const { data: departmentOptions, isFetching } = useGetEmployeeGroupV1Query({ groupType: "department" })

  const departmentSelectOptions =
    departmentOptions?.map((group) => ({
      label:
        group.display_name ||
        intl.formatMessage({
          id: "department.unknown.label",
          defaultMessage: "Unknown department",
          description: "Label for unknown department",
        }),
      value: group,
    })) ?? []

  const selectedDepartmentsSet = new Set(departments.map((d) => d.id))

  if (!workflowKind) {
    return null
  }

  return (
    <Box>
      <Text>
        <FormattedMessage
          id="requests.start.modal.workflow.header"
          description="Header for the start workflow modal workflow parameters section"
          defaultMessage="2. Provide more information about your <strong>{workflowKind}</strong> request"
          values={{ workflowKind: displayWorkflowKind(workflowKind) }}
        />
      </Text>
      <Controller
        name="display_name"
        control={control}
        rules={{
          required: intl.formatMessage({
            id: "requests.start.modal.name.required",
            description: "Error message for required request name field in the start request modal form",
            defaultMessage: "Request name is required",
          }),
          validate: (value) =>
            validateProseString(
              value,
              intl.formatMessage({
                id: "requests.start.modal.name.validation.proseString",
                defaultMessage: "Request name must contain at least one letter",
                description: "Validation error message if a text fields requires prose",
              })
            ),
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired marginTop={3}>
            <FormLabel>
              <FormattedMessage
                defaultMessage="Request name"
                description="The label for the request name field in the start request modal form"
                id="requests.start.modal.name.label"
              />
            </FormLabel>
            <Input
              value={field.value}
              onChange={field.onChange}
              placeholder={intl.formatMessage(
                {
                  id: "requests.start.modal.name.placeholder",
                  defaultMessage: "Ex. {workflowKind} Nods",
                  description: "Placeholder for request name field in the start request modal form",
                },
                {
                  workflowKind: displayWorkflowKind(workflowKind),
                }
              )}
            />
            {fieldState.error?.message && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
          </FormControl>
        )}
      />
      <Controller
        name="workflow_owner"
        control={control}
        rules={{
          required: intl.formatMessage({
            id: "requests.start.modal.champ.required",
            description: "Error message for required request champ field in the start request modal form",
            defaultMessage: "Champ is required",
          }),
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired marginTop={3}>
            <FormLabel display="flex">
              <HStack>
                <FormattedMessage
                  defaultMessage="Champ"
                  description="The label for the request name field in the start request modal form"
                  id="requests.start.modal.champ.label"
                />
                <Tooltip
                  label={
                    <FormattedMessage
                      id="requests.start.modal.champ.tooltip"
                      description="Tooltip text explaining what role the champ will play in the request, in the request start modal"
                      defaultMessage="The champ will be responsible for driving the request"
                    />
                  }
                  shouldWrapChildren
                >
                  <Icon as={ChampIcon} />
                </Tooltip>
              </HStack>
            </FormLabel>
            <OrganizationEntityPicker
              includedEntities={["user", "person"]}
              onChangeUser={(user) => field.onChange(user)}
              initialSelected={field.value}
              placeholder={intl.formatMessage({
                id: "requests.start.modal.champ.placeholder",
                description: "Placeholder text for request owner aka champ",
                defaultMessage: "The Champ will own the request process",
              })}
              isRequired
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-modal)" }),
              }}
            />
            {fieldState.error?.message && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
          </FormControl>
        )}
      />
      <Controller
        name="target_date"
        control={control}
        rules={{
          required: intl.formatMessage({
            id: "requests.start.modal.complete_by.required",
            description: "Error message for required request complete by field in the start request modal form",
            defaultMessage: "Complete-by date is required",
          }),
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired marginTop={3}>
            <FormLabel>
              <FormattedMessage
                defaultMessage="Complete by"
                description="The label for the complete by field in the start request modal form"
                id="requests.start.modal.complete_by.label"
              />
            </FormLabel>
            <DatePickerInput
              ariaLabel={intl.formatMessage({
                id: "requests.start.modal.complete_by.aria_label",
                description: "Aria label for the complete by field in the start request modal form",
                defaultMessage: "Complete by date",
              })}
              value={field.value}
              onChange={field.onChange}
              dateShortcuts={getDefaultDateShortcuts(intl, {
                sign: 1,
                additionalShortcuts: [
                  {
                    displayName: "In 2 weeks",
                    date: Temporal.PlainDate.from(Temporal.Now.plainDateISO())
                      .add(Temporal.Duration.from({ weeks: 2 }))
                      .toString(),
                  },
                  {
                    displayName: "In 2 months",
                    date: Temporal.PlainDate.from(Temporal.Now.plainDateISO())
                      .add(Temporal.Duration.from({ months: 2 }))
                      .toString(),
                  },
                ],
              })}
              minDate={Temporal.Now.plainDateISO().toString()}
            />
            {fieldState.error?.message && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
          </FormControl>
        )}
      />
      {/* Only render the department picker if the departments query has completed and some department options were returned */}
      {isFetching ||
        (departmentSelectOptions.length > 0 && (
          <Controller
            name="departments"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid} marginTop={3}>
                <FormLabel>
                  <FormattedMessage
                    defaultMessage="Departments"
                    description="The label for the departments field in the start request modal form"
                    id="requests.start.modal.departments.label"
                  />
                </FormLabel>
                <MultiSelect<{ label: string; value: unknown }, true>
                  isMulti={true}
                  closeMenuOnSelect={false}
                  value={departmentSelectOptions.filter((t) => selectedDepartmentsSet.has(t.value.id))}
                  options={departmentSelectOptions}
                  onChange={(e) => field.onChange(e.map((v) => v.value))}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-modal)" }),
                  }}
                />
                {fieldState.error?.message && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
              </FormControl>
            )}
          />
        ))}
    </Box>
  )
}
