import type { WorkflowKind, WorkflowRunStatus } from "@brm/schema-types/types.js"
import { Badge, Icon, Text } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { DotIcon } from "../../../components/icons/icons.js"
import { workflowRunStatusColors } from "./status-colors.js"
import WorkflowKindIcon from "./WorkflowRunKindIcon.js"

export function WorkflowRunStatusBadge({ status, kind }: { status: WorkflowRunStatus; kind: WorkflowKind }) {
  return (
    <Badge variant="subtleOutlined" size="sm" gap={1}>
      <WorkflowKindIcon kind={kind} />
      <Text as="span" isTruncated>
        {kind === "software_purchase" || kind === "software_renewal" ? (
          <FormattedMessage
            id="workflowRun.badge.kind.software"
            description="Label for software type workflow runs in status badge"
            defaultMessage="Software"
          />
        ) : kind === "vendor_purchase" || kind === "vendor_renewal" ? (
          <FormattedMessage
            id="workflowRun.badge.kind.vendor"
            description="Label for vendor/goods & services type workflow runs in status badge"
            defaultMessage="Goods & Services"
          />
        ) : (
          <FormattedMessage
            id="workflowRun.badge.kind.dataGathering"
            description="Label for data gathering type workflow runs in status badge"
            defaultMessage="Data Gathering"
          />
        )}
      </Text>
      <Icon as={DotIcon} color={`${workflowRunStatusColors[status]}.500`} />
    </Badge>
  )
}
