import type {
  CreditCardInterval,
  FieldCategory,
  FrequencyOrOneTime,
  WorkflowKind,
  WorkflowRunStatus,
} from "@brm/schema-types/types.js"
import { FieldCategorySchema, WorkflowKindSchema, WorkflowRunStatusSchema } from "@brm/schemas"
import { estimatedDaysInDuration } from "@brm/util/duration.js"
import { getEnumMemberTitle } from "@brm/util/schema.js"
import { Temporal } from "@js-temporal/polyfill"

export function displayWorkflowKind(kind: WorkflowKind) {
  return getEnumMemberTitle(kind, WorkflowKindSchema)
}

export function displayWorkflowStatus(status: WorkflowRunStatus) {
  return getEnumMemberTitle(status, WorkflowRunStatusSchema)
}

export function displayFieldCategory(category: FieldCategory) {
  return getEnumMemberTitle(category, FieldCategorySchema)
}

const creditCardIntervalMap: Record<FrequencyOrOneTime, CreditCardInterval> = {
  P1M: "monthly",
  P3M: "quarterly",
  P1Y: "yearly",
  P1D: "daily",
  P1W: "weekly",
  P4M: "tertiary",
}

export function convertDurationToCreditCardInterval(frequency: FrequencyOrOneTime): CreditCardInterval | undefined {
  if (frequency === "one_time") {
    return "total"
  }
  const duration = Temporal.Duration.from(frequency)
  const ccInterval = creditCardIntervalMap[duration.toString()]
  if (ccInterval) {
    return ccInterval
  }
  // Find next largest interval
  const intervals = Object.keys(creditCardIntervalMap).map((key) => Temporal.Duration.from(key))
  const sortedIntervals = intervals.sort((a, b) => estimatedDaysInDuration(a) - estimatedDaysInDuration(b))
  const nextLargestInterval = sortedIntervals.find(
    (interval) => estimatedDaysInDuration(interval) > estimatedDaysInDuration(duration)
  )
  if (nextLargestInterval) {
    return creditCardIntervalMap[nextLargestInterval.toString()]
  }
  return undefined
}

export const configurableWorkflowKinds: WorkflowKind[] = [
  "software_purchase",
  "software_renewal",
  "vendor_purchase",
  "vendor_renewal",
]
