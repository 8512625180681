import { Box, Container, Divider, Flex, Heading, Stack, Text, useBreakpointValue } from "@chakra-ui/react"
import { useColorModeValue } from "@chakra-ui/system"
import type { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import BrmLogo from "../../components/BrmLogo.js"
import { Link } from "../../components/Link.js"

export const LoginBaseContainer = ({
  heading,
  subtitleElement,
  children,
  noBox = false,
}: {
  heading: string
  subtitleElement?: ReactNode
  children: ReactNode
  noBox?: boolean
}) => {
  const bgValue = useBreakpointValue({
    base: "transparent",
    sm: "bg-surface",
  })
  const shadowValue = useColorModeValue("md", "md-dark")

  return (
    <Container maxW="lg" py={{ base: "12", md: "24" }} px={{ base: "0", sm: "8" }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Flex justifyContent="center">
            <BrmLogo h="200px" w="200px" />
          </Flex>
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading as="h1" size={useBreakpointValue({ base: "xs", md: "sm" })} userSelect="none">
              {heading}
              {subtitleElement}
            </Heading>
          </Stack>
        </Stack>
        <Box
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          {...(!noBox && {
            bg: bgValue,
            boxShadow: {
              base: "none",
              sm: shadowValue,
            },
            borderRadius: { base: "none", sm: "xl" },
          })}
        >
          <Stack spacing="5">
            {children}
            <Divider />
            <TermsAndConditions />
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export const TermsAndConditions = () => {
  return (
    <Text align="center" fontSize="xs">
      <FormattedMessage
        id="login.agree-to-terms"
        description="Text for specifying that you agree to terms and conditions"
        defaultMessage="By using BRM you agree to our {privacyPolicy} and {termsOfService}."
        values={{
          privacyPolicy: (
            <Text as="u">
              <Link to="https://brm.ai/privacy">
                <FormattedMessage
                  description="privacy policy"
                  id="login.privacy-policy"
                  defaultMessage="privacy policy"
                />
              </Link>
            </Text>
          ),
          termsOfService: (
            <Text as="u">
              <Link to="https://brm.ai/terms">
                <FormattedMessage
                  description="terms of service"
                  id="login.terms-of-service"
                  defaultMessage="terms of service"
                />
              </Link>
            </Text>
          ),
        }}
      />
    </Text>
  )
}
