import { mutableClone } from "@brm/util/mutable.js"
import type { Props as SelectProps } from "chakra-react-select"
import { forwardRef, useMemo, type ForwardRefExoticComponent, type PropsWithoutRef, type RefAttributes } from "react"
import { useIntl } from "react-intl"
import Select from "../../../components/Select/Select.js"
import { dataTypeOptions, getDataTypeLabel } from "./data-types.js"
import type { JSONSchemaObjectOnly } from "./types.js"

const FieldSchemaTypeSelect: ForwardRefExoticComponent<
  PropsWithoutRef<SelectProps<JSONSchemaObjectOnly | undefined, false>> & RefAttributes<HTMLInputElement>
> = forwardRef(function FieldSchemaTypeSelect(props, ref) {
  const intl = useIntl()
  // Clone the global options because react-hook-form mutates state
  const options = useMemo(() => mutableClone(dataTypeOptions), [])
  return (
    <Select<JSONSchemaObjectOnly | undefined>
      options={options}
      getOptionLabel={(option) => getDataTypeLabel(option, intl)}
      isOptionSelected={(option, [selectedOption]) => {
        if (!selectedOption || !option) {
          return false
        }
        return (
          option.type === selectedOption.type &&
          option.$ref === selectedOption.$ref &&
          option.properties?.object_type?.const === selectedOption.properties?.object_type?.const &&
          option.singleLine === selectedOption.singleLine &&
          "anyOf" in option === "anyOf" in selectedOption &&
          (option.items && "anyOf" in option.items) === (selectedOption.items && "anyOf" in selectedOption.items)
        )
      }}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-popover)" }),
      }}
      menuPortalTarget={document.body}
      {...props}
      ref={(selectInstance) => {
        if (typeof ref === "function") {
          ref?.(selectInstance?.inputRef ?? null)
        } else if (ref) {
          ref.current = selectInstance?.inputRef ?? null
        }
      }}
    />
  )
})

export default FieldSchemaTypeSelect
