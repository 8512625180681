import type { FieldConfig } from "@brm/schema-types/types.js"
import { titleToFieldName } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import {
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  IconButton,
  Input,
  List,
  ListItem,
} from "@chakra-ui/react"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import type { KeyboardEvent } from "react"
import { useMemo, type FunctionComponent } from "react"
import { Controller, useFieldArray, type UseFormReturn } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { PlusIcon, XIcon } from "../../../components/icons/icons.js"
import type { CriterionFormState } from "./types.js"

export const EnumOptionEditor: FunctionComponent<{
  form: UseFormReturn<CriterionFormState>
  /** The location of the root schema for the enum (i.e. where the `anyOf` keyword sits) */
  enumRootPath: "dataType" | "dataType.items"

  isReadOnly?: boolean
  savedCriterion?: FieldConfig
}> = ({ form, enumRootPath, isReadOnly, savedCriterion }) => {
  const intl = useIntl()
  const arrayField = useFieldArray({
    name: `${enumRootPath}.anyOf`,
    control: form.control,
  })

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === "Enter") {
      event.preventDefault()
      arrayField.insert(index + 1, { const: "", title: "" })
    }
  }

  const savedEnumOptions: JSONSchemaObject[] = useMemo(() => {
    if (!savedCriterion) return []
    const schema = savedCriterion.field_schema
    if (!isObject(schema)) return []

    // Type guard to check if schema has items property
    const hasItems = (obj: unknown): obj is { items: unknown } =>
      isObject(obj) && "items" in obj && obj.items !== null && typeof obj.items === "object"

    // Type guard to check if schema has anyOf property
    const hasAnyOf = (obj: unknown): obj is { anyOf: unknown[] } =>
      isObject(obj) && "anyOf" in obj && Array.isArray(obj.anyOf)

    // Get enum schemas based on schema structure
    const enumSchemas =
      hasItems(schema) && hasAnyOf(schema.items)
        ? (schema.items.anyOf as JSONSchemaObject[])
        : hasAnyOf(schema)
          ? (schema.anyOf as JSONSchemaObject[])
          : []

    return enumSchemas
  }, [savedCriterion])

  return (
    <Card variant="outline">
      <CardBody>
        <Flex flexDir="column" gap={3}>
          <List display="contents">
            {arrayField.fields.map((enumMemberSchema, index) => (
              <ListItem key={enumMemberSchema.id}>
                <Controller
                  control={form.control}
                  name={`${enumRootPath}.anyOf.${index}.title`}
                  rules={{ required: true }}
                  shouldUnregister
                  render={({ field, fieldState }) => (
                    <FormControl
                      isInvalid={fieldState.invalid}
                      isRequired
                      isReadOnly={
                        savedEnumOptions.some(
                          (option) => option.const === form.getValues(`${enumRootPath}.anyOf.${index}.const`)
                        )
                          ? isReadOnly
                          : false
                      }
                    >
                      <Flex gap={1} alignItems="center">
                        <Input
                          {...field}
                          onChange={({ target: { value } }) => {
                            field.onChange(value)
                            form.setValue(`${enumRootPath}.anyOf.${index}.const`, titleToFieldName(value, intl))
                          }}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          placeholder={intl.formatMessage({
                            defaultMessage: `Option ${index + 1}…`,
                            description: "Placeholder for enum option input field",
                            id: "enumOptionEditor.option.placeholder",
                          })}
                          aria-label={intl.formatMessage({
                            defaultMessage: "Option label",
                            description: "Aria label for enum option input field",
                            id: "enumOptionEditor.option.ariaLabel",
                          })}
                        />
                        {!isReadOnly && (
                          <IconButton
                            icon={<Icon as={XIcon} />}
                            size="sm"
                            variant="ghost"
                            aria-label={intl.formatMessage({
                              defaultMessage: "Remove option",
                              description: "Aria label for remove button of enum option input field",
                              id: "enumOptionEditor.option.remove.ariaLabel",
                            })}
                            onClick={() => arrayField.remove(index)}
                          />
                        )}
                      </Flex>
                      {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
              </ListItem>
            ))}
          </List>
          <Button
            variant="ghost"
            size="sm"
            leftIcon={<Icon as={PlusIcon} />}
            onClick={() => arrayField.append({ const: "", title: "" })}
            alignSelf="start"
          >
            <FormattedMessage
              defaultMessage="Add value"
              description="Add option button label"
              id="enumOptionEditor.button.addValue"
            />
          </Button>
        </Flex>
      </CardBody>
    </Card>
  )
}
