// Polyfills
import "core-js/modules/web.url.can-parse.js" // Stable since Chrome 120, can be removed in a couple of months
import "core-js/proposals/array-grouping-v2.js" // Remove once Ghost inspector supports Chrome v117+ https://docs.ghostinspector.com/changelog/
import "core-js/proposals/set-methods-v2" // Remove once Ghost inspector supports Chrome v122+ https://docs.ghostinspector.com/changelog/

import "./default-props.js"

import { chakra } from "@chakra-ui/react"
import createEmotionCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import "@fontsource-variable/inter"
import * as Sentry from "@sentry/react"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import React, { useEffect } from "react"
import { I18nProvider } from "react-aria-components"
import ReactDOM from "react-dom/client"
import { IntlProvider } from "react-intl"
import { Provider } from "react-redux"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"
import App from "./App.js"
import { store } from "./app/store.js"
import UnexpectedError from "./features/error/UnexpectedError.js"

if (import.meta.env.VITE_POSTHOG_KEY) {
  posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: "https://ph.brmapp.net",
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
  })
}

const initializeApp = async () => {
  Sentry.init({
    enabled: !!import.meta.env.VITE_SENTRY_DSN,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    normalizeDepth: 10,
    tracesSampleRate: 0.2,
    release: import.meta.env.VITE_RELEASE_SHA,
    environment: import.meta.env.VITE_BRM_ENV,
    ignoreErrors: ["Object captured as exception", "Object captured as promise rejection with keys"],
  })

  const emotionCache = createEmotionCache({
    // Override to not include the prefixer plugin by default, which is not needed in modern browsers, bloats the
    // bundle size and makes CSS harder to debug with the browser dev tools.
    stylisPlugins: [],
    key: "css",
  })

  // We currently only have an English translation, but we can keep the region to support localized date/number/etc formatting
  const locale = new Intl.Locale(navigator.language, { language: "en" })

  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <Sentry.ErrorBoundary showDialog fallback={(error) => <UnexpectedError error={error.error} />}>
        <Provider store={store}>
          <IntlProvider
            locale={locale.toString()}
            defaultLocale={locale.toString()}
            defaultRichTextElements={{
              br: () => <br />,
              strong: (content) => <strong>{content}</strong>,
              em: (content) => <em>{content}</em>,
              kbd: (content) => <kbd>{content}</kbd>,
              lowercase: (content) => <chakra.span textTransform="lowercase">{content}</chakra.span>,
              capitalize: (content) => <chakra.span textTransform="capitalize">{content}</chakra.span>,
            }}
          >
            <I18nProvider locale={locale.toString()}>
              <CacheProvider value={emotionCache}>
                <PostHogProvider client={posthog}>
                  <App />
                </PostHogProvider>
              </CacheProvider>
            </I18nProvider>
          </IntlProvider>
        </Provider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  )
}

await initializeApp()
