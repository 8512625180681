import { Box, Button, Checkbox, HStack, Icon, Text } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { useCallback, useEffect, useState, type FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import CalendarDatePickerModal from "../CalendarDatePickerModal/CalendarDatePickerModal.js"
import { FormattedDate } from "../FormattedDate.js"
import { CalendarIcon } from "../icons/icons.js"

export interface MentionFromRichText {
  display_name: string
  user_id: string
  organization_id: string
}

const AtMentionTaskArea: FC<{
  mentions: MentionFromRichText[]
  setMentionsToCreateTasksFor: (mentions: Map<string, string>) => void
}> = ({ mentions, setMentionsToCreateTasksFor }) => {
  const [mentionsChecked, setMentionsChecked] = useState<Map<string, string>>(new Map())

  useEffect(() => {
    setMentionsToCreateTasksFor(mentionsChecked)
  }, [mentionsChecked, setMentionsToCreateTasksFor])

  const onAtMentionRowChanged = useCallback(
    (user_id: string, enabled: boolean, date: string) => {
      if (enabled) {
        const newMap = new Map(mentionsChecked)
        newMap.set(user_id, date)
        setMentionsChecked(newMap)
      } else if (mentionsChecked.has(user_id)) {
        const newMap = new Map(mentionsChecked)
        newMap.delete(user_id)
        setMentionsChecked(newMap)
      }
    },
    [mentionsChecked]
  )

  return (
    <Box>
      {mentions.map((mention) => (
        <AtMentionRow key={mention.user_id} mention={mention} onChange={onAtMentionRowChanged} />
      ))}
    </Box>
  )
}

export default AtMentionTaskArea

const AtMentionRow: FC<{
  mention: MentionFromRichText
  onChange: (user_id: string, enabled: boolean, date: string) => void
}> = ({ mention, onChange }) => {
  const intl = useIntl()
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false)
  const tomorrow = Temporal.Now.plainDateISO().add({ days: 1 }).toString()
  const [date, setDate] = useState<string>(tomorrow)
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(event.target.checked)
      onChange(mention.user_id, event.target.checked, date)
    },
    [mention.user_id, onChange, date]
  )

  const handleCalendarClick = useCallback(() => {
    setIsCalendarModalOpen(true)
  }, [])

  return (
    <HStack width="100%" py={2} px={0} gap={1} justifyContent="space-between">
      <CalendarDatePickerModal
        header={intl.formatMessage({
          id: "atMentionTask.dateModal.header",
          defaultMessage: "Reply by?",
          description: "At mention task area date modal header",
        })}
        isOpen={isCalendarModalOpen}
        onClose={() => setIsCalendarModalOpen(false)}
        onSubmit={(newDate) => {
          setDate(newDate)
          setIsChecked(true)
          setIsCalendarModalOpen(false)
          onChange(mention.user_id, true, newDate)
        }}
        initialDate={date}
      />
      <HStack gap={1} flex="0 1 auto">
        <Checkbox isChecked={isChecked} onChange={handleCheckboxChange} />
        <Text fontSize="xs">
          <FormattedMessage
            id="at-mention-task-area.create-task-for-user-to-reply"
            description="Task for user to reply"
            defaultMessage="Create a task for {name} to reply by"
            values={{
              name: mention.display_name,
            }}
          />
        </Text>
      </HStack>
      <Button variant="unstyled" onClick={handleCalendarClick} overflow="hidden" flex="1 0 auto" maxWidth="fit-content">
        <HStack color="gray.600" gap={1}>
          <Icon as={CalendarIcon} boxSize={3.5} />
          <Text fontSize="xs" whiteSpace="nowrap" fontWeight="normal">
            <FormattedDate value={date} year={undefined} />
          </Text>
        </HStack>
      </Button>
    </HStack>
  )
}
