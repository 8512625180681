import type {
  DateString,
  FieldMetadata,
  FieldMetadataWithSuggestions,
  FieldSourceOutputProperties,
} from "@brm/schema-types/types.js"
import { isValidPlainDate } from "@brm/util/date-time.js"
import {
  Button,
  Icon,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useDisclosure,
  useStyleConfig,
} from "@chakra-ui/react"
import { parseDate } from "@internationalized/date"
import type { Temporal } from "@js-temporal/polyfill"
import type { InputProps } from "chakra-react-select"
import type { Ref } from "react"
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react"
import { DateField, DateInput, DateSegment } from "react-aria-components"
import { FormattedMessage, useIntl } from "react-intl"
import { getDefaultDateShortcuts, isNewOption, type DateShortcut } from "../../util/form.js"
import CalendarWithDatePanel from "../Calendar/CalendarWithDatePanel.js"
import { FormattedDate } from "../FormattedDate.js"
import { CheckIcon, ChevronDownIcon } from "../icons/icons.js"
import OptionWithFieldSource from "./OptionWithFieldSource.js"
import type { DynamicFormFieldApproval, ValueWithSource } from "./types.js"

interface DatePickerInputProps {
  value: DateString | null
  onChange: (value: DateString | null, fieldSource?: FieldMetadata) => void
  isReadOnly?: boolean
  isInvalid?: boolean
  suggestions?: ValueWithSource<Temporal.PlainDate>[]
  isDisabled?: boolean
  ariaLabel: InputProps["aria-label"]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: DynamicFormFieldApproval
  /** Dates before this value will be disabled */
  minDate?: DateString
  /** Dates after this value will be disabled */
  maxDate?: DateString
  /**
   * Date shortcuts to render in the calendar. If not provided, will show default shortcuts anchored off of today's date.
   */
  dateShortcuts?: DateShortcut[]
}

// Just a placeholder option to open up the calendar
interface CustomDateOption {
  isCustomOption: boolean
}

const isCustomOption = (
  option: ValueWithSource<Temporal.PlainDate | undefined> | CustomDateOption
): option is CustomDateOption => "isCustomOption" in option

const Option = ({
  option,
  onClick,
  isSelected,
  fieldMetadata,
  fieldApproval,
}: {
  option: ValueWithSource<Temporal.PlainDate | undefined> | CustomDateOption | null
  onClick?: () => void
  isSelected: boolean
  fieldMetadata?: FieldMetadata
  fieldApproval?: DynamicFormFieldApproval
}) => {
  let contents
  if (option === null) {
    contents = null
  } else if (isCustomOption(option)) {
    contents = (
      <Text flex={1}>
        <FormattedMessage
          defaultMessage="Custom Date"
          description="The label for a custom date option in the date picker"
          id="datePicker.option.customDate"
        />
      </Text>
    )
  } else {
    const fieldSources = option?.field_sources as FieldSourceOutputProperties[] | undefined
    if (!option.value) {
      return null
    }
    contents = (
      <>
        <OptionWithFieldSource fieldSources={fieldSources}>
          <FormattedDate value={option.value.toString()} month="numeric" />
        </OptionWithFieldSource>
        {isSelected && <Icon as={CheckIcon} boxSize={3} />}
      </>
    )
  }
  const newOption =
    option !== null && !isCustomOption(option)
      ? isNewOption(option.field_sources, fieldMetadata, fieldApproval)
      : undefined
  return (
    <Button
      backgroundColor="white"
      _hover={{
        backgroundColor: "gray.50",
      }}
      _active={{
        backgroundColor: "gray.200",
      }}
      _focusVisible={{
        boxShadow: "none",
        backgroundColor: "gray.100",
      }}
      textAlign="left"
      fontWeight="regular"
      display="flex"
      onClick={onClick}
      role="option"
      alignItems="center"
      borderRadius={0}
      {...(newOption?.isNew && { backgroundColor: `${newOption.colorScheme}.50` })}
    >
      {contents}
    </Button>
  )
}

/**
 * A date input with accompanying popover. The popover can either show a calendar for selecting any date, or show a list of suggestions as a dropdown menu.
 */
export const DatePickerInput = forwardRef(function DatePickerInput(
  props: DatePickerInputProps,
  ref: Ref<HTMLButtonElement | null>
) {
  const {
    onChange,
    value,
    isReadOnly,
    isInvalid,
    isDisabled,
    suggestions,
    ariaLabel,
    fieldMetadata,
    fieldApproval,
    minDate,
    maxDate,
    dateShortcuts,
  } = props
  const intl = useIntl()
  // Controls whether or the popover is open
  const { isOpen, onOpen, onClose } = useDisclosure()
  // Whether or not the custom date option is toggled. If this is true, the popover will show the calendar view regardless of whether there are suggestions.
  const [customDateOptionToggled, setCustomDateOptionToggled] = useState(false)
  const inputRef = useRef<HTMLButtonElement>(null)
  const optionsRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => inputRef.current)
  const options = useMemo(
    () => [null, ...(suggestions || []), { isCustomOption: true } satisfies CustomDateOption],
    [suggestions]
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputStyleConfig = useStyleConfig("Input") as any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menuStyleConfig = useStyleConfig("Menu") as any

  // Whether or not the calendar should be rendered. If the custom date option is toggled, or there are no suggestions, the calendar will be rendered.
  const shouldRenderCalendar = customDateOptionToggled || !suggestions || suggestions.length === 0
  const closeAll = useCallback(() => {
    onClose()
    setCustomDateOptionToggled(false)
    inputRef.current?.blur()
  }, [onClose, setCustomDateOptionToggled])

  const shortcuts = useMemo(() => dateShortcuts ?? getDefaultDateShortcuts(intl), [dateShortcuts, intl])
  return (
    <Popover onClose={closeAll} isOpen={!isReadOnly && isOpen} closeOnBlur={true} matchWidth={true} autoFocus={false}>
      <PopoverTrigger>
        <Button
          ref={inputRef}
          isDisabled={isDisabled}
          onClick={onOpen}
          isActive={!isReadOnly}
          sx={{
            ...inputStyleConfig.field,
            justifyContent: "start",
            cursor: "auto",
            fontWeight: "regular",
            background: 0,
            _active: {
              background: 0,
              ...(isInvalid && {
                ...inputStyleConfig.field._invalid,
              }),
            },
            _hover: { borderColor: 0 },
            ...(isOpen && inputStyleConfig.field._focus),
            ...(isReadOnly && inputStyleConfig.field._readOnly),
            ...(isInvalid && {
              ...inputStyleConfig.field._invalid,
              boxShadow: "0 0 0 3px var(--chakra-colors-error-100)",
            }),
          }}
          onBlur={(e) => {
            if (!optionsRef.current?.contains(e.relatedTarget as Node)) {
              closeAll()
            }
          }}
        >
          <DateField
            isReadOnly={isReadOnly}
            value={value ? parseDate(value?.toString()) : null}
            aria-label={ariaLabel}
            shouldForceLeadingZeros={true}
            onChange={(v) => {
              const newDate = v?.toString()
              if (newDate && isValidPlainDate(newDate)) {
                const matchingSuggestion = suggestions?.find((suggestion) => suggestion.value.toString() === newDate)
                onChange?.(newDate, matchingSuggestion?.field_sources?.[0])
              }
            }}
            isInvalid={isInvalid}
            onFocus={onOpen}
            onBlur={(e) => {
              if (!optionsRef.current?.contains(e.relatedTarget as Node)) {
                closeAll()
              }
            }}
          >
            <DateInput style={{ display: "flex" }}>{(segment) => <DateSegment segment={segment} />}</DateInput>
          </DateField>
          <Icon as={ChevronDownIcon} marginRight={2} marginLeft={1} position="absolute" right={0} />
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          width="fit-content"
          sx={{ ...menuStyleConfig.list, borderRadius: "lg" }}
          ref={optionsRef}
          maxW={shouldRenderCalendar ? "500px" : undefined}
        >
          <PopoverBody padding={0}>
            {shouldRenderCalendar ? (
              <CalendarWithDatePanel
                value={value}
                suggestions={suggestions}
                dateShortcuts={shortcuts}
                minDate={minDate}
                maxDate={maxDate}
                onChange={onChange}
                onClose={() => {
                  onClose()
                  setCustomDateOptionToggled(false)
                }}
                onCleared={() => {
                  onClose()
                  setCustomDateOptionToggled(false)
                  onChange(null)
                }}
                isDismissible={true}
              />
            ) : (
              <Stack gap={0}>
                {options.map((option, i) => (
                  <Option
                    fieldApproval={fieldApproval}
                    fieldMetadata={fieldMetadata}
                    option={option}
                    key={i}
                    isSelected={
                      option === null
                        ? value === null
                        : !isCustomOption(option) && option?.value && value === option.value.toString()
                    }
                    onClick={() => {
                      if (option === null) {
                        onChange(null)
                        onClose()
                        setCustomDateOptionToggled(false)
                      } else if (!isCustomOption(option)) {
                        onChange(option.value.toString(), option.field_sources?.[0])
                        onClose()
                        setCustomDateOptionToggled(false)
                      } else {
                        setCustomDateOptionToggled(true)
                      }
                    }}
                  />
                ))}
              </Stack>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
})
