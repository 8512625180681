import type { CreditCardListItem } from "@brm/schema-types/types.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { shortenUUID } from "@brm/util/short-uuids.js"
import { Box, Center, Heading, HStack, Icon, Stack, Text } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import { usePostCreditCardV1ListQuery, type PostCreditCardV1ListApiArg } from "../app/services/generated-api.js"
import {
  packageSortFilterOptionsForAPI,
  shownColumnsForTableParamState,
  TABLE_DEFAULT_PARAMS,
  type TableParamsState,
} from "../util/schema-table.js"
import { useObjectSchema } from "../util/use-schema.js"
import { CREDIT_CARDS_TABLE_ID } from "./constants.js"
import { CreditCard } from "./CreditCard.js"
import TablePageHeader from "./DataTable/SchemaFilter/TablePageHeader.js"
import { CreditCardIcon } from "./icons/icons.js"

const defaultColumns: BRMPaths<CreditCardListItem>[] = ["display_name", "last_four"]

export default function CardList(
  props: Pick<PostCreditCardV1ListApiArg, "toolId" | "vendorId"> & {
    filterTransactions: (cardId: string) => void
  }
) {
  const intl = useIntl()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const creditCardSchema = useObjectSchema("CreditCard")
  const [cardToLoad, setCardToLoad] = useState<string | undefined>(undefined)

  const [tableParams, updateTableParams] = useState<TableParamsState<string>>({
    ...TABLE_DEFAULT_PARAMS,
    sorting: [{ id: "display_name", desc: true }],
    filterMap: new Map([
      [
        "status",
        {
          column: "status",
          fields: {
            comparator: "any",
            values: ["active"],
            includeNull: false,
          },
        },
      ],
    ]),
  })
  const shownColumns = useMemo(
    () => tableParams && shownColumnsForTableParamState(tableParams, defaultColumns),
    [tableParams]
  )

  const apiParams = useMemo(
    () =>
      creditCardSchema && tableParams && packageSortFilterOptionsForAPI<string>(tableParams, creditCardSchema, intl),
    [intl, tableParams, creditCardSchema]
  )

  const { data, isFetching } = usePostCreditCardV1ListQuery(
    apiParams
      ? {
          toolId: props.toolId,
          vendorId: props.vendorId,
          listQueryStringParams: apiParams,
        }
      : skipToken
  )

  if (!tableParams || !creditCardSchema || !shownColumns) {
    return null
  }

  return (
    <Stack width="full" gap={4}>
      {data ? (
        <TablePageHeader
          title={intl.formatMessage(
            {
              id: "creditCard.tab.heading.withCount",
              description: "Heading for credit cards overview section",
              defaultMessage: "Credit Cards ({total})",
            },
            { total: data.total }
          )}
          tableId={CREDIT_CARDS_TABLE_ID}
          filterMap={tableParams.filterMap}
          onChangeFilters={(filterMap) => updateTableParams({ ...tableParams, filterMap })}
          objectSchema={creditCardSchema}
          selectedColumns={shownColumns}
        />
      ) : (
        <Heading size="xs">
          <FormattedMessage
            id="transaction.tab.heading"
            description="Heading for credit cards overview section"
            defaultMessage="Credit Cards"
          />
        </Heading>
      )}
      <HStack overflowX="auto" padding={2}>
        {data?.cards.map((card) => (
          <CreditCard
            key={card.id}
            {...card}
            filterTransactions={(cardId) => props.filterTransactions(cardId)}
            selected={
              queryParams.get("card.id")?.includes(card.id) ||
              queryParams.get("card.id")?.includes(shortenUUID(card.id))
            }
            // Only show loading state if the card is being locked or unlocked
            isFetching={isFetching && cardToLoad === card.id}
            onLock={() => setCardToLoad(card.id)}
            onUnlock={() => setCardToLoad(card.id)}
          />
        ))}
      </HStack>
      {!isFetching && (data?.cards.length === 0 || !data) && (
        <Center>
          <Stack justifyContent="center" alignItems="center" gap={3} maxW="350px" textAlign="center">
            <Box border="1px solid" borderColor="gray.200" borderRadius="lg" padding={3} boxShadow="xs">
              <Icon as={CreditCardIcon} boxSize={6} />
            </Box>
            <Text fontWeight="semibold" fontSize="md">
              <FormattedMessage
                id="cardList.emptyState.title"
                description="Title text for empty state on credit cards table"
                defaultMessage="No credit cards found"
              />
            </Text>
            <Text fontSize="md" color="gray.600">
              <FormattedMessage
                id="cardList.emptyState.description"
                description="Description text for empty state on credit cards table"
                defaultMessage="Issued credit cards from requests will appear here for tracking and management."
              />
            </Text>
          </Stack>
        </Center>
      )}
    </Stack>
  )
}
