import { Checkbox, Flex, FormControl, FormErrorMessage, Icon, Tooltip } from "@chakra-ui/react"
import type { ReactNode } from "react"
import { HelpIcon } from "../icons/icons.js"

interface CheckboxWithHelpTooltipProps {
  // Form control props
  isDisabled?: boolean
  isInvalid?: boolean
  errorMessage?: string

  // Checkbox props
  isChecked?: boolean
  onChange: (value: boolean) => void
  inputRef?: React.Ref<HTMLInputElement>

  // Label props
  label: string
  tooltip: string
}

export const CheckboxWithHelpTooltip = ({
  isDisabled = false,
  isInvalid = false,
  errorMessage,
  isChecked,
  onChange,
  inputRef,
  label,
  tooltip,
}: CheckboxWithHelpTooltipProps): ReactNode => {
  return (
    <FormControl isInvalid={isInvalid} isDisabled={isDisabled}>
      <Flex alignItems="center" gap={2}>
        <Checkbox ref={inputRef} isChecked={isChecked} onChange={(e) => onChange(e.target.checked)}>
          {label}
        </Checkbox>
        <Tooltip label={tooltip} shouldWrapChildren>
          <Icon as={HelpIcon} />
        </Tooltip>
      </Flex>
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  )
}
