import type { WorkflowDef } from "@brm/schema-types/types.js"
import { formatDate } from "@brm/util/format-date-time.js"
import { displayPersonName } from "@brm/util/names.js"
import { Button, Divider, HStack, Icon, Stack, Text } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetWorkflowV1DefinitionsQuery } from "../../../app/services/generated-api.js"
import CardWithHoverActions from "../../../components/CardWithHoverActions.js"
import { NextIcon, PlusIcon } from "../../../components/icons/icons.js"
import { Link } from "../../../components/Link.js"
import { SettingsContainer } from "../../settings/SettingsContainer.js"
import { SettingsHeader } from "../../settings/SettingsHeader.js"
import WorkflowKindIcon from "../run/WorkflowRunKindIcon.js"
import { configurableWorkflowKinds, displayWorkflowKind } from "../util.js"

export default function WorkflowDefinitionList() {
  const intl = useIntl()
  const { data: workflowDefinitions } = useGetWorkflowV1DefinitionsQuery()
  if (!workflowDefinitions) {
    return null
  }

  const defsByKind = Object.groupBy(workflowDefinitions, (def) => def.kind)

  return (
    <SettingsContainer>
      <SettingsHeader
        title={intl.formatMessage({
          defaultMessage: "Requests",
          description: "Requests configuration page heading",
          id: "requests.definitions.heading",
        })}
      />
      <Divider />
      <Stack mt={4} gap={5}>
        {configurableWorkflowKinds.map((kind) => (
          <Stack alignItems="flex-start" key={kind}>
            {defsByKind[kind] ? (
              <Stack>
                {defsByKind[kind].map((def) => (
                  <WorkflowDefinitionCard key={def.id} workflowDef={def} />
                ))}
              </Stack>
            ) : (
              <Button
                as={Link}
                to={`/requests/definitions/create/${kind}`}
                variant="ghost"
                leftIcon={<Icon as={PlusIcon} />}
              >
                <FormattedMessage
                  defaultMessage="New {kind} Request"
                  description="Requests configuration page button to create a new purchase request"
                  id="requests.definitions.button.newPurchaseRequest"
                  values={{ kind: displayWorkflowKind(kind) }}
                />
              </Button>
            )}
          </Stack>
        ))}
      </Stack>
    </SettingsContainer>
  )
}

function WorkflowDefinitionCard({ workflowDef }: { workflowDef: WorkflowDef }) {
  const intl = useIntl()
  return (
    <Link to={`/requests/definitions/${workflowDef.id}`} variant="noUnderline">
      <CardWithHoverActions cardBodyProps={{ px: 3 }}>
        <HStack justifyContent="space-between" width={645}>
          <HStack gap={4}>
            <Stack textDecoration="none">
              <HStack>
                <WorkflowKindIcon kind={workflowDef.kind} />
                <Text fontWeight="semibold">{displayWorkflowKind(workflowDef.kind)}</Text>
              </HStack>
              <Text>
                <FormattedMessage
                  id="requests.definition.lastUpdatedBy"
                  description="Text on the workflow definition display card that describes when this definition was last updated and by who"
                  defaultMessage="Last updated by {user} on {date}"
                  values={{
                    user: displayPersonName(workflowDef.last_updated_by, intl),
                    date: formatDate(intl, workflowDef.updated_at),
                  }}
                />
              </Text>
            </Stack>
          </HStack>
          <Icon as={NextIcon} />
        </HStack>
      </CardWithHoverActions>
    </Link>
  )
}
