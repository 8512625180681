import { Temporal } from "@js-temporal/polyfill"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  useGetNotificationV1Query,
  useGetUserV1WhoamiQuery,
  usePutNotificationV1BulkMutation,
  usePutNotificationV1ByIdMutation,
  type GetNotificationV1ApiArg,
  type InboxNotification,
} from "../../app/services/generated-api.js"
import { getLocalStorageKeyForUser, LocalStorageKeys } from "../../util/local-storage.js"
import { log } from "../../util/logger.js"
import { getNotificationSrcUrl } from "./notification/helpers.js"

interface UseInboxNotificationsResult {
  inboxNotifications: InboxNotification[]

  // pagination
  loadingMoreNotifications: boolean
  hasMoreNotifications: boolean
  loadMoreDisabled: boolean
  loadMore: () => void

  // filtering
  filterByUnread: boolean
  filterByMentioned: boolean
  toggleFilterByUnread: () => void
  toggleFilterByMentioned: () => void

  // selection
  selectedNotificationId: string | undefined
  selectNotification: (notification: InboxNotification) => void

  // notification item mutations
  toggleRead: (notification: InboxNotification) => void
  deleteNotification: (notification: InboxNotification) => void

  // bulk inbox mutations
  markAllAsRead: () => void
  markAllAsUnread: () => void
  deleteRead: () => void
  deleteAll: () => void
}

const GET_NOTIFICATIONS_LIMIT = 100

/**
 * This custom hook surfaces the Displayed Inbox Notifications and its associated interfaces like
 * pagination support
 * filtering
 * selection
 * notification item mutation
 *
 * We have 3 inbox notification states.
 * 1. backend source of truth
 * 2. client side mirror of backend source of truth
 * 3. displayed list inbox notifications
 *
 * Original Reason to maintain 2 frontend stores:
 * Read At exists as a Frontend State which can deviate from backend source of truth.
 */
export const useInboxNotifications = (): UseInboxNotificationsResult => {
  const { data: whoami } = useGetUserV1WhoamiQuery()

  // core frontend state. maintaining all and displayed allows for independent frontend state
  const [allNotifications, setAllNotifications] = useState<InboxNotification[]>([])
  const [displayedNotifications, setDisplayedNotifications] = useState<InboxNotification[]>([])

  // filter state. maintaining in local storage
  const UNREAD_FILTER_KEY = getLocalStorageKeyForUser(LocalStorageKeys.UNREAD_FILTER_STORAGE_KEY, whoami?.id || "")
  const MENTIONED_FILTER_KEY = getLocalStorageKeyForUser(
    LocalStorageKeys.MENTIONED_FILTER_STORAGE_KEY,
    whoami?.id || ""
  )
  const [filterByUnread, setFilterByUnread] = useState(localStorage.getItem(UNREAD_FILTER_KEY) === "true")
  const [filterByMentioned, setFilterByMentioned] = useState(localStorage.getItem(MENTIONED_FILTER_KEY) === "true")

  // selected notification. Initialized from url params (only expected to happen on refresh)
  const { notificationId } = useParams()
  const [selectedNotificationId, setSelectedNotificationId] = useState<string | undefined>(notificationId)

  // state to manage pagination.
  const [getNotificationsParams, setGetNotificationsParams] = useState<GetNotificationV1ApiArg>({
    limit: GET_NOTIFICATIONS_LIMIT,
    filterByUnread,
    filterByMentioned,
  })
  const [hasMoreNotifications, setHasMoreNotifications] = useState<boolean>(true)

  // ~~~~~~~~~~~~~~~~~~~ build frontend representation of SOT through paginated queries ~~~~~~~~~~~~~~~~~~~

  const inboxNotificationsQueryResult = useGetNotificationV1Query(getNotificationsParams)

  const loadMore = () => {
    setGetNotificationsParams({
      until: allNotifications.at(-1)?.created_at,
      limit: GET_NOTIFICATIONS_LIMIT,
      filterByUnread,
      filterByMentioned,
    })
  }

  // maintains frontend representation of all notifications with paginated queries
  useEffect(() => {
    // on data change, add the notifications to the allNotifications state
    setAllNotifications((current) => {
      const mergedNotifications = mergeNotifications({
        currentNotifications: current,
        newNotifications: inboxNotificationsQueryResult.data?.items ?? [],
      })
      // if the next page to get falls within existing notifications, get the next page
      // imagine frontend has filtered notifications 1, 5, 10, 15, 20.
      //We remove filters and backend gets 1, 2, 3, 4, 5.
      // We want to fill gaps, so we continue fetching until we get to 20.
      if (mergedNotifications.at(-1)?.id !== inboxNotificationsQueryResult.data?.items.at(-1)?.id) {
        setGetNotificationsParams({
          until: inboxNotificationsQueryResult.data?.nextPageParams?.until,
          limit: GET_NOTIFICATIONS_LIMIT,
        })
      } else {
        setHasMoreNotifications(!!inboxNotificationsQueryResult.data?.nextPageParams)
      }

      return mergedNotifications
    })
  }, [inboxNotificationsQueryResult.data])

  // on data change, merge the filtered notifications into displayedNotifications
  useEffect(() => {
    setDisplayedNotifications((current) =>
      mergeNotifications({
        currentNotifications: current,
        newNotifications: filterNotifications({
          notifications: allNotifications,
          filterByUnread,
          filterByMentioned,
        }),
      })
    )
  }, [allNotifications, filterByUnread, filterByMentioned])

  // ~~~~~~~~~~~~~~~~~~~  Filtering ~~~~~~~~~~~~~~~~~~~

  // 1. update filter
  // 2. update displayed notifications according to filter (this might miss some notifications)
  // 3. refetch notifications starting from the first in order to backfill missing notifications
  const toggleFilter = (
    filterType: "unread" | "mentioned",
    currentValue: boolean,
    setFilter: (value: boolean) => void,
    storageKey: string
  ) => {
    const newFilterValue = !currentValue
    setFilter(newFilterValue)
    localStorage.setItem(storageKey, newFilterValue.toString())

    const filteredNotifications = filterNotifications({
      notifications: allNotifications,
      filterByUnread: filterType === "unread" ? newFilterValue : filterByUnread,
      filterByMentioned: filterType === "mentioned" ? newFilterValue : filterByMentioned,
    })
    setDisplayedNotifications(filteredNotifications)
    if (
      selectedNotificationId &&
      filteredNotifications[0] &&
      !filteredNotifications.some((n) => n.id === selectedNotificationId)
    ) {
      selectNotification(filteredNotifications[0])
    }

    // When we toggle a filter, we need to refetch notifications starting from the first in order to backfill gaps
    setGetNotificationsParams({
      limit: GET_NOTIFICATIONS_LIMIT,
      filterByUnread: filterType === "unread" ? newFilterValue : filterByUnread,
      filterByMentioned: filterType === "mentioned" ? newFilterValue : filterByMentioned,
    })
  }

  const toggleFilterByUnread = () => {
    toggleFilter("unread", filterByUnread, setFilterByUnread, UNREAD_FILTER_KEY)
  }

  const toggleFilterByMentioned = () => {
    toggleFilter("mentioned", filterByMentioned, setFilterByMentioned, MENTIONED_FILTER_KEY)
  }

  // ~~~~~~~~~~~~~~~~~~~  Selection ~~~~~~~~~~~~~~~~~~~

  const [updateNotification] = usePutNotificationV1ByIdMutation()
  // optimistic update read_at
  const updateReadAt = useCallback(
    (notificationId: string, readAt: string | null) => {
      setDisplayedNotifications((current) =>
        current.map((n) => (n.id === notificationId ? { ...n, read_at: readAt } : n))
      )
      setAllNotifications((current) => current.map((n) => (n.id === notificationId ? { ...n, read_at: readAt } : n)))

      updateNotification({
        id: notificationId,
        notificationUpdate: {
          read_at: readAt,
        },
      }).catch((err) => {
        log.error(`Failed to toggle read status of notification ${notificationId}`, err)
      })
    },
    [updateNotification]
  )

  // TODO: there's definitely a better way to view a notification than navigating to a url
  const navigate = useNavigate()

  /**
   * Navigates to the notification and marks it as read
   * Optimistically updates frontend state
   * @param notification The notification to navigate to
   */
  const navigateToNotification = useCallback(
    (notification: InboxNotification) => {
      navigate(getNotificationSrcUrl(notification, whoami?.organization_id ?? ""))

      if (!notification.read_at) {
        updateReadAt(notification.id, Temporal.Now.instant().toString())
      }
    },
    [navigate, updateReadAt, whoami?.organization_id]
  )

  const selectNotification = useCallback(
    (notification: InboxNotification) => {
      setSelectedNotificationId(notification.id)
      navigateToNotification(notification)
    },
    [navigateToNotification]
  )

  // ~~~~~~~~~~~~~~~~~~~  Notification Mutations ~~~~~~~~~~~~~~~~~~~

  // Single notification actions
  const toggleRead = useCallback(
    (notification: InboxNotification) => {
      const updatedReadAt = notification.read_at ? null : Temporal.Now.instant().toString()
      updateReadAt(notification.id, updatedReadAt)
    },
    [updateReadAt]
  )

  const deleteNotification = useCallback(
    (notification: InboxNotification) => {
      setDisplayedNotifications((current) => current.filter((n) => n.id !== notification.id))
      setAllNotifications((current) => current.filter((n) => n.id !== notification.id))

      updateNotification({
        id: notification.id,
        notificationUpdate: {
          archived_at: Temporal.Now.instant().toString(),
        },
      }).catch((err) => {
        log.error(`Failed to delete notification ${notification.id}`, err)
      })
    },
    [updateNotification, setDisplayedNotifications]
  )

  // Bulk inbox actions. THESE APPLY TO ALL NOTIFICATIONS, NOT JUST THE DISPLAYED NOTIFICATIONS OR FILTERED NOTIFICATIONS
  const [bulkUpdateNotifications] = usePutNotificationV1BulkMutation()

  const markAllAsRead = useCallback(() => {
    void bulkUpdateNotifications({ notificationBulkActionBody: { action: "mark_all_read" } })
    const updatedNotifications = allNotifications.map((n) => ({ ...n, read_at: Temporal.Now.instant().toString() }))
    setAllNotifications(updatedNotifications)
    setDisplayedNotifications(
      filterNotifications({ notifications: updatedNotifications, filterByUnread, filterByMentioned })
    )
  }, [allNotifications, bulkUpdateNotifications, filterByMentioned, filterByUnread])

  const markAllAsUnread = useCallback(() => {
    void bulkUpdateNotifications({ notificationBulkActionBody: { action: "mark_all_unread" } })
    const updatedNotifications = allNotifications.map((n) => ({ ...n, read_at: null }))
    setAllNotifications(updatedNotifications)
    setDisplayedNotifications(
      filterNotifications({ notifications: updatedNotifications, filterByUnread, filterByMentioned })
    )
  }, [allNotifications, bulkUpdateNotifications, filterByMentioned, filterByUnread])

  const deleteRead = useCallback(() => {
    void bulkUpdateNotifications({ notificationBulkActionBody: { action: "delete_read" } })
    const remainingNotifications = allNotifications.filter((n) => !n.read_at)
    setAllNotifications(remainingNotifications)
    setDisplayedNotifications(
      filterNotifications({ notifications: remainingNotifications, filterByUnread, filterByMentioned })
    )
  }, [allNotifications, bulkUpdateNotifications, filterByMentioned, filterByUnread])

  const deleteAll = useCallback(() => {
    void bulkUpdateNotifications({ notificationBulkActionBody: { action: "delete_all" } })
    setAllNotifications([])
    setDisplayedNotifications([])
    setHasMoreNotifications(false)
  }, [bulkUpdateNotifications])

  return {
    inboxNotifications: displayedNotifications,
    loadingMoreNotifications: inboxNotificationsQueryResult.isFetching || inboxNotificationsQueryResult.isLoading,
    hasMoreNotifications,
    loadMoreDisabled: inboxNotificationsQueryResult.isError,
    loadMore,
    filterByUnread,
    filterByMentioned,
    toggleFilterByUnread,
    toggleFilterByMentioned,
    selectedNotificationId,
    selectNotification,
    toggleRead,
    deleteNotification,
    markAllAsRead,
    markAllAsUnread,
    deleteRead,
    deleteAll,
  }
}

// ~~~~~~~~~~~~~~~~~~~ helpers ~~~~~~~~~~~~~~~~~~~

/**
 * Merges two arrays of notifications, removing duplicates and sorting by creation date.
 * @param currentNotifications The existing array of notifications
 * @param newNotifications The new notifications to merge in
 * @param updateExistingNotifications Whether to update existing notifications with new data
 * @returns A new array containing all unique notifications sorted by created_at in descending order
 */
function mergeNotifications({
  currentNotifications,
  newNotifications,
  updateExistingNotifications = false,
}: {
  currentNotifications: InboxNotification[]
  newNotifications: InboxNotification[]
  updateExistingNotifications?: boolean
}): InboxNotification[] {
  // If updateExistingNotifications is true, we replace any existing notifications with new ones
  // If false, we only add notifications that don't exist yet
  const mergedNotifications = updateExistingNotifications
    ? currentNotifications.map((current) => {
        const updated = newNotifications.find((n) => n.id === current.id)
        return updated || current
      })
    : currentNotifications

  // Add any completely new notifications that don't exist in current
  const uniqueNewNotifications = newNotifications.filter(
    (notification) => !currentNotifications.some((n) => n.id === notification.id)
  )

  return [...mergedNotifications, ...uniqueNewNotifications].sort((a, b) => b.created_at.localeCompare(a.created_at))
}

function filterNotifications({
  notifications,
  filterByUnread,
  filterByMentioned,
}: {
  notifications: InboxNotification[]
  filterByUnread: boolean
  filterByMentioned: boolean
}): InboxNotification[] {
  return notifications.filter((notification) => {
    if (filterByUnread && notification.read_at) {
      return false
    }
    if (filterByMentioned && notification.type !== "mention") {
      return false
    }
    return true
  })
}
