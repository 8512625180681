import { schemaToFormFields } from "@brm/schema-helpers/schema.js"
import type { AgreementType, CustomizableObjectType, FieldCategory } from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { getDiscriminatorSchema, getTitle } from "@brm/util/schema.js"
import { Badge, Box, Collapse, Heading, HStack, Switch, Text, Tooltip } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMemo } from "react"
import { Controller, useWatch, type Control } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import DynamicSorForm from "../../../components/DynamicForm/DynamicForm.js"
import { Link } from "../../../components/Link.js"
import { useObjectInputSchema, useObjectPatchSchema } from "../../../util/use-schema.js"
import { formatCustomizableObjectType } from "../../settings/criteria/util.js"
import type { ConfigureWorkflowStepsFormState } from "./utils.js"

export function ObjectFormPreview({
  objectType,
  category,
  control,
  objectIndex,
  stepIndex,
}: {
  objectType: CustomizableObjectType
  control: Control<ConfigureWorkflowStepsFormState>
  category?: FieldCategory
  objectIndex: number
  stepIndex: number
}) {
  const intl = useIntl()
  const categorySchema = FieldCategorySchema.anyOf.find((c) => c.const === category)

  const stdObjectSchema = useObjectPatchSchema(objectType === "LegalAgreement" ? skipToken : objectType)
  const legalAgreementObjectSchema = useObjectInputSchema(objectType === "LegalAgreement" ? objectType : skipToken)
  const discriminatedRootSchema = useMemo(
    () => getDiscriminatorSchema(legalAgreementObjectSchema, { agreement_type: "enterprise" satisfies AgreementType }),
    [legalAgreementObjectSchema]
  )
  const objectSchema = stdObjectSchema ?? discriminatedRootSchema
  const formFields = useMemo(() => {
    if (!objectSchema) {
      return []
    }
    const formFields = schemaToFormFields({
      schema: objectSchema,
      objectType,
      category,
      isRequestConfig: true,
    })
    const mappedFields = formFields.map((field) => ({
      ...field,
      path: field.is_custom ? ["custom", field.field_name] : [field.field_name],
      is_internal_only: false,
    }))
    return mappedFields
  }, [objectSchema, objectType, category])

  const isDisabled = useWatch({ control, name: `steps.${stepIndex}.objects.${objectIndex}.disabled_at` })

  if (!objectSchema) {
    return null
  }

  return (
    <Box outline="1px solid" outlineColor="gray.200" borderRadius="md" bg={isDisabled ? "gray.50" : undefined}>
      <HStack justifyContent="space-between" px={4} py={3}>
        <Heading size="xs" display="flex" alignItems="center" gap={2} color={isDisabled ? "gray.400" : undefined}>
          {formatCustomizableObjectType(objectType, intl)}
          {category && (
            <Badge
              colorScheme={categorySchema?.colorScheme}
              variant="subtleOutlined"
              size="sm"
              opacity={isDisabled ? 0.7 : undefined}
            >
              {getTitle(category, categorySchema)}
            </Badge>
          )}
        </Heading>

        <Controller
          control={control}
          name={`steps.${stepIndex}.objects.${objectIndex}.disabled_at`}
          render={({ field }) => (
            <Tooltip
              label={
                <FormattedMessage
                  id="workflow.definition.step.form.disableToggle.tooltip"
                  defaultMessage="Toggle to enable or disable this form section in this request step"
                  description="Tooltip explaining the switch that enables/disables a form section in workflow requests"
                />
              }
              shouldWrapChildren
            >
              <Switch
                isChecked={field.value === null}
                onChange={() => field.onChange(field.value === null ? Temporal.Now.instant().toString() : null)}
              />
            </Tooltip>
          )}
        />
      </HStack>
      <Collapse in={!isDisabled}>
        {formFields.length > 0 ? (
          <DynamicSorForm
            initialFormValues={{}}
            rootSchema={objectSchema}
            formFields={formFields}
            onSubmit={async () => {}}
            formHeader={null}
            documentDownloadURL={undefined}
            isEditing={false}
          />
        ) : (
          <Text color="gray.600" px={4} py={3}>
            <FormattedMessage
              id="workflow.definition.step.form.noFields"
              defaultMessage="There are no fields configured for this section. You can add fields by navigating to <link>criteria settings</link>."
              description="Help text shown when a form section has no fields configured"
              values={{
                link: (chunks) => (
                  <Link to="/settings/criteria" color="blue.600" fontWeight="medium">
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Text>
        )}
      </Collapse>
    </Box>
  )
}
