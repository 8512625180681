import { isValidFieldName } from "@brm/schema-helpers/field-names.js"
import type { CustomizableObjectType, FieldConfig } from "@brm/schema-types/types.js"
import { getFieldMapKey } from "@brm/type-helpers/field.js"
import { dereferenceSchema } from "@brm/util/schema.js"
import { Box, Button, Divider, Flex, HStack, useToast } from "@chakra-ui/react"
import type { JSONSchema, JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { useId, useMemo, type FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import type { FieldConfigMinimal } from "../../../app/services/generated-api.js"
import {
  useDeleteSchemaV1ByObjectTypeFieldsAndFieldNameMutation,
  usePutSchemaV1ByObjectTypeFieldsAndFieldNameMutation,
} from "../../../app/services/generated-api.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import { CriterionConfigForm } from "./CriterionConfigForm.js"
import { CriterionProvenance } from "./CriterionProvenance.js"
import { DEFAULT_CRITERION_FORM_STATE } from "./util.js"

export const CriterionInspector: FunctionComponent<{
  objectType: CustomizableObjectType
  fieldConfig?: FieldConfig
  defs: Record<string, ReadonlyDeep<JSONSchema>>
  onCriterionDeleted: () => void
  onCriterionUpdated?: (updatedCriterion: FieldConfig) => void
  onCriterionCreated?: (newCriterion: FieldConfigMinimal) => void
  fieldKeyMap?: Record<string, FieldConfig>
}> = ({ objectType, fieldConfig, defs, onCriterionDeleted, onCriterionUpdated, onCriterionCreated, fieldKeyMap }) => {
  const toast = useToast()
  const intl = useIntl()
  const isNewCriterion = fieldConfig === undefined

  const [upsertFieldConfig, upsertFieldConfigStatus] = usePutSchemaV1ByObjectTypeFieldsAndFieldNameMutation()
  const [deleteCustomField, deleteCustomFieldStatus] = useDeleteSchemaV1ByObjectTypeFieldsAndFieldNameMutation()

  const dereferencedConfig = useMemo(() => {
    if (!fieldConfig?.field_schema) return null
    const schema = dereferenceSchema(fieldConfig.field_schema as JSONSchemaObject, defs) as JSONSchemaObject
    return { ...fieldConfig, field_schema: schema }
  }, [defs, fieldConfig])

  const onValidSubmit = async (fieldConfigInput: FieldConfigMinimal): Promise<void> => {
    try {
      if (isNewCriterion) {
        const result = await upsertFieldConfig({
          objectType: fieldConfigInput.object_type,
          fieldName: fieldConfigInput.field_name,
          fieldConfigInput,
        }).unwrap()

        toast({
          status: "success",
          description: (
            <FormattedMessage
              defaultMessage="Created new criterion"
              description="Created new criterion toast message"
              id="settings.criteria.object.criterionCreated"
            />
          ),
        })

        if (onCriterionCreated) {
          onCriterionCreated(result)
        }
      } else {
        const updated = await upsertFieldConfig({
          objectType: fieldConfigInput.object_type,
          fieldName: fieldConfigInput.field_name,
          fieldConfigInput,
        }).unwrap()

        if (onCriterionUpdated) {
          onCriterionUpdated(updated)
        }

        toast({
          status: "success",
          description: (
            <FormattedMessage
              defaultMessage="Updated criterion"
              description="Updated criterion toast message"
              id="settings.criteria.object.criterionUpdated"
            />
          ),
        })
      }
    } catch (err) {
      toast({
        status: "error",
        description: getAPIErrorMessage(err) ?? (
          <FormattedMessage
            defaultMessage="An error occurred updating the criterion"
            description="Error message when updating a criterion fails"
            id="settings.criteria.object.updateCriterionFailed"
          />
        ),
      })
    }
  }

  const onDelete = async () => {
    if (!fieldConfig) return
    try {
      await deleteCustomField({
        fieldName: fieldConfig.field_name,
        objectType: fieldConfig.object_type,
      }).unwrap()
      toast({
        status: "success",
        description: (
          <FormattedMessage
            defaultMessage="Deleted criterion"
            description="Deleted criterion toast message"
            id="settings.criteria.object.criterionDeleted"
          />
        ),
      })
      onCriterionDeleted()
    } catch (err) {
      toast({
        status: "error",
        description: getAPIErrorMessage(err) ?? (
          <FormattedMessage
            defaultMessage="An error occurred deleting the criterion"
            description="Error message when deleting a criterion fails"
            id="settings.criteria.object.deleteCriterionFailed"
          />
        ),
      })
    }
  }

  const formId = `criterion-form-${useId()}`

  return (
    <Flex flexDirection="column" gap={4}>
      <CriterionConfigForm
        fieldConfig={fieldConfig}
        onValidSubmit={onValidSubmit}
        id={formId}
        defaultValues={isNewCriterion ? { ...DEFAULT_CRITERION_FORM_STATE, object_type: objectType } : undefined}
        validateField={(field) => {
          const matchingField = fieldKeyMap?.[getFieldMapKey(field)]
          if (matchingField) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "You have an existing {objectType} field with this name. Consider using the existing field or choosing a different name.",
                description: "Error message for duplicate criterion name",
                id: "settings.criteria.newCriterionModal.duplicateName",
              },
              { objectType: matchingField.object_type }
            )
          }
          if (!isValidFieldName(field.field_name)) {
            return intl.formatMessage({
              defaultMessage: "Invalid field name.",
              description: "Error message for invalid field name",
              id: "settings.criteria.newCriterionModal.invalidFieldName",
            })
          }
          return true
        }}
      />
      <Divider />
      <HStack>
        <Box color="gray.600" fontSize="sm" mr="auto">
          {!isNewCriterion && fieldConfig && <CriterionProvenance fieldConfig={fieldConfig} />}
        </Box>
        {(isNewCriterion || dereferencedConfig?.field_schema.configurable !== false) && (
          <>
            {!isNewCriterion && fieldConfig?.is_custom && (
              <Button
                colorScheme="error"
                variant="subtleOutlined"
                onClick={onDelete}
                isLoading={deleteCustomFieldStatus.isLoading}
              >
                <FormattedMessage
                  defaultMessage="Delete"
                  description="Button label for deleting a criterion"
                  id="settings.criteria.object.deleteCriterion"
                />
              </Button>
            )}
            <Button colorScheme="brand" type="submit" form={formId} isLoading={upsertFieldConfigStatus.isLoading}>
              {isNewCriterion ? (
                <FormattedMessage
                  defaultMessage="Create"
                  description="Button label for creating a new criterion"
                  id="settings.criteria.object.createCriterion"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Save"
                  description="Button label for saving a criterion"
                  id="settings.criteria.object.saveCriterion"
                />
              )}
            </Button>
          </>
        )}
      </HStack>
    </Flex>
  )
}
