import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import { type FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import {
  DeleteIcon,
  DotsHorizontalIcon,
  NotificationBoxIcon,
  ReadUnreadIcon,
  TrashIcon,
} from "../../components/icons/icons.js"

interface InboxActionsMenuProps {
  markAllAsRead: () => void
  markAllAsUnread: () => void
  deleteRead: () => void
  deleteAll: () => void
}

export const InboxActionsMenu: FunctionComponent<InboxActionsMenuProps> = ({
  markAllAsRead,
  markAllAsUnread,
  deleteRead,
  deleteAll,
}) => (
  <Menu>
    <MenuButton as={IconButton} variant="ghost" icon={<Icon as={DotsHorizontalIcon} />} />
    <MenuList>
      <MenuItem icon={<Icon as={ReadUnreadIcon} />} onClick={markAllAsRead}>
        <FormattedMessage
          id="notifications.bulkActions.markAllAsRead"
          description="menu item to mark all notifications as read"
          defaultMessage="Mark all as read"
        />
      </MenuItem>
      <MenuItem icon={<Icon as={NotificationBoxIcon} />} onClick={markAllAsUnread}>
        <FormattedMessage
          id="notifications.bulkActions.markAllAsUnread"
          description="Mark all as unread"
          defaultMessage="Mark all as unread"
        />
      </MenuItem>
      <MenuItem icon={<Icon as={DeleteIcon} />} onClick={deleteRead}>
        <FormattedMessage
          id="notifications.bulkActions.deleteAllRead"
          description="Delete all read"
          defaultMessage="Delete all read"
        />
      </MenuItem>
      <MenuItem icon={<Icon as={TrashIcon} />} onClick={deleteAll}>
        <FormattedMessage
          id="notifications.bulkActions.deleteAll"
          description="Delete all notifications"
          defaultMessage="Delete all notifications"
        />
      </MenuItem>
    </MenuList>
  </Menu>
)
