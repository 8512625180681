import { getPersonProperties } from "@brm/util/flags.js"
import { createListenerMiddleware, createSlice } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/browser"
import posthog from "posthog-js"
import { generatedApi } from "../../app/services/generated-api.js"
import type { RootState } from "../../app/store-types.js"
import {
  enterOnboarding,
  initializeOnboardingState,
  selectIsOnboardingInitialized,
} from "../onboarding/onboarding-slice.js"

const userSlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(generatedApi.endpoints.getUserV1Whoami.matchFulfilled, (_state, { payload }) => {
      Sentry.setUser({ id: payload.id, email: payload.email })
      posthog.identify(payload.id, getPersonProperties(payload, payload.organization))
    })
  },
})

// Create listener middleware to handle side effects
export const userListenerMiddleware = createListenerMiddleware()

userListenerMiddleware.startListening({
  matcher: generatedApi.endpoints.getUserV1Whoami.matchFulfilled,
  effect: async (a, { dispatch, getState }) => {
    const {
      organization: { is_lite },
    } = a.payload
    if (!is_lite) return

    const state = getState() as RootState
    const isInitialized = selectIsOnboardingInitialized(state)
    if (!isInitialized) {
      const result = await dispatch(generatedApi.endpoints.getOnboardingV1Latest.initiate())
      const { data: userOnboardingStep } = result
      if (userOnboardingStep?.step) {
        dispatch(initializeOnboardingState({ step: userOnboardingStep.step, userHasOnboardingFlow: is_lite }))
      } else if (is_lite) {
        dispatch(enterOnboarding())
      }
    }
  },
})

export const userReducer = userSlice.reducer
