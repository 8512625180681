import { isPendingDocumentClassificationStatus } from "@brm/schema-helpers/document.js"
import { hasPermission } from "@brm/schema-helpers/role.js"
import type {
  DocumentMinimal,
  DocumentWithExtraction,
  FieldMetadataWithSuggestions,
  FieldSourceOutputProperties,
  LegalAgreementInput,
  LegalAgreementListItem,
  LegalClausesFieldsMetadata,
  UserWithOrganization,
} from "@brm/schema-types/types.js"
import { FieldSourceInputPropertiesSchema } from "@brm/schemas"
import { getDiscriminatorSchema } from "@brm/util/schema.js"
import { isEmpty } from "@brm/util/type-guard.js"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Stack,
  useDisclosure,
  useToast,
  type MenuItemProps,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { skipToken } from "@reduxjs/toolkit/query"
import deepmerge from "deepmerge"
import { excludeKeys } from "filter-obj"
import { flatten } from "flat"
import { usePostHog } from "posthog-js/react"
import { useCallback, useEffect, useMemo, useRef, useState, type FunctionComponent, type ReactNode } from "react"
import type { Control, FieldPath, Path, SetValueConfig } from "react-hook-form"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import type { Except } from "type-fest"
import { isNotUndefined } from "typed-assert"
import { useDebouncedCallback } from "use-debounce"
import { useResizeObserver, useSessionStorage } from "usehooks-ts"
import zIndices from "../../../../../packages/theme/src/foundations/z-index.js"
import {
  useGetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlQuery,
  useGetLegalV1AgreementsByIdQuery,
  useGetUserV1WhoamiQuery,
  usePostDocumentV1ByIdBackgroundExtractMutation,
  usePostLegalV1AgreementsByIdVerificationMutation,
  usePostLegalV1AgreementsListQuery,
  usePutLegalV1AgreementsByIdMutation,
} from "../../../app/services/generated-api.js"
import { useContextMenu } from "../../../components/ContextMenu/context-menu.js"
import UserWithProviderBadge from "../../../components/DataTable/CellRenderer/UserWithProviderBadge.js"
import { DocumentViewerWithHighlighting } from "../../../components/Document/DocumentViewerWithHighlighting.js"
import { DocumentsBulkUploadModal } from "../../../components/Document/DocumentsUploadModal.js"
import {
  DynamicFormField,
  DynamicFormRefWrapper,
  type DynamicFormFieldProps,
} from "../../../components/DynamicForm/DynamicFormField.js"
import { FieldSourceFooter } from "../../../components/DynamicForm/FieldSourceFooter.js"
import { getDefaultUserFieldSource, updateFormFieldsWithDirtyFields } from "../../../components/DynamicForm/utils.js"
import { IconButtonWithTooltip } from "../../../components/IconButtonWithTooltip.js"
import { OnboardingPopover } from "../../../components/Onboarding/OnboardingPopover.js"
import { RenderedMarkdown } from "../../../components/RenderedMarkdown.js"
import {
  BackIcon,
  CheckIcon,
  EditIcon,
  MoreMenuIcon,
  NextIcon,
  PlusIcon,
  TrashIcon,
  TwoStarsIcon,
} from "../../../components/icons/icons.js"
import { ownDocumentDownloadUrl } from "../../../util/document.js"
import { StatusCodes, getAPIErrorMessage, getAPIErrorStatusCode } from "../../../util/error.js"
import { initializeReactHookFormState } from "../../../util/form.js"
import { pathForLegalAgreement } from "../../../util/json-schema.js"
import { log } from "../../../util/logger.js"
import { packageSortFilterOptionsForAPI } from "../../../util/schema-table.js"
import { useObjectInputSchema, useObjectSchema } from "../../../util/use-schema.js"
import { HighlightPulseWrapper } from "../../onboarding/HighlightPulseWrapper.js"
import { goToNextOnboardingStep, selectCurrentOnboardingStep } from "../../onboarding/onboarding-slice.js"
import { cleanValues } from "../../workflows/run/utils.js"
import { AgreementListSidebar } from "../AgreementListSidebar.js"
import { DeleteLegalAgreementModal } from "../DeleteLegalAgreementModal.js"
import { AGREEMENT_LIST_MINIMIZE_STORAGE_KEY } from "../constants.js"
import { AssociatedDocuments, type AssociatedDocumentsFormState } from "./AssociatedDocuments.js"
import { DisplayNameField } from "./LegalAgreementEditor.js"
import {
  SAVE_AGREEMENT_DEBOUNCE_DELAY,
  baseUnverifiedAgreementsApiParams,
  formatInputForApi,
  getFieldSourceLink,
} from "./util.js"

/**
 * Given there exists legal agreement in the path, this component will fetch and render the legal agreement editor.
 * Or render a create legal agreement drawer if there is no existing legalAgreementId.
 */
const LegalAgreementEditorLite: FunctionComponent = () => {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const { legalAgreementId } = useParams()

  const hashParams = new URLSearchParams(location.hash.slice(1))
  const documentId = hashParams.get("document")

  const [minimizeSidebar, setMinimizeSidebar] = useSessionStorage(AGREEMENT_LIST_MINIMIZE_STORAGE_KEY, false)
  const containerRef = useRef<HTMLDivElement>(null)
  useResizeObserver({
    ref: containerRef,
    onResize: ({ width }) => {
      if (width !== undefined && width < 1200 && !minimizeSidebar) {
        setMinimizeSidebar(true)
      }
    },
  })

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const legalAgreementSchema = useObjectSchema("LegalAgreement")
  const {
    data: legalAgreement,
    isError,
    error,
    refetch: fetchAgreement,
  } = useGetLegalV1AgreementsByIdQuery(legalAgreementId ? { id: legalAgreementId } : skipToken)
  const [nextAgreementId, setNextAgreementId] = useState<string | undefined>(undefined)

  const currentSource: DocumentWithExtraction | undefined = useMemo(() => {
    const matchingDocument = legalAgreement?.documents.find((d) => d.id === documentId)
    const firstDocument = legalAgreement?.documents[0]

    return matchingDocument ?? firstDocument
  }, [legalAgreement?.documents, documentId])

  const documentUrlResult = useGetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlQuery(
    currentSource && legalAgreementId ? { documentId: currentSource.id, id: legalAgreementId } : skipToken
  )
  const downloadUrl = documentUrlResult.data?.download_url
  const apiParams = useMemo(
    () =>
      legalAgreementSchema &&
      packageSortFilterOptionsForAPI<string>(baseUnverifiedAgreementsApiParams, legalAgreementSchema, intl),
    [legalAgreementSchema, intl]
  )
  const { data: pendingAgreements } = usePostLegalV1AgreementsListQuery(
    apiParams ? { listQueryStringParams: apiParams } : skipToken
  )
  const agreementsIndex = pendingAgreements?.items.findIndex((agreement) => agreement.id === legalAgreement?.id) ?? -1

  useEffect(() => {
    if (pendingAgreements && pendingAgreements.total > 1 && agreementsIndex !== -1) {
      const nextAgreement = pendingAgreements.items[(agreementsIndex + 1) % pendingAgreements.total]
      setNextAgreementId(nextAgreement?.id)
    }
  }, [agreementsIndex, pendingAgreements])

  const onClose = () => {
    navigate(location.state?.prevLocation ?? "/agreements")
  }

  if (!legalAgreement || !whoami) {
    return null
  }

  return (
    <Flex flex={1} height="full" width="full" overflow="hidden" ref={containerRef}>
      <AgreementListSidebar
        selectedAgreementId={legalAgreement.id}
        agreementList={pendingAgreements}
        isMinimized={minimizeSidebar}
      />
      {isError && (
        <Flex direction="column" flex={1}>
          <Alert status="error">
            <AlertDescription>
              {getAPIErrorStatusCode(error) === StatusCodes.FORBIDDEN ? (
                <FormattedMessage
                  defaultMessage="You do not have permissions to read this legal agreement."
                  description="Error message when user lacks permissions to read this legal agreement"
                  id="legalAgreementEditorDrawer.noPermissions"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Something went wrong loading the legal agreement."
                  description="Error message when legal agreement fails to load"
                  id="legalAgreementEditorDrawer.loadError"
                />
              )}
            </AlertDescription>
          </Alert>
        </Flex>
      )}
      <LegalAgreementEditorContent
        assignToCriteriaFieldSource={{
          type: "document",
          id: currentSource?.id,
          source_display_name: currentSource?.file_name ?? undefined,
        }}
        legalAgreement={legalAgreement}
        whoami={whoami}
        onClose={onClose}
        currentDocument={currentSource}
        refetchAgreement={fetchAgreement}
        downloadUrl={downloadUrl}
        verifiedAgreementFooter={
          nextAgreementId && (
            <Button
              gap={2}
              onClick={() => {
                if (nextAgreementId) {
                  navigate(pathForLegalAgreement(nextAgreementId, true), {
                    state: location.state,
                  })
                }
              }}
              variant="outline"
              rightIcon={<Icon as={NextIcon} />}
            >
              <FormattedMessage
                id="legalAgreement.modal.nextAgreement.button"
                defaultMessage="Next agreement"
                description="Button to navigate to the next legal agreement"
              />
            </Button>
          )
        }
      />
    </Flex>
  )
}

// This is a separate component so that it can receive the fetched `legalAgreement` as opposed to just the ID and doesn't have to deal with data fetching.
const LegalAgreementEditorContent: FunctionComponent<{
  legalAgreement: LegalAgreementListItem
  whoami: UserWithOrganization
  /** Document to view in document viewer */
  currentDocument?: DocumentWithExtraction
  /** Object type used for assigning criteria field source. */
  assignToCriteriaFieldSource?: FieldSourceOutputProperties
  /** Document url for document viewer */
  downloadUrl?: string
  refetchAgreement: (args: { id: string }) => void
  onClose: () => void
  /** CTA to render in footer after agreement is verified */
  verifiedAgreementFooter?: ReactNode
}> = ({
  legalAgreement,
  onClose,
  whoami,
  currentDocument,
  downloadUrl,
  refetchAgreement,
  assignToCriteriaFieldSource,
  verifiedAgreementFooter,
}) => {
  const toast = useToast()
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const posthog = usePostHog()
  const dispatch = useDispatch()

  const [verifyLegalAgreement] = usePostLegalV1AgreementsByIdVerificationMutation()
  const [updateAgreement] = usePutLegalV1AgreementsByIdMutation()
  const [uncontrolledComponentResetId, setUncontrolledComponentResetId] = useState(0)
  const incrementUncontrolledComponentResetId = useCallback(() => setUncontrolledComponentResetId((id) => id + 1), [])
  const [initializeExtractionById] = usePostDocumentV1ByIdBackgroundExtractMutation()
  const uploadModal = useDisclosure()

  const [isEditingDisplayName, setIsEditingDisplayName] = useState(legalAgreement?.display_name ? false : true)
  const [selectedProvenancePath, setSelectedProvenancePath] = useState<(string | number)[]>()
  const [deleteMessage, setDeleteMessage] = useState<string | undefined>()
  const rootSchema = useObjectInputSchema("LegalAgreement")

  const form = useForm<LegalAgreementInput>({
    defaultValues: legalAgreement,
    shouldFocusError: false,
  })
  initializeReactHookFormState(form)

  const currentStep = useSelector(selectCurrentOnboardingStep)

  const debouncedSaveLegalAgreement = useDebouncedCallback(async () => {
    const legalAgreementPayload =
      rootSchema &&
      updateFormFieldsWithDirtyFields<LegalAgreementInput>(form.formState.dirtyFields, form.getValues(), rootSchema)
    if (legalAgreementPayload && !isEmpty(legalAgreementPayload) && legalAgreement?.id) {
      const cleanedValues = cleanValues(legalAgreementPayload, rootSchema)
      const updatedFields = Object.keys(
        flatten(form.formState.dirtyFields)
      ) as (keyof typeof form.formState.dirtyFields)[]
      // Display name is handled separately
      void form.trigger(updatedFields.filter((path) => path !== "display_name"))
      const requestInput = deepmerge<LegalAgreementInput>(legalAgreement, cleanedValues as LegalAgreementInput, {
        arrayMerge: (_, source) => source,
      })
      await updateAgreement({ body: requestInput, id: legalAgreement.id })
      // Reset dirty fields when the draft state gets flushed to the server so that changing a value back
      // to its original value will get recorded as a field change event even though from the perspective
      // of the form, the value is equal to its default value and therefore not dirty.
      form.reset(form.getValues(), { keepValues: true, keepDefaultValues: false, keepErrors: true })
    }
  }, SAVE_AGREEMENT_DEBOUNCE_DELAY)

  useEffect(() => {
    // Subscribe to react-hook-form watch to execute onChange on form state change
    const subscription = form.watch(() => {
      // react-hook-form does not update dirtyFields by the time the watch onChange is called.
      // setTimeout so we can leverage the dirtyField state and cleanly update only the draftState
      // fields that have been changed since the form was loaded.
      setTimeout(debouncedSaveLegalAgreement, 1)
    })
    return () => subscription.unsubscribe()
  }, [form, debouncedSaveLegalAgreement, legalAgreement])

  const pathString = selectedProvenancePath?.join(".")

  useEffect(() => {
    form.reset(legalAgreement, { keepDirtyValues: true, keepErrors: true })
  }, [form, legalAgreement])

  // TODO: remove when legal agreement updates through pub sub
  useEffect(() => {
    // Poll for document status updates every second
    const interval = setInterval(async () => {
      if (
        document.visibilityState !== "hidden" &&
        legalAgreement &&
        legalAgreement.documents.find(
          (document) =>
            isPendingDocumentClassificationStatus(document.extraction_status) ||
            (document.extraction_jobs &&
              document.extraction_jobs.find(
                (extraction) => extraction.extraction_type === "legal_agreement_fields" && !extraction.extracted_at
              ))
        )
      ) {
        refetchAgreement({ id: legalAgreement.id })
      }
    }, 5000)
    return () => clearTimeout(interval)
  }, [legalAgreement, refetchAgreement])
  const legalAgreementType = form.watch("agreement_type")
  const discriminatedRootSchema = useMemo(
    () => getDiscriminatorSchema(rootSchema, { agreement_type: legalAgreementType }),
    [rootSchema, legalAgreementType]
  )

  const getValue = useCallback((path: string) => form.watch((path || undefined) as Path<LegalAgreementInput>), [form])
  // setValue is expecting a path from the shape of the formState. We could make DynamicForm generically typed
  // to support this, but due to the dynamic nature of the form generation we will keep as is for now.
  const setValue = form.setValue as (path: string, value: unknown, options?: SetValueConfig) => void

  const deleteLegalAgreementModal = useDisclosure()

  const onProvenanceClick = useCallback(
    (provenancePath: (number | string)[], fieldSource?: FieldSourceOutputProperties) => {
      if (!fieldSource || !("id" in fieldSource)) {
        return
      }
      const destinationLink = getFieldSourceLink(location, fieldSource)
      if (destinationLink) {
        navigate(destinationLink, { state: location.state })
      }

      setSelectedProvenancePath(provenancePath)
    },
    [location, navigate]
  )

  const renderFieldSource = useCallback(
    (path: (string | number)[], fieldSource?: FieldSourceOutputProperties): ReactNode => {
      if (!fieldSource) {
        return null
      }

      return <FieldSourceFooter fieldSource={fieldSource} onClick={() => onProvenanceClick(path, fieldSource)} />
    },
    [onProvenanceClick]
  )

  const onVerifyField = useCallback(
    async (fieldPath: (string | number)[] | undefined, values: LegalAgreementInput) => {
      if (!legalAgreement) {
        return
      }
      if (fieldPath) {
        posthog.capture("legal_agreement_verify_field_lite")
      } else {
        posthog.capture("legal_agreement_verify_all_lite")
      }
      const legalAgreementInput = formatInputForApi(values)
      try {
        await verifyLegalAgreement({
          id: legalAgreement.id,
          agreementVerificationRequest: { input: legalAgreementInput, field_path: fieldPath },
        }).unwrap()
        refetchAgreement({ id: legalAgreement.id })
        // User has clicked verify all, so we can move onto the next onboarding step
        if (!fieldPath) {
          if (currentStep === "verify_agreement.accept_agreement") {
            dispatch(goToNextOnboardingStep())
          }
        }
      } catch (err) {
        log.error("Error saving legal agreement", err, { legalAgreement: legalAgreementInput })
        toast({
          status: "error",
          description: getAPIErrorMessage(err) ?? (
            <FormattedMessage
              defaultMessage="An error occurred saving the legal agreement"
              description="The toast description for an error when saving a legal agreement"
              id="legalAgreement.modal.toast.error"
            />
          ),
        })
      }
    },
    [legalAgreement, verifyLegalAgreement, refetchAgreement, toast, posthog, currentStep, dispatch]
  )

  const onDiscard = useCallback(() => {
    form.reset()
    onClose()
  }, [form, onClose])

  const getDocumentDownloadURL = useCallback(
    (path: (string | number)[], document: DocumentMinimal) => {
      // If the legal agreement is new or the document was just changed, we can access the documents through the owned-documents route (since the user would have just uploaded them)
      if (!legalAgreement || form.getFieldState(path.join(".") as FieldPath<LegalAgreementInput>).isDirty) {
        return ownDocumentDownloadUrl(document)
      }
      // Else access them through the legal agreement ID
      return new URL(
        `/legal/v1/agreements/${legalAgreement.id}/${path.join("/")}/content`,
        import.meta.env.VITE_API_BASE_URL
      ).href
    },
    [form, legalAgreement]
  )
  const { menuListProps, menuProps, subjectProps, menuItemProps, betsyProps, menuButtonProps } =
    useContextMenu<HTMLDivElement>({
      betsyEnabled: true,
    })

  const menuActions = useCallback(
    (additionalProps?: MenuItemProps) => (
      <>
        <MenuItem
          key="rename-agreement"
          icon={<Icon as={EditIcon} />}
          iconSpacing={0}
          onClick={() => setIsEditingDisplayName(true)}
          {...additionalProps}
        >
          <FormattedMessage
            defaultMessage="Rename agreement"
            description="The label for the rename legal agreement option in the options menu"
            id="legalAgreement.modal.options.rename"
          />
        </MenuItem>
        {hasPermission(whoami.roles, "legal:delete") && (
          <MenuItem
            key="delete-agreement"
            icon={<Icon as={TrashIcon} />}
            iconSpacing={0}
            onClick={deleteLegalAgreementModal.onOpen}
            {...additionalProps}
          >
            <FormattedMessage
              defaultMessage="Delete agreement"
              description="The label for the delete legal agreement option in the options menu"
              id="legalAgreement.modal.options.delete"
            />
          </MenuItem>
        )}
        <MenuItem
          key="add-documents"
          icon={<Icon as={PlusIcon} />}
          iconSpacing={0}
          onClick={uploadModal.onOpen}
          {...additionalProps}
        >
          <FormattedMessage
            defaultMessage="Add documents"
            description="The label for the add document option in the options menu"
            id="legalAgreement.modal.options.addDocuments"
          />
        </MenuItem>
      </>
    ),

    [whoami.roles, deleteLegalAgreementModal.onOpen, uploadModal.onOpen]
  )

  const legalFormProps: Except<DynamicFormFieldProps, "path" | "formField" | "rootBaseSchema"> = useMemo(
    () => ({
      control: form.control,
      getValue,
      setValue,
      resetField: form.resetField as (path: string, options?: SetValueConfig) => void,
      isReadOnly: false,
      uncontrolledComponentResetId,
      incrementUncontrolledComponentResetId,
      renderFieldSource,
      format: "compact",
      onProvenanceClick,
      getDocumentDownloadUrl: getDocumentDownloadURL,
    }),
    [
      form,
      getValue,
      setValue,
      uncontrolledComponentResetId,
      incrementUncontrolledComponentResetId,
      renderFieldSource,
      onProvenanceClick,
      getDocumentDownloadURL,
    ]
  )

  const onUploadSubmit = async (uploadedDocuments: DocumentMinimal[]) => {
    // Trigger bulk-processing
    await Promise.all(
      uploadedDocuments.map((doc) =>
        initializeExtractionById({
          id: doc.id,
          documentBackgroundExtractionRequest: {
            extraction_request_type: "legal_agreement",
            legal_agreement_id: legalAgreement.id,
          },
        }).unwrap()
      )
    )
  }

  const contextMenuActions = useMemo(() => menuActions(menuItemProps), [menuActions, menuItemProps])

  return (
    <Flex alignItems="stretch" flexGrow={1} {...subjectProps.baseProps}>
      <Portal>
        <Menu {...menuProps}>
          <MenuButton {...menuButtonProps} />
          <MenuList zIndex={zIndices.popover} {...menuListProps}>
            {menuListProps.children}
            {contextMenuActions}
          </MenuList>
        </Menu>
      </Portal>
      {betsyProps?.BetsyModal}
      <Center boxShadow="xl" minH={0} borderRightWidth="1px" height="100%" flex={2}>
        <DocumentViewerWithHighlighting
          requestingEntity={{
            object_type: "LegalAgreement",
            object_id: legalAgreement?.id,
          }}
          document={currentDocument}
          menuActions={contextMenuActions}
          fieldMetadata={
            selectedProvenancePath &&
            (getValue(["fields_metadata", ...selectedProvenancePath].join(".")) as FieldMetadataWithSuggestions)
          }
          path={selectedProvenancePath}
          onAssignToCriteriaSuccess={async (path, newVal, source) => {
            setValue(path.join("."), newVal, {
              shouldDirty: true,
            })
            const existingFieldMetadata = excludeKeys(
              getValue(["fields_metadata", ...path].join(".")) || {},
              (k) => k in FieldSourceInputPropertiesSchema.properties
            )
            if (
              assignToCriteriaFieldSource?.id &&
              (assignToCriteriaFieldSource.type === "document" || assignToCriteriaFieldSource?.type === "transaction")
            ) {
              // Set assigned by metadata
              if (path.includes("clauses")) {
                setValue(
                  ["fields_metadata", ...path].join("."),
                  {
                    ...existingFieldMetadata,
                    ...getDefaultUserFieldSource(intl, whoami),
                    assigned_by_metadata: {
                      source,
                      object_field_source: {
                        id: assignToCriteriaFieldSource.id,
                        type: assignToCriteriaFieldSource.type,
                        source_display_name: assignToCriteriaFieldSource.source_display_name,
                      },
                    },
                  } satisfies FieldMetadataWithSuggestions,
                  {
                    shouldDirty: true,
                  }
                )
                const clausesMetadata = excludeKeys(
                  (getValue(["fields_metadata", ...path.slice(0, -1)].toString()) || {}) as LegalClausesFieldsMetadata,
                  Array.from(Object.keys(FieldSourceInputPropertiesSchema.properties))
                )
                setValue(
                  ["fields_metadata", "clauses"].join("."),
                  {
                    ...clausesMetadata,
                    verified: false,
                    updated_at: Temporal.Now.instant().toString(),
                  } satisfies FieldMetadataWithSuggestions,
                  {
                    shouldDirty: true,
                  }
                )
              } else {
                setValue(
                  ["fields_metadata", ...path].join("."),
                  {
                    ...existingFieldMetadata,
                    ...getDefaultUserFieldSource(intl, whoami),
                    verified: false,
                    assigned_by_metadata: {
                      source,
                      object_field_source: {
                        id: assignToCriteriaFieldSource.id,
                        type: assignToCriteriaFieldSource.type,
                        source_display_name: assignToCriteriaFieldSource.source_display_name,
                      },
                    },
                  } satisfies FieldMetadataWithSuggestions,
                  {
                    shouldDirty: true,
                  }
                )
              }
            }
            incrementUncontrolledComponentResetId()
          }}
          value={pathString && getValue(pathString)}
          rootSchema={rootSchema}
          downloadUrl={downloadUrl}
          closeButton={
            location?.state?.prevLocation && (
              <IconButtonWithTooltip
                icon={<Icon as={BackIcon} />}
                onClick={onDiscard}
                variant="ghost"
                label={intl.formatMessage({
                  defaultMessage: "Back",
                  id: "documentViewer.backButton.tooltip",
                  description: "label for previous page button in document viewer toolbar",
                })}
              />
            )
          }
        />
      </Center>
      <Flex maxW="lg" flexDirection="column" alignItems="stretch" flex={1}>
        <Flex direction="column" p={4} borderBottomWidth={1}>
          <Flex justifyContent="space-between">
            <OnboardingPopover
              step="overview.view_agreement"
              isOpen={currentStep === "overview.view_agreement"}
              position="left"
              anchorElement={
                <DisplayNameField
                  legalAgreement={legalAgreement}
                  onChange={async (updatedName) => {
                    const displayNameFieldProps = form.watch("fields_metadata.display_name")
                    await onVerifyField(["display_name"], {
                      ...(form.formState.defaultValues as LegalAgreementInput),
                      agreement_type: legalAgreement.agreement_type,
                      display_name: updatedName,
                      fields_metadata: {
                        display_name: { ...displayNameFieldProps, ...getDefaultUserFieldSource(intl, whoami) },
                      },
                    })
                    setIsEditingDisplayName(false)
                  }}
                  isEditing={isEditingDisplayName}
                  control={form.control}
                  value={form.watch("display_name")}
                />
              }
            />

            {legalAgreement?.email_source?.map((userWithProvider) => (
              <UserWithProviderBadge key={userWithProvider.id} user={userWithProvider} />
            ))}
            {!isEditingDisplayName && (
              <Menu placement="bottom-end">
                <MenuButton
                  as={IconButton}
                  aria-label={intl.formatMessage({
                    defaultMessage: "Agreement options button",
                    description: "The icon label for a button to open the options menu",
                    id: "legalAgreement.modal.options.iconButton",
                  })}
                  size="sm"
                  icon={<Icon as={MoreMenuIcon} />}
                  variant="ghost"
                />
                <MenuList fontSize="sm">{menuActions()}</MenuList>
              </Menu>
            )}
          </Flex>
        </Flex>
        <Flex direction="column" overflowY="auto" flex={1}>
          <Box paddingX={4}>
            <AssociatedDocuments
              control={form.control as unknown as Control<AssociatedDocumentsFormState>}
              legalAgreement={legalAgreement}
              // fields is used to render "New extracted fields count" in the document items. For simplification purposes, we can ignore for now.
              fields={[]}
              legalAgreementInput={formatInputForApi(form.getValues())}
              fieldsMetadata={form.getValues("fields_metadata")}
              onDocumentAdded={(extractionResponse) => {
                if (!extractionResponse.agreement_id) {
                  return
                }
                refetchAgreement({ id: extractionResponse.agreement_id })
              }}
              submitDocuments={async () => {
                isNotUndefined(legalAgreement, "Legal agreement must be defined to remove a document")
                await onVerifyField(["documents"], {
                  ...(form.formState.defaultValues as LegalAgreementInput),
                  agreement_type: legalAgreement.agreement_type,
                  documents: form.getValues().documents,
                })
              }}
              onLastDocumentRemoved={async () => {
                setDeleteMessage(
                  intl.formatMessage({
                    id: "legalAgreement.modal.deleteLast.document.message",
                    defaultMessage:
                      "You have removed the last document from this agreement. Do you want to delete the agreement as well? It cannot be restored.",
                    description:
                      "Error message shown when the user tries to delete an agreement with only one document",
                  })
                )
                deleteLegalAgreementModal.onOpen()
              }}
            />
          </Box>
          <Stack padding={4} paddingTop={0}>
            {currentDocument?.summary && (
              <Accordion allowToggle defaultIndex={0} marginBottom={2}>
                <AccordionItem border={0}>
                  <h2>
                    <AccordionButton
                      flex={1}
                      justifyContent="space-between"
                      paddingX={0}
                      _hover={{ backgroundColor: "white" }}
                      fontWeight="medium"
                    >
                      <Flex gap={1} justifyContent="start">
                        <FormattedMessage
                          defaultMessage="Document Summary"
                          description="The title for the agreement executive summary section"
                          id="legalAgreement.modal.summary.title"
                        />
                        <Icon as={TwoStarsIcon} color="purple.700" />
                      </Flex>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    borderWidth={1}
                    borderColor="gray.200"
                    borderRadius="md"
                    backgroundColor="gray.50"
                    padding={2}
                  >
                    <RenderedMarkdown content={currentDocument.summary} />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
            {discriminatedRootSchema && (
              <Stack gap={6}>
                <DynamicFormField
                  {...legalFormProps}
                  formField={{
                    field_name: "vendor",
                    field_title: intl.formatMessage({
                      defaultMessage: "Vendor",
                      description: "The title for the vendor field",
                      id: "legalAgreement.modal.vendor.title",
                    }),
                    is_custom: false,
                    is_required: false,
                    object_type: "LegalAgreement",
                    is_internal_only: false,
                  }}
                  path={["vendor"]}
                  rootBaseSchema={discriminatedRootSchema}
                  renderFieldSource={(path, fieldSource) => {
                    if (!fieldSource) {
                      return null
                    }
                    return (
                      <OnboardingPopover
                        step="verify_agreement.verify_vendor"
                        isOpen={currentStep === "verify_agreement.verify_vendor"}
                        position="left"
                        anchorElement={
                          <HighlightPulseWrapper isHighlighted={currentStep === "verify_agreement.verify_vendor"}>
                            <FieldSourceFooter
                              fieldSource={fieldSource}
                              onClick={() => onProvenanceClick(path, fieldSource)}
                            />
                          </HighlightPulseWrapper>
                        }
                      />
                    )
                  }}
                />

                <DynamicFormField
                  {...legalFormProps}
                  formField={{
                    field_name: "total_contract_value",
                    field_title: intl.formatMessage({
                      defaultMessage: "Total Contract Value",
                      description: "The title for the total contract value field",
                      id: "legalAgreement.modal.totalContractValue.title",
                    }),
                    is_custom: false,
                    is_required: false,
                    object_type: "LegalAgreement",
                    is_internal_only: false,
                  }}
                  path={["total_contract_value"]}
                  rootBaseSchema={discriminatedRootSchema}
                />
                <DynamicFormField
                  {...legalFormProps}
                  formField={{
                    field_name: "start_date",
                    field_title: intl.formatMessage({
                      defaultMessage: "Start Date",
                      description: "The title for the start date field",
                      id: "legalAgreement.modal.startDate.title",
                    }),
                    is_custom: false,
                    is_required: false,
                    object_type: "LegalAgreement",
                    is_internal_only: false,
                  }}
                  path={["start_date"]}
                  rootBaseSchema={discriminatedRootSchema}
                />
                <DynamicFormField
                  {...legalFormProps}
                  formField={{
                    field_name: "end_date",
                    field_title: intl.formatMessage({
                      defaultMessage: "End Date",
                      description: "The title for the end date field",
                      id: "legalAgreement.modal.endDate.title",
                    }),
                    is_custom: false,
                    is_required: false,
                    object_type: "LegalAgreement",
                    is_internal_only: false,
                  }}
                  path={["end_date"]}
                  rootBaseSchema={discriminatedRootSchema}
                />
                <DynamicFormField
                  {...legalFormProps}
                  formField={{
                    field_name: "auto_renews",
                    field_title: intl.formatMessage({
                      defaultMessage: "Auto Renews",
                      description: "The title for the auto renews field",
                      id: "legalAgreement.modal.autoRenews.title",
                    }),
                    is_custom: false,
                    is_required: false,
                    object_type: "LegalAgreement",
                    is_internal_only: false,
                  }}
                  path={["auto_renews"]}
                  rootBaseSchema={discriminatedRootSchema}
                  renderFieldSource={(path, fieldSource) => {
                    if (!fieldSource) {
                      return null
                    }
                    return (
                      <OnboardingPopover
                        step="verify_agreement.verify_auto_renews"
                        isOpen={currentStep === "verify_agreement.verify_auto_renews"}
                        position="left"
                        anchorElement={
                          <HighlightPulseWrapper isHighlighted={currentStep === "verify_agreement.verify_auto_renews"}>
                            <FieldSourceFooter
                              fieldSource={fieldSource}
                              onClick={() => onProvenanceClick(path, fieldSource)}
                            />
                          </HighlightPulseWrapper>
                        }
                      />
                    )
                  }}
                />
                <DynamicFormField
                  {...legalFormProps}
                  formField={{
                    field_name: "auto_renewal_opt_out_period",
                    field_title: intl.formatMessage({
                      defaultMessage: "Auto Renewal Opt Out Period",
                      description: "The title for the auto renewal opt out period field",
                      id: "legalAgreement.modal.autoRenewalOptOutPeriod.title",
                    }),
                    is_custom: false,
                    is_required: false,
                    object_type: "LegalAgreement",
                    is_internal_only: false,
                  }}
                  path={["auto_renewal_opt_out_period"]}
                  rootBaseSchema={discriminatedRootSchema}
                />
                <OnboardingPopover
                  step="verify_agreement.verify_decision_date"
                  isOpen={currentStep === "verify_agreement.verify_decision_date"}
                  position="left"
                  anchorElement={
                    <DynamicFormRefWrapper
                      {...legalFormProps}
                      formField={{
                        field_name: "decision_date",
                        field_title: intl.formatMessage({
                          defaultMessage: "Decision Date",
                          description: "The title for the decision date field",
                          id: "legalAgreement.modal.decisionDate.title",
                        }),
                        is_custom: false,
                        is_required: false,
                        object_type: "LegalAgreement",
                        is_internal_only: false,
                      }}
                      path={["decision_date"]}
                      rootBaseSchema={discriminatedRootSchema}
                    />
                  }
                />
              </Stack>
            )}
          </Stack>
        </Flex>
        <Divider borderColor="gray.300" />
        <Flex gap={2} justifyContent="space-between" p={4} alignItems="center">
          {/* Left side of footer */}
          {legalAgreement?.verification_status === "verified" && verifiedAgreementFooter ? (
            verifiedAgreementFooter
          ) : (
            <Spacer />
          )}
          {/* Right side of footer */}
          {legalAgreement?.verification_status === "verified" ? (
            <Flex gap={2} fontWeight="semibold" color="brand.700">
              <FormattedMessage
                id="legalAgreement.modal.verification.message"
                defaultMessage="Verified"
                description="Message shown when the legal agreement has been verified"
              />
              <Icon as={CheckIcon} />
            </Flex>
          ) : (
            <OnboardingPopover
              step="verify_agreement.accept_agreement"
              isOpen={currentStep === "verify_agreement.accept_agreement"}
              position="left"
              anchorElement={
                <HighlightPulseWrapper isHighlighted={currentStep === "verify_agreement.accept_agreement"}>
                  <Button
                    colorScheme="brand"
                    onClick={() => onVerifyField(undefined, form.getValues())}
                    rightIcon={<Icon as={CheckIcon} />}
                  >
                    <FormattedMessage
                      id="legalAgreement.modal.acceptAll.button"
                      defaultMessage="Accept all"
                      description="Button to accept all criteria"
                    />
                  </Button>
                </HighlightPulseWrapper>
              }
            />
          )}
        </Flex>
      </Flex>
      <DeleteLegalAgreementModal
        {...deleteLegalAgreementModal}
        legalAgreementBeingDeleted={{ id: legalAgreement.id }}
        deletionMessage={deleteMessage}
        onCancel={() => {
          setDeleteMessage(undefined)
          deleteLegalAgreementModal.onClose()
        }}
        onDelete={() => {
          setDeleteMessage(undefined)
          deleteLegalAgreementModal.onClose()
          onClose()
        }}
      />
      {uploadModal.isOpen && (
        // set returnFocusOnClose false to prevent unintended side nav focus issues
        <DocumentsBulkUploadModal
          {...uploadModal}
          onSubmit={onUploadSubmit}
          objectType="LegalAgreement"
          returnFocusOnClose={false}
        />
      )}
    </Flex>
  )
}

export default LegalAgreementEditorLite
