import {
  // eslint-disable-next-line no-restricted-imports
  AsyncCreatableSelect as ChakraAsyncCreatableSelect,
  type AsyncCreatableSelectComponent,
} from "chakra-react-select"
import { forwardRef } from "react"
import DropdownIndicator from "./DropdownIndicator.js"

const AsyncCreatableSelect = forwardRef(function AsyncCreatableSelect(props, ref) {
  return (
    <ChakraAsyncCreatableSelect
      {...props}
      ref={ref}
      components={{
        ...props.components,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DropdownIndicator,
      }}
    />
  )
}) as AsyncCreatableSelectComponent
export default AsyncCreatableSelect
