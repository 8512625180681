import type { ContainsFilter, ObjectType, TableIdentifier } from "@brm/schema-types/types.js"
import type { Filter } from "@brm/type-helpers/filters.js"
import {
  Badge,
  Box,
  Flex,
  HStack,
  Heading,
  Icon,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  chakra,
  useToast,
  type StackProps,
} from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import type { ReactNode } from "react"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { useDebouncedCallback } from "use-debounce"
import { log } from "../../../util/logger.js"
import type { TabData } from "../../../util/tabs.js"
import { SearchInput } from "../../Form/SearchInput.js"
import { IconButtonWithTooltip } from "../../IconButtonWithTooltip.js"
import { DownloadIcon, LinkIcon } from "../../icons/icons.js"
import TableSaveViewMenu from "../TableSaveViewMenu.js"
import type { SavedViewProps } from "../types.js"
import AddFilterPopover from "./AddFilterPopover.js"
import AppliedFiltersRow from "./AppliedFiltersRow.js"

export interface TableFiltersProps extends SavedViewProps {
  tableId: TableIdentifier

  primarySearch?: {
    column: string
    currentPrimaryFilter: ContainsFilter | undefined | null
    placeholder?: string
    onPrimaryFilterChange: (value: ContainsFilter | null) => void
  }

  tabProps?: {
    data: TabData[]
    selectedIndex: number
    handleChangeTab: (index: number) => void
  }

  /** Selected columns for the table which are a subset of the filter definitions, ordering of the columns is important */
  selectedColumns: readonly string[]
  /** An array of valid paths of filters that are always pinned to the top  */
  pinnedFilters?: string[]

  objectSchema: ReadonlyDeep<JSONSchema>
  filterMap: ReadonlyMap<string, Filter>
  onChangeFilters: (filterMap: ReadonlyMap<string, Filter>) => void
  queryAiFilter?: (query: string) => Promise<void>

  /**
   * Custom component that is right aligned on the same row as the main row where Tabs, Add Filter, and Primary Search are
   * but appears before the saved view and copy URL buttons. Used for things like document extraction status spinners
   */
  beforeSavedView?: ReactNode

  /**
   * Custom component that is right aligned on the same row as the main row where Tabs, Add Filter, and Primary Search are
   * but appears after the saved view and copy URL buttons. Used for things like document upload.
   */
  afterSavedView?: ReactNode

  isExporting?: boolean

  /** CSV export callback that returns the exported url to download, we will render csv export button if this is non null */
  exportCsv?: () => Promise<string | undefined>

  /** Additional filters for pickable objects query */
  pickableFilters?: Partial<Record<ObjectType, object>>

  /** If true, the "Show all filters" option will not be shown */
  hideShowAllFilters?: boolean

  /** Title for the table */
  title?: string
  headerProps?: StackProps
}

/**
 * Table Filter is a component that renders both a primary search filter along with a {@link AppliedFiltersRow} component
 */
export default function TablePageHeader({
  tableId,
  objectSchema,
  primarySearch,
  tabProps,
  queryAiFilter,
  selectedColumns,

  filterMap,
  onChangeFilters,
  pinnedFilters,

  savedViews,
  savedViewState,
  onSavedViewStateChange,

  beforeSavedView,
  afterSavedView,
  isExporting,
  exportCsv,
  pickableFilters,
  hideShowAllFilters = false,

  title,
  headerProps,
}: TableFiltersProps) {
  const toast = useToast()
  const intl = useIntl()

  const copyLinkLabel = intl.formatMessage({
    id: "tableFilters.copyUrlToClipboard",
    description: "Tooltip and aria label for the copy url to clipboard button",
    defaultMessage: "Copy table URL to clipboard",
  })

  const exportCsvLabel = intl.formatMessage({
    id: "tableFilters.exportToCsv",
    description: "Tooltip and aria label for csv export button",
    defaultMessage: "Export table to CSV file",
  })

  // Primary search filters are always text input and use the "contains" comparator
  const handlePrimaryFilterChange = (value: string | null) => {
    if (!primarySearch) {
      return
    }
    primarySearch.onPrimaryFilterChange(value ? { comparator: "contains", value } : null)
  }
  const debouncedHandlePrimaryFilterChange = useDebouncedCallback(handlePrimaryFilterChange, 200)

  const primaryFilter = primarySearch && primarySearch.currentPrimaryFilter

  const [primarySearchQuery, setPrimarySearchQuery] = useState<string>(primaryFilter?.value ?? "")

  useEffect(() => {
    setPrimarySearchQuery(primaryFilter?.value ?? "")
  }, [primaryFilter?.value])

  const [newFilterPath, setNewFilterPath] = useState<string | undefined>()
  return (
    <Stack gap="0.5rem" paddingBottom={3} {...headerProps}>
      <HStack maxHeight="2.75rem" gap="1rem">
        {title && <Heading size="xs">{title}</Heading>}
        {tabProps && (
          <Tabs onChange={tabProps.handleChangeTab} index={tabProps.selectedIndex} variant="enclosed-shaded">
            <TabList justifyContent="space-between">
              {tabProps.data.map((tab, index) => (
                <Tab key={tab.locationHash} isDisabled={tab.isDisabled}>
                  <Text as="span" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                    {tab.label}
                  </Text>
                  <Flex justifyContent="end">
                    <Badge
                      opacity={tab.total === undefined ? 0 : 1}
                      variant={index === tabProps.selectedIndex ? "subtleOutlined" : "outline"}
                      size="sm"
                      marginLeft={2}
                      borderRadius="full"
                      colorScheme={index === tabProps.selectedIndex ? "brand" : "gray"}
                    >
                      <chakra.div minW="3ch" minH="1lh">
                        {tab.total}
                      </chakra.div>
                    </Badge>
                  </Flex>
                </Tab>
              ))}
            </TabList>
          </Tabs>
        )}
        <AddFilterPopover
          pickableFilters={pickableFilters}
          pinnedFilters={pinnedFilters}
          objectSchema={objectSchema}
          selectedColumns={selectedColumns}
          filterPath={newFilterPath}
          hideShowAllFilters={hideShowAllFilters}
          onFilterPathChange={setNewFilterPath}
          filter={newFilterPath ? filterMap.get(newFilterPath)?.fields : undefined}
          onFilterChange={(filter) => {
            if (newFilterPath) {
              const copy = new Map(filterMap)
              copy.set(newFilterPath, { column: newFilterPath, fields: filter })
              onChangeFilters(copy)
            }
          }}
          onClose={() => setNewFilterPath(undefined)}
          queryAiFilter={queryAiFilter}
        />
        {primarySearch && (
          <SearchInput
            value={primarySearchQuery}
            onClear={() => {
              handlePrimaryFilterChange(null)
              setPrimarySearchQuery("")
            }}
            onChange={(e) => {
              const value = e.target.value
              setPrimarySearchQuery(value)
              debouncedHandlePrimaryFilterChange(value)
            }}
            placeholder={primarySearch.placeholder}
            size="md"
            width="30ch"
            flexGrow={0}
            flexShrink={1}
          />
        )}
        {/* Spacer */}
        <Box flexGrow={1} />
        <HStack gap={2}>
          {exportCsv && (
            <IconButtonWithTooltip
              label={exportCsvLabel}
              isLoading={isExporting}
              variant="outline"
              aria-label={exportCsvLabel}
              icon={<Icon as={DownloadIcon} />}
              onClick={async () => {
                try {
                  const url = await exportCsv()
                  if (url) {
                    const link = document.createElement("a")
                    link.href = url
                    link.click()
                  }
                } catch (err) {
                  log.error("Failed to export CSV file", err)
                  toast({
                    description: intl.formatMessage({
                      id: "tool.list.filer.csv.error",
                      description: "error message when the csv export fails",
                      defaultMessage: "Failed to export CSV file",
                    }),
                    status: "error",
                  })
                }
              }}
            />
          )}
          {beforeSavedView}
          {savedViewState && onSavedViewStateChange && (
            <>
              <IconButtonWithTooltip
                label={copyLinkLabel}
                variant="outline"
                icon={<Icon as={LinkIcon} />}
                onClick={async (e) => {
                  if (savedViewState.view && !savedViewState.view.is_shared) {
                    toast({
                      status: "warning",
                      description: intl.formatMessage({
                        id: "dataTable.copyUrlToClipboard.error.description",
                        description: "error toast description when trying to copy a private view url",
                        defaultMessage:
                          "This view is only visible to you. Please share it to ensure others can access it.",
                      }),
                    })
                  }
                  e.stopPropagation()
                  const url = new URL(window.location.href)
                  await navigator.clipboard.writeText(url.href)
                }}
              />
              <TableSaveViewMenu
                savedViewState={savedViewState}
                onUpdate={(updatedView) => onSavedViewStateChange({ id: updatedView.id, view: updatedView })}
                onDelete={() => {
                  const defaultView = savedViews?.[0]
                  if (defaultView) {
                    onSavedViewStateChange?.({ id: defaultView.id, view: defaultView })
                  }
                }}
                tableId={tableId}
              />
            </>
          )}
          {afterSavedView}
        </HStack>
      </HStack>
      {filterMap.size > 0 && (
        <AppliedFiltersRow
          objectSchema={objectSchema}
          filterMap={filterMap}
          onChange={onChangeFilters}
          pickableFilters={pickableFilters}
        />
      )}
    </Stack>
  )
}
