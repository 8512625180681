import { Temporal } from "@js-temporal/polyfill"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMemo } from "react"
import { useIntl } from "react-intl"
import { Navigate } from "react-router-dom"
import { usePostLegalV1AgreementsListQuery } from "../../../app/services/generated-api.js"
import { packageSortFilterOptionsForAPI } from "../../../util/schema-table.js"
import { useObjectSchema } from "../../../util/use-schema.js"
import { baseUnverifiedAgreementsApiParams } from "./util.js"

export default function LegalAgreementOnboarding() {
  const intl = useIntl()
  const legalAgreementSchema = useObjectSchema("LegalAgreement")
  const apiParams = useMemo(
    () =>
      legalAgreementSchema &&
      packageSortFilterOptionsForAPI<string>(baseUnverifiedAgreementsApiParams, legalAgreementSchema, intl),
    [legalAgreementSchema, intl]
  )
  const { data: pendingAgreements } = usePostLegalV1AgreementsListQuery(
    apiParams ? { listQueryStringParams: apiParams } : skipToken
  )
  if (!pendingAgreements) {
    return null
  }
  // Sample agreement will always be the first agreement created in the org
  const sampleAgreement = pendingAgreements.items.toSorted((a, b) =>
    Temporal.Instant.compare(a.created_at, b.created_at)
  )[0]
  if (!sampleAgreement) {
    return <Navigate to="/agreements" />
  }
  return <Navigate to={`/agreements/${sampleAgreement.id}`} />
}
