import { isDocumentOrURLStringType, isFrequencyOrOneTimeType } from "@brm/schema-helpers/schema.js"
import { DateDurationString } from "@brm/schemas"
import {
  isArrayLengthConditionalSchema,
  isBooleanConditionalSchema,
  isCompliantWithDocumentConditionalSchema,
  isCurrencyAmountConditionalSchema,
  isDateStringConditionalSchema,
  isDurationStringConditionalSchema,
  isEnumArrayConditionalSchema,
  isNumberConditionalSchema,
} from "@brm/type-helpers/conditional-schema.js"
import { isArraySchema, isCompliantWithDocumentType, isCurrencyAmountType } from "@brm/type-helpers/schema.js"
import {
  getEnumOptions,
  isBooleanType,
  isEnumArrayType,
  isIntegerType,
  isNotNullSchema,
  isNullSchema,
  isNumberType,
  isStringType,
} from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import { Alert, AlertDescription, AlertIcon, Button, GridItem, HStack, Input, Tooltip } from "@chakra-ui/react"
import type { JSONSchema, JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { FormattedMessage, useIntl } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { log } from "../../util/logger.js"
import { BooleanSelect } from "../Form/BooleanSelect.js"
import { CurrencyAmountInputGroup } from "../Form/CurrencyAmountInput.js"
import { DATE_DURATION_UNITS, DATE_TIME_DURATION_UNITS } from "../Form/duration-unit.js"
import { DurationInputGroup } from "../Form/DurationInput.js"
import Select from "../Select/Select.js"
import { DatePickerInput } from "./DatePicker.js"

export interface SchemaComparatorProps {
  conditionalSchema: ReadonlyDeep<JSONSchemaObject>
  referenceSchema: ReadonlyDeep<JSONSchema>
  onChange: (value: ReadonlyDeep<JSONSchemaObject>) => void
}

const onChangeMock = () => (_: unknown) => {}
export function SchemaComparator(props: SchemaComparatorProps) {
  const { conditionalSchema, referenceSchema, onChange } = props
  const intl = useIntl()

  if (!isObject(referenceSchema)) {
    return null
  }

  if (isNullSchema(conditionalSchema) || isNotNullSchema(conditionalSchema)) {
    return (
      <>
        <GridItem colSpan={1}>
          <IsOrIsNotComparator
            value={{ value: isNullSchema(conditionalSchema) }}
            onChange={(newValue) => {
              if (!newValue.value) {
                onChange({ not: { const: null } })
              } else {
                onChange({ const: null })
              }
            }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Input
            value={intl.formatMessage({
              defaultMessage: "Empty",
              id: "condition.value.empty",
              description: "Condition value describing an empty value",
            })}
            readOnly
          />
        </GridItem>
      </>
    )
  }

  if (isBooleanType(referenceSchema)) {
    if (!isBooleanConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaWarning />
    }
    return (
      <>
        <GridItem colSpan={1}>
          <IsOrIsNotComparator value={{ value: true }} onChange={onChangeMock} isReadOnly />
        </GridItem>
        <GridItem colSpan={2}>
          <BooleanSelect
            onChange={(value) => (typeof value === "boolean" ? onChange({ type: "boolean", const: value }) : null)}
            value={conditionalSchema.const}
          />
        </GridItem>
      </>
    )
  }

  if ((isStringType(referenceSchema) || isDocumentOrURLStringType(referenceSchema)) && conditionalSchema.pattern) {
    return (
      <>
        <GridItem colSpan={1}>
          <Input
            value={intl.formatMessage({
              defaultMessage: "contains",
              id: "condition.value.string.contains",
              description: "Condition value describing a string contains pattern",
            })}
            readOnly
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Input
            onChange={(e) => onChange({ type: "string", pattern: e.target.value })}
            value={conditionalSchema.pattern}
          />
        </GridItem>
      </>
    )
  }

  if (
    (isStringType(referenceSchema) && referenceSchema.format === "duration") ||
    isFrequencyOrOneTimeType(referenceSchema)
  ) {
    if (!isDurationStringConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaWarning />
    }
    const currValue =
      "formatMinimum" in conditionalSchema ? conditionalSchema.formatMinimum : conditionalSchema.formatMaximum
    return (
      <>
        <GridItem colSpan={1}>
          <GreaterThanLessThanComparator
            value={{ value: "formatMinimum" in conditionalSchema ? "greaterThan" : "lessThan" }}
            onChange={(newOption) => {
              if (newOption.value === "lessThan") {
                onChange({
                  type: "string",
                  format: "duration",
                  formatMaximum: currValue,
                })
              } else {
                onChange({
                  type: "string",
                  format: "duration",
                  formatMinimum: currValue,
                })
              }
            }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <DurationInputGroup
            value={currValue}
            onChange={(newDuration) => {
              if (newDuration) {
                if ("formatMinimum" in conditionalSchema) {
                  onChange({
                    type: "string",
                    format: "duration",
                    formatMinimum: newDuration.toString(),
                  })
                } else {
                  onChange({
                    type: "string",
                    format: "duration",
                    formatMaximum: newDuration.toString(),
                  })
                }
              }
            }}
            units={
              referenceSchema.pattern === DateDurationString.pattern ? DATE_DURATION_UNITS : DATE_TIME_DURATION_UNITS
            }
          />
        </GridItem>
      </>
    )
  }

  if (isStringType(referenceSchema) && referenceSchema.format === "date") {
    if (!isDateStringConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaWarning />
    }
    const currValue =
      "formatMinimum" in conditionalSchema ? conditionalSchema.formatMinimum : conditionalSchema.formatMaximum
    return (
      <>
        <GridItem colSpan={1}>
          <GreaterThanLessThanComparator
            value={{ value: "formatMinimum" in conditionalSchema ? "greaterThan" : "lessThan" }}
            onChange={(newOption) => {
              if (newOption.value === "lessThan") {
                onChange({
                  type: "string",
                  format: "date",
                  formatMaximum: currValue,
                })
              } else {
                onChange({
                  type: "string",
                  format: "date",
                  formatMinimum: currValue,
                })
              }
            }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <DatePickerInput
            onChange={(newDate) => {
              if (newDate) {
                if ("formatMinimum" in conditionalSchema) {
                  onChange({
                    type: "string",
                    format: "date",
                    formatMinimum: newDate.toString(),
                  })
                } else {
                  onChange({
                    type: "string",
                    format: "date",
                    formatMaximum: newDate.toString(),
                  })
                }
              }
            }}
            value={currValue}
            ariaLabel={intl.formatMessage({
              id: "form.date.ariaLabel",
              defaultMessage: "Enter date",
              description: "Date input field aria label",
            })}
          />
        </GridItem>
      </>
    )
  }

  if (isIntegerType(referenceSchema) || isNumberType(referenceSchema)) {
    if (!isNumberConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaWarning />
    }
    const currValue =
      "exclusiveMinimum" in conditionalSchema ? conditionalSchema.exclusiveMinimum : conditionalSchema.exclusiveMaximum
    return (
      <>
        <GridItem colSpan={1}>
          <GreaterThanLessThanComparator
            value={{ value: "exclusiveMinimum" in conditionalSchema ? "greaterThan" : "lessThan" }}
            onChange={(newOption) => {
              if (newOption.value === "lessThan") {
                onChange({
                  type: "number",
                  exclusiveMaximum: currValue,
                })
              } else {
                onChange({
                  type: "number",
                  exclusiveMinimum: currValue,
                })
              }
            }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Input
            type="number"
            value={currValue}
            onChange={(e) => {
              const numberValue = Number(e.target.value)
              if (isNaN(numberValue)) {
                return
              }
              onChange(
                "exclusiveMinimum" in conditionalSchema
                  ? { type: "number", exclusiveMinimum: numberValue }
                  : { type: "number", exclusiveMaximum: numberValue }
              )
            }}
            step={isIntegerType(referenceSchema) ? 1 : undefined}
          />
        </GridItem>
      </>
    )
  }

  if (isEnumArrayType({ schema: referenceSchema })) {
    if (!isEnumArrayConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaWarning />
    }

    const options = Array.from(
      getEnumOptions(referenceSchema).map((option) => ({
        label: option.title,
        value: option.const,
      }))
    )
    const enumSchema = "not" in conditionalSchema ? conditionalSchema.not : conditionalSchema
    const value = enumSchema.contains.const
    const selectedOption = options.find((option) => option.value === value)
    return (
      <>
        <GridItem colSpan={1}>
          <ContainsComparator
            value={{ value: "not" in conditionalSchema ? "does_not_contain" : "contains" }}
            onChange={(contains) => {
              if (contains.value === "contains") {
                onChange({ ...enumSchema, type: "array" })
              } else {
                onChange({ type: "array", not: enumSchema })
              }
            }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Select<{ label: string; value: unknown }, false>
            value={selectedOption}
            options={options}
            onChange={(selectedOption) => {
              if (!selectedOption) {
                return
              }
              if ("not" in conditionalSchema) {
                onChange({
                  type: "array",
                  not: {
                    ...conditionalSchema.not,
                    contains: { ...conditionalSchema.not.contains, const: selectedOption.value },
                  },
                })
              } else {
                onChange({
                  ...conditionalSchema,
                  contains: { ...conditionalSchema.contains, const: selectedOption.value },
                  type: "array",
                })
              }
            }}
          />
        </GridItem>
      </>
    )
  }

  if (isCurrencyAmountType(referenceSchema)) {
    if (!isCurrencyAmountConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaWarning />
    }
    const currValue =
      "formatMinimum" in conditionalSchema.properties.amount
        ? conditionalSchema.properties.amount.formatMinimum
        : conditionalSchema.properties.amount.formatMaximum
    const currencyValue = {
      amount: currValue,
      currency_code: conditionalSchema.properties.currency_code.const,
    }
    return (
      <>
        <GridItem colSpan={1}>
          <GreaterThanLessThanComparator
            value={{ value: "formatMinimum" in conditionalSchema.properties.amount ? "greaterThan" : "lessThan" }}
            onChange={(newOption) => {
              if (newOption.value === "lessThan") {
                onChange({
                  type: "object",
                  properties: {
                    amount: { formatMaximum: currValue, format: "number", type: "string" },
                    currency_code: { type: "string", const: conditionalSchema.properties.currency_code.const },
                  },
                })
              } else {
                onChange({
                  type: "object",
                  properties: {
                    amount: { formatMinimum: currValue, format: "number", type: "string" },
                    currency_code: { type: "string", const: conditionalSchema.properties.currency_code.const },
                  },
                })
              }
            }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <CurrencyAmountInputGroup
            value={currencyValue}
            onChange={(newCurrencyAmount) => {
              if (newCurrencyAmount) {
                onChange({
                  type: "object",
                  properties: {
                    amount:
                      "formatMinimum" in conditionalSchema.properties.amount
                        ? { formatMinimum: newCurrencyAmount.amount, format: "number", type: "string" }
                        : { formatMaximum: newCurrencyAmount.amount, format: "number", type: "string" },
                    currency_code: { type: "string", const: newCurrencyAmount.currency_code },
                  },
                })
              }
            }}
          />
        </GridItem>
      </>
    )
  }

  if (isCompliantWithDocumentType(referenceSchema)) {
    if (!isCompliantWithDocumentConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaWarning />
    }
    return (
      <>
        <GridItem colSpan={1}>
          {/* readOnly field because the value is either true or false, only allow user to toggle that instead of is or is not */}
          <IsOrIsNotComparator value={{ value: true }} onChange={onChangeMock} isReadOnly />
        </GridItem>
        <GridItem colSpan={2}>
          <BooleanSelect
            onChange={(value) =>
              onChange({
                type: "object",
                properties: { compliant: { type: "boolean", const: value } },
              })
            }
            value={conditionalSchema.properties.compliant.const}
          />
        </GridItem>
      </>
    )
  }

  if (isArraySchema(referenceSchema)) {
    if (!isArrayLengthConditionalSchema(conditionalSchema)) {
      return <InvalidSchemaWarning />
    }
    if (conditionalSchema.minItems === 1) {
      return (
        <>
          <GridItem colSpan={1}>
            <IsOrIsNotComparator value={{ value: false }} onChange={onChangeMock} isReadOnly />
          </GridItem>
          <GridItem colSpan={2}>
            <Input
              value={intl.formatMessage({
                defaultMessage: "Empty",
                id: "condition.value.empty",
                description: "Condition value describing an empty value",
              })}
              readOnly
            />
          </GridItem>
        </>
      )
    }
    if (conditionalSchema.maxItems === 0) {
      return (
        <>
          <GridItem colSpan={1}>
            <IsOrIsNotComparator value={{ value: true }} onChange={onChangeMock} isReadOnly />
          </GridItem>
          <GridItem colSpan={2}>
            <Input
              value={intl.formatMessage({
                defaultMessage: "Empty",
                id: "condition.value.empty",
                description: "Condition value describing an empty value",
              })}
              readOnly
            />
          </GridItem>
        </>
      )
    }
  }

  log.error(`Unsupported schema comparator: ${JSON.stringify(referenceSchema)}`, null)
  return <InvalidSchemaWarning />
}

interface IsOrIsNot {
  value: boolean
}

interface Contains {
  value: "contains" | "does_not_contain"
}

function IsOrIsNotComparator({
  value,
  onChange,
  isReadOnly,
}: {
  value: IsOrIsNot
  onChange: (value: IsOrIsNot) => void
  isReadOnly?: boolean
}) {
  const intl = useIntl()
  return (
    <Select<IsOrIsNot, false>
      value={value}
      options={[{ value: true }, { value: false }]}
      getOptionLabel={(opt) =>
        opt.value
          ? intl.formatMessage({
              defaultMessage: "is",
              id: "condition.comparator.is",
              description: "Condition comparator describing a field being equal to a value",
            })
          : intl.formatMessage({
              defaultMessage: "is not",
              id: "condition.comparator.isNot",
              description: "Condition comparator describing a field being not equal to a value",
            })
      }
      getOptionValue={(opt) => String(opt.value)}
      onChange={(opt) => onChange(opt ?? { value: true })}
      isReadOnly={isReadOnly}
    />
  )
}

function ContainsComparator({ value, onChange }: { value: Contains; onChange: (value: Contains) => void }) {
  const intl = useIntl()
  return (
    <Select<Contains, false>
      value={value}
      options={[{ value: "contains" }, { value: "does_not_contain" }]}
      getOptionLabel={(opt) =>
        opt.value === "contains"
          ? intl.formatMessage({
              defaultMessage: "has",
              id: "condition.comparator.has",
              description: "Condition comparator describing a field being equal to a value",
            })
          : intl.formatMessage({
              defaultMessage: "doesn’t have",
              id: "condition.comparator.doesNotHave",
              description: "Condition comparator describing a field not containing a value",
            })
      }
      onChange={(opt) => onChange(opt ?? { value: "contains" as const })}
    />
  )
}

interface GreaterThanLessThan {
  value: "greaterThan" | "lessThan"
}

function GreaterThanLessThanComparator({
  value,
  onChange,
}: {
  value: GreaterThanLessThan
  onChange: (value: GreaterThanLessThan) => void
}) {
  const intl = useIntl()
  return (
    <Select<GreaterThanLessThan, false>
      value={value}
      options={[{ value: "greaterThan" }, { value: "lessThan" }]}
      getOptionLabel={(opt) =>
        opt.value === "greaterThan"
          ? intl.formatMessage({
              defaultMessage: ">",
              id: "condition.comparator.greaterThan",
              description: "Condition comparator describing a field being greater than a value",
            })
          : intl.formatMessage({
              defaultMessage: "<",
              id: "condition.comparator.lessThan",
              description: "Condition comparator describing a field being less than a value",
            })
      }
      getOptionValue={(opt) => opt.value}
      onChange={(opt) => onChange(opt ?? { value: "greaterThan" })}
    />
  )
}

function InvalidSchemaWarning() {
  const intl = useIntl()
  return (
    <GridItem as={HStack} colSpan={2}>
      <Tooltip
        label={intl.formatMessage({
          defaultMessage: "Something went wrong while creating this condition. Please try again or contact support.",
          description: "Invalid schema alert description",
          id: "condition.comparator.invalidSchema",
        })}
      >
        <Alert
          status="error"
          py={1}
          as={Button}
          onClick={() => {
            window.Pylon?.("show")
          }}
        >
          <AlertIcon />
          <AlertDescription>
            <FormattedMessage
              defaultMessage="Contact support"
              description="Invalid schema alert description"
              id="condition.comparator.invalidSchema"
            />
          </AlertDescription>
        </Alert>
      </Tooltip>
    </GridItem>
  )
}
