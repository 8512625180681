// Filter row height is enforced to stay in sync with the offset required for table sticky column headers and filters

import type { FilterField } from "@brm/schema-types/types.js"
import { isObject } from "@brm/util/type-guard.js"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import type { IntlShape } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { getDateGreaterThanEqualFilterOptions, getDateLessThanFilterOptions } from "./options.js"

// 350 is roughly distance the filter menu list opens from the top of the TableFilter header plus a pagination control buffer at the bottom
export const FILTER_POPOVER_MAX_HEIGHT_CALC = "calc(100vh - 350px)"

// Matches the 2rem height used in the theme for menu items
export const FILTER_POPOVER_ITEM_HEIGHT = 32

export function formatComparator(filter: FilterField, fieldSchema: ReadonlyDeep<JSONSchema>, intl: IntlShape): string {
  if (isObject(fieldSchema) && (fieldSchema.format === "date" || fieldSchema.format === "date-time")) {
    let comparator = filter.comparator
    // This is a bit confusing, but when a predefined option is selected, the comparator used is "eq".
    // In that case, we actually want to use the display text of the "gte" or "lt" comparator, which ever is actually used.
    if (filter.comparator === "eq") {
      const options =
        fieldSchema.defaultFilter === "lt"
          ? getDateLessThanFilterOptions(intl)
          : getDateGreaterThanEqualFilterOptions(intl)
      if (Object.hasOwn(options, filter.value)) {
        comparator = fieldSchema.defaultFilter ?? "lt"
      }
    }
    switch (comparator) {
      case "eq":
        return intl.formatMessage({
          defaultMessage: "is",
          description:
            "Text in a filter between the column name and the values when a user is searching for a relative date",
          id: "filter.date.eq",
        })
      case "gte":
        return intl.formatMessage({
          defaultMessage: "later than",
          description:
            "Text in a filter between the column name and the values when a user is searching a date later than the value",
          id: "filter.date.gte",
        })
      case "lt":
        return intl.formatMessage({
          defaultMessage: "earlier than",
          description:
            "Text in a filter between the column name and the values when a user is searching a date earlier than the value",
          id: "filter.date.lte",
        })
    }
  }
  switch (filter.comparator) {
    case "eq":
      return "="
    case "ne":
      return "≠"
    case "gt":
      return ">"
    case "gte":
      return "≥"
    case "range_gte":
      return "≥"
    case "lt":
      return "<"
    case "lte":
      return "≤"
    case "between":
      return intl.formatMessage({
        defaultMessage: "between",
        description:
          "Text in a filter between the column name and the values when a user is searching a single value between a range",
        id: "filter.between",
      })
    case "range_within":
      return intl.formatMessage({
        defaultMessage: "is",
        description:
          "Text in a filter between the column name and the values when a user is searching a range value within another range",
        id: "filter.range_within",
      })
    case "contains":
      return intl.formatMessage({
        defaultMessage: "contains",
        description:
          "Text in a filter between the column name and the values when a user is searching a string that contains a substring",
        id: "filter.contains",
      })
    case "starts_with":
      return intl.formatMessage({
        defaultMessage: "starts with",
        description:
          "Text in a filter between the column name and the values when a user is searching a value that starts with a string",
        id: "filter.starts_with",
      })
    case "any":
      return intl.formatMessage({
        defaultMessage: "is",
        description:
          "Text in a filter between the column name and the values when a user is searching a value within a list",
        id: "filter.any",
      })
    case "arr_contains":
      return intl.formatMessage({
        defaultMessage: "is one of",
        description:
          "Text in a filter between the column name and the values when a user is searching an array value that contains any value from a list",
        id: "filter.arr_contains",
      })
    case "exists":
      return intl.formatMessage({
        defaultMessage: "is",
        description:
          "Text in a filter between the column name and the values when a user is searching for a value that exists",
        id: "filter.exists",
      })
  }
}
