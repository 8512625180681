import type {
  FieldMetadata,
  FieldMetadataWithSuggestions,
  VendorOption,
  VendorOptionWithTools,
} from "@brm/schema-types/types.js"
import { useDisclosure, type UseModalProps } from "@chakra-ui/react"
import { type ReactNode } from "react"
import { type RefCallBack } from "react-hook-form"
import { usePostVendorV1Mutation } from "../../app/services/generated-api.js"
import type { DynamicFormFieldApproval, ValueWithSource } from "../../components/DynamicForm/types.js"
import { VendorOptionPicker, VendorOptionPickerCreatable } from "../../components/Form/VendorOptionPicker.js"
import { NewEntityModal } from "../NewEntityModal.js"

type NewVendorModalProps = UseModalProps & {
  setVendor: (vendor: VendorOption | null) => void
  vendor: VendorOption
}

export function NewVendorModal(props: NewVendorModalProps): ReactNode {
  const [createVendor] = usePostVendorV1Mutation()
  return (
    <NewEntityModal<VendorOption>
      {...props}
      setEntity={props.setVendor}
      entity={props.vendor}
      config={{
        type: "Vendor",
        renderEntityDisplay: ({ value }) => <VendorOptionPicker value={value} isReadOnly />,
        createMutation: async (data) => await createVendor(data).unwrap(),
      }}
    />
  )
}

export function VendorOptionPickerCreatableWithModal(props: {
  autoFocus: boolean
  allowCreate: boolean
  value: VendorOptionWithTools | undefined
  onChange: (vendor?: VendorOptionWithTools | null, fieldSource?: FieldMetadata) => void
  onCreate: (vendor: VendorOptionWithTools | null) => void
  suggestions?: ValueWithSource<VendorOptionWithTools>[]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: DynamicFormFieldApproval
  isReadOnly?: boolean
  inputRef: RefCallBack
}): ReactNode {
  const { onChange, value, onCreate, ...rest } = props
  const newVendorModal = useDisclosure()

  return (
    <>
      {newVendorModal.isOpen && value && (
        <NewVendorModal
          {...newVendorModal}
          setVendor={(vendor) => {
            onCreate(vendor)
          }}
          vendor={value}
        />
      )}
      <VendorOptionPickerCreatable
        value={value && { value }}
        onChange={(vendor) => {
          onChange(vendor?.value || null, vendor?.field_sources?.[0])
          if (vendor && vendor?.value?.vendor_listing_id === "new") {
            newVendorModal.onOpen()
          }
        }}
        {...rest}
      />
    </>
  )
}
