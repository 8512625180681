import type { InvitedSellerUser } from "@brm/schema-types/types.js"
import { formatPersonName } from "@brm/util/names.js"
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Avatar,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  chakra,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { type FC } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import avatarGroup from "../../../../assets/avatarGroup.png"
import {
  usePostWorkflowV1LinksByCodeMutation,
  usePostWorkflowV1LinksMutation,
} from "../../../app/services/generated-api.js"
import TrailingButtonInput from "../../../components/Form/TrailingButtonInput.js"
import { AddSellerUserIcon, AddSellerUsersIcon, LinkIcon, UserIcon } from "../../../components/icons/icons.js"
import { RelativeDateTimeDisplay } from "../../../components/Timestamp.js"
import { getAPIErrorMessage, getAPIErrorStatusCode } from "../../../util/error.js"
import type { WorkflowRunWithExternalFlag } from "./utils.js"

export function ShareBrmLinkPopover({
  workflowRun,
  vendorName,
  isSignedOutView,
}: {
  workflowRun: Pick<WorkflowRunWithExternalFlag, "id" | "steps" | "seller_users" | "is_external" | "links">
  vendorName?: string
  isSignedOutView: boolean
}): JSX.Element {
  const intl = useIntl()
  const toast = useToast()
  const [createLink, createLinkResult] = usePostWorkflowV1LinksMutation()
  const [createLinkForSignedOutUser, createLinkForSignedOutUserResult] = usePostWorkflowV1LinksByCodeMutation()
  const popover = useDisclosure()
  const { code } = useParams<{ code: string }>()
  interface FormState {
    email: string
  }
  const form = useForm<FormState>({
    defaultValues: {
      email: "",
    } satisfies FormState,
  })

  const onSubmit = async (values: FormState) => {
    await (isSignedOutView
      ? createLinkForSignedOutUser({
          code: code || "",
          createBrmLinkInput: {
            email: values.email.trim(),
            workflow_run_id: workflowRun.id,
            is_seller: workflowRun.is_external,
          },
        })
      : createLink({
          createBrmLinkInput: {
            email: values.email.trim(),
            workflow_run_id: workflowRun.id,
            is_seller: workflowRun.is_external,
          },
        }))

    form.reset()
  }

  const createLinkResultErrorMessage =
    createLinkResult.isError && getAPIErrorStatusCode(createLinkResult.error) === 403
      ? getAPIErrorMessage(createLinkResult.error)
      : undefined
  const createLinkForSignedOutUserResultErrorMessage =
    createLinkForSignedOutUserResult.isError && getAPIErrorStatusCode(createLinkForSignedOutUserResult.error) === 403
      ? getAPIErrorMessage(createLinkForSignedOutUserResult.error)
      : undefined

  const copyLinkLabel = intl.formatMessage({
    defaultMessage: "Copy link to request",
    description: "The aria label for the copy link button",
    id: "requests.run.shareLink.copyLinkToClipboardButton.label",
  })

  const sellerAuthCode = workflowRun.links[0]?.auth_code

  return (
    <Popover {...popover} isLazy placement="bottom-end">
      <PopoverTrigger>
        {workflowRun.is_external ? (
          <Button leftIcon={<Icon as={AddSellerUsersIcon} />} variant="outline" colorScheme="gray" flexShrink={0}>
            <FormattedMessage
              defaultMessage="Invite teammates"
              description="The button label to create a shareable link to send to a seller’s teammates"
              id="requests.run.button.createShareableLink.toTeammates.label"
            />
          </Button>
        ) : (
          <Button rightIcon={<Icon as={AddSellerUsersIcon} />} variant="solid" colorScheme="brand" flexShrink={0}>
            <FormattedMessage
              defaultMessage="Invite seller"
              description="The button label to create a shareable link to send to a seller’s email"
              id="requests.run.button.createShareableLink.toSellers.label"
            />
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent width="md" maxH="70vh">
        <chakra.form display="contents" onSubmit={form.handleSubmit(onSubmit)}>
          <PopoverHeader padding={6} display="block" border="none">
            <Stack alignItems="center">
              <Image src={avatarGroup} alt="" padding={4} width="30%" />
              <Heading size="xs" as="h3">
                <FormattedMessage
                  defaultMessage="Share Request Link"
                  description="The title of the brm link request external input modal"
                  id="requests.run.link.title"
                />
              </Heading>
              <Text color="gray.600">
                {workflowRun.is_external ? (
                  <FormattedMessage
                    defaultMessage="Invite teammates to collaborate and help you complete the request"
                    description="The title of the brm link request external input modal when viewed by a seller"
                    id="requests.run.link.title.toTeammates"
                  />
                ) : vendorName ? (
                  <FormattedMessage
                    defaultMessage="Invite the {vendorName} sales team to help you complete this request!"
                    description="The title of the brm link request external input modal"
                    id="requests.run.link.title.withVendorName"
                    values={{ vendorName }}
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Invite the sales team to help you complete this request!"
                    description="The title of the brm link request external input modal"
                    id="requests.run.link.title.withoutVendorName"
                  />
                )}
              </Text>
            </Stack>
            <PopoverCloseButton />
          </PopoverHeader>
          <PopoverBody
            display="flex"
            flexDirection="column"
            gap={3}
            overflowY="auto"
            paddingTop={4}
            paddingLeft={6}
            paddingRight={6}
            paddingBottom={4}
          >
            <Controller
              name="email"
              control={form.control}
              rules={{
                required: true,
                validate: (value) =>
                  !workflowRun.seller_users
                    .map((user) => user.email.toLowerCase())
                    .includes(value.toLowerCase().trim()) ||
                  intl.formatMessage({
                    defaultMessage: "This email has already been invited",
                    description: "Error message when an email already has an invited",
                    id: "requests.run.shareLink.error.emailAlreadyInvited",
                  }),
              }}
              render={({ field, fieldState }) => (
                <FormControl isRequired={true} isInvalid={fieldState.invalid}>
                  <TrailingButtonInput
                    isLoading={createLinkResult.isLoading || createLinkForSignedOutUserResult.isLoading}
                    id={field.name}
                    buttonText={intl.formatMessage({
                      defaultMessage: "Invite",
                      description: "The button label to invite a seller to a request",
                      id: "requests.run.shareLink.inviteButton.label",
                    })}
                    size="md"
                    type="email"
                    onChange={field.onChange}
                    value={field.value}
                    buttonType="submit"
                    icon={<Icon as={AddSellerUserIcon} />}
                  />
                  <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                </FormControl>
              )}
            />
            {(createLinkResult.isError || createLinkForSignedOutUserResult.isError) && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>
                  {createLinkResultErrorMessage || createLinkForSignedOutUserResultErrorMessage || (
                    <FormattedMessage
                      defaultMessage="Error creating link"
                      description="Error message when creating a share link failed"
                      id="requests.run.shareLink.error"
                    />
                  )}
                </AlertTitle>
              </Alert>
            )}
            <SharedWithSection invitedSellers={workflowRun.seller_users} />
          </PopoverBody>
          {sellerAuthCode && (
            <PopoverFooter padding={6}>
              <HStack justifyContent="space-between">
                <Text size="sm">
                  {workflowRun.is_external ? (
                    <FormattedMessage
                      defaultMessage="Anyone with the link can edit"
                      description="Explanation of permissions person has if they get the link"
                      id="requests.run.link.description.external"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Anyone with the link can edit, comment and submit information back to you to review"
                      description="Explanation of permissions person has if they get the link"
                      id="requests.run.link.description.internal"
                    />
                  )}
                </Text>

                <Tooltip closeOnClick={false} closeOnEsc={false} label={copyLinkLabel}>
                  <IconButton
                    icon={<Icon as={LinkIcon} />}
                    variant="outline"
                    aria-label={copyLinkLabel}
                    onClick={async () => {
                      const url = workflowRun.is_external
                        ? window.location
                        : new URL(`links/${sellerAuthCode}`, window.location.origin)
                      await navigator.clipboard.writeText(url.toString())
                      toast({
                        description: intl.formatMessage({
                          id: "requests.run.shareLink.copiedToClipboard",
                          description: "Message displayed when the user successfully copies request link to clipboard",
                          defaultMessage: "Copied to clipboard",
                        }),
                        status: "success",
                      })
                    }}
                  />
                </Tooltip>
              </HStack>
            </PopoverFooter>
          )}
        </chakra.form>
      </PopoverContent>
    </Popover>
  )
}

interface SharedWithSectionProps {
  invitedSellers: Array<InvitedSellerUser>
}

const SharedWithSection: FC<SharedWithSectionProps> = ({ invitedSellers }) => {
  if (invitedSellers.length === 0) {
    return null
  }
  return (
    <Stack spacing={4}>
      <Text fontWeight="medium">
        <FormattedMessage
          defaultMessage="Shared with"
          description="The title of the shared with section"
          id="requests.run.shareLink.sharedWithSection.label"
        />
      </Text>
      <Stack spacing={2} maxHeight={40} overflow="auto" paddingRight={2.5}>
        {invitedSellers.map((invitedSeller) => (
          <SharedPersonRow
            key={invitedSeller.email}
            firstName={invitedSeller.first_name}
            lastName={invitedSeller.last_name}
            email={invitedSeller.email}
            inviteSentAt={invitedSeller.invited_at}
            pending={invitedSeller.is_pending}
          />
        ))}
      </Stack>
    </Stack>
  )
}

interface SharedPersonRowProps {
  firstName: string | null
  lastName: string | null
  email: string
  inviteSentAt: string
  pending: boolean
}

const SharedPersonRow: FC<SharedPersonRowProps> = ({ email, firstName, lastName, inviteSentAt, pending }) => {
  const intl = useIntl()
  const displayName =
    firstName && lastName ? formatPersonName({ first_name: firstName, last_name: lastName }, intl) : undefined
  return (
    <HStack spacing={2} alignItems="center">
      <Avatar boxSize="10" icon={<Icon as={UserIcon} fontSize="20px" />} name={displayName || email} />
      {displayName ? (
        <Stack gap={0}>
          <Text size="xs" fontWeight="semibold">
            {displayName}
          </Text>
          <Text size="xs">{email}</Text>
        </Stack>
      ) : (
        <Text>{email}</Text>
      )}
      {pending && (
        <Tooltip
          label={
            <FormattedMessage
              defaultMessage="Link shared {relativeTime}"
              description="Tooltip label for a seller user that is still pending and has not opened this request"
              id="requests.run.shareLink.sharedWithSection.pending"
              values={{
                relativeTime: <RelativeDateTimeDisplay dateTime={inviteSentAt} />,
              }}
            />
          }
        >
          <Text size="sm" fontWeight="normal" marginLeft="auto">
            <FormattedMessage
              defaultMessage="Pending"
              description="A label on a seller user explaining this user is still pending and has not opened this request"
              id="requests.run.shareLink.sharedWithSection.pending"
            />
          </Text>
        </Tooltip>
      )}
    </HStack>
  )
}
