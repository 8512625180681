import type { LegalAgreementWithRenewal, WorkflowRunListItem } from "@brm/schema-types/types.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { Box } from "@chakra-ui/react"
import type { CreatableProps, GroupBase, Props, SelectInstance } from "chakra-react-select"
import type { Ref } from "react"
import { useState } from "react"
import { useIntl } from "react-intl"
import { usePostLegalV1AgreementsListQuery } from "../../app/services/generated-api.js"
import { chakraStylesForProfilePicturePickerOption } from "../../components/Form/constants.js"
import CreatableSelect from "../../components/Select/CreatableSelect.js"
import Select from "../../components/Select/Select.js"
import { getPublicImageGcsUrl } from "../../util/url.js"

export type LegalAgreementOption = Omit<LegalAgreementWithRenewal, "documents">
export type CreatableLegalAgreementOption =
  | LegalAgreementOption
  | { id?: undefined; display_name: string; vendor?: undefined }

export type LegalAgreementPickerRef = SelectInstance<LegalAgreementOption, false>
export type CreatableLegalAgreementPickerRef = SelectInstance<CreatableLegalAgreementOption, false>

export type LegalAgreementPickerProps =
  | ({
      allowCreate: true
      selectRef?: Ref<CreatableLegalAgreementPickerRef>
    } & CreatableProps<CreatableLegalAgreementOption, false, GroupBase<CreatableLegalAgreementOption>>)
  | ({
      allowCreate?: false
      selectRef?: Ref<LegalAgreementPickerRef>
    } & Props<LegalAgreementOption, false, GroupBase<LegalAgreementOption>>)

export function LegalAgreementPicker(props: LegalAgreementPickerProps) {
  const intl = useIntl()
  const [query, setQuery] = useState("")

  const { data, isLoading } = usePostLegalV1AgreementsListQuery({
    listQueryStringParams: {
      limit: 10,
      filter: query
        ? [
            [
              {
                column: "display_name" satisfies BRMPaths<WorkflowRunListItem>,
                fields: { comparator: "contains", value: query },
              },
            ],
          ]
        : undefined,
    },
  })

  return (
    <Box flexGrow={1}>
      {props.allowCreate ? (
        <CreatableSelect<CreatableLegalAgreementOption>
          options={data?.items || []}
          isLoading={isLoading}
          chakraStyles={chakraStylesForProfilePicturePickerOption((legalAgreement) =>
            getPublicImageGcsUrl(legalAgreement.vendor?.image_asset?.gcs_file_name)
          )}
          getOptionLabel={(legalAgreement) =>
            "id" in legalAgreement && legalAgreement.id
              ? legalAgreement.display_name
              : intl.formatMessage(
                  {
                    defaultMessage: "Create new agreement: “{name}”",
                    description: "The label for the create new option in the legal agreement picker",
                    id: "legalAgreementPicker.option.create.label",
                  },
                  { name: legalAgreement.display_name }
                )
          }
          getOptionValue={(legalAgreement) => legalAgreement.id ?? "new"}
          inputValue={query}
          onInputChange={setQuery}
          ref={props.selectRef}
          menuPortalTarget={document.body}
          getNewOptionData={(inputValue) => ({ display_name: inputValue })}
          {...props}
        />
      ) : (
        <Select<LegalAgreementOption>
          options={data?.items || []}
          isLoading={isLoading}
          chakraStyles={chakraStylesForProfilePicturePickerOption((legalAgreement) =>
            getPublicImageGcsUrl(legalAgreement.vendor?.image_asset?.gcs_file_name)
          )}
          getOptionLabel={(legalAgreement) => legalAgreement.display_name}
          getOptionValue={(legalAgreement) => legalAgreement.id}
          inputValue={query}
          onInputChange={setQuery}
          ref={props.selectRef}
          menuPortalTarget={document.body}
          {...props}
        />
      )}
    </Box>
  )
}
