import { Flex, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import { type FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { AtIcon, CheckIcon, FilterIcon, NotificationBoxIcon } from "../../components/icons/icons.js"

interface InboxFiltersMenuProps {
  filterByUnread: boolean
  filterByMentioned: boolean
  toggleFilterByUnread: () => void
  toggleFilterByMentioned: () => void
}

export const InboxFiltersMenu: FunctionComponent<InboxFiltersMenuProps> = ({
  filterByUnread,
  filterByMentioned,
  toggleFilterByUnread,
  toggleFilterByMentioned,
}) => (
  <Menu closeOnSelect={false}>
    {({ isOpen }) => (
      <>
        <MenuButton
          as={IconButton}
          variant="ghost"
          icon={
            <Icon as={FilterIcon} color={!isOpen && (filterByUnread || filterByMentioned) ? "brand.700" : undefined} />
          }
        />
        <MenuList>
          <MenuItem icon={<Icon as={NotificationBoxIcon} />} onClick={toggleFilterByUnread}>
            <Flex justifyContent="space-between" width="100%" alignItems="center" gap={2}>
              <FormattedMessage
                id="notifications.filterBy.unread"
                description="Filter by unread notifications"
                defaultMessage="Unread only"
              />
              <Icon as={CheckIcon} color="brand.700" visibility={filterByUnread ? "visible" : "hidden"} />
            </Flex>
          </MenuItem>
          <MenuItem icon={<Icon as={AtIcon} />} onClick={toggleFilterByMentioned}>
            <Flex justifyContent="space-between" width="100%" alignItems="center" gap={2}>
              <FormattedMessage
                id="notifications.filterBy.mentioned"
                description="Filter by mentioned notifications"
                defaultMessage="Mentioning me"
              />
              <Icon as={CheckIcon} color="brand.700" visibility={filterByMentioned ? "visible" : "hidden"} />
            </Flex>
          </MenuItem>
        </MenuList>
      </>
    )}
  </Menu>
)
