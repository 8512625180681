// eslint-disable-next-line no-restricted-imports
import { CreatableSelect as ChakraCreatableSelect, type CreatableSelectComponent } from "chakra-react-select"
import { forwardRef } from "react"
import DropdownIndicator from "./DropdownIndicator.js"

const CreatableSelect = forwardRef(function CreatableSelect(props, ref) {
  return (
    <ChakraCreatableSelect
      {...props}
      ref={ref}
      components={{
        ...props.components,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DropdownIndicator,
      }}
    />
  )
}) as CreatableSelectComponent
export default CreatableSelect
