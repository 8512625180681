export function scrollIntoViewCenter(ref: HTMLDivElement | null): void {
  setTimeout(() => {
    ref?.scrollIntoView({ behavior: "smooth", block: "center" })
  }, 500)
}

export const scrollIntoViewNearest = (ref: HTMLElement | null) => {
  ref?.setAttribute("style", `scroll-margin-top: ${ref?.scrollHeight - 1}px;`)
  ref?.scrollIntoView({ block: "nearest", behavior: "instant" })
}
