import { Temporal } from "@js-temporal/polyfill"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { generatedApi } from "../../app/services/generated-api.js"
import { log } from "../../util/logger.js"

const IDLE_TIMEOUT = 60_000 * 30 // 30 min idle reset
// Note that there is a matching heartbeat timer of 15_000 in the api subscribe/v1/ route
const CONNECTION_RESET_TIMEOUT = 30_000
const RETRY_MS = 10_000

/**
 * This subscription hook ensures we have a stable SSE connection for long periods of time.
 *
 * It accomplishes this via some server-side logic in the subscribe/v1 route to send a heartbeat,
 * two client side timers, and reset logic from fetchEventSource.
 *
 * The idle timer triggers a reset after a long period of inactivity.
 * The connection timer triggers a reset if we don't receive a heartbeat from the server.
 * The fetchEventSource onerror triggers an internal reset when we receive either an onclose event or a network error (ie. the server is dead).
 *
 * @param url
 */
export const useSubscribe = (url: string) => {
  const dispatch = useDispatch()

  const abortControllerRef = useRef<AbortController>()
  const connectionTimerRef = useRef<NodeJS.Timeout>()
  const idleTimerRef = useRef<NodeJS.Timeout>()

  const [reset, setReset] = useState({
    timestamp: Temporal.Now.instant().toString(),
  })

  useEffect(() => {
    // abort the previous connection
    if (abortControllerRef.current !== undefined) {
      abortControllerRef.current.abort()
    }

    abortControllerRef.current = new AbortController()

    // the origin is for debugging purposes
    const reset = (resetOrigin: string) => {
      log.debug(`[BRM] reset triggered by ${resetOrigin}`)
      abortControllerRef.current?.abort()
      clearTimeout(connectionTimerRef.current)
      clearTimeout(idleTimerRef.current)
      setReset(() => ({
        timestamp: Temporal.Now.instant().toString(),
      }))
    }

    const resetConnectionTimer = (resetOrigin: string) => {
      clearTimeout(connectionTimerRef.current)
      connectionTimerRef.current = setTimeout(() => reset(resetOrigin), CONNECTION_RESET_TIMEOUT)
    }

    const resetIdleTimer = (resetOrigin: string) => {
      clearTimeout(idleTimerRef.current)
      idleTimerRef.current = setTimeout(() => reset(resetOrigin), IDLE_TIMEOUT)
    }

    let isForbidden = false

    // https://github.com/Azure/fetch-event-source
    void fetchEventSource(url, {
      method: "GET",
      credentials: "include",
      openWhenHidden: true,
      keepalive: true,
      signal: abortControllerRef.current.signal,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Accept: "text/event-stream",
        ["Content-Type"]: "application/json",
      },
      async onopen(res) {
        if (res.ok && res.status === 200) {
          log.debug("[BRM] subscription opened")
          resetConnectionTimer("onopen heartbeat")
          resetIdleTimer("onopen idle")
          return
        }

        if (res.status >= 400 && res.status < 500) {
          switch (res.status) {
            case 401:
            case 403:
              isForbidden = true
              break
          }
          return
        }

        const errorMessage = "pubsub sse connection failure"
        log.error(errorMessage, res)
        throw new Error(errorMessage, { cause: res.statusText })
      },
      onmessage(e) {
        resetConnectionTimer("onmessage heartbeat")
        if (e.event === "invalidate") {
          log.debug(`[BRM] invalidate ${e.data}`)
          const tags = JSON.parse(e.data)
          if (tags && Array.isArray(tags)) {
            dispatch(generatedApi.util.invalidateTags(tags))
          } else {
            log.error("invalidate tags is not an array", tags)
          }
          resetIdleTimer("onmessage idle")
        }
        if (e.event === "heartbeat") {
          const data = JSON.parse(e.data)
          log.debug(`[BRM] ${data?.timestamp}`)
        }
      },
      onclose() {
        // don't retry if the connection was closed by a 401, 403
        if (isForbidden) {
          return
        }
        // throw a TypeError to retry
        throw new TypeError("connection was closed")
      },
      onerror(err: Error) {
        if (err.name === "TypeError") {
          return RETRY_MS
        }
        log.error("unexpected fatal error in useSubscribe", err)
        throw err
      },
    })

    return () => {
      abortControllerRef.current?.abort()
      clearTimeout(connectionTimerRef.current)
      clearTimeout(idleTimerRef.current)
    }
  }, [reset, dispatch, url])
}
