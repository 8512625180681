import type { VariableType } from "@brm/schema-types/types.js"
import { uniqBy } from "@brm/util/collections.js"
import { Box, Button, Divider, Flex, Heading, HStack, Icon, Spacer, Stack, Text, Tooltip } from "@chakra-ui/react"
import { DragDropContext, Draggable, type DraggableProvided, Droppable, type DropResult } from "@hello-pangea/dnd"
import { Fragment, useCallback, useRef, useState } from "react"
import { type Control, Controller, type FieldArrayWithId, useFieldArray } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { isPresent } from "ts-is-present"
import CardWithHoverActions from "../../../components/CardWithHoverActions.js"
import { ArrowDownIcon, DragAndDropIcon, HelpIcon, PlusIcon } from "../../../components/icons/icons.js"
import DeleteIconButton from "../../../components/icons/system/DeleteIconButton.js"
import { Link } from "../../../components/Link.js"
import ApprovalStepDefinition from "./ApprovalStepDefinition.js"
import ConditionalPrompt from "./ConditionalPrompt.js"
import ConditionalToggle from "./ConditionalToggle.js"
import { ObjectFormPreview } from "./FormPreview.js"
import {
  type ConfigureWorkflowFormState,
  type ConfigureWorkflowStepsFormState,
  getPrevStepObjects,
  OPTIONAL_APPROVAL_STEP_TYPES,
} from "./utils.js"

export default function StepDefinition({
  step,
  stepIndex,
  control,
  steps,
  approverVariableTypes,
}: {
  step: FieldArrayWithId<ConfigureWorkflowFormState, "steps", "id">
  stepIndex: number
  control: Control<ConfigureWorkflowStepsFormState>
  steps: ConfigureWorkflowFormState["steps"]
  approverVariableTypes?: VariableType[]
}) {
  const intl = useIntl()
  const approvalStepFieldArray = useFieldArray({
    name: `steps.${stepIndex}.approval_steps`,
    control,
    rules: {
      minLength: OPTIONAL_APPROVAL_STEP_TYPES.includes(step.type) ? 0 : 1,
    },
  })

  const onDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (!destination) {
        // Dropped outside the list, do nothing
        return
      }
      approvalStepFieldArray.move(source.index, destination.index)
    },
    [approvalStepFieldArray]
  )

  const [conditionalToggle, setConditionalToggle] = useState<null | "conditional">(
    step.condition ? "conditional" : null
  )
  const conditionalInputRef = useRef<{ focus: () => void }>(null)

  const isApproverOptional = OPTIONAL_APPROVAL_STEP_TYPES.includes(step.type)
  return (
    <Flex flex={1} minHeight={0}>
      <Flex
        flex={1}
        flexDirection="column"
        alignItems="center"
        overflowY="auto"
        style={{ scrollbarGutter: "stable" }}
        borderLeftWidth={1}
        p={4}
      >
        <Stack flex={1} width="100%" maxWidth="800px">
          <Heading as="h2" size="sm">
            {step.display_name}
          </Heading>
          <Controller
            name={`steps.${stepIndex}.condition`}
            control={control}
            render={({ field }) => (
              <>
                <Flex alignItems="center">
                  <ConditionalToggle
                    conditionalToggle={conditionalToggle}
                    setConditionalToggle={(value) => {
                      setConditionalToggle(value)
                      if (value === "conditional") {
                        setTimeout(() => {
                          conditionalInputRef.current?.focus()
                        }, 10)
                      }
                    }}
                    onChangeConditional={field.onChange}
                    labels={{
                      conditional: intl.formatMessage({
                        id: "workflow.definition.step.type.conditional",
                        defaultMessage: "Run this step when...",
                        description: "Option to conditionally execute this step",
                      }),
                      always: intl.formatMessage({
                        id: "workflow.definition.step.type.always",
                        defaultMessage: "Always run this step",
                        description: "Option to always execute this step",
                      }),
                    }}
                  />
                  <Tooltip
                    shouldWrapChildren
                    label={intl.formatMessage({
                      id: "workflow.definition.step.approver.add.tooltip",
                      defaultMessage:
                        "Add conditional logic to the step, enabling the right process wherever it is needed. You may reference any criteria applied to any step before this one. For example:<br></br><br></br> Run the legal step when Requested Spend is greater than $25,000.00.",
                      description: "Tooltip text for the add multiple approvers button",
                    })}
                  >
                    <Icon as={HelpIcon} color="gray.500" />
                  </Tooltip>
                </Flex>
                {conditionalToggle === "conditional" && (
                  <ConditionalPrompt
                    ref={conditionalInputRef}
                    onChange={field.onChange}
                    getEligibleObjects={() =>
                      uniqBy(
                        getPrevStepObjects(steps, step).filter((object) => !object.disabled_at),
                        (object) => `${object.object_type}.${object.category}`
                      )
                    }
                    basePath={`steps.${stepIndex}.condition`}
                    control={control}
                  />
                )}
              </>
            )}
          />
          <Divider my={2} />
          <Text fontSize="sm" color="gray.600">
            <FormattedMessage
              id="workflow.definition.step.form.preview"
              defaultMessage="Below is a preview of the form that requestors will see. Toggle sections on/off to control what data is gathered. Use <link>criteria settings</link> to set whether fields are required, shared with sellers, or included in the form."
              description="Helper text explaining the form preview and how to configure fields in workflow definition"
              values={{
                link: (chunks) => (
                  <Link to="/settings/criteria" color="blue.600" fontWeight="medium">
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Text>
          {step.objects.map((object, index) => (
            <ObjectFormPreview
              key={object.object_type}
              objectType={object.object_type}
              category={object.category ?? undefined}
              control={control}
              objectIndex={index}
              stepIndex={stepIndex}
            />
          ))}
        </Stack>
      </Flex>
      <Stack flex={1} py={4} px={4} minHeight={0} borderLeftWidth={1} overflowY="auto">
        <Stack mb={2} pr={4}>
          <Heading as="h3" size="xs">
            {isApproverOptional ? (
              <FormattedMessage
                id="request.config.step.approvalSteps.label.optional"
                description="Label on the request step section of request configuration to set the optional approval steps of a step"
                defaultMessage="Approvers (optional)"
              />
            ) : (
              <FormattedMessage
                id="request.config.step.approvalSteps.label"
                description="Label on the request step section of request configuration to set the approvers of a step"
                defaultMessage="Approvers"
              />
            )}
          </Heading>
          <Text fontSize="sm" color="gray.600">
            <FormattedMessage
              id="workflow.definition.step.approvers.description"
              defaultMessage="Approvers review submitted data and can request changes or reject the request. Configure multiple approval steps to create a sequential approval chain with individual approvers or approver groups."
              description="Helper text explaining how approvers review and approve workflow steps"
            />
          </Text>
          {approvalStepFieldArray.fields.length > 0 && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Stack>
                <Droppable droppableId="approval-steps">
                  {(droppableProvided) => (
                    <Stack paddingTop={2} {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                      {approvalStepFieldArray.fields.map((approvalStep, approvalStepIdx) => {
                        return (
                          <Fragment key={`approval-step-${approvalStep.id}`}>
                            {approvalStepIdx > 0 && <Icon as={ArrowDownIcon} mx="auto" />}
                            <HStack>
                              <Controller
                                name={`steps.${stepIndex}.approval_steps.${approvalStepIdx}`}
                                control={control}
                                rules={{
                                  validate: (approvalStep) =>
                                    approvalStep?.approvers &&
                                    approvalStep.approvers.length > 0 &&
                                    approvalStep.approvers.some(isPresent),
                                }}
                                render={({ fieldState }) => (
                                  <Draggable draggableId={`approval-step-${approvalStep.id}`} index={approvalStepIdx}>
                                    {(draggableProvided: DraggableProvided) => (
                                      <CardWithHoverActions
                                        flex={1}
                                        hasError={Boolean(fieldState.error)}
                                        {...draggableProvided.draggableProps}
                                        ref={draggableProvided.innerRef}
                                        cardBodyProps={{
                                          p: 0,
                                        }}
                                      >
                                        <HStack bg="gray.50" py={3} px={4} borderBottomWidth={1} borderColor="gray.200">
                                          <Text fontWeight="semibold" size="xl">
                                            <FormattedMessage
                                              id="workflow.definition.step.approvalStep.index"
                                              defaultMessage="Step {index}"
                                              description="Label for the approval step index"
                                              values={{ index: approvalStepIdx + 1 }}
                                            />
                                          </Text>
                                          <Spacer />
                                          <DeleteIconButton
                                            size="sm"
                                            variant="ghost"
                                            label={intl.formatMessage({
                                              id: "workflow.definition.step.approvalStep.remove",
                                              defaultMessage: "Remove approval step",
                                              description: "Button to remove an approval step from the approval chain",
                                            })}
                                            onClick={() => {
                                              approvalStepFieldArray.remove(approvalStepIdx)
                                            }}
                                          />
                                          <Box {...draggableProvided.dragHandleProps}>
                                            <Icon as={DragAndDropIcon} />
                                          </Box>
                                        </HStack>
                                        <Box py={3} px={4}>
                                          <ApprovalStepDefinition
                                            approvalStep={approvalStep}
                                            approvalStepIdx={approvalStepIdx}
                                            stepIndex={stepIndex}
                                            control={control}
                                            stepType={step.type}
                                            approverVariableTypes={approverVariableTypes}
                                          />
                                        </Box>
                                      </CardWithHoverActions>
                                    )}
                                  </Draggable>
                                )}
                              />
                            </HStack>
                          </Fragment>
                        )
                      })}
                      {droppableProvided.placeholder}
                    </Stack>
                  )}
                </Droppable>
              </Stack>
            </DragDropContext>
          )}
          <Box>
            <Button
              variant="ghost"
              onClick={() => {
                approvalStepFieldArray.append({ approvers: [], condition: null })
              }}
              leftIcon={<Icon as={PlusIcon} />}
              colorScheme="brand"
            >
              <FormattedMessage
                id="workflow.definition.step.approvalStep.add"
                defaultMessage="Add approval step"
                description="Button text to add a new approval step to the approver chain"
              />
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Flex>
  )
}
