import { Box, Center, Flex, Image, Stack, Text } from "@chakra-ui/react"
import type { FC } from "react"
import { useIntl, type IntlShape } from "react-intl"
import inboxIconEmpty from "../../../../assets/inbox_icon_empty.svg"
import inboxIconFull from "../../../../assets/inbox_icon_full.svg"
import {
  useGetNotificationV1CountUnreadQuery,
  useGetNotificationV1TasksQuery,
} from "../../../app/services/generated-api.js"
import Spinner from "../../../components/spinner.js"
import FullTextSearch from "../../search/FullTextSearch.js"

const InboxEmptyState: FC = () => {
  return (
    <Flex flexDirection="column" gap={2} height="100%" minHeight={0} width="100%">
      <Flex gap={2} p={2} justifyContent="flex-end">
        <Box width="200px" maxWidth="320px" flexGrow={1}>
          <FullTextSearch />
        </Box>
      </Flex>
      <InboxEmptyStateBody />
    </Flex>
  )
}

const InboxEmptyStateBody: FC = () => {
  const intl = useIntl()
  const notificationUnreadCountResult = useGetNotificationV1CountUnreadQuery()
  const tasksQuery = useGetNotificationV1TasksQuery()

  if (!tasksQuery.data || !notificationUnreadCountResult.data) {
    return (
      <Center flexGrow={1}>
        <Spinner size="md" />
      </Center>
    )
  }

  const taskCount = tasksQuery.data.items.length ?? 0
  const unreadCount = notificationUnreadCountResult.data.count ?? 0

  return (
    <Center flexGrow={1}>
      <Stack alignItems="center" justifyContent="center" gap={2}>
        <Image
          src={taskCount > 0 || unreadCount > 0 ? inboxIconFull : inboxIconEmpty}
          alt={intl.formatMessage({
            id: "inbox.icon.alt",
            description: "Alternative text for the inbox icon image",
            defaultMessage: "Inbox Icon",
          })}
          w={100}
          h={100}
        />
        <Text>{getMessage(taskCount, unreadCount, intl)}</Text>
      </Stack>
    </Center>
  )
}

export default InboxEmptyState

const getMessage = (taskCount: number, unreadCount: number, intl: IntlShape) => {
  if (taskCount === 0 && unreadCount === 0) {
    return intl.formatMessage({
      id: "inbox.count.none",
      description: "Text shown when there are no tasks and no unread notifications",
      defaultMessage: "You have no unread notifications",
    })
  }

  if (unreadCount === 0) {
    return intl.formatMessage(
      {
        id: "inbox.count.taskLabel",
        description: "Label for tasks when you have no unread notifications",
        defaultMessage: "You have {count} {count, plural, one {task} other {tasks}}",
      },
      { count: taskCount }
    )
  }

  if (taskCount === 0) {
    return intl.formatMessage(
      {
        id: "inbox.count.notificationLabel",
        description: "Label for unread notifications when you have no tasks",
        defaultMessage: "You have {count} unread {count, plural, one {notification} other {notifications}}",
      },
      { count: unreadCount }
    )
  }

  return intl.formatMessage(
    {
      id: "inbox.count.single",
      description: "Text showing count of either tasks or notifications",
      defaultMessage:
        "You have {taskCount} {taskCount, plural, one {task} other {tasks}} and {unreadCount} unread {unreadCount, plural, one {notification} other {notifications}}",
    },
    { taskCount, unreadCount }
  )
}
