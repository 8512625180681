import type { NullableOnboardingStep } from "@brm/type-helpers/onboarding.js"
import { ONBOARDING_FLOW_CONFIG } from "@brm/type-helpers/onboarding.js"
import { createListenerMiddleware, createSlice } from "@reduxjs/toolkit"
import { generatedApi } from "../../app/services/generated-api.js"
import type { RootState } from "../../app/store-types.js"

interface OnboardingState {
  currentStep: NullableOnboardingStep
  initialized: boolean
  userHasOnboardingFlow: boolean
}

const initialState: OnboardingState = {
  currentStep: null,
  initialized: false,
  userHasOnboardingFlow: false,
}

function getNextStep(currentStep: NullableOnboardingStep): NullableOnboardingStep {
  return currentStep ? ONBOARDING_FLOW_CONFIG[currentStep].nextStep : null
}

// Selectors
export const selectCurrentOnboardingStep = (state: RootState): NullableOnboardingStep =>
  state.onboarding.userHasOnboardingFlow ? state.onboarding.currentStep : null
export const selectIsOnboardingInitialized = (state: RootState): boolean => state.onboarding.initialized

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    initializeOnboardingState: (
      state,
      {
        payload: { step, userHasOnboardingFlow },
      }: { payload: { step: NullableOnboardingStep; userHasOnboardingFlow: boolean } }
    ) => {
      if (state.initialized) return
      if (!userHasOnboardingFlow) return
      if (!step) return

      state.currentStep = step
      state.initialized = true
      state.userHasOnboardingFlow = userHasOnboardingFlow

      if (step && step !== "complete" && window.location.pathname !== ONBOARDING_FLOW_CONFIG[step].path) {
        window.location.href = ONBOARDING_FLOW_CONFIG[step].path
      }
    },
    enterOnboarding: (state) => {
      state.currentStep = "contract_collector.connect_gmail"
      state.initialized = true
      state.userHasOnboardingFlow = true
      if (window.location.pathname !== ONBOARDING_FLOW_CONFIG[state.currentStep].path) {
        window.location.href = ONBOARDING_FLOW_CONFIG[state.currentStep].path
      }
    },
    goToNextOnboardingStep: (state) => {
      if (!state.userHasOnboardingFlow) return
      if (!state.currentStep) return

      const prevStep = state.currentStep
      const nextStep = getNextStep(state.currentStep)
      const navigateOnNextStep = ONBOARDING_FLOW_CONFIG[state.currentStep].navigateOnNextStep
      state.currentStep = nextStep
      state.initialized = true

      if (
        nextStep &&
        navigateOnNextStep &&
        ONBOARDING_FLOW_CONFIG[prevStep].path !== ONBOARDING_FLOW_CONFIG[nextStep].path
      ) {
        window.location.href = ONBOARDING_FLOW_CONFIG[nextStep].path
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(generatedApi.endpoints.getOnboardingV1Latest.matchFulfilled, (state, { payload }) => {
        state.currentStep = payload?.step ?? null
      })
      .addMatcher(generatedApi.endpoints.postOnboardingV1StepByStepTypeRecord.matchFulfilled, (state, { payload }) => {
        state.currentStep = payload.step
      })
  },
})

// Create listener middleware to sync state changes with backend
export const onboardingListenerMiddleware = createListenerMiddleware()

onboardingListenerMiddleware.startListening({
  actionCreator: onboardingSlice.actions.enterOnboarding,
  effect: async (_, { dispatch, getState }) => {
    const state = getState() as RootState
    const currentStep = selectCurrentOnboardingStep(state)
    if (currentStep) {
      await dispatch(
        generatedApi.endpoints.postOnboardingV1StepByStepTypeRecord.initiate({
          stepType: currentStep,
        })
      ).unwrap()
    }
  },
})

onboardingListenerMiddleware.startListening({
  actionCreator: onboardingSlice.actions.goToNextOnboardingStep,
  effect: async (_, { dispatch, getState }) => {
    const state = getState() as RootState
    const currentStep = selectCurrentOnboardingStep(state)
    if (currentStep) {
      await dispatch(
        generatedApi.endpoints.postOnboardingV1StepByStepTypeRecord.initiate({
          stepType: currentStep,
        })
      ).unwrap()
    }
  },
})

export const { enterOnboarding, goToNextOnboardingStep, initializeOnboardingState } = onboardingSlice.actions
export const onboardingReducer = onboardingSlice.reducer
