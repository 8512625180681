import { hasPermission } from "@brm/schema-helpers/role.js"
import { Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { NavLink, Outlet } from "react-router-dom"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import { NavButton } from "../../components/Nav/NavButton.js"
import {
  ArchiveIcon,
  CircleDotIcon,
  ComplianceIcon,
  CriteriaIcon,
  FinanceIcon,
  IntegrationIcon,
  ITIcon,
  LegalIcon,
  MailIcon,
  NotificationsDisabledIcon,
  ProfileSettingsIcon,
  RequestSettingsIcon,
  SecurityIcon,
  UsersIcon,
} from "../../components/icons/icons.js"

export default function Settings() {
  const intl = useIntl()

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const isLiteOrganization = whoami?.organization?.is_lite

  return (
    <Flex as="section" minHeight="0" flexGrow={1}>
      <Flex
        position="sticky"
        top={0}
        height="100%"
        flex={0}
        bg="bg-surface"
        boxShadow={useColorModeValue("sm", "sm-dark")}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
      >
        <Stack justify="space-between" spacing="1" width="full">
          <Stack spacing="8" shouldWrapChildren>
            <Stack>
              <Text color="subtle" fontWeight="medium">
                <FormattedMessage
                  id="settings.menu.group.my-account"
                  description="Settings menu grouping label for tabs related to the user`s account"
                  defaultMessage="My Account"
                />
              </Text>
              <Stack spacing="1">
                <NavButton
                  as={NavLink}
                  label={intl.formatMessage({
                    id: "settings.menu.profile",
                    description: "Settings menu link to view user profile settings",
                    defaultMessage: "Profile",
                  })}
                  icon={ProfileSettingsIcon}
                  to="/settings/profile"
                />
                <NavButton
                  as={NavLink}
                  label={intl.formatMessage({
                    id: "settings.menu.contractCollector",
                    description: "Settings menu link to view contract collector settings",
                    defaultMessage: "Contract Collector",
                  })}
                  icon={MailIcon}
                  to="/settings/contract-collector"
                />
                <NavButton
                  as={NavLink}
                  label={intl.formatMessage({
                    id: "settings.menu.notifications",
                    description: "Settings menu link to view notification settings",
                    defaultMessage: "Notifications",
                  })}
                  icon={NotificationsDisabledIcon}
                  to="/settings/notification"
                />
              </Stack>
            </Stack>
            <Stack>
              <Text color="subtle" fontWeight="medium">
                <FormattedMessage
                  id="settings.menu.group.organization"
                  description="Settings menu grouping label for organization pages"
                  defaultMessage="Organization"
                />
              </Text>
              <Stack spacing="1">
                <NavButton
                  as={NavLink}
                  label={intl.formatMessage({
                    id: "settings.menu.users",
                    description: "Settings menu link to view users",
                    defaultMessage: "Users",
                  })}
                  icon={UsersIcon}
                  to="/settings/users"
                />
                {!isLiteOrganization && hasPermission(whoami?.roles, "organization:update") && (
                  <NavButton
                    as={NavLink}
                    label={intl.formatMessage({
                      id: "settings.menu.security",
                      description: "Settings menu link to view security settings",
                      defaultMessage: "Security",
                    })}
                    icon={SecurityIcon}
                    to="/settings/security"
                  />
                )}
                {!isLiteOrganization && hasPermission(whoami?.roles, "field_config:read") && (
                  <NavButton
                    as={NavLink}
                    label={intl.formatMessage({
                      id: "settings.menu.criteria",
                      description: "Settings menu link to view criteria",
                      defaultMessage: "Criteria",
                    })}
                    icon={CriteriaIcon}
                    to="/settings/criteria"
                    subItems={[
                      { label: "General", to: "/settings/criteria/general", icon: CircleDotIcon },
                      { label: "Finance", to: "/settings/criteria/finance", icon: FinanceIcon },
                      { label: "Compliance", to: "/settings/criteria/compliance", icon: ComplianceIcon },
                      { label: "IT", to: "/settings/criteria/it", icon: ITIcon },
                      { label: "Legal", to: "/settings/criteria/legal", icon: LegalIcon },
                    ]}
                  />
                )}
                {!isLiteOrganization && hasPermission(whoami?.roles, "integration:read") && (
                  <NavButton
                    as={NavLink}
                    label={intl.formatMessage({
                      id: "settings.menu.integrations",
                      description: "Settings menu link to view integrations",
                      defaultMessage: "Integrations",
                    })}
                    icon={IntegrationIcon}
                    to="/settings/integrations"
                  />
                )}
                {!isLiteOrganization && hasPermission(whoami?.roles, "workflow_def:read") && (
                  <NavButton
                    as={NavLink}
                    label={intl.formatMessage({
                      id: "settings.menu.requests",
                      description: "Settings menu link to view requests",
                      defaultMessage: "Requests",
                    })}
                    icon={RequestSettingsIcon}
                    to="/settings/requests"
                  />
                )}
                {!isLiteOrganization && hasPermission(whoami?.roles, "tool:read:hidden") && (
                  <NavButton
                    as={NavLink}
                    label={intl.formatMessage({
                      id: "settings.menu.archived",
                      description: "Settings menu link to view archived",
                      defaultMessage: "Archived",
                    })}
                    icon={ArchiveIcon}
                    to="/settings/archived"
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Flex>
      <Outlet />
    </Flex>
  )
}
