import type { CommentTimelineEvent, TimelineEventReplyTimelineEvent } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { Button, Flex, Text, Tooltip, useToast } from "@chakra-ui/react"
import { type FunctionComponent } from "react"
import { FormattedList, FormattedMessage, useIntl } from "react-intl"
import { useSearchParams } from "react-router-dom"
import {
  useDeleteTimelineV1EventsByIdReactionsAndReactionIdMutation,
  useGetUserV1WhoamiQuery,
  usePostTimelineV1EventsByIdReactionsMutation,
} from "../../app/services/generated-api.js"

export const ReactionRow: FunctionComponent<{ event: CommentTimelineEvent | TimelineEventReplyTimelineEvent }> = ({
  event,
}) => {
  const intl = useIntl()
  const toast = useToast()

  const [, setSearchParams] = useSearchParams()

  const whoami = useGetUserV1WhoamiQuery()

  const [addReaction] = usePostTimelineV1EventsByIdReactionsMutation()
  const [deleteReaction] = useDeleteTimelineV1EventsByIdReactionsAndReactionIdMutation()

  const handleAddReaction = async (emoji: string, timelineEventId: string) => {
    setSearchParams(
      (current) => {
        current.delete("event")
        return current
      },
      { replace: true }
    )
    try {
      await addReaction({
        id: timelineEventId,
        reactionInput: {
          emoji,
        },
      }).unwrap()
    } catch (_) {
      toast({
        description: intl.formatMessage({
          id: "request.comment.reaction.add.error",
          description: "Toast error message when adding a reaction fails",
          defaultMessage: "There was an error adding your reaction",
        }),
        status: "error",
      })
    }
  }

  const handleRemoveReaction = async (timelineEventId: string, reactionId: string) => {
    setSearchParams(
      (current) => {
        current.delete("event")
        return current
      },
      { replace: true }
    )
    try {
      await deleteReaction({ id: timelineEventId, reactionId }).unwrap()
    } catch (_err) {
      toast({
        description: intl.formatMessage({
          id: "request.comment.reaction.delete.error",
          description: "Toast error message when deleting a reaction fails",
          defaultMessage: "There was an error deleting your reaction",
        }),
        status: "error",
      })
    }
  }

  return (
    <Flex gap={1} flexWrap="wrap">
      {event.reaction_groups.map(({ emoji, count, reactions }) => {
        const ownReaction = reactions.find((reaction) => reaction.user.id === whoami.data?.id)
        return (
          <Tooltip
            key={emoji}
            label={
              <FormattedMessage
                defaultMessage="{users} reacted with {emoji}"
                description="Tooltip label for the react button on the field timeline"
                id="workflowRun.form.field.timeline.comment.react.tooltip"
                values={{
                  emoji,
                  users: (
                    <FormattedList
                      value={reactions.slice(0, 50).map((reaction) => displayPersonName(reaction.user, intl))}
                    />
                  ),
                }}
              />
            }
          >
            <Button
              variant="outline"
              background="white"
              size="sm"
              aria-label={intl.formatMessage({
                defaultMessage: "React to this comment",
                description: "Aria label for the react button on the field timeline",
                id: "workflowRun.form.field.timeline.comment.react",
              })}
              aria-pressed={!!ownReaction}
              _pressed={{ background: "brand.50", borderColor: "brand.200" }}
              onClick={() =>
                ownReaction ? handleRemoveReaction(event.id, ownReaction.id) : handleAddReaction(emoji, event.id)
              }
              display="flex"
              gap={1}
              alignItems="center"
              paddingInline="6px"
            >
              <Text as="span" fontSize="md">
                {emoji}
              </Text>
              {count > 1 && count}
            </Button>
          </Tooltip>
        )
      })}
    </Flex>
  )
}
