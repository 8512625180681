import type { Tool, Vendor } from "@brm/schema-types/types.js"
import {
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  useToast,
  type UseModalProps,
} from "@chakra-ui/react"
import { capitalCase } from "change-case"
import { useRef, useState, type ReactElement, type ReactNode } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { NextIcon } from "../components/icons/icons.js"
import { Link } from "../components/Link.js"
import { getAPIErrorMessage } from "../util/error.js"
import { getDisplayUrl } from "../util/url.js"
import { validateURL } from "../util/validation.js"

type EntityType = Tool["object_type"] | Vendor["object_type"]
type ModalScreens = "createMenu" | "generation"

interface EntityConfig<T> {
  type: EntityType
  renderEntityDisplay: (props: { value: T }) => ReactNode
  createMutation: (data: { body: { user_provided_url: string; user_provided_display_name: string } }) => Promise<T>
}

interface EntityDetails {
  display_name: string
  description: string | null
  website: string | null
}

type NewEntityModalProps<T extends EntityDetails> = UseModalProps & {
  setEntity: (entity: T | null) => void
  entity: T
  config: EntityConfig<T>
}

export function NewEntityModal<T extends EntityDetails>(props: NewEntityModalProps<T>): ReactNode {
  const { isOpen, onClose, setEntity, entity, config } = props
  const toast = useToast()
  const intl = useIntl()

  const { handleSubmit, control, trigger } = useForm({
    defaultValues: {
      new_entity_url: null,
      new_entity_display_name: entity?.display_name,
    },
  })

  const [newEntity, setNewEntity] = useState<T | null>(null)
  const initialFocusRef = useRef<HTMLInputElement>(null)
  const displayEntity = newEntity || entity
  const [screen, setScreen] = useState<ModalScreens>("createMenu")

  const EntityDetails = ({ entity }: { entity: EntityDetails }): ReactNode => {
    const { website, description } = entity

    return (
      <>
        <FormControl marginTop={3} isReadOnly>
          <FormLabel>
            <FormattedMessage
              defaultMessage="Description"
              description="The label for the description field in the create entity modal form"
              id="entity.modal.description.label"
            />
          </FormLabel>
          <Textarea
            rows={10}
            value={description ?? ""}
            placeholder={intl.formatMessage({
              id: "entity.modal.description.placeholder",
              defaultMessage: "No description provided",
              description: "Placeholder shown when there is no description on a displayed entity",
            })}
            isReadOnly
          />
        </FormControl>

        {website && (
          <Link to={website} target="_blank" display="inline-block" color="blue.500">
            <HStack marginTop={2} gap={1}>
              <FormattedMessage
                id="entity.modal.website"
                description="Link text to click which navigates user to the website of a entity"
                defaultMessage="Learn more about {website}"
                values={{ website: getDisplayUrl(new URL(website)) }}
              />
              <Icon as={NextIcon} />
            </HStack>
          </Link>
        )}
      </>
    )
  }

  const screens: Record<ModalScreens, ReactElement> = {
    createMenu: (
      <>
        <ModalBody overflowY="auto">
          {entity && (
            <FormControl marginTop={3} isReadOnly>
              <FormLabel>{capitalCase(config.type)}</FormLabel>
              {config.renderEntityDisplay({ value: displayEntity })}
            </FormControl>
          )}
          <Text marginTop={3}>
            <FormattedMessage
              id="entity.modal.help_message"
              description="Header for the entity modal selection section"
              defaultMessage="{name} will be added as a {type} in your organization"
              values={{
                name: entity?.display_name,
                type: capitalCase(config.type),
              }}
            />
          </Text>
          <Controller
            name="new_entity_url"
            control={control}
            rules={{
              required: true,
              validate: validateURL(intl),
            }}
            render={(formState) => {
              const { field, fieldState } = formState

              return (
                <FormControl isInvalid={fieldState.invalid} isRequired marginTop={3}>
                  <FormLabel>
                    <FormattedMessage
                      defaultMessage="URL"
                      description="The label for the entity URL field in the modal form"
                      id="entity.modal.url.label"
                    />
                  </FormLabel>

                  <Input
                    inputMode="url"
                    ref={initialFocusRef}
                    isReadOnly={newEntity ? true : false}
                    autoComplete="off"
                    value={field?.value ?? ""}
                    placeholder={intl.formatMessage({
                      id: "entity.modal.url.placeholder",
                      description: "Placeholder for the entity URL input field",
                      defaultMessage: "Please provide a URL...",
                    })}
                    onChange={field.onChange}
                  />

                  <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                </FormControl>
              )
            }}
          />
          {newEntity && <EntityDetails entity={newEntity} />}
        </ModalBody>
        <Divider />
        <ModalFooter justifyContent="right">
          <Flex gap={2}>
            <Button
              disabled={false}
              colorScheme="brand"
              onClick={handleSubmit(async (data) => {
                if (newEntity) {
                  setEntity(newEntity)
                  onClose()
                  return
                }

                if ((await trigger("new_entity_url")) && data.new_entity_display_name) {
                  setScreen("generation")

                  try {
                    const result = await config.createMutation({
                      body: {
                        user_provided_url: data.new_entity_url ?? "",
                        user_provided_display_name: data.new_entity_display_name ?? "",
                      },
                    })
                    setNewEntity(result)
                  } catch (err) {
                    const errorDescription = getAPIErrorMessage(err)
                    toast({
                      title: intl.formatMessage(
                        {
                          id: "entity.error.toast",
                          description: "Entity creation error toast title",
                          defaultMessage: "There was an issue finding information about {entityName}",
                        },
                        { entityName: data.new_entity_display_name }
                      ),
                      description: errorDescription,
                      status: "error",
                      duration: null,
                    })
                  }

                  setScreen("createMenu")
                }
              })}
              rightIcon={<Icon as={NextIcon} />}
            >
              <FormattedMessage
                id="entity.modal.continue"
                defaultMessage="Continue"
                description="Entity modal continue button text"
              />
            </Button>
          </Flex>
        </ModalFooter>
      </>
    ),
    generation: (
      <>
        <ModalBody overflowY="auto">
          <Center height="100%">
            <Flex flexDirection="column" gap={3}>
              <Center>
                <Spinner size="md" colorScheme="brand" />
              </Center>
              <FormattedMessage
                id="entity.modal.loading"
                defaultMessage="Loading..."
                description="Entity modal loading text"
              />
            </Flex>
          </Center>
        </ModalBody>
        <Divider />
        <ModalFooter justifyContent="right">
          <Button colorScheme="brand" isDisabled={true} rightIcon={<Icon as={NextIcon} />}>
            <FormattedMessage
              id="entity.modal.continue"
              defaultMessage="Continue"
              description="Entity modal continue button text"
            />
          </Button>
        </ModalFooter>
      </>
    ),
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setEntity(null)
        onClose()
      }}
      size="lg"
      closeOnOverlayClick={false}
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent height={680}>
        <ModalHeader>
          <Text>
            {config.type === "Tool" ? (
              <FormattedMessage
                id="entity.modal.title.tool"
                description="Title of the create tool entity modal"
                defaultMessage="Create New Tool"
              />
            ) : (
              <FormattedMessage
                id="entity.modal.title"
                description="Title of the create vendor entity modal"
                defaultMessage="Create New Vendor"
              />
            )}
          </Text>
          <ModalCloseButton />
        </ModalHeader>
        <Divider />
        {screens[screen]}
      </ModalContent>
    </Modal>
  )
}
