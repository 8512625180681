import type { DateString } from "@brm/schema-types/types.js"
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { useCallback, useState, type FC } from "react"
import type { DateShortcut } from "../../util/form.js"
import Button from "../Button/Button.js"
import { ButtonStyles } from "../Button/types.js"
import CalendarWithDatePanel from "../Calendar/CalendarWithDatePanel.js"

interface CalendarDatePickerModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (date: DateString) => void
  maxDate?: DateString
  options?: DateShortcut[]
  initialDate?: DateString
  header?: string
}
const CalendarDatePickerModal: FC<CalendarDatePickerModalProps> = ({
  isOpen,
  maxDate,
  onClose,
  onSubmit,
  options,
  initialDate,
  header,
}) => {
  const [date, setDate] = useState<DateString | null>(initialDate ?? null)
  const tomorrow = Temporal.Now.plainDateISO().add({ days: 1 }).toString()

  const onCloseWrapper = useCallback(() => {
    setDate(null)
    onClose()
  }, [onClose])

  return (
    <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onCloseWrapper} size="lg" closeOnOverlayClick={true}>
      <ModalOverlay />
      <ModalContent height={440}>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody borderBottom="none">
          <CalendarWithDatePanel
            minDate={tomorrow}
            maxDate={maxDate}
            value={date?.toString() || null}
            onChange={(date) => {
              setDate(date)
            }}
            dateShortcuts={options}
            sign={1}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={!date}
            onClick={async () => {
              if (!date) return
              onSubmit(date)
            }}
            buttonStyles={ButtonStyles.SolidBrand}
            size="md"
            label="Confirm"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CalendarDatePickerModal
