import { Button, Divider, Icon, Tooltip, type ButtonProps, type TooltipProps } from "@chakra-ui/react"
import { type Props } from "chakra-react-select"
import AsyncCreatableSelect from "./components/Select/AsyncCreatableSelect.js"
import AsyncSelect from "./components/Select/AsyncSelect.js"
import CreatableSelect from "./components/Select/CreatableSelect.js"
import Select from "./components/Select/Select.js"
import Spinner from "./components/spinner.js"

// These default props align selects with our design system. They can't be controlled by the theme system because
// they are on the outermost Select component, while the theme keys are for subcomponents of the Select.
//
// Further customizations of the chakra-react-select component are in the theme.
const defaultProps: Props = {
  useBasicStyles: true,
  isClearable: false,
  selectedOptionStyle: "check",
  menuPlacement: "auto",
  className: "chakra-react-select",
  classNamePrefix: "chakra-react-select",
}

;(Select as React.ComponentType<Props>).defaultProps = defaultProps
;(CreatableSelect as React.ComponentType<Props>).defaultProps = defaultProps
;(AsyncSelect as React.ComponentType<Props>).defaultProps = defaultProps
;(AsyncCreatableSelect as React.ComponentType<Props>).defaultProps = defaultProps

// This aligns the spinner on buttons with out design system
;(Button as React.ComponentType<ButtonProps>).defaultProps = {
  spinner: <Spinner />,
}
;(Icon as React.ComponentType<ButtonProps>).defaultProps = {
  role: "presentation",
}
;(Divider as React.ComponentType<ButtonProps>).defaultProps = {
  role: "presentation",
}
;(Tooltip as React.ComponentType<TooltipProps>).defaultProps = {
  placement: "top",
}
