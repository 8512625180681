import type { DynamoRunStatus } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  chakra,
  Heading,
  HStack,
  Icon,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Progress,
  Spacer,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useDisclosure,
  useToast,
  VStack,
  type CardBodyProps,
  type CardHeaderProps,
  type CardProps,
  type PopoverBodyProps,
  type PopoverContentProps,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { skipToken } from "@reduxjs/toolkit/query"
import { useEffect, useState, type FC, type ReactNode } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetDynamoV1ByIdIsDynamoRunningQuery,
  useGetDynamoV1RunByRunIdQuery,
  useGetUserV1WhoamiQuery,
  usePostDynamoV1ByIdReportDataTelemetryMutation,
  usePostDynamoV1ByToolListingIdMutation,
} from "../../app/services/generated-api.js"
import BraimIcon from "../../components/BraimIcon.js"
import { IconButtonWithTooltip } from "../../components/IconButtonWithTooltip.js"
import { ChevronDownIcon, FlagIcon, InfoIcon, LinkExternalIcon, RefreshIcon } from "../../components/icons/icons.js"
import { Link } from "../../components/Link.js"
import { Timestamp } from "../../components/Timestamp.js"
import { log } from "../../util/logger.js"
import { getDisplayUrl } from "../../util/url.js"
import { FlagDataModal } from "./FlagDataModal.js"

const getIndex = (status: DynamoRunStatus): number => {
  const statusMap: Record<DynamoRunStatus, number> = {
    init: 0,
    collected: 1,
    answered: 2,
    done: 3,
    collection_failed: -1,
    answering_failed: -1,
    unreachable_url: -2,
  }
  const index = statusMap[status]
  if (index !== undefined) {
    return index
  }
  unreachable(status as never)
}

export const DynamoTelemetry: FC<{
  toolListingId: string | null | undefined
}> = (props) => {
  const { toolListingId } = props

  const { data: whoami } = useGetUserV1WhoamiQuery()

  if (whoami) {
    return <LoggedInDynamoTelemetry toolListingId={toolListingId} />
  }

  // TODO: implement link auth dynamo telemetry
  return null
}

export const LoggedInDynamoTelemetry: FC<{
  toolListingId: string | null | undefined
}> = (props) => {
  const { toolListingId } = props

  const [createBadDataReport] = usePostDynamoV1ByIdReportDataTelemetryMutation()
  const [runDynamo] = usePostDynamoV1ByToolListingIdMutation()

  const [isRunComplete, setRunComplete] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: dynamoRunInfo } = useGetDynamoV1ByIdIsDynamoRunningQuery(
    toolListingId
      ? {
          id: toolListingId,
        }
      : skipToken
  )

  const { data: dynamoRun } = useGetDynamoV1RunByRunIdQuery(
    dynamoRunInfo && !isRunComplete
      ? {
          runId: dynamoRunInfo.id,
        }
      : skipToken,
    {
      pollingInterval: 5000,
    }
  )

  useEffect(() => {
    if (dynamoRun) {
      setRunComplete(dynamoRun.status === "done")
    }
  }, [dynamoRun])

  return dynamoRun && toolListingId ? (
    <>
      <TelemetryCard
        stepIndex={getIndex(dynamoRun.status)}
        timestamp={dynamoRun.created_at}
        relevantLinks={dynamoRun.relevant_links ?? []}
        reviewedLinks={dynamoRun.reviewed_links ?? []}
        onOpenFlagDataModal={onOpen}
        onRerun={async () => {
          await runDynamo({ toolListingId }).unwrap()
          setRunComplete(false)
        }}
        onContactSupport={() => {
          window.Pylon?.("show")
        }}
      />

      <FlagDataModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={async (reportBody) => {
          await createBadDataReport({
            id: toolListingId,
            body: {
              report_body: reportBody,
              dynamo_run_id: dynamoRun.id,
            },
          }).unwrap()
        }}
      />
    </>
  ) : null
}

const defaultFontSize = "sm"

const numberOfDays = 30

const defaultCardProps: CardProps = {
  padding: 4,
  borderWidth: "thin",
  borderColor: "gray.200",
  boxShadow: "xs",
  borderRadius: "lg",
  gap: 3,
}

const defaultCardHeaderProps: CardHeaderProps = {
  padding: 0,
  display: "flex",
  gap: 2,
  alignItems: "center",
}

const defaultCardBodyProps: CardBodyProps = {
  padding: 0,
  gap: 3,
  display: "flex",
  flexDirection: "column",
}

const defaultPopoverContentProps: PopoverContentProps = {
  bottom: 3,
  width: "md",
}

const defaultPopoverBodyProps: PopoverBodyProps = {
  padding: 3,
  gap: 3,
}

const TimestampText: FC<{
  timestamp: string
}> = (props) => {
  const { timestamp } = props

  return (
    <Text as="span" color="gray.700" fontSize={defaultFontSize} alignContent="center">
      <Timestamp dateTime={timestamp} />
    </Text>
  )
}

const FAQ_LINK = "https://support.brm.ai/articles/6975122933-brm-ai-assistant"

const ExternalLink: FC<{
  href: string
  children: ReactNode
}> = (props) => {
  const { href, children } = props

  return (
    <Link to={href} target="_blank" rel="noreferrer">
      {children}
      <Icon as={LinkExternalIcon} marginLeft={1} />
    </Link>
  )
}

export const TelemetryCard: FC<{
  timestamp: string
  stepIndex: number
  reviewedLinks: Array<string>
  relevantLinks: Array<string>
  onOpenFlagDataModal: () => void
  onRerun: () => Promise<void>
  onContactSupport: () => void
}> = (props) => {
  const { timestamp, stepIndex, reviewedLinks, relevantLinks, onOpenFlagDataModal, onRerun, onContactSupport } = props

  const threshold = Temporal.Now.instant().subtract({ hours: numberOfDays * 24 })
  const isEligibleForRerun = Temporal.Instant.compare(timestamp, threshold) < 0

  const intl = useIntl()
  const toast = useToast()

  const steps = [
    {
      title: intl.formatMessage({
        id: "telemetry.step.browsing",
        defaultMessage: "Browsing",
        description: "browsing stepper header",
      }),
      info: (
        <Text as="h2" fontSize={defaultFontSize} key={0} paddingLeft={0} paddingRight={2} paddingTop={1}>
          <FormattedMessage
            id="telemetry.reviewedWebpages"
            defaultMessage="Reviewed {count} webpages"
            description="Text showing the number of reviewed webpages"
            values={{ count: reviewedLinks.length }}
          />
        </Text>
      ),
    },
    {
      title: intl.formatMessage({
        id: "telemetry.step.analyzing",
        defaultMessage: "Analyzing",
        description: "analyzing stepper header",
      }),
      info: (
        <Accordion allowToggle key={1}>
          <AccordionItem>
            <h2>
              <AccordionButton paddingLeft={0} paddingRight={4} paddingTop={2} paddingBottom={2}>
                <chakra.span flex="1" textAlign="left" fontSize={defaultFontSize}>
                  <FormattedMessage
                    id="telemetry.relevantLinks"
                    defaultMessage="Found {count} relevant links"
                    description="Text showing the number of relevant links found"
                    values={{ count: relevantLinks.length }}
                  />
                </chakra.span>
                <Icon as={ChevronDownIcon} />
              </AccordionButton>
            </h2>
            <AccordionPanel paddingBottom={4}>
              <VStack overflowY="scroll" maxHeight={120} alignItems="self-start" paddingBottom={1}>
                {relevantLinks.map((link, index) => (
                  <chakra.span key={index} marginLeft={3} display="flex">
                    <chakra.span marginRight={3} color="gray.700">
                      {`${index + 1}.`}
                    </chakra.span>

                    <Link to={link} textAlign="left">
                      {getDisplayUrl(new URL(link))}
                    </Link>
                  </chakra.span>
                ))}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ),
    },
    {
      title: intl.formatMessage({
        id: "telemetry.step.updating",
        defaultMessage: "Updating",
        description: "updating stepper header",
      }),
      info: null,
    },
  ]

  if (stepIndex === -2) {
    return null
  }

  if (stepIndex === -1) {
    return (
      <Card {...defaultCardProps}>
        <CardHeader {...defaultCardHeaderProps}>
          <Heading fontSize="md" display="flex">
            <FormattedMessage
              defaultMessage="Gathering Data"
              description="A header that shows the status of the BRM AI compliance agent"
              id="dynamo.telemetry.header"
            />
          </Heading>

          <Popover trigger="hover" placement="right-start">
            <PopoverTrigger>
              <HStack>
                <BraimIcon />
                <Icon as={InfoIcon} color="orange.600" />
              </HStack>
            </PopoverTrigger>

            <Portal>
              <PopoverContent {...defaultPopoverContentProps}>
                <PopoverBody {...defaultPopoverBodyProps}>
                  <FormattedMessage
                    id="tooltip.dynamo.telemetry.header.stuck"
                    description="Information tooltip for the dynamo telemetry header."
                    defaultMessage="BRM’s AI Agent is having trouble gathering data right now. Our team of expert humans are on the case!"
                  />
                  <Spacer height={2} />
                  <ExternalLink href={FAQ_LINK}>
                    {intl.formatMessage({
                      id: "tooltip.dynamo.telemetry.header.link",
                      description: "The link to the FAQ page in the stuck state.",
                      defaultMessage: "Learn more",
                    })}
                  </ExternalLink>
                  <Button onClick={onContactSupport} variant="link" fontSize={defaultFontSize} marginLeft={2}>
                    <FormattedMessage
                      id="tooltip.dynamo.telemetry.header.contactSupport"
                      description="The button to contact support"
                      defaultMessage="Chat with support"
                    />
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>

          <Spacer />

          <TimestampText timestamp={timestamp} />
        </CardHeader>
        <CardBody {...defaultCardBodyProps}>
          <Progress value={100} size="xs" colorScheme="orange" />
        </CardBody>
      </Card>
    )
  }

  return (
    <Card {...defaultCardProps}>
      <CardHeader {...defaultCardHeaderProps}>
        <Heading fontSize="md" display="flex">
          {stepIndex >= steps.length ? (
            <FormattedMessage
              defaultMessage="Data Gathered"
              description="A header that shows the status of the BRM AI compliance agent"
              id="dynamo.telemetry.header"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Gathering Data"
              description="A header that shows the status of the BRM AI compliance agent"
              id="dynamo.telemetry.header"
            />
          )}
        </Heading>

        <Popover trigger="hover" placement="right-start">
          <PopoverTrigger>
            <HStack>
              <BraimIcon />
              <Icon as={InfoIcon} color="gray.500" />
            </HStack>
          </PopoverTrigger>

          <Portal>
            <PopoverContent {...defaultPopoverContentProps}>
              <PopoverBody {...defaultPopoverBodyProps}>
                {stepIndex >= steps.length ? (
                  <FormattedMessage
                    defaultMessage="BRM’s AI Agent browsed the web and answered questions to accelerate your buying process"
                    description="A header that shows the status of the BRM AI compliance agent"
                    id="dynamo.telemetry.header"
                  />
                ) : (
                  <FormattedMessage
                    id="tooltip.dynamo.telemetry.header"
                    description="Information tooltip for the dynamo telemetry header"
                    defaultMessage="BRM’s AI Agent is browsing the web and answering questions to accelerate your buying process"
                  />
                )}
                <Spacer height={2} />
                <ExternalLink href={FAQ_LINK}>
                  <FormattedMessage
                    id="tooltip.dynamo.telemetry.header.link"
                    description="The link to the FAQ page"
                    defaultMessage="Learn more"
                  />
                </ExternalLink>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>

        <Spacer />

        <TimestampText timestamp={timestamp} />

        {stepIndex >= steps.length ? (
          <>
            <IconButtonWithTooltip
              size="sm"
              variant="ghost"
              colorScheme="gray"
              icon={<Icon as={FlagIcon} />}
              aria-label={intl.formatMessage({
                id: "iconButtons.dynamo.telemetry.flag_data",
                description: "The aria label for the flag data button",
                defaultMessage: "A button to report bad data",
              })}
              label={intl.formatMessage({
                id: "iconButtons.dynamo.telemetry.flag_data.tooltip",
                description: "The aria label for the report bad data button tooltip",
                defaultMessage: "Report bad data",
              })}
              onClick={onOpenFlagDataModal}
            />

            {isEligibleForRerun ? (
              <IconButtonWithTooltip
                size="sm"
                variant="ghost"
                colorScheme="gray"
                icon={<Icon as={RefreshIcon} />}
                aria-label={intl.formatMessage({
                  id: "iconButtons.dynamo.telemetry.rerun",
                  description: "The aria label for the rerun AI data gathering button.",
                  defaultMessage: "A button to rerun the AI data gathering",
                })}
                label={intl.formatMessage({
                  id: "iconButtons.dynamo.telemetry.rerun.tooltip",
                  description: "The aria label for the rerun AI data gathering button tooltip.",
                  defaultMessage: "Rerun data gathering",
                })}
                onClick={async () => {
                  try {
                    await onRerun()
                    toast({
                      title: intl.formatMessage({
                        id: "rerun.success.toast",
                        description: "Success toast title for rerunning data gathering",
                        defaultMessage: "Gathering data",
                      }),
                      status: "success",
                      duration: 3000,
                    })
                  } catch (err) {
                    log.error("Error rerunning dynamo", err)
                    toast({
                      title: intl.formatMessage({
                        id: "rerun.error.toast",
                        description: "Error toast title for rerunning data gathering",
                        defaultMessage: "An error occurred, unable to rerun data gathering",
                      }),
                      status: "error",
                      duration: 5000,
                    })
                  }
                }}
              />
            ) : null}
          </>
        ) : null}
      </CardHeader>
      <CardBody {...defaultCardBodyProps}>
        {stepIndex < steps.length ? (
          <Progress isIndeterminate size="xs" colorScheme="blue" />
        ) : (
          <Progress value={0} size="xs" />
        )}
        <Stepper index={stepIndex} size="sm">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>

        {steps.slice(0, stepIndex + 1).map((step) => step.info)}
      </CardBody>
    </Card>
  )
}

export default DynamoTelemetry
