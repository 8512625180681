import type { WorkflowKind } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Icon } from "@chakra-ui/react"
import BraimIcon from "../../../components/BraimIcon.js"
import { PurchaseIcon, RenewalIcon } from "../../../components/icons/icons.js"

interface Props {
  kind: WorkflowKind
}

export default function WorkflowKindIcon({ kind }: Props) {
  switch (kind) {
    case "software_purchase":
    case "vendor_purchase":
      return <Icon as={PurchaseIcon} color="blue.500" />
    case "software_renewal":
    case "vendor_renewal":
      return <Icon as={RenewalIcon} color="green.600" />
    case "gather_data":
      return <Icon as={BraimIcon} />
    default:
      unreachable(kind)
  }
}
