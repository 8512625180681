import type { FieldCategory, WorkflowRunListItem } from "@brm/schema-types/types.js"
import type { Filter } from "@brm/type-helpers/filters.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { Flags } from "@brm/util/flags.js"
import { Button, Icon, Menu, MenuButton, MenuItem, MenuList, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetDynamoV1ByIdIsDynamoRunningQuery,
  usePostDynamoV1ByToolListingIdMutation,
  usePostWorkflowV1RunsListQuery,
} from "../../app/services/generated-api.js"
import BraimIcon from "../../components/BraimIcon.js"
import { InfoSquareIcon, RequestIcon } from "../../components/icons/icons.js"
import { Link } from "../../components/Link.js"
import StartWorkflowModal from "../workflows/run/start/StartWorkflowModal.js"

export const GatherDataMenuButtonCore = ({
  onClickDynamoOption,
  onClickRequestOption,
  showDynamoOption,
  showRequestOption,
  existingRequestId,
}: {
  onClickDynamoOption: () => Promise<void>
  onClickRequestOption: () => void
  showDynamoOption: boolean
  showRequestOption: boolean
  existingRequestId: string | undefined
}) => {
  const intl = useIntl()
  const toast = useToast()

  if (!showDynamoOption && !showRequestOption) {
    return null
  }

  return (
    <Menu placement="bottom-end">
      <Tooltip
        label={intl.formatMessage({
          id: "gatherData.button.tooltip",
          defaultMessage:
            "Augment the data of your tools under management<br></br><br></br>Use BRM’s AI agent to gather data from the web or create a request to gather information from a vendor contact",
          description: "Tooltip for the button that opens gather data options",
        })}
      >
        <MenuButton as={Button} variant="ghost" size="sm" leftIcon={<Icon as={InfoSquareIcon} />}>
          <FormattedMessage
            id="gatherData.button.label"
            defaultMessage="Gather data"
            description="Label for the button that opens gather data options"
          />
        </MenuButton>
      </Tooltip>
      <MenuList>
        {showDynamoOption && (
          <MenuItem
            icon={<BraimIcon />}
            onClick={async () => {
              try {
                await onClickDynamoOption()
                toast({
                  status: "success",
                  title: intl.formatMessage({
                    id: "gatherData.success",
                    defaultMessage: "AI data gathering started",
                    description: "Toast message for successful data gathering initiation",
                  }),
                })
              } catch (_err) {
                toast({
                  status: "error",
                  title: intl.formatMessage({
                    id: "gatherData.error",
                    defaultMessage: "An error occurred while starting AI data gathering",
                    description: "Toast message for failed data gathering initiation",
                  }),
                })
              }
            }}
          >
            <FormattedMessage
              id="gatherData.menu.withAI"
              defaultMessage="With AI"
              description="Menu option to gather data using AI"
            />
          </MenuItem>
        )}
        {showRequestOption &&
          (existingRequestId ? (
            <MenuItem as={Link} icon={<Icon as={RequestIcon} />} to={`/requests/${existingRequestId}`}>
              <FormattedMessage
                id="gatherData.menu.withRequest"
                defaultMessage="View existing data request"
                description="Menu option to view existing data request"
              />
            </MenuItem>
          ) : (
            <MenuItem icon={<Icon as={RequestIcon} />} onClick={onClickRequestOption}>
              <FormattedMessage
                id="gatherData.menu.withRequest"
                defaultMessage="With request"
                description="Menu option to gather data using a request"
              />
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  )
}

const GatherDataMenuButton = ({
  toolListingId,
  toolId,
  category,
}: {
  toolListingId: string
  toolId: string
  category: FieldCategory
}) => {
  const dataGatherRequestEnabled = useFeatureFlagEnabled(Flags.DATA_GATHER_REQUEST_FLAG) ?? false
  const gatherDataRequestModal = useDisclosure()
  const [runDynamo] = usePostDynamoV1ByToolListingIdMutation()

  const { data: dynamoRun } = useGetDynamoV1ByIdIsDynamoRunningQuery({
    id: toolListingId,
  })

  // Filter for requests of "gather_data" kind where the tool is the current tool
  const toolDataGatherFilters: Filter[][] = [
    [
      {
        column: "kind" satisfies BRMPaths<WorkflowRunListItem>,
        fields: { comparator: "eq", value: "gather_data" },
      },
    ],
    [
      {
        column: "tools.*.id",
        fields: { comparator: "arr_contains", values: [toolId], includeNull: false },
      },
    ],
  ]

  const { data: gatherDataRequest } = usePostWorkflowV1RunsListQuery({
    listQueryStringParams: {
      limit: 1,
      filter: toolDataGatherFilters,
    },
  })

  return (
    <>
      <GatherDataMenuButtonCore
        showDynamoOption={!dynamoRun}
        showRequestOption={dataGatherRequestEnabled}
        existingRequestId={gatherDataRequest?.list[0]?.id}
        onClickDynamoOption={async () => {
          await runDynamo({ toolListingId }).unwrap()
        }}
        onClickRequestOption={() => gatherDataRequestModal.onOpen()}
      />
      {gatherDataRequestModal.isOpen && (
        <StartWorkflowModal
          isOpen={gatherDataRequestModal.isOpen}
          onClose={gatherDataRequestModal.onClose}
          kind="gather_data"
          toolId={toolId}
          category={category}
        />
      )}
    </>
  )
}

export default GatherDataMenuButton
