import { Icon, chakra, keyframes } from "@chakra-ui/react"
import type { FC } from "react"
import { FormattedMessage } from "react-intl"
import { SpinnerIcon } from "./icons/icons.js"

interface SpinnerProps {
  size?: "xs" | "sm" | "md" | "lg"
  colorScheme?: "brand" | "gray" | "white"
}

const sizes = {
  xs: "12px",
  sm: "16px",
  md: "24px",
  lg: "32px",
}

const Spinner: FC<SpinnerProps> = (props: SpinnerProps) => {
  const rotate = keyframes`  
  from {transform: rotate(0deg);}   
  to {transform: rotate(360deg)} 
`
  const loadingAnimation = `${rotate} .5s steps(8, end) infinite`

  const { size, colorScheme = "gray" } = props

  return (
    <Icon
      as={SpinnerIcon}
      color={`${colorScheme}.500`}
      fontSize={size ? sizes[size] : undefined}
      animation={loadingAnimation}
    >
      <chakra.span srOnly>
        <FormattedMessage
          defaultMessage="Loading ..."
          description="Loading message for screen readers in a spinner"
          id="components.spinner.loading"
        />
      </chakra.span>
    </Icon>
  )
}

export default Spinner
