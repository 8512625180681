import { type EnabledOnboardingStep } from "@brm/type-helpers/onboarding.js"
import { Box, Button, Flex, Icon, Stack, Text } from "@chakra-ui/react"
import { useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { goToNextOnboardingStep } from "../../features/onboarding/onboarding-slice.js"
import { OnboardingCalloutConfigurations } from "./OnboardingCalloutConfigurations.js"

export default function OnboardingCallout({ step }: { step: EnabledOnboardingStep }) {
  const dispatch = useDispatch()
  const goToNextStep = useCallback(() => {
    dispatch(goToNextOnboardingStep())
  }, [dispatch])

  const stepConfig = OnboardingCalloutConfigurations[step]
  if (!stepConfig) {
    return null
  }

  const { icon, heading, subtitle, canSkip } = stepConfig

  return (
    <Box p={4} maxWidth="25vw">
      <Flex gap="1rem" userSelect="none">
        <Icon as={icon} borderRadius="md" p=".5rem" boxSize="2.5rem" bg="brand.lime" mt=".25rem" />
        <Stack>
          <Text fontSize="lg" fontWeight="semibold">
            {heading}
          </Text>
          <Text fontSize="md" fontWeight="normal">
            {subtitle}
          </Text>
          {canSkip && (
            <Button variant="outline" size="md" onClick={goToNextStep} alignSelf="end">
              <FormattedMessage
                id="onboarding.callout.proceed"
                description="Message shown when user needs to proceed without completing onboarding action"
                defaultMessage="Got it"
              />
            </Button>
          )}
        </Stack>
      </Flex>
    </Box>
  )
}
