import { Card, CardBody, CardHeader, Flex, Heading, Stack } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { Navigate } from "react-router-dom"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import PageWrapper from "../../components/PageWrapper.js"
import FullTextSearch from "../search/FullTextSearch.js"
import { Timeline } from "../timeline/Timeline.js"
import HomeSummaryCards from "./HomeSummaryCards.js"

export const Home: React.FunctionComponent = () => {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  if (whoami?.organization?.is_lite) {
    return <Navigate to="/inbox" />
  }
  return (
    <PageWrapper>
      <Flex pt={0} pb={3} gap={2} borderBottomWidth="1px">
        <FullTextSearch order={2} ml="auto" />
      </Flex>
      <Flex width="100%" gap={6} wrap="wrap" alignItems="flex-start" flexGrow={1} minHeight={0} pt={4}>
        <Stack flex={1} flexBasis="50%" minH={0} height="100%" overflowY="auto" pr={2} pb={4}>
          <Stack gap={4}>{whoami && <HomeSummaryCards />}</Stack>
        </Stack>
        <Card variant="outline" width="410px" minHeight={0} height="100%">
          <CardHeader pb={2} px={4}>
            <Heading size="sm">
              <FormattedMessage
                id="home.timeline.heading"
                description="Heading for the activity timeline section on the home page"
                defaultMessage="Activities"
              />
            </Heading>
          </CardHeader>
          <CardBody
            padding={0}
            paddingLeft={4}
            display="flex"
            flexDirection="column"
            minHeight={0}
            flexShrink={1}
            flexGrow={1}
          >
            <Timeline />
          </CardBody>
        </Card>
      </Flex>
    </PageWrapper>
  )
}
