import type { CreditCardPatch } from "@brm/schema-types/types.js"
import type { ReactElement } from "react"
import { ValueChangeFieldTimelineEvent } from "./ValueChangeFieldTimelineEvent.js"

import { getSchemaAtPath } from "@brm/util/schema.js"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { FormattedList, FormattedMessage } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { FieldValue } from "../../DynamicForm/FieldValue.js"
interface Props {
  fieldLabel: ReactElement
  objectLink?: ReactElement
  actor: ReactElement
  showFieldName?: boolean
  oldValue: CreditCardPatch | undefined
  newValue: CreditCardPatch | undefined
  fieldSchema: ReadonlyDeep<JSONSchema>
  fieldPath: readonly (string | number)[]
}
export default function CreditCardFieldChangeEvent({
  fieldLabel,
  objectLink,
  actor,
  showFieldName,
  oldValue,
  newValue,
  fieldSchema,
  fieldPath,
}: Props) {
  const changedFields: { old: unknown; new: unknown; schema: ReadonlyDeep<JSONSchema> }[] = []
  // When clearing and setting from null, render as high level event
  if (!newValue || (!oldValue && newValue)) {
    return (
      <ValueChangeFieldTimelineEvent
        actor={actor}
        oldValue={oldValue}
        newValue={newValue}
        objectLink={objectLink}
        fieldSchema={fieldSchema}
        fieldLabel={fieldLabel}
        fieldPath={fieldPath}
        showFieldName={showFieldName}
      />
    )
  }

  if (oldValue?.display_name !== newValue?.display_name || oldValue?.id !== newValue?.id) {
    const schemaAtPath = getSchemaAtPath(fieldSchema, ["display_name"])
    if (schemaAtPath) {
      changedFields.push({
        old: oldValue?.display_name,
        new: newValue?.display_name,
        schema: schemaAtPath,
      })
    }
  }

  if (oldValue?.spend_limit?.amount.toString() !== newValue?.spend_limit?.amount.toString()) {
    const schemaAtPath = getSchemaAtPath(fieldSchema, ["spend_limit"])
    if (schemaAtPath) {
      changedFields.push({
        old: oldValue?.spend_limit,
        new: newValue?.spend_limit,
        schema: schemaAtPath,
      })
    }
  }

  if (oldValue?.spend_limit_interval?.toString() !== newValue?.spend_limit_interval?.toString()) {
    const schemaAtPath = getSchemaAtPath(fieldSchema, ["spend_limit_interval"])
    if (schemaAtPath) {
      changedFields.push({
        old: oldValue?.spend_limit_interval,
        new: newValue?.spend_limit_interval,
        schema: schemaAtPath,
      })
    }
  }

  if (oldValue?.auto_lock_date !== newValue?.auto_lock_date) {
    const schemaAtPath = getSchemaAtPath(fieldSchema, ["auto_lock_date"])
    if (schemaAtPath) {
      changedFields.push({
        old: oldValue?.auto_lock_date,
        new: newValue?.auto_lock_date,
        schema: schemaAtPath,
      })
    }
  }

  if (changedFields.length > 0) {
    const changedFieldsString = (
      <FormattedList
        type="conjunction"
        value={changedFields.map(({ old, new: newVal, schema }, i) =>
          newVal && old ? (
            <FormattedMessage
              key={i}
              defaultMessage="{old} to {new}"
              description="Subtitle of a field change event with an old and new value on the field timeline"
              id="timeline.field_change.with_old_value.subheading.withField"
              values={{
                old: <FieldValue value={old} fieldPath={fieldPath} fieldSchema={schema} />,
                new: <FieldValue value={newVal} fieldPath={fieldPath} fieldSchema={schema} />,
              }}
            />
          ) : old ? (
            <FormattedMessage
              key={i}
              defaultMessage="cleared {old}"
              description="Subtitle of a field change event when new value is null"
              id="timeline.field_change.with_old_value.subheading.withField"
              values={{ old: <FieldValue value={old} fieldPath={fieldPath} fieldSchema={schema} /> }}
            />
          ) : (
            <FormattedMessage
              key={i}
              defaultMessage="updated {new}"
              description="Subtitle of a field change event when new value is null"
              id="timeline.field_change.with_old_value.subheading.withField"
              values={{ new: <FieldValue value={newVal} fieldPath={fieldPath} fieldSchema={schema} /> }}
            />
          )
        )}
      />
    )
    return showFieldName ? (
      <FormattedMessage
        defaultMessage="{actor} updated {fieldLabel} {changedFields}"
        description="Subtitle of a field change event with an old and new value on the field timeline"
        id="timeline.field_change.with_old_value.subheading.withField"
        values={{
          actor,

          fieldLabel,
          changedFields: changedFieldsString,
        }}
      />
    ) : (
      <FormattedMessage
        defaultMessage="{actor} updated {changedFields}"
        description="Subtitle of a field change event on the field timeline"
        id="timeline.field_change.subheading.withField"
        values={{ actor, changedFields: changedFieldsString }}
      />
    )
  }
  return (
    <ValueChangeFieldTimelineEvent
      actor={actor}
      oldValue={oldValue}
      newValue={newValue}
      objectLink={objectLink}
      fieldSchema={fieldSchema}
      fieldLabel={fieldLabel}
      fieldPath={fieldPath}
      showFieldName={showFieldName}
    />
  )
}
