import type { ObjectType } from "@brm/schema-types/types.js"
import { mutableClone } from "@brm/util/mutable.js"
import { dereferenceSchema } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import type { JSONSchema, JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { skipToken, type SkipToken } from "@reduxjs/toolkit/query"
import { useEffect, useMemo } from "react"
import type { ReadonlyDeep } from "type-fest/source/readonly-deep.js"
import {
  useGetSchemaV1ByObjectTypeInputQuery,
  useGetSchemaV1ByObjectTypePatchQuery,
  useGetSchemaV1ByObjectTypeQuery,
  useGetUserV1WhoamiQuery,
  usePostSchemaV1MapQuery,
  type GetSchemaV1ByObjectTypeInputApiArg,
  type GetSchemaV1ByObjectTypePatchApiArg,
} from "../app/services/generated-api.js"

const objectSchemaCache = new Map<string, ReadonlyDeep<JSONSchemaObject>>()
const objectInputSchemaCache = new Map<string, ReadonlyDeep<JSONSchemaObject>>()
const objectPatchSchemaCache = new Map<string, ReadonlyDeep<JSONSchemaObject>>()

const useGetCacheKey = (objectType: ObjectType | SkipToken): string => {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const orgId = whoami?.organization_id
  return orgId ? `${orgId}:${String(objectType)}` : String(objectType)
}

/**
 * Fetches and dereferences the schema for the given object type.
 *
 * @returns The JSON schema or `undefined` while loading.
 * @throws {Error} If the schema could not be fetched.
 */
export const useObjectSchema = (objectType: ObjectType | SkipToken): ReadonlyDeep<JSONSchemaObject> | undefined => {
  // Get cache key based on org ID and object type
  const cacheKey = useGetCacheKey(objectType)

  // Only query if we have a valid object type and it's not already cached
  const shouldQuery = objectType !== skipToken && !objectSchemaCache.has(cacheKey)
  const objectSchemaResult = useGetSchemaV1ByObjectTypeQuery(shouldQuery ? { objectType } : skipToken)

  // Clear cache when new data arrives
  useEffect(() => {
    if (objectSchemaResult.data) {
      objectSchemaCache.delete(cacheKey)
    }
  }, [objectSchemaResult.data, cacheKey])

  // Return cached schema if available
  if (objectSchemaCache.has(cacheKey)) {
    return objectSchemaCache.get(cacheKey)
  }

  // Return undefined if skipped
  if (objectType === skipToken) {
    return undefined
  }

  // Return undefined while loading
  if (objectSchemaResult.data === undefined) {
    return undefined
  }

  // Dereference and validate schema
  const schema = dereferenceSchema(mutableClone(objectSchemaResult.data) as JSONSchema)
  if (!isObject(schema)) {
    throw Object.assign(new Error("Invalid object schema returned"), { schema })
  }

  // Cache valid schema
  if (!objectSchemaCache.has(cacheKey) && schema) {
    objectSchemaCache.set(cacheKey, schema)
  }

  return schema
}

/**
 * Fetches and dereferences the schema for multiple object types.
 *
 * @returns A map from the object type to the JSON schema.
 * @throws {Error} If any schema could not be fetched.
 */
export const useObjectSchemasMap = (objectTypes: ObjectType[]) => {
  const objectSchemasResult = usePostSchemaV1MapQuery({ body: { object_types: objectTypes } })

  const objectSchemas = useMemo(() => {
    if (objectSchemasResult.data === undefined) {
      return undefined
    }
    const objectSchemasMap = objectSchemasResult.data as Partial<Record<ObjectType, JSONSchema | undefined>>
    return Object.fromEntries(
      Object.entries(objectSchemasMap).map(([objectType, schema]) => {
        if (schema === undefined || !isObject(schema)) {
          throw Object.assign(new Error("Invalid object schema returned"), { objectType, schema })
        }
        return [objectType, dereferenceSchema(mutableClone(schema) as JSONSchemaObject)]
      })
    )
  }, [objectSchemasResult.data])
  return objectSchemas
}

/**
 * Fetches and dereferences the patch schema for the given object type, which is specifically used for patching workflow draft_state.
 *
 * @returns The JSON schema or `undefined` while loading.
 * @throws {Error} If the schema could not be fetched.
 */
export const useObjectPatchSchema = (
  objectType: GetSchemaV1ByObjectTypePatchApiArg["objectType"] | SkipToken,
  linkCode?: string
): ReadonlyDeep<JSONSchemaObject> | undefined => {
  const objectSchemaResult = useGetSchemaV1ByObjectTypePatchQuery(
    objectType === skipToken ? skipToken : { objectType, linkCode }
  )
  const cacheKey = useGetCacheKey(objectType)

  const objectSchema = useMemo(() => {
    if (objectType === skipToken) {
      return undefined
    }

    if (objectPatchSchemaCache.has(cacheKey)) {
      return objectPatchSchemaCache.get(cacheKey)
    }

    if (objectSchemaResult.data === undefined) {
      return undefined
    }

    const schema = dereferenceSchema(mutableClone(objectSchemaResult.data) as JSONSchemaObject)
    if (!isObject(schema)) {
      throw Object.assign(new Error("Invalid object patch schema returned"), { schema })
    }

    objectPatchSchemaCache.set(cacheKey, schema)
    return schema
  }, [objectSchemaResult.data, objectType, cacheKey])

  return objectSchema
}

/**
 * Fetches and dereferences the input schema for the given object type.
 *
 * @returns The JSON schema or `undefined` while loading.
 * @throws {Error} If the schema could not be fetched.
 */
export const useObjectInputSchema = (
  objectType: GetSchemaV1ByObjectTypeInputApiArg["objectType"] | SkipToken,
  linkCode?: string
): ReadonlyDeep<JSONSchemaObject> | undefined => {
  const objectSchemaResult = useGetSchemaV1ByObjectTypeInputQuery(
    objectType === skipToken ? skipToken : { objectType, linkCode }
  )
  const cacheKey = useGetCacheKey(objectType)
  const objectSchema = useMemo(() => {
    if (objectType === skipToken) {
      return undefined
    }

    if (objectInputSchemaCache.has(cacheKey)) {
      return objectInputSchemaCache.get(cacheKey)
    }

    if (objectSchemaResult.data === undefined) {
      return undefined
    }

    const schema = dereferenceSchema(mutableClone(objectSchemaResult.data) as JSONSchemaObject)
    if (!isObject(schema)) {
      throw Object.assign(new Error("Invalid object input schema returned"), { schema })
    }

    objectInputSchemaCache.set(cacheKey, schema)
    return schema
  }, [objectSchemaResult.data, objectType, cacheKey])

  return objectSchema
}
