import type { FieldMetadata, FieldMetadataWithSuggestions } from "@brm/schema-types/types.js"
import { type Props } from "chakra-react-select"
import { forwardRef, useMemo } from "react"
import { useIntl, type IntlShape } from "react-intl"
import { isNewOption } from "../../util/form.js"
import OptionWithFieldSource from "../DynamicForm/OptionWithFieldSource.js"
import type { DynamicFormFieldApproval, ValueWithSource } from "../DynamicForm/types.js"
import Select from "../Select/Select.js"

function formatBooleanOption(value: boolean | null | undefined, intl: IntlShape) {
  if (value === true) {
    return intl.formatMessage({
      id: "form.select.yes",
      description: "Form select option for yes input",
      defaultMessage: "Yes",
    })
  }
  if (value === false) {
    return intl.formatMessage({
      id: "form.select.no",
      description: "Form select option for no input",
      defaultMessage: "No",
    })
  }
  return ""
}

type BooleanSelectProps = Pick<Props, "isRequired" | "isReadOnly" | "menuPortalTarget"> & {
  value: boolean | null | undefined
  onChange: (value: boolean | null | undefined, fieldSource: FieldMetadata | undefined) => void
  allowNull?: boolean
  suggestions?: ValueWithSource<boolean | null>[]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: DynamicFormFieldApproval
}

export const BooleanSelect = forwardRef<HTMLInputElement, BooleanSelectProps>(function BooleanSelect(
  { value, onChange, allowNull, isReadOnly, menuPortalTarget, suggestions, fieldMetadata, fieldApproval },
  ref
) {
  const intl = useIntl()

  const options = useMemo(
    () => [
      ...(allowNull ? [{ value: null, field_sources: [] }] : []),
      {
        value: true,
        field_sources: suggestions?.find((suggestion) => suggestion.value === true)?.field_sources ?? [],
      },
      {
        value: false,
        field_sources: suggestions?.find((suggestion) => suggestion.value === false)?.field_sources ?? [],
      },
    ],
    [allowNull, suggestions]
  )

  return (
    <Select<ValueWithSource<boolean | null | undefined>>
      options={options}
      openMenuOnFocus={true}
      value={typeof value === "boolean" ? { value, field_sources: fieldMetadata ? [fieldMetadata] : [] } : null}
      onChange={(option) => {
        if (option?.value !== undefined) {
          onChange(
            option.value,
            // Prioritize the selected field source
            suggestions?.find((suggestion) => suggestion.value === option.value)?.field_sources?.[0] ??
              option?.field_sources?.[0]
          )
        }
      }}
      ref={(select) => {
        if (ref) {
          if (typeof ref === "function") {
            ref(select?.inputRef ?? null)
          } else {
            ref.current = select?.inputRef ?? null
          }
        }
      }}
      placeholder={intl.formatMessage({
        id: "form.select.placeholder",
        defaultMessage: "Select an option...",
        description: "Placeholder for selection input",
      })}
      menuPortalTarget={menuPortalTarget}
      isSearchable={false}
      isReadOnly={isReadOnly}
      formatOptionLabel={({ value, field_sources }, { context }) => {
        const label = formatBooleanOption(value, intl)
        return context === "menu" ? (
          <OptionWithFieldSource fieldSources={field_sources}>{label}</OptionWithFieldSource>
        ) : (
          label
        )
      }}
      styles={{
        menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
      }}
      chakraStyles={{
        option: (provided, { data }) => {
          const { isNew, colorScheme } = isNewOption(data.field_sources, fieldMetadata, fieldApproval)
          return {
            ...provided,
            ...(isNew && {
              backgroundColor: `${colorScheme}.50`,
            }),
          }
        },
      }}
    />
  )
})
