import { AUTH_SEARCH_PARAMS } from "@brm/schema-helpers/auth.js"
import { isObject } from "@brm/util/type-guard.js"
import {
  Button,
  chakra,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Progress,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate, useSearchParams } from "react-router-dom"
import { zxcvbn } from "zxcvbn-typescript"
import {
  useGetUserV1CodeByCodeEmailQuery,
  usePostUserV1ChangePasswordMutation,
  usePostUserV1LoginMutation,
} from "../../app/services/generated-api.js"
import PasswordField from "../../components/Form/PasswordField.js"
import { Link } from "../../components/Link.js"
import { GoogleIcon } from "../../components/icons/provider-icons.js"
import { LoginBaseContainer } from "./LoginBaseContainer.js"

// Types
interface SignupFormState {
  email: string
  password: string
  token: string | null
}

interface PasswordState {
  password: string
  isValid: boolean
  score: number
  invalidMessage: string
}

// Custom Hook for Authentication Logic
function useSignup() {
  const [changePassword] = usePostUserV1ChangePasswordMutation()
  const [login, loginResult] = usePostUserV1LoginMutation()
  const navigate = useNavigate()
  const toast = useToast()
  const intl = useIntl()
  const [searchParams, setSearchParam] = useSearchParams()

  const handleSignup = async (values: SignupFormState, pwCode?: string | null) => {
    await changePassword({
      changePasswordInput: {
        passwordResetCode: pwCode ?? undefined,
        newPassword: values.password,
      },
    })

    searchParams.delete("error")
    setSearchParam(searchParams)

    try {
      const result = await login({
        loginInput: {
          email: values.email,
          password: values.password,
          ...(values.token ? { token: values.token } : {}),
        },
      }).unwrap()

      if (result.state === "success") {
        navigate("/", { replace: true })
      }
    } catch (err) {
      if (isObject(err) && "status" in err && err.status !== 401) {
        toast({
          status: "error",
          description: intl.formatMessage({
            id: "signup.error.toast",
            description: "Signup error toast message",
            defaultMessage: "Something went wrong while signing up. Please try again or contact support.",
          }),
        })
      }
    }
  }

  return { handleSignup, isLoading: loginResult.isLoading }
}

// Subcomponents
function WelcomeSubtitle() {
  const intl = useIntl()
  return (
    <Text fontSize="lg" color="gray.600" fontWeight="normal">
      {intl.formatMessage(
        {
          id: "signup.subtitle",
          description: "Subtitle on Signup page",
          defaultMessage: "Manage your contracts {highlight}",
        },
        {
          highlight: (
            <Text as="span" color="brand.600" display="inline" fontStyle="italic" fontWeight="medium">
              {intl.formatMessage({
                id: "signup.subtitle.highlight",
                description: "Subtitle highlight on Signup page",
                defaultMessage: "Automagically",
              })}
            </Text>
          ),
        }
      )}
    </Text>
  )
}

function GoogleOAuthButton() {
  return (
    <Button
      id="google-signup"
      variant="outline"
      as={Link}
      to={`${import.meta.env.VITE_API_BASE_URL}/oauth/v1/signin/google`}
      target="_self"
      width="full"
      height="8"
    >
      <HStack>
        <GoogleIcon />
        <Text>
          <FormattedMessage
            id="create_account.oauth.option"
            description="OAuth create account option CTA"
            defaultMessage="Continue with Google"
          />
        </Text>
      </HStack>
    </Button>
  )
}

function AuthDivider() {
  return (
    <HStack>
      <Divider />
      <Text whiteSpace="nowrap" color="muted">
        <FormattedMessage
          id="signup.form.oauth"
          description="Signup form message to direct users to oauth sign in options"
          defaultMessage="or"
        />
      </Text>
      <Divider />
    </HStack>
  )
}

function PasswordSignupForm({
  email,
  onSubmit,
  isLoading,
}: {
  email: string | null
  onSubmit: (values: SignupFormState) => Promise<void>
  isLoading: boolean
}) {
  const intl = useIntl()
  const [password, setPassword] = useState<PasswordState>({
    password: "",
    isValid: false,
    score: 0,
    invalidMessage: "",
  })

  const form = useForm<SignupFormState>({
    defaultValues: {
      email: email ?? "",
      password: "",
      token: null,
    },
    reValidateMode: "onSubmit",
  })

  let progressColor = "error"
  if (password.score === 2) {
    progressColor = "yellow"
  } else if (password.score >= 3) {
    progressColor = "green"
  }

  return (
    <Stack spacing="5" as={chakra.form} onSubmit={(event) => form.handleSubmit(onSubmit)(event)}>
      <Controller
        control={form.control}
        name="email"
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired>
            <FormLabel htmlFor="email">
              <FormattedMessage
                id="signup.form.email"
                description="Label for signup form email field"
                defaultMessage="Work Email"
              />
            </FormLabel>
            <Input {...field} id="email" type="email" isDisabled value={email ?? ""} />
          </FormControl>
        )}
      />

      <Stack spacing="5">
        <Controller
          control={form.control}
          name="password"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <PasswordField
              {...field}
              id="new-password"
              autoComplete="new-password"
              labelText={intl.formatMessage({
                id: "signup.form.password",
                defaultMessage: "Password",
                description: "Password field label in signup form",
              })}
              onChange={(e) => {
                field.onChange(e)
                const result = zxcvbn(e.target.value)
                setPassword({
                  password: e.target.value,
                  isValid: result.score >= 3,
                  score: result.score,
                  invalidMessage:
                    e.target.value.length > 0 && result.score < 3
                      ? result.feedback.warning ||
                        intl.formatMessage({
                          id: "signup.form.password-too-simple",
                          defaultMessage: "Password is too simple",
                          description: "Password is too simple error message",
                        })
                      : "",
                })
              }}
              isInvalid={password.password.length > 0 && (fieldState.invalid || !password.isValid)}
              invalidMessage={password.invalidMessage}
            />
          )}
        />
        {password.password.length > 0 && <Progress colorScheme={progressColor} max={4} value={password.score} />}
      </Stack>

      <Button
        id="password-signup"
        isLoading={isLoading}
        isDisabled={!password.isValid}
        colorScheme="brand"
        type="submit"
      >
        <FormattedMessage
          id="signup.form.continue"
          description="Signup form continue button text"
          defaultMessage="{ctaText}"
          values={{ ctaText: password.isValid ? "Continue with Password" : "Set a Password" }}
        />
      </Button>
    </Stack>
  )
}

// Main Component
export default function Signup() {
  const [searchParams] = useSearchParams()
  const pwCode = searchParams.get(AUTH_SEARCH_PARAMS.INVITE_CODE)
  const { handleSignup, isLoading } = useSignup()
  const intl = useIntl()
  const { data: userByCodeEmail } = useGetUserV1CodeByCodeEmailQuery({ code: pwCode ?? "" }, { skip: !pwCode })

  const email = userByCodeEmail?.email

  return (
    <LoginBaseContainer
      heading={intl.formatMessage({
        id: "signup.heading",
        description: "Heading on Signup page",
        defaultMessage: "Welcome to BRM",
      })}
      subtitleElement={<WelcomeSubtitle />}
      noBox={true}
    >
      <GoogleOAuthButton />
      {email && pwCode && (
        <>
          <AuthDivider />
          <PasswordSignupForm email={email} onSubmit={(values) => handleSignup(values, pwCode)} isLoading={isLoading} />
        </>
      )}
    </LoginBaseContainer>
  )
}
