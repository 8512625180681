import type { CommentReplyTask } from "@brm/schema-types/types.js"
import { formatDate } from "@brm/util/format-date-time.js"
import { HStack, Icon, Text } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { CheckCircleIcon, InfoIcon } from "../icons/icons.js"

const PendingReplyTaskNotice = ({ replyTask }: { replyTask: CommentReplyTask }) => {
  const intl = useIntl()

  const isCompleted = !!replyTask.dismissed_at || !!replyTask.resolved_at

  return (
    <HStack py={1}>
      <Icon as={isCompleted ? CheckCircleIcon : InfoIcon} color="gray.600" />
      <Text
        color="gray.600"
        fontSize="xs"
        textDecoration={replyTask.dismissed_at || replyTask.resolved_at ? "line-through" : undefined}
        textDecorationColor="gray.500"
      >
        <FormattedMessage
          defaultMessage="{name} has a task to reply to by {dueDate}"
          description="Notice showing who needs to reply and by when"
          id="timeline.comment.pending_reply_with_date"
          values={{
            name: replyTask.recipient.first_name,
            dueDate: formatDate(intl, replyTask.due_by, { dateStyle: "short" }),
          }}
        />
      </Text>
    </HStack>
  )
}

export default PendingReplyTaskNotice
