import { Flex, Text } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useEffect, useState } from "react"
import { FormattedMessage, FormattedNumber } from "react-intl"
import {
  useGetIntegrationV1InitialExtractionBannerQuery,
  useGetUserV1WhoamiQuery,
  type InitialExtractionBanner,
} from "../../app/services/generated-api.js"
import { Link } from "../Link.js"

export default function InitialGmailExtractionBanner() {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { data } = useGetIntegrationV1InitialExtractionBannerQuery(whoami?.organization.is_lite ? undefined : skipToken)

  // When we hit 100% progress, data stops being returned. We still want to show a finished banner
  // This variable keeps the state of the data so we can show the banner when it's done
  const [seenData, setSeenData] = useState<InitialExtractionBanner | undefined>(undefined)

  useEffect(() => {
    if (!whoami?.organization.is_lite) {
      return
    }

    if (data) {
      setSeenData(data)
      // If there is no data but there is seenData, then we have finished initial extraction
      // When we replace polling with pubsub we can remove this
    } else if (seenData && seenData.extraction_progress < 1) {
      setSeenData({ ...seenData, extraction_progress: 1 })
    }
  }, [data, seenData, whoami])

  // Only show the banner if the user is a lite user
  if (!whoami || !whoami.organization.is_lite || !seenData) {
    return null
  }

  const progressPercent = seenData.extraction_progress

  return (
    <>
      <Flex
        minH="30px"
        width="100vw"
        background="brand.500"
        alignItems="center"
        justifyContent="center"
        textColor="white"
        gap={2}
      >
        <Text as="span" zIndex={1}>
          {progressPercent >= 1 ? (
            <FormattedMessage
              defaultMessage="Finished extracting documents <link>{count} agreements</link> found!"
              description="The text for the banner that shows the progress of the initial Gmail extraction"
              id="gmail.initialExtraction.banner.extracted"
              values={{
                link: (chunks) => (
                  <Link to="/agreements" fontWeight="medium" textDecoration="underline">
                    {chunks}
                  </Link>
                ),
                count: seenData?.documents_extracted ?? 0,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Found <link>{count} {count, plural, one {agreement} other {agreements}}</link> in your email – {percent} complete"
              description="The text for the banner that shows the progress of the initial Gmail extraction"
              id="gmail.initialExtraction.banner.extracting"
              values={{
                link: (chunks) => (
                  <Link to="/agreements" fontWeight="medium" textDecoration="underline">
                    {chunks}
                  </Link>
                ),
                count: seenData?.documents_extracted ?? 0,
                percent: (
                  <FormattedNumber
                    value={progressPercent}
                    style="percent"
                    minimumFractionDigits={0}
                    maximumFractionDigits={1}
                  />
                ),
              }}
            />
          )}
        </Text>
      </Flex>
      <Flex
        minH="30px"
        width={`${Math.floor(progressPercent * 1000) / 10}vw`}
        zIndex={0}
        background="brand.600"
        position="absolute"
        top={0}
        left={0}
        transition="width 0.3s linear"
      />
    </>
  )
}
