import { uniqBy } from "@brm/util/collections.js"
import { displayPersonName } from "@brm/util/names.js"
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react"
import { useState } from "react"
import type { Control, FieldArrayWithId } from "react-hook-form"
import { Controller } from "react-hook-form"
import { FormattedMessage, useIntl, type IntlShape } from "react-intl"
import CardWithHoverActions from "../../../components/CardWithHoverActions.js"
import { PlusIcon, UserIcon } from "../../../components/icons/icons.js"
import DeleteIconButton from "../../../components/icons/system/DeleteIconButton.js"
import { getPublicImageGcsUrl } from "../../../util/url.js"
import WorkflowStepIcon from "../WorkflowStepIcon.js"
import type { ConfigureWorkflowFormState } from "./utils.js"
import { OPTIONAL_APPROVAL_STEP_TYPES } from "./utils.js"

const validateApprovalSteps = (step: ConfigureWorkflowFormState["steps"][number], intl: IntlShape) => {
  if (!OPTIONAL_APPROVAL_STEP_TYPES.includes(step.type)) {
    if (
      // No approval steps configured
      step.approval_steps.length === 0 ||
      // No approval steps configured
      step.approval_steps.every((approvalStep) => approvalStep === null) ||
      // No approvers selected for any approval step
      step.approval_steps.every((approvalStep) => approvalStep.approvers.length === 0)
    ) {
      return intl.formatMessage({
        defaultMessage: "At least one approver is required",
        description: "Error message displayed when no approvers are selected",
        id: "requests.definition.step.approver.required",
      })
    }
  }
  return true
}

export default function DefinitionStepCards({
  control,
  steps,
  missingStandardSteps,
  openStepForm,
  deleteStepById,
  addStep,
  selectedStepId,
  showSelected,
}: {
  control: Control<ConfigureWorkflowFormState>
  steps: FieldArrayWithId<ConfigureWorkflowFormState, "steps", "id">[]
  missingStandardSteps: ConfigureWorkflowFormState["steps"]
  openStepForm: (stepId: string) => void
  deleteStepById: (stepId: string) => void
  addStep: (step: ConfigureWorkflowFormState["steps"][number]) => void
  selectedStepId?: string | null
  showSelected?: boolean
}) {
  const intl = useIntl()
  const detailsStep = steps.find((step) => step.type === "details")
  const criteriaSteps = steps.filter(
    (step) => step.type === "compliance" || step.type === "finance" || step.type === "legal" || step.type === "it"
  )
  const closeStep = steps.find((step) => step.type === "close")
  return (
    <Stack py={4}>
      <Heading as="h2" size="sm" px={4}>
        <FormattedMessage
          id="request.config.request_steps.heading"
          description="Heading on the request steps section of request configuration"
          defaultMessage="Steps {numStepsBadge}"
          values={{
            numStepsBadge: (
              <Badge variant="subtleOutlined" size="sm" marginLeft={1} borderRadius="2xl">
                {steps.length}
              </Badge>
            ),
          }}
        />
      </Heading>
      <Text px={4} color="gray.600" fontSize="sm">
        <FormattedMessage
          id="request.config.request_steps.description"
          description="Helper text explaining the purpose of workflow steps"
          defaultMessage="Gather data in steps to avoid unnecessary work. Stakeholders are notified when their action is required."
        />
      </Text>
      <Stack gap={4} pl={4} pr={8}>
        {detailsStep && (
          <>
            <Controller
              name={`steps.${steps.findIndex((step) => step.id === detailsStep.id)}`}
              control={control}
              rules={{
                required: true,
                // If the step is optional, it's valid to have no approver
                validate: (step) => validateApprovalSteps(step, intl),
              }}
              render={({ fieldState }) => (
                <WorkflowStep
                  step={detailsStep}
                  onDeleteStep={() => deleteStepById(detailsStep.id)}
                  onClick={() => openStepForm(detailsStep.id)}
                  selected={showSelected && selectedStepId === detailsStep.id}
                  hasError={!!fieldState.error}
                />
              )}
            />
            <Divider />
          </>
        )}
        {criteriaSteps.length > 0 ? (
          <>
            {criteriaSteps.map((criteriaStep) => (
              <Controller
                key={criteriaStep.id}
                name={`steps.${steps.findIndex((step) => step.id === criteriaStep.id)}`}
                control={control}
                rules={{
                  required: true,
                  validate: (step) => validateApprovalSteps(step, intl),
                }}
                render={({ fieldState }) => (
                  <WorkflowStep
                    step={criteriaStep}
                    onDeleteStep={() => deleteStepById(criteriaStep.id)}
                    onClick={() => openStepForm(criteriaStep.id)}
                    selected={showSelected && selectedStepId === criteriaStep.id}
                    hasError={!!fieldState.error}
                  />
                )}
              />
            ))}
            <Divider />
          </>
        ) : null}
        {closeStep && (
          <Controller
            name={`steps.${steps.findIndex((step) => step.id === closeStep.id)}`}
            control={control}
            rules={{
              required: true,
              // If the step is optional, it's valid to have no approver
              validate: (step) => validateApprovalSteps(step, intl),
            }}
            render={({ fieldState }) => (
              <WorkflowStep
                step={closeStep}
                onDeleteStep={() => deleteStepById(closeStep.id)}
                onClick={() => openStepForm(closeStep.id)}
                selected={showSelected && selectedStepId === closeStep.id}
                hasError={!!fieldState.error}
              />
            )}
          />
        )}
        <Box>
          {missingStandardSteps.map((step) => (
            <Button
              mr={2}
              mb={2}
              variant="outline"
              key={step.display_name}
              leftIcon={<Icon as={PlusIcon} />}
              onClick={() => addStep(step)}
            >
              {step.display_name}
            </Button>
          ))}
        </Box>
      </Stack>
    </Stack>
  )
}

function WorkflowStep({
  step,
  onDeleteStep,
  onClick,
  selected,
  hasError,
}: {
  step: ConfigureWorkflowFormState["steps"][number]
  onDeleteStep: () => void
  onClick: () => void
  selected?: boolean
  hasError?: boolean
}) {
  const intl = useIntl()
  const [deletePrompted, setDeletePrompted] = useState(false)

  // Get all unique users or variables that are involved on this step
  const uniqStepApprovers = uniqBy(
    step.approval_steps.flatMap((approvalStep) => approvalStep.approvers),
    (approver) => (approver.type === "user" ? approver.user.id : approver.variable)
  )
  return (
    <Box>
      <CardWithHoverActions
        justifyContent="space-between"
        rightAction={<DeleteIconButton variant="unstyledMini" onClick={() => setDeletePrompted(true)} />}
        selected={selected}
        hasError={hasError}
        onClick={onClick}
      >
        <HStack justifyContent="space-between">
          <HStack gap={4}>
            <WorkflowStepIcon stepType={step.type} />
            <Flex flexDirection="column">
              <Text fontWeight="semibold">{step.display_name}</Text>
            </Flex>
          </HStack>
          <AvatarGroup>
            {uniqStepApprovers.map((approver, approverIdx) =>
              approver.type === "user" ? (
                <Avatar
                  key={approver?.id ?? approverIdx}
                  name={displayPersonName(approver.user, intl)}
                  src={getPublicImageGcsUrl(approver.user.profile_image?.gcs_file_name)}
                />
              ) : (
                <Avatar key={approver?.id ?? approverIdx} icon={<Icon as={UserIcon} />} />
              )
            )}
          </AvatarGroup>
        </HStack>
      </CardWithHoverActions>
      {deletePrompted ? (
        <HStack justifyContent="end" mt={2}>
          <Button onClick={() => setDeletePrompted(false)} variant="unstyled" height="unset">
            <FormattedMessage
              id="request.config.field.remove.cancel"
              description="Button text on field removal prompt to cancel this field removal"
              defaultMessage="Cancel"
            />
          </Button>
          <Button onClick={onDeleteStep} variant="unstyled" color="error.600" height="unset">
            <FormattedMessage
              id="request.config.field.remove.confirm"
              description="Button text on field removal prompt to confirm this field removal"
              defaultMessage="Remove"
            />
          </Button>
        </HStack>
      ) : null}
    </Box>
  )
}
