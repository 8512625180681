import type { CurrencyAmount, FieldMetadata, FieldMetadataWithSuggestions } from "@brm/schema-types/types.js"
import { COMMON_CURRENCIES, MORE_CURRENCIES } from "@brm/util/currency/consts.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import { isEmpty } from "@brm/util/type-guard.js"
import type { FormControlProps } from "@chakra-ui/react"
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  chakra,
  useDisclosure,
} from "@chakra-ui/react"
import { chakraComponents, type GroupBase, type Props } from "chakra-react-select"
import equal from "fast-deep-equal"
import type { ForwardedRef, ReactNode, Ref, RefObject } from "react"
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { FormattedDisplayName, useIntl } from "react-intl"
import { isNewOption } from "../../util/form.js"
import OptionWithFieldSource from "../DynamicForm/OptionWithFieldSource.js"
import type { DynamicFormFieldApproval, ValueWithSource } from "../DynamicForm/types.js"
import Select from "../Select/Select.js"

function withoutSymbol(parts: Intl.NumberFormatPart[]): string {
  return parts
    .filter((part) => part.type !== "currency")
    .map((part) => part.value)
    .join("")
    .trim()
}

function trimOrPadAmount(amount: string, currencyCode: string): string {
  return withoutSymbol(
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
      useGrouping: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).formatToParts(amount as any)
  )
}

const defaultCurrency = "USD"

type CurrencyAmountFormControlProps = FormControlProps &
  CurrencyAmountInputGroupProps & {
    legend: ReactNode
    errorMessage?: ReactNode
  }

/**
 * A compound select box + input for a currency amount.
 */
export const CurrencyAmountFormControl = forwardRef(function CurrencyAmountFormControl(
  { legend, errorMessage, value, onChange, isReadOnly, ...formControlProps }: CurrencyAmountFormControlProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <FormControl as="fieldset" isReadOnly={isReadOnly} {...formControlProps}>
      <FormLabel as="legend">{legend}</FormLabel>
      <CurrencyAmountInputGroup ref={ref} value={value} onChange={onChange} isReadOnly={isReadOnly} />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
})

interface CurrencyAmountInputGroupProps {
  value: CurrencyAmount | null
  onChange?: (value: CurrencyAmount | null, fieldSource?: FieldMetadata) => void
  isReadOnly?: boolean
  disabled?: boolean
  disableCurrencySelect?: boolean
  suggestions?: ValueWithSource<CurrencyAmount>[]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: DynamicFormFieldApproval
}

export const CurrencyAmountInputGroup = forwardRef(function CurrencyAmountInputGroup(
  {
    value,
    onChange,
    isReadOnly,
    disabled,
    suggestions,
    fieldMetadata,
    fieldApproval,
    menuPortalTarget,
    disableCurrencySelect,
  }: CurrencyAmountInputGroupProps & Pick<Props, "menuPortalTarget">,
  ref: Ref<HTMLInputElement | null>
) {
  // Keep ref to move focus to the amount input when the currency code is changed.
  const amountInputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => amountInputRef.current)
  const inputGroupRef = useRef<HTMLDivElement>(null)

  // Keep internal state of currency code so that the currency code can be set even if amount is empty.
  const [currencyCode, setCurrencyCode] = useState<string>(value?.currency_code ?? defaultCurrency)
  const { isOpen, onOpen, onClose } = useDisclosure()

  // Trim decimals on first render according to the currency on render because our backend always returns 4
  // decimals for simplicity. The NumericString is essentially a US locale floating number without decimal
  // separators and without a currency sign, so we can use Intl.NumberFormat for this.

  return (
    <InputGroup
      ref={inputGroupRef}
      // Only open dropdown when suggestions are available. Otherwise drop down is only openable via currency selector
      onFocus={suggestions && suggestions.length > 0 ? onOpen : undefined}
      onBlur={onClose}
    >
      <CurrencyInput
        ref={amountInputRef}
        value={value}
        onChange={onChange}
        isReadOnly={isReadOnly}
        disabled={disabled}
        suggestions={suggestions}
      />
      <InputRightElement justifyContent="right">
        {disableCurrencySelect || isReadOnly ? (
          <Box flexGrow={0} flexShrink={0} display="flex" alignItems="center" paddingLeft={4} paddingRight={4}>
            <Text>{currencyCode}</Text>
          </Box>
        ) : (
          <CurrencySelect
            disabled={disabled}
            value={{ amount: value?.amount || "", currency_code: currencyCode }}
            suggestions={suggestions}
            onChange={(option) => {
              let fieldSource = option.field_sources?.[0]
              const newValue = {
                amount: trimOrPadAmount(option.value?.amount || "", option.value?.currency_code || ""),
                currency_code: option.value?.currency_code || "",
              }
              // Fill fieldSource if selected option values match a suggestion
              if (!fieldSource) {
                const matchingSuggestion = suggestions?.find(
                  (suggestion) =>
                    trimOrPadAmount(suggestion.value.amount, suggestion.value.currency_code) === newValue.amount &&
                    suggestion.value.currency_code === newValue.currency_code
                )
                fieldSource = matchingSuggestion?.field_sources?.[0]
              }
              setCurrencyCode(newValue?.currency_code ?? defaultCurrency)

              // Update only when value changes or suggested value is selected to prevent fieldSource resetting
              if (
                newValue &&
                (suggestions?.some((suggestion) => equal(option, suggestion)) || !equal(newValue, value))
              ) {
                onChange?.(newValue, fieldSource)
              }
              onClose()
            }}
            isReadOnly={isReadOnly}
            isOpen={isOpen}
            parentRef={inputGroupRef}
            fieldMetadata={fieldMetadata}
            fieldApproval={fieldApproval}
            onClose={onClose}
            onOpen={onOpen}
            menuPortalTarget={menuPortalTarget}
          />
        )}
      </InputRightElement>
    </InputGroup>
  )
})

/**
 * This function normalizes the grouping and decimal separators to the US locale.
 * It removes grouping separators and ensures decimal separators are a period (.) to allow the string value to be parsed into a number
 */
function normalizeAmount(
  amount: string,
  currencySymbol: string | undefined,
  decimalSeparator: string | undefined,
  groupingSeparator: string | undefined
): string {
  const _amount = currencySymbol ? amount.replaceAll(currencySymbol, "") : amount

  // this is for the US locale 1,000.00 => 1000.00
  if (decimalSeparator === ".") {
    return groupingSeparator ? _amount.replaceAll(groupingSeparator, "") : _amount
  }

  if (decimalSeparator === ",") {
    const decimalNormalized = _amount.replace(decimalSeparator, ".")
    return groupingSeparator ? decimalNormalized.replaceAll(groupingSeparator, "") : decimalNormalized
  }

  throw new Error("Unsupported grouping separator")
}

const ignoreKeys = [
  ";",
  "{",
  "}",
  "[",
  "]",
  "|",
  "\\",
  "`",
  "~",
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "_",
  "-", // the minus sign
  "+",
  "=",
  "?",
  ".",
  "<",
  ">",
  "/",
  " ",
  "",
]

const allowedKeys = ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Backspace", "Delete"]

/**
 * An input for a currency amount. This displays the currency symbol and allows for editing the amount.
 */
export const CurrencyInput = forwardRef(function CurrencyInput(
  { value, onChange, isReadOnly, disabled, suggestions }: CurrencyAmountInputGroupProps,
  ref: Ref<HTMLInputElement | null>
) {
  const { amount, currency_code } = value ?? { amount: "0.00", currency_code: defaultCurrency }

  const intl = useIntl()

  const currency = new Intl.NumberFormat(intl.locale, {
    style: "currency",
    currency: currency_code,
  }).formatToParts(parseFloat(amount))

  const currencySymbol = currency.find((data) => data.type === "currency")?.value
  const decimalSeparator = currency.find((data) => data.type === "decimal")?.value
  const groupingSeparator = currency.find((data) => data.type === "group")?.value
  const groupingSeparatorCount = currency.filter((data) => data.type === "group").length

  const displayAmount = currency.map((data) => data.value).join("")

  const intlZeroAmount = useMemo(
    () =>
      new Intl.NumberFormat(intl.locale, {
        style: "currency",
        currency: currency_code,
      }).format(0),
    [currency_code, intl.locale]
  )

  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)
  const [selectionRange, setSelectionRange] = useState<{ start: number | null; end: number | null }>({
    start: null,
    end: null,
  })

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(selectionRange.start, selectionRange.end)
    }
  }, [selectionRange])

  return (
    <Input
      ref={inputRef}
      isReadOnly={isReadOnly}
      isDisabled={disabled}
      type="text"
      // empty string allows the placeholder to be displayed
      value={value === null ? "" : displayAmount}
      placeholder={intlZeroAmount}
      autoComplete="off"
      onKeyDownCapture={(e) => {
        // move the cursor to the location of the decimal separator
        if (decimalSeparator && e.key === decimalSeparator) {
          e.preventDefault()
          setSelectionRange({
            start: displayAmount.indexOf(decimalSeparator) + 1,
            end: displayAmount.indexOf(decimalSeparator) + 1,
          })
          return
        }

        if (allowedKeys.includes(e.key)) {
          return
        }

        // prevent non-numeric characters from being entered
        if (e.key.match(/[a-z]/iu) || ignoreKeys.includes(e.key)) {
          e.preventDefault()
          return
        }
      }}
      onChange={(e) => {
        const { selectionStart, selectionEnd } = e.target

        const newStringAmount = e.target.value

        // short_circuit: the amount is empty
        if (newStringAmount === "") {
          onChange?.(null)
          return
        }

        // if the incoming stringAmount does not include the currency symbol, then we need to offset the selection range by 1
        let currencySymbolOffset = 0
        if (currencySymbol && !newStringAmount.includes(currencySymbol)) {
          currencySymbolOffset = 1
        }

        const normStringAmount = normalizeAmount(newStringAmount, currencySymbol, decimalSeparator, groupingSeparator)
        const numericAmount = parseFloat(normStringAmount)

        // short_circuit: the normalizedAmount produced NaN
        if (isNaN(numericAmount)) {
          onChange?.(null)
          return
        }

        const newCurrency = new Intl.NumberFormat(intl.locale, {
          style: "currency",
          currency: currency_code,
        }).formatToParts(numericAmount)

        // short_circuit: parsing resulted in a NaN or Infinity so we should do nothing
        if (newCurrency.some((data) => data.type === "nan" || data.type === "infinity")) {
          return
        }

        const newAmount = newCurrency
          .filter((data) => data.type !== "currency" && data.type !== "group")
          .map((data) => data.value)
          .join("")

        const trimmedAmount = normalizeAmount(newAmount, currencySymbol, decimalSeparator, groupingSeparator)

        // short_circuit: the new value is the same as the old value
        if (value?.amount === trimmedAmount) {
          return
        }

        // at this point in time, we know that we have a net new, valid value

        // adjust the cursor position to account for new grouping separators
        // which will get added during the formatting on the next render
        const newGroupingSeparatorCount = newCurrency.filter((part) => part.type === "group").length
        if (newGroupingSeparatorCount > groupingSeparatorCount && selectionStart !== null && selectionEnd !== null) {
          // offset the selection range by the number of grouping separators
          const offset = newGroupingSeparatorCount - groupingSeparatorCount
          setSelectionRange({
            start: selectionStart + offset + currencySymbolOffset,
            end: selectionEnd + offset + currencySymbolOffset,
          })
        } else if (
          newGroupingSeparatorCount < groupingSeparatorCount &&
          selectionStart !== null &&
          selectionEnd !== null
        ) {
          // offset the selection range by the number of grouping separators
          const offset = groupingSeparatorCount - newGroupingSeparatorCount
          setSelectionRange({
            start: selectionStart - offset + currencySymbolOffset,
            end: selectionEnd - offset + currencySymbolOffset,
          })
        } else {
          setSelectionRange({
            start: (selectionStart ?? 0) + currencySymbolOffset,
            end: (selectionEnd ?? 0) + currencySymbolOffset,
          })
        }

        const matchingSuggestion = suggestions?.find(
          (suggestion) =>
            trimmedAmount &&
            formatCurrency(suggestion.value, intl) ===
              formatCurrency(
                {
                  amount: trimmedAmount,
                  currency_code,
                },
                intl
              )
        )

        onChange?.({ amount: trimmedAmount, currency_code }, matchingSuggestion?.field_sources?.[0])
      }}
    />
  )
})

/**
 * A select box for a currency code.
 */
const CurrencySelect = forwardRef(function CurrencySelect(
  {
    value,
    onChange,
    isReadOnly,
    disabled,
    suggestions,
    isOpen,
    parentRef,
    fieldMetadata,
    onOpen,
    onClose,
    fieldApproval,
    menuPortalTarget,
  }: {
    /** The current 3-character ISO currency code. */
    value: CurrencyAmount

    /** Called with the new currency code. */
    onChange: (value: ValueWithSource<CurrencyAmount | undefined>) => void
    isReadOnly?: boolean
    disabled?: boolean
    suggestions?: ValueWithSource<CurrencyAmount>[]
    fieldMetadata?: FieldMetadataWithSuggestions
    isOpen?: boolean
    parentRef?: RefObject<HTMLDivElement>
    onOpen?: () => void
    onClose?: () => void
    fieldApproval?: DynamicFormFieldApproval
  } & Pick<Props, "menuPortalTarget">,
  ref: Ref<HTMLInputElement | null>
) {
  const intl = useIntl()

  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)

  return (
    // Make sure that Enter in the menu doesn't submit a surrounding form
    <Box display="contents">
      <Select<
        ValueWithSource<CurrencyAmount | undefined>,
        false,
        GroupBase<ValueWithSource<CurrencyAmount | undefined>>
      >
        value={{ value, field_sources: fieldMetadata ? [fieldMetadata] : [] }}
        isOptionSelected={(option) => {
          if (!option.value) {
            return false
          }
          const amountEquals = formatCurrency(option.value, intl) === formatCurrency(value, intl)
          const fieldSourceEquals =
            option.field_sources?.some((source) => source.id === fieldMetadata?.id) ||
            (isEmpty(option.field_sources) && fieldMetadata?.type === "user")

          return amountEquals && fieldSourceEquals
        }}
        components={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          SingleValue: (props) => (
            <chakraComponents.SingleValue {...props}>{props.data.value?.currency_code}</chakraComponents.SingleValue>
          ),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Option: (props) => {
            const fieldSources = props.data.field_sources
            return fieldSources && fieldSources.length > 0 ? (
              <chakraComponents.Option {...props}>
                <OptionWithFieldSource fieldSources={fieldSources}>
                  {formatCurrency(props.data.value, intl)}
                </OptionWithFieldSource>
              </chakraComponents.Option>
            ) : (
              <chakraComponents.Option {...props}>
                <chakra.span>{props.data.value?.currency_code}</chakra.span>
                <Text
                  as="span"
                  fontSize="sm"
                  opacity={0.7}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  <FormattedDisplayName value={props.data.value?.currency_code ?? ""} type="currency" fallback="none" />
                </Text>
              </chakraComponents.Option>
            )
          },
        }}
        onMenuOpen={onOpen}
        onMenuClose={onClose}
        menuIsOpen={isOpen}
        isSearchable={false}
        menuPortalTarget={menuPortalTarget}
        styles={{
          menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
        }}
        chakraStyles={{
          container: (styles) => ({ ...styles, flexGrow: 0, flexShrink: 0 }),
          indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
          // Remove padding from the group heading because we only want to display the bottom border and no text.
          groupHeading: (styles) => ({ ...styles, borderBottomWidth: 1, px: 2, height: 0, padding: 0 }),
          dropdownIndicator: (styles) => ({
            ...styles,
            px: 1,
            display: isReadOnly ? "none" : "flex",
            background: "none",
            width: "unset",
          }),
          // This is a hack to make the menu the same width as the input field.
          // TODO: To improve this, we should override react-select's input with the currency input
          menu: (styles) => ({ ...styles, width: parentRef?.current?.clientWidth, right: 0 }),
          option: (styles, { data }) => {
            const { isNew, colorScheme } = isNewOption(data.field_sources, fieldMetadata, fieldApproval)
            return {
              ...styles,
              display: "flex",
              ...(isNew && {
                backgroundColor: `${colorScheme}.50`,
              }),
            }
          },
          control: (styles) => ({
            ...styles,
            borderWidth: 0,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            _focus: { ...(styles as any)._focus, boxShadow: "none" },
          }),
          valueContainer: (styles) => ({ ...styles, paddingInlineStart: 1, paddingInlineEnd: 1 }),
        }}
        options={[
          {
            options: suggestions || [],
          },
          {
            options: COMMON_CURRENCIES.map((currency) => ({
              value: { amount: value.amount, currency_code: currency },
              fieldSources: [],
            })),
          },
          {
            options: MORE_CURRENCIES.map((currency) => ({
              value: { amount: value.amount, currency_code: currency },
              fieldSources: [],
            })),
          },
        ]}
        onChange={(v) => {
          if (v) {
            onChange(v)
          }
        }}
        isReadOnly={isReadOnly}
        isDisabled={disabled}
        ref={(select) => {
          inputRef.current = select?.inputRef ?? null
        }}
      />
    </Box>
  )
})
