import type { DocumentBatchExtractionRequest } from "@brm/schema-types/types.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { Button, Card, Icon, Stack, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import {
  usePostDocumentV1BackgroundExtractBatchMutation,
  usePostDocumentV1ListQuery,
  type DocumentListItem,
  type DocumentMinimal,
  type PostDocumentV1ListApiArg,
} from "../../app/services/generated-api.js"
import { PAGE_PADDING_X, PAGE_PADDING_Y } from "../../util/constant.js"
import {
  packageSortFilterOptionsForAPI,
  shownColumnsForTableParamState,
  TABLE_DEFAULT_PARAMS,
} from "../../util/schema-table.js"
import { useObjectSchema } from "../../util/use-schema.js"
import DataTable from "../DataTable/DataTable.js"
import TablePageHeader from "../DataTable/SchemaFilter/TablePageHeader.js"
import { ROOT_COLUMN_ID, useSchemaColumns } from "../DataTable/use-schema-columns.js"
import { useLocalStorageTableParamsSync, useUrlTableParams } from "../DataTable/use-schema-table-params.js"
import { FolderIcon, UploadIcon } from "../icons/icons.js"
import { DocumentsBulkUploadModal } from "./DocumentsUploadModal.js"
import { DOCUMENTS_TABLE_ID } from "./util.js"

const primaryColumn = ROOT_COLUMN_ID
const defaultColumns: BRMPaths<DocumentListItem>[] = ["field_category", "uploaded_by", "relation_created_at"]

export default function DocumentsPanel({ toolId, vendorId }: Pick<PostDocumentV1ListApiArg, "toolId" | "vendorId">) {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const toast = useToast()

  const hashParams = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash])
  const bulkUploadOpen = hashParams.get("upload")

  const bulkUploadModal = useDisclosure({
    isOpen: !!bulkUploadOpen,
    onClose: () => {
      hashParams.delete("upload")
      navigate({ search: location.search, hash: `#${hashParams}` })
    },
    onOpen: () => {
      hashParams.set("upload", "true")
      navigate({ search: location.search, hash: `#${hashParams}` })
    },
  })

  const [backgroundExtractDocuments] = usePostDocumentV1BackgroundExtractBatchMutation()

  useLocalStorageTableParamsSync(DOCUMENTS_TABLE_ID)

  const documentSchema = useObjectSchema("Document")
  const { tableParams, getUpdatedSerializedParams, updateTableParams } = useUrlTableParams<string>({
    defaultParams: TABLE_DEFAULT_PARAMS,
    objectSchema: documentSchema,
    savedViews: [],
  })

  const onBulkUploadSubmit = async (uploadedDocuments: DocumentMinimal[]) => {
    const documentBatchExtractionRequest: DocumentBatchExtractionRequest | undefined = toolId
      ? {
          extraction_request_type: "tool",
          document_ids: uploadedDocuments.map((doc) => doc.id),
          tool_id: toolId,
        }
      : vendorId
        ? {
            extraction_request_type: "vendor",
            document_ids: uploadedDocuments.map((doc) => doc.id),
            vendor_id: vendorId,
          }
        : undefined
    if (!documentBatchExtractionRequest) {
      return
    }

    // Trigger bulk-processing
    await backgroundExtractDocuments({ documentBatchExtractionRequest }).unwrap()
    // Sort by created_at descending to show the new documents at the top of the table as they get extracted
    updateTableParams?.({ sorting: [{ id: "document_relation_created_at", desc: true }] })
    toast({
      status: "success",
      description: (
        <FormattedMessage
          defaultMessage="Upload successful. Starting field extraction for the uploaded {documentsCount, plural, one {document} other {documents}} in the background."
          description="Toast success message for triggering background extraction of documents"
          id="documents.table.bulkUpload.started.success"
          values={{ documentsCount: uploadedDocuments.length }}
        />
      ),
      duration: 60_000,
    })
  }

  const shownColumns = useMemo(
    () => tableParams && shownColumnsForTableParamState(tableParams, defaultColumns),
    [tableParams]
  )
  const columns = useSchemaColumns<DocumentListItem>({ objectSchema: documentSchema, shownColumns, primaryColumn })
  const apiParams = useMemo(
    () => documentSchema && tableParams && packageSortFilterOptionsForAPI<string>(tableParams, documentSchema, intl),
    [intl, tableParams, documentSchema]
  )
  const { data: documentData } = usePostDocumentV1ListQuery(
    apiParams
      ? {
          toolId,
          vendorId,
          listQueryStringParams: apiParams,
        }
      : skipToken
  )

  if (!tableParams || !documentSchema || !shownColumns || !columns || !getUpdatedSerializedParams) {
    return null
  }

  return (
    <>
      <Stack width="full" paddingX={PAGE_PADDING_X} paddingY={PAGE_PADDING_Y}>
        <TablePageHeader
          tableId={DOCUMENTS_TABLE_ID}
          title={intl.formatMessage(
            {
              id: "documents.title",
              description: "Title for the documents panel",
              defaultMessage: "Documents ({count})",
            },
            { count: documentData?.documents?.length ?? 0 }
          )}
          filterMap={tableParams.filterMap}
          onChangeFilters={(filterMap) => updateTableParams?.({ filterMap })}
          objectSchema={documentSchema}
          selectedColumns={shownColumns}
          hideShowAllFilters={true}
          headerProps={{ paddingX: 4, paddingY: 3 }}
          beforeSavedView={
            <Button colorScheme="brand" onClick={bulkUploadModal.onOpen} gap={2}>
              <Icon as={UploadIcon} />
              <FormattedMessage
                id="documents.panel.bulkUpload.button"
                description="Button label for bulk-uploading documents"
                defaultMessage="Upload"
              />
            </Button>
          }
        />
        {documentData && documentData.total > 0 ? (
          <DataTable
            borderWidth="1px"
            overflow="auto"
            data={documentData.documents}
            columns={columns}
            sorting={tableParams.sorting}
            onSortingChange={(newSorting) =>
              newSorting instanceof Function
                ? updateTableParams?.({ sorting: newSorting(tableParams.sorting) })
                : updateTableParams?.({ sorting: newSorting })
            }
            paginationProps={{
              page: tableParams.page,
              pageSize: tableParams.pageSize,
              onPageChange: (page) => updateTableParams?.({ page }),
              onPageSizeChange: (pageSize) => updateTableParams?.({ pageSize }),
              totalListElements: documentData.total,
            }}
          />
        ) : (
          tableParams.filterMap.size === 0 && (
            <Stack borderColor="gray.200" borderWidth={1} padding={8} alignItems="center" justifyContent="center">
              <Card variant="outline" padding={3}>
                <Icon as={FolderIcon} boxSize={6} />
              </Card>
              <Text fontWeight="semibold" fontSize="medium" color="gray.900" marginTop={2}>
                <FormattedMessage
                  id="documents.emptyState.action"
                  description="What the user sees when no documents are uploaded yet"
                  defaultMessage="No documents uploaded yet"
                />
              </Text>
              <Text color="gray.600">
                {toolId ? (
                  <FormattedMessage
                    id="documents.emptyState.tool.description"
                    description="What the user sees when no documents are uploaded yet"
                    defaultMessage="Update documents relevant to the tool, such as SOC2 Reports, DPAs, etc."
                  />
                ) : (
                  <FormattedMessage
                    id="documents.emptyState.vendorId.description"
                    description="What the user sees when no documents are uploaded yet"
                    defaultMessage="Update documents relevant to the vendor, such as W-9s, NDA, etc."
                  />
                )}
              </Text>
              <Button
                colorScheme="brand"
                gap={2}
                padding={5}
                fontSize="medium"
                marginTop={2}
                onClick={bulkUploadModal.onOpen}
              >
                <Icon as={UploadIcon} />
                <FormattedMessage
                  id="documents.emptyState.button"
                  description="Button label for uploading documents"
                  defaultMessage="Upload"
                />
              </Button>
            </Stack>
          )
        )}
      </Stack>
      {bulkUploadModal.isOpen && (
        // set returnFocusOnClose false to prevent unintended side nav focus issues
        <DocumentsBulkUploadModal
          {...bulkUploadModal}
          onSubmit={onBulkUploadSubmit}
          objectType={toolId ? "Tool" : "Vendor"}
          returnFocusOnClose={false}
        />
      )}
    </>
  )
}
