import type { SystemStyleObject } from "@chakra-ui/react"
import type { ChakraStylesConfig, GroupBase } from "chakra-react-select"

function pickerOptionProfilePictureStyle<T>(
  styles: SystemStyleObject,
  { data, isSelected }: { data: T; isSelected?: boolean },
  getImage: (data: T) => string | undefined | null
) {
  const { __isNew__ } = data as T & {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __isNew__?: boolean
  }
  const beforePictureElement = {
    ":before": {
      // eslint-disable-next-line prefer-smart-quotes/prefer
      content: '" "',
      width: "1lh",
      height: "1lh",
      marginRight: "8px",
      display: "block",
      backgroundImage: getImage(data) ?? "none",
      backgroundColor: "var(--chakra-colors-gray-300)",
      backgroundSize: "cover",
      borderRadius: "100%",
      visibility: __isNew__ ? "hidden" : "visible",
      flexShrink: 0,
    } satisfies SystemStyleObject,
  }

  return {
    ...styles,
    display: "flex",
    alignItems: "center",
    ...(isSelected && { backgroundColor: "var(--chakra-colors-gray-200)", color: "var(--chakra-colors-gray-900)" }),
    ...beforePictureElement,
  }
}

/**
 * Generic styles for displaying picker options using the `chakra-react-select` Select component. Accepts a function
 * that returns the image url for a given option.
 */
function baseChakraStylesForPickerOption<
  TOption,
  TIsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
>(): ChakraStylesConfig<TOption, TIsMulti, TGroup> {
  return {
    valueContainer: (styles) => ({ ...styles, paddingLeft: "10px" }),
    container: (styles) => ({
      ...styles,
      borderRadius: "8px",
    }),
    inputContainer: (styles, props) => (props.hasValue && !props.isMulti ? { ...styles, paddingLeft: "31px" } : styles),
    group: (styles) => ({ ...styles, borderWidth: 0 }),
  }
}

export function chakraStylesForProfilePicturePickerOption<T>(
  getImage: (data: T) => string | undefined | null
): ChakraStylesConfig<T> {
  return {
    ...baseChakraStylesForPickerOption(),
    option: (styles, props) => pickerOptionProfilePictureStyle(styles, props, getImage),
    singleValue: (styles, props) => ({
      ...pickerOptionProfilePictureStyle(styles, props, getImage),
      color: "var(--chakra-color-gray.400)",
    }),
    multiValue: (styles, props) => ({
      ...pickerOptionProfilePictureStyle(styles, props, getImage),
      color: "var(--chakra-color-gray.400)",
    }),
  }
}
