import { hasPermission } from "@brm/schema-helpers/role.js"
import type {
  Permission,
  PermissionedEntityType,
  PermissionedPickableEntity,
  Role,
  User,
  VariableType,
} from "@brm/schema-types/types.js"
import { type InviteUser, type UserPicker } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { BoxProps } from "@chakra-ui/react"
import { Avatar, Box, HStack, Icon, Text, useToast } from "@chakra-ui/react"
import type { SelectInstance, Props as SelectProps } from "chakra-react-select"
import { useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { usePostOrganizationV1PermissionedPickableEntitiesQuery } from "../../app/services/generated-api.js"
import InviteOrUpdateUserModal from "../../features/organization/invites/InviteOrUpdateUserModal.js"
import { UserUpdateModal } from "../../features/organization/users/UserUpdateModal.js"
import { translatePersonVariableName } from "../../features/person/util.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { UserIcon } from "../icons/icons.js"
import Select from "../Select/Select.js"

interface OrganizationEntityMultiPickerProps extends SelectProps<PermissionedPickableEntity, true> {
  boxProps?: BoxProps
  ignoreMap?: Map<PermissionedEntityType, Set<string>>
  includedEntities: PermissionedEntityType[]
  variableTypes?: VariableType[]
  // If the field the entity is being picked for requires a permission to be set, this should be provided.
  permission?: {
    value: Permission
    // If true, all users regardless of their permissions will be included in the picker - allows selecting a user and updating their permission simultaneously
    includeAll: boolean
    // If set, the default role will be populated to newly invited users or users missing the required permission
    defaultInviteRole?: Role
  }
  limit?: number
}

/**
 * Improved version of BasicOrganizationEntityMultiPicker that allows for persons to be invited as users and for users to be updated as a modal
 * within the picker itself. Will be used for most cases where an entity needs to be picked, but cannot be used in the InviteOrUpdateUserModal
 * or UserUpdateModal because it would cause a circular dependency.
 */
function OrganizationEntityMultiPicker(props: OrganizationEntityMultiPickerProps) {
  const { boxProps, includedEntities, ignoreMap, onChange, value, variableTypes, permission, limit = 10 } = props
  const openInviteForPerson = permission !== undefined

  const intl = useIntl()
  const toast = useToast()
  const { code } = useParams<{ code: string }>()

  const [pickableSearchValue, setPickableSearchValue] = useState("")
  const [selectedInvitee, setSelectedInvitee] = useState<InviteUser | null>(null)
  const [userToUpdate, setUserToUpdate] = useState<UserPicker | null>(null)
  const ref = useRef<SelectInstance<PermissionedPickableEntity, true>>(null)

  const { data, isLoading } = usePostOrganizationV1PermissionedPickableEntitiesQuery({
    body: {
      filter: {
        name: pickableSearchValue,
        entities: includedEntities,
        permission,
      },
      limit,
      link_code: code,
    },
  })

  const options = useMemo(() => {
    if (!data) {
      return data
    }
    const dataWithVariables =
      includedEntities.includes("variable") && variableTypes
        ? [...variableTypes.map((v) => ({ type: "variable" as const, variable: v })), ...data]
        : data

    if (!ignoreMap) {
      return dataWithVariables
    }

    return dataWithVariables.filter(
      (entity) => !ignoreMap.get(entity.type)?.has(entity.type === "variable" ? entity.variable : entity.id)
    )
  }, [data, ignoreMap, includedEntities, variableTypes])

  const onChangePickables: SelectProps<PermissionedPickableEntity, true>["onChange"] = (pickables, action) => {
    if (!pickables) {
      onChange?.([], action)
      return
    }
    const maybeUserWithoutPermission = pickables.find(
      (p) => p.type === "user" && !hasPermission(p.roles, permission?.value)
    )
    if (maybeUserWithoutPermission) {
      setUserToUpdate(maybeUserWithoutPermission as UserPicker)
      return
    }
    const maybePersonToInvite = pickables.find((p) => p.type === "person" && openInviteForPerson)
    if (maybePersonToInvite) {
      setSelectedInvitee(maybePersonToInvite as InviteUser)
      return
    }
    onChange?.(pickables, action)
  }

  const onUserRoleChange = (user: User) => {
    if (hasPermission(user.roles, permission?.value)) {
      const newUserToAdd = {
        type: "user",
        object_type: "User",
        id: user.id,
        display_name: displayPersonName(user, intl),
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        image_asset: user.profile_image,
        roles: user.roles,
        organization_id: user.organization_id,
      } as const
      if (Array.isArray(value)) {
        onChange?.([...value, newUserToAdd], { action: "select-option", option: newUserToAdd })
      } else {
        onChange?.([newUserToAdd], { action: "select-option", option: newUserToAdd })
      }
    } else {
      // InviteModal today does not force the invited user to have the required permission
      toast({
        description: intl.formatMessage(
          {
            id: "organization.entityPicker.insufficientPermissions",
            description: "Toast message when assigning a user with insufficient permissions to a selection",
            defaultMessage: "Selected user does not have the required permission: {requiredPermission}",
          },
          { requiredPermission: permission?.value }
        ),
        status: "error",
        position: "top",
      })
    }
  }

  const getOptionLabel = (pickable: PermissionedPickableEntity) => {
    if (pickable.type === "variable") {
      return translatePersonVariableName(pickable.variable, intl)
    }
    const name = displayPersonName(pickable, intl)
    switch (pickable.type) {
      case "user":
        if (!hasPermission(pickable.roles, permission?.value)) {
          return intl.formatMessage(
            {
              defaultMessage: "{name} (update permissions)",
              description:
                "Label for the option text in a user picker when clicking on it will open a permission modal",
              id: "components.ComboBox.OrganizationEntityMultiPicker.updateUserPermissions",
            },
            { name }
          )
        }
        break
      case "person":
        if (openInviteForPerson) {
          return intl.formatMessage(
            {
              defaultMessage: "{name} (invite to BRM)",
              description: "Label for the option text in a person picker when clicking on it will open an invite modal",
              id: "components.ComboBox.OrganizationEntityMultiPicker.invitePerson",
            },
            { name }
          )
        }
        break
      default:
        unreachable(pickable)
    }

    return name
  }

  return (
    <Box flexGrow={1} {...boxProps}>
      <Select<PermissionedPickableEntity, true>
        isMulti
        options={options}
        isLoading={isLoading}
        getOptionLabel={getOptionLabel}
        formatOptionLabel={(pickable) => (
          <HStack pl="1px">
            <Avatar
              icon={<Icon as={UserIcon} />}
              size={props.size}
              src={pickable.type === "variable" ? undefined : getPublicImageGcsUrl(pickable.image_asset?.gcs_file_name)}
            />
            <Text>{getOptionLabel(pickable)}</Text>
          </HStack>
        )}
        getOptionValue={(pickable) => (pickable.type === "variable" ? pickable.variable : pickable.id)}
        value={value}
        inputValue={pickableSearchValue}
        onInputChange={(val) => setPickableSearchValue(val)}
        ref={ref}
        menuPortalTarget={document.body}
        {...props}
        onChange={onChangePickables}
      />
      {selectedInvitee && (
        <InviteOrUpdateUserModal
          onClose={() => {
            setSelectedInvitee(null)
            setPickableSearchValue("")
          }}
          isOpen={selectedInvitee !== null}
          onSuccess={onUserRoleChange}
          initialState={selectedInvitee}
          finalFocusRef={{ current: ref.current?.inputRef ?? null }}
          requiredPermission={permission?.value}
        />
      )}
      {userToUpdate && (
        <UserUpdateModal
          user={{
            ...userToUpdate,
            profile_image: userToUpdate.image_asset,
            roles: permission?.defaultInviteRole
              ? [...userToUpdate.roles, permission.defaultInviteRole]
              : userToUpdate.roles,
          }}
          isOpen={userToUpdate !== null}
          onClose={() => {
            setUserToUpdate(null)
          }}
          finalFocusRef={{ current: ref.current?.inputRef ?? null }}
          onSuccess={onUserRoleChange}
          requiredPermission={permission?.value}
        />
      )}
    </Box>
  )
}
export default OrganizationEntityMultiPicker
