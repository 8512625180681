import type { Schema as CfJsonSchema } from "@cfworker/json-schema"
import * as cfWorkerJsonSchema from "@cfworker/json-schema"
import type { JSONSchema, JSONSchemaBoolean, JSONSchemaObject, Properties } from "@json-schema-tools/meta-schema"
import { capitalCase } from "change-case"
import { includeKeys } from "filter-obj"
import { RefResolver } from "json-schema-ref-resolver"
import type { SchemaObject } from "json-schema-traverse"
import traverseJsonSchema from "json-schema-traverse"
import traverse from "neotraverse"
import objectPath from "object-path"
import slugify from "slugify"
import type { ReadonlyDeep } from "type-fest"
import * as typedAssert from "typed-assert"
import { customKeywords, type EnumColorScheme, type IconType } from "./custom-keywords.js"
import { omitUndefined } from "./omit-nulls.js"
import { NUMERIC_STRING_PATTERN } from "./string.js"
import { hasOwnProperty, isObject } from "./type-guard.js"

export class UnexpectedSchemaError extends Error {
  public override readonly name = "UnexpectedSchemaError"
  constructor(
    message: string,
    public readonly schema: unknown,
    options?: ErrorOptions
  ) {
    super(`${message}\n\n${JSON.stringify(schema, null, 2)}`, options)
  }
}

/**
 * Checks if the given schema is a {@link Nullable} schema.
 */
export const isNullableSchema = (
  schema: unknown
): schema is JSONSchemaObject & { anyOf: (NullSchema | JSONSchema)[] } =>
  isObject(schema) &&
  "anyOf" in schema &&
  Array.isArray(schema.anyOf) &&
  schema.anyOf.length === 2 &&
  schema.anyOf.some((schema) => isNullSchema(schema))

export type NullSchema = { type: "null" } | { const: null }

/**
 * Checks if the given schema is a schema that only allows `null` as a literal value.
 *
 * Example null schemas: { "type": "null" } or { "const": null }
 */
export const isNullSchema = (schema: unknown): schema is NullSchema =>
  isObject(schema) && (("type" in schema && schema.type === "null") || ("const" in schema && schema.const === null))

/**
 * Checks if the given schema is a schema that explicitly forbids `null` as a literal value.
 *
 * Example not null schemas: { "not": { "type": "null" } } or { "not": { "const": null } }
 */
export const isNotNullSchema = (schema: unknown): schema is JSONSchemaObject & { not: NullSchema } =>
  isObject(schema) && "not" in schema && isNullSchema(schema.not)

export const isObjectWithIdSchema = (
  schema: ReadonlyDeep<JSONSchema> | undefined
): schema is ReadonlyDeep<JSONSchemaObject> & { properties: { id: ReadonlyDeep<JSONSchemaObject> } } =>
  isObject(schema) && isObject(schema.properties?.id)

/**
 * For a JSON schema type `T | null` (or plain `T`), returns `T`.
 */
export function unwrapNullableSchema(schema: ReadonlyDeep<JSONSchemaObject>): ReadonlyDeep<JSONSchemaObject>
export function unwrapNullableSchema(schema: ReadonlyDeep<JSONSchema>): ReadonlyDeep<JSONSchema>
export function unwrapNullableSchema(schema: ReadonlyDeep<JSONSchema>): ReadonlyDeep<JSONSchema> {
  if (isObject(schema) && isNullableSchema(schema)) {
    const nonNullableSchema = schema.anyOf.find((schema) => !isNullSchema(schema))
    if (isObject(nonNullableSchema)) {
      const {
        description = nonNullableSchema.description,
        title = nonNullableSchema.title,
        uiDescription = nonNullableSchema.uiDescription,
        ...rest
      } = schema as JSONSchemaObject
      return {
        ...includeKeys(rest, customKeywords),
        ...nonNullableSchema,
        ...omitUndefined({ description, title, uiDescription }),
      }
    }
    return nonNullableSchema ?? schema
  }
  return schema
}

/**
 * Gets the JSON subSchema for a given path in an object structure.
 *
 * The object structure itself is not passed to the function.
 *
 * @param schema The schema describing the entire object structure.
 * @param path The path in the object structure as a sequence of property names and array indices.
 */
export function getSchemaAtPath(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  path: string | readonly (string | number)[],
  unwrapNullableSchema: boolean = true
): ReadonlyDeep<JSONSchema> | undefined {
  if (typeof path === "string") {
    path = path.split(".").filter(Boolean)
  }
  const [property, ...rest] = path
  if (property === "") {
    throw new Error("Invalid path segment: empty string")
  }
  // Falsy value 0 is a valid property in an array schema so we explicitly check property is not undefined
  if (property === undefined) {
    // If the schema is a union type of a null schema and another schema, return the other schema
    if (isNullableSchema(schema) && unwrapNullableSchema) {
      const anyOfMember = schema.anyOf.find((s) => !isNullSchema(s))
      if (anyOfMember !== undefined) {
        if (!isObject(anyOfMember)) {
          return anyOfMember
        }
        // Make sure to not lose metadata from the union type when returning the anyOf member
        // https://json-schema.org/understanding-json-schema/reference/generic.html
        const metadata = includeKeys(schema, ["title", "description", "readOnly", ...customKeywords])
        return { ...anyOfMember, ...metadata }
      }
    }
    return schema
  }
  if (!isObject(schema)) {
    return undefined
  }
  const itemsSchema = schema.items || schema.contains
  if (itemsSchema) {
    return getSchemaAtPath(itemsSchema, rest, unwrapNullableSchema)
  }
  const result =
    schema.properties?.[property] && getSchemaAtPath(schema.properties[property], rest, unwrapNullableSchema)
  if (result) {
    return result
  }
  for (const subSchema of schema?.allOf ?? []) {
    const result = getSchemaAtPath(subSchema, path, unwrapNullableSchema)
    if (result) {
      return result
    }
  }
  for (const subSchema of schema?.anyOf ?? []) {
    const result = getSchemaAtPath(subSchema, path, unwrapNullableSchema)
    if (result) {
      return result
    }
  }
  for (const subSchema of schema?.oneOf ?? []) {
    const result = getSchemaAtPath(subSchema, path, unwrapNullableSchema)
    if (result) {
      return result
    }
  }
  return undefined
}

export function shouldSkipChildrenDisplay(schema: ReadonlyDeep<JSONSchema>): boolean {
  return isObject(schema) && (schema.childrenTableDisplay === false || schema.displayable === false)
}

export function pathExistsInSchema(schema: ReadonlyDeep<JSONSchema> | undefined, path: (string | number)[]): boolean {
  return getSchemaAtPath(schema, path) !== undefined
}

export function getValueAtPath(value: unknown, path: (string | number)[]): unknown {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return path.reduce((value: any, key) => (isObject(value) ? value[key as keyof typeof value] : value), value)
}

export interface ConstSchema<T = unknown> {
  const: T
}

/**
 * Checks if the given schema is a schema that only allows one literal value
 *
 * Example const schemas: { "const": true } or { "const": null }
 */
export function isConstSchema(schema: unknown): schema is ConstSchema {
  return isObject(schema) && "const" in schema
}

export function isStringConstSchema(schema: unknown): schema is ConstSchema<string> {
  return isConstSchema(schema) && typeof schema.const === "string"
}

export function isLeafNodeSchema(schema: unknown): boolean {
  return (
    !isObject(schema) ||
    !(
      ("properties" in schema && schema.properties) ||
      ("items" in schema && schema.items) ||
      ("contains" in schema && schema.contains)
    )
  )
}

export const isInternalOnlyFieldConfig = (schema: unknown): boolean => {
  return isObject(schema) && hasOwnProperty(schema, "internalOnly") && schema.internalOnly === true
}

/**
 * Takes a `properties` definition and returns an object that fulfills all the properties that were a `const`
 * schema by having the value stated in the `const` schema.
 *
 * Any properties that are not a `const` schema are ignored.
 */
export function createObjectFromConstSchemas(properties: Record<string, unknown>): object {
  return Object.fromEntries(
    Object.entries(properties)
      .filter((entry): entry is [property: string, schema: ConstSchema] => {
        const [_property, schema] = entry
        return isConstSchema(schema)
      })
      .map(([property, schema]) => [property, schema.const])
  )
}

export type CallbackWithPath = ({
  schema,
  jsonPtr,
  rootSchema,
  path,
  parentJsonPtr,
  parentKeyword,
  parentSchema,
  keyIndex,
}: {
  schema: SchemaObject
  jsonPtr: string
  rootSchema: SchemaObject
  path: (string | number)[]
  parentJsonPtr?: string
  parentKeyword?: string
  parentSchema?: SchemaObject
  keyIndex?: string | number
}) => void
export function traverseJsonSchemaWithPath(rootSchema: SchemaObject, cb: CallbackWithPath): void {
  const currentPath: (string | number)[] = []
  traverseJsonSchema(rootSchema, {
    cb: {
      pre: (schema, jsonPtr, rootSchema, parentJsonPtr, parentKeyword, parentSchema, keyIndex) => {
        if (keyIndex !== undefined) {
          currentPath.push(keyIndex)
        } else if (parentKeyword === "contains" || parentKeyword === "items") {
          currentPath.push(0)
        }
        cb({
          schema,
          jsonPtr,
          rootSchema,
          path: Array.from(currentPath),
          parentJsonPtr,
          parentKeyword,
          parentSchema,
          keyIndex,
        })
      },
      post: (_schema, _jsonPtr, _rootSchema, _parentJsonPtr, _parentKeyword, _parentSchema, keyIndex) => {
        if (keyIndex === currentPath.at(-1)) {
          currentPath.pop()
        }
      },
    },
  })
}

export type ValidateFunction = (value: unknown, schema: ReadonlyDeep<JSONSchema>) => boolean

export const cfValidate: ValidateFunction = (value, schema) =>
  cfWorkerJsonSchema.validate(value, schema as CfJsonSchema).valid

/**
 * Returns the field schema from a `then` or `else` branch for a given objectValue, objectSchema and fieldName
 * returns undefined if the schema is not a conditional schema.
 */
export function findConditionalPropertySchema(
  objectValue: unknown,
  objectSchema: ReadonlyDeep<JSONSchema> | undefined,
  fieldName: string,
  validate: ValidateFunction = cfValidate
): JSONSchema | undefined {
  // cfWorker JSON schema throws on `undefined` values
  if (!isObject(objectSchema) || objectValue === undefined || Array.isArray(objectValue)) {
    return undefined
  }
  if (isObject(objectValue)) {
    objectValue = omitUndefined(objectValue)
  }

  for (const schema of [objectSchema, ...(objectSchema.allOf ?? [])]) {
    if (
      isObject(schema) &&
      schema.if &&
      ((isObject(schema.then) && schema.then?.properties?.[fieldName]) ||
        (isObject(schema.else) && schema.else?.properties?.[fieldName]))
    ) {
      const conditionalSchema = schema[validate(objectValue, schema.if) ? "then" : "else"]
      if (!isObject(conditionalSchema)) {
        return undefined
      }
      return conditionalSchema.properties?.[fieldName]
    }
  }
  return undefined
}

/**
 * Given an objectSchema, returns the field names that are conditional fields or [] if the schema is not a conditional schema.
 */
export function findConditionalFields(objectSchema: ReadonlyDeep<JSONSchema> | undefined): string[] {
  if (!isObject(objectSchema)) {
    return []
  }

  for (const schema of [objectSchema, ...(objectSchema.allOf ?? [])]) {
    if (isObject(schema) && schema.if && isObject(schema.if)) {
      return schema.if.properties ? Object.keys(schema.if.properties) : []
    }
  }
  return []
}

export function removeCircularReferences<T>(object: T): T {
  const clone = structuredClone(object)
  traverse(clone, { includeSymbols: false }).forEach(function () {
    if (this.circular) {
      this.remove()
    }
  })
  return clone
}

/**
 * Resolves all local `$ref`s in the given schema (replaces them with real references to the referenced object).
 * The schema must be self-contained (no external references, only JSON pointers to other parts of the schema).
 */
export function dereferenceSchema(
  schema: ReadonlyDeep<JSONSchema>,
  defs?: Record<string, ReadonlyDeep<JSONSchema>>
): ReadonlyDeep<JSONSchema> {
  typedAssert.isRecord(schema)
  const rootId = schema.$id ?? "refresolver:rootSchema"
  const refResolver = new RefResolver()
  for (const defSchema of Object.values(defs ?? schema.$defs ?? schema.definitions ?? []) as JSONSchema[]) {
    typedAssert.isRecord(defSchema)
    typedAssert.isString(defSchema.$id, "$defs schema must have a $id")
    refResolver.addSchema(defSchema)
  }
  refResolver.addSchema({ ...schema, $id: rootId })
  return removeCircularReferences(refResolver.getDerefSchema(rootId))
}

export function getTitle(key: string, schema: unknown): string {
  return (isObject(schema) && "title" in schema && typeof schema.title === "string" && schema.title) || capitalCase(key)
}

/**
 * Essentially does the inverse of `getTitle()`, returning a `snake_case` name suitable as a property name, with
 * special characters replaced.
 */
export function titleToFieldName(title: string, intl: { locale: string }): string {
  return slugify.default(title, {
    replacement: "_",
    strict: true,
    lower: true,
    locale: intl.locale,
  })
}

interface EnumFields {
  title: string
  const: string
  icon?: IconType
  colorScheme?: EnumColorScheme
}
/**
 * Returns an array of enum options in a consistent format with each option having a `title` and `const` property,
 * not flattening enum groups. The returned array will only contain top-level options and groups, not the members
 * of groups (they are available through the `anyOf` property).
 */
export function getEnumOptions(
  schema: ReadonlyDeep<JSONSchema>,
  flattenGroups: false
): ReadonlyDeep<JSONSchemaObject & (EnumFields | { title: string; anyOf: (JSONSchemaObject & EnumFields)[] })[]>
/**
 * Returns an array of enum options in a consistent format with each option having a `title` and `const` property,
 * flattening enum groups. The returned array will only contain leaf enum options, no groups.
 */
export function getEnumOptions(
  schema: ReadonlyDeep<JSONSchema>,
  flattenGroups?: true
): ReadonlyDeep<(JSONSchemaObject & EnumFields)[]>
export function getEnumOptions(
  schema: ReadonlyDeep<JSONSchema>,
  flattenGroups: boolean = true
): ReadonlyDeep<
  (JSONSchemaObject &
    (
      | EnumFields
      | {
          title: string
          anyOf: (JSONSchemaObject & EnumFields)[]
        }
    ))[]
> {
  if (!isObject(schema)) {
    throw new UnexpectedSchemaError("Unexpected enum schema", schema)
  }
  if (schema.enum) {
    return schema.enum.map((value) => ({
      const: value,
      title: getTitle(value, null),
    }))
  }
  if (
    schema.anyOf?.every(
      (s): s is ConstSchema<string> | { anyOf: ConstSchema<string>[]; title: string } =>
        isStringConstSchema(s) || (isObject(s) && !!s.anyOf?.every(isStringConstSchema) && !!s.title)
    )
  ) {
    // Flatten enum groups
    return schema.anyOf.flatMap(
      (schema): ReadonlyDeep<(JSONSchemaObject & (EnumFields | { title: string; anyOf: EnumFields[] }))[]> => {
        // Check if schema is an enum group
        if ("anyOf" in schema) {
          if (flattenGroups) {
            return getEnumOptions(schema)
          }
          // Add consistent `title` properties to the group members.
          return [{ ...schema, anyOf: getEnumOptions(schema) }]
        }
        // Enum value option
        return [{ ...schema, title: getTitle(schema.const, schema) }]
      }
    )
  }
  if (schema.items) {
    return getEnumOptions(schema.items)
  }
  throw new UnexpectedSchemaError("Unexpected enum schema", schema)
}

export function getEnumMemberTitle(
  value: string | null | undefined,
  fieldSchema: ReadonlyDeep<JSONSchema> | undefined
): string {
  if (!value) {
    return ""
  }
  if (isObject(fieldSchema) && fieldSchema.anyOf) {
    const enumMemberSchema = fieldSchema.anyOf.find((s) => isObject(s) && s.const === value)
    return getTitle(value, enumMemberSchema)
  }
  return capitalCase(value)
}

export function isStringType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is string | undefined {
  return isObject(schema) && schema.type === "string"
}

export function isURLType(schema: ReadonlyDeep<JSONSchema> | undefined, value?: unknown): value is string | undefined {
  return Boolean(
    schema && isObject(schema) && isStringType(schema) && (schema.format === "uri" || schema.format === "uri-reference")
  )
}

export function isIntegerType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is number | undefined {
  return isObject(schema) && schema.type === "integer"
}

export function isNumberType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is number | undefined {
  return isObject(schema) && schema.type === "number"
}

export function isGrowthRateType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is number | undefined {
  return isObject(schema) && schema.type === "number" && schema.numberStyle === "percent"
}

export function isNumericStringType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is `${number}` | undefined {
  return isObject(schema) && schema.type === "string" && schema.pattern === NUMERIC_STRING_PATTERN.source
}

export function isBooleanType(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): value is boolean | undefined {
  return isObject(schema) && schema.type === "boolean"
}

export type EnumTypeSchema<T> = T &
  (
    | { enum: string[] }
    | { anyOf: { const: string; title?: string }[] }
    // Enum groups
    | { anyOf: { anyOf: { const: string; title?: string }[] }[]; title?: string }
  )
export function isEnumType<T extends ReadonlyDeep<JSONSchemaObject>>(schemaWithValue: {
  schema: T | JSONSchemaBoolean | undefined
  value?: unknown
}): schemaWithValue is { schema: EnumTypeSchema<T>; value: string } {
  const { schema } = schemaWithValue
  return (
    isObject(schema) &&
    ((schema.enum instanceof Array && schema.enum.every((value) => typeof value === "string")) ||
      (schema.anyOf instanceof Array &&
        schema.anyOf.every((schema) => isStringConstSchema(schema) || isEnumType({ schema }))))
  )
}

export function isEnumArrayType<T extends ReadonlyDeep<JSONSchemaObject>>(schemaWithValue: {
  schema: T | JSONSchemaBoolean | undefined
  value?: unknown
}): schemaWithValue is {
  schema: T & { items: { anyOf: { const: string; title?: string }[] } | { enum: string[] } }
  value: Array<string>
} {
  const { schema } = schemaWithValue
  return (
    isObject(schema) &&
    schema.type === "array" &&
    // schema.items can be JSONSchema (includes boolean) | SchemaArray
    // Those cases do not fit our multi select rendering scheme
    !(schema.items instanceof Array) &&
    isEnumType({ schema: schema.items })
  )
}

export function isDiscriminatedUnionSchema<T extends ReadonlyDeep<JSONSchemaObject>>(
  schema: ReadonlyDeep<JSONSchema> | undefined
): schema is Omit<T, "oneOf"> & {
  discriminator: { propertyName: string; mapping: Record<string, string> }
  oneOf: [ReadonlyDeep<JSONSchemaObject>, ...ReadonlyDeep<JSONSchemaObject>[]]
} {
  return (
    isObject(schema) &&
    schema.discriminator &&
    isObject(schema.discriminator) &&
    "propertyName" in schema.discriminator &&
    schema.discriminator.propertyName &&
    "oneOf" in schema &&
    schema.oneOf instanceof Array &&
    isObject(schema.oneOf[0])
  )
}

export function getDiscriminatorSchema(
  schema: ReadonlyDeep<JSONSchema> | undefined,
  value?: unknown
): ReadonlyDeep<JSONSchemaObject> | undefined {
  if (
    !isDiscriminatedUnionSchema(schema) ||
    !isObject(value) ||
    !hasOwnProperty(value, schema.discriminator.propertyName)
  ) {
    return undefined
  }

  const discriminatorValue = value[schema.discriminator.propertyName] as string | undefined
  const chosenSchema =
    schema.oneOf.find((s) => s.properties?.[schema.discriminator.propertyName].const === discriminatorValue) ??
    schema.oneOf[0]

  const discriminatorMetadata = includeKeys(chosenSchema.properties?.[schema.discriminator.propertyName], [
    "title",
    "description",
    "readOnly",
    ...customKeywords,
  ])
  const hasNullDiscriminator = schema.oneOf.find((s) => isNullSchema(s))
  const discriminatorSchema = {
    anyOf: schema.oneOf.map((s) => s.properties?.[schema.discriminator.propertyName]),
    ...(!hasNullDiscriminator ? { not: { const: null } } : {}),
    ...discriminatorMetadata,
  }

  return {
    ...chosenSchema,
    properties: {
      ...chosenSchema.properties,
      [schema.discriminator.propertyName]: discriminatorSchema,
    },
  }
}

export function isSchemaWithDisplayName(schema: ReadonlyDeep<JSONSchema> | undefined): boolean {
  return isObject(schema) && !!schema.properties?.display_name
}

/**
 * Given a JSONSchemaObject and an array of field names which are paths separated by dots,
 * return a portion of the JSONSchemaObject that matches the paths
 */
export function pickFieldsFromSchema(
  schema: ReadonlyDeep<JSONSchemaObject>,
  fieldPathStrings: string[]
): ReadonlyDeep<JSONSchemaObject> {
  if (!schema.properties) {
    return {}
  }

  const parentLevelKeys = new Map<string, string[]>()
  for (const fieldPathString of fieldPathStrings) {
    const [pathItem, ...remainingArray] = fieldPathString.split(".")
    if (!pathItem) {
      continue
    }
    let subPathMap = parentLevelKeys.get(pathItem)
    if (!subPathMap) {
      subPathMap = []
      parentLevelKeys.set(pathItem, subPathMap)
    }
    if (remainingArray.length === 0) {
      continue
    }
    subPathMap.push(remainingArray.join("."))
  }

  const properties: Properties = {}
  const required = schema.required?.filter((r) => parentLevelKeys.get(r))
  const pickedSchema: ReadonlyDeep<JSONSchemaObject> = {
    ...structuredClone(schema),
    properties,
    ...(required ? { required } : {}),
  }
  for (const [field, subFieldPathStrings] of parentLevelKeys.entries()) {
    const fieldSchema = getSchemaAtPath(schema, [field], false)
    if (!isObject(fieldSchema)) {
      continue
    }

    if (subFieldPathStrings.length === 0) {
      objectPath.set(properties, field, fieldSchema)
    } else {
      const pickedFieldSchema = pickFieldsFromSchema(fieldSchema, subFieldPathStrings)
      objectPath.set(properties, field, pickedFieldSchema)
    }
  }

  return pickedSchema
}
