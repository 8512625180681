import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react"
import { useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { type VendorMinimal } from "../app/services/generated-api.js"
import { getPublicImageGcsUrl } from "../util/url.js"
import { VendorLogo } from "./icons/Logo.js"
import { Link } from "./Link.js"

export function LockSingleCreditCardModal({
  onLock,
  vendors,
  isOpen,
  displayName,
  onClose,
}: {
  onLock: () => void
  vendors: VendorMinimal[]
  isOpen: boolean
  displayName: string
  onClose: () => void
}) {
  const cancelRef = useRef<HTMLButtonElement>(null)
  return (
    <AlertDialog leastDestructiveRef={cancelRef} isCentered isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            <FormattedMessage
              id="creditCard.lock.confirmHeader"
              description="Header for credit card lock confirmation dialog"
              defaultMessage="Lock Credit Card"
            />
          </AlertDialogHeader>

          <AlertDialogBody>
            {vendors && vendors.length > 0 && (
              <Stack spacing={2} mb={4}>
                <FormattedMessage
                  id="creditCard.terminate.vendorCharges"
                  description="Message showing number of vendor charges found on credit card"
                  defaultMessage="{count} {count, plural, one {Vendor charge} other {Vendor charges}} found on this card:"
                  values={{ count: vendors.length }}
                />
                <Stack paddingY={2}>
                  {vendors.slice(0, 5).map((vendor) => (
                    <Link target="_blank" key={vendor.id} to={`/vendors/${vendor.id}`}>
                      <HStack>
                        <VendorLogo
                          logo={getPublicImageGcsUrl(vendor.image_asset?.gcs_file_name)}
                          backgroundColor="white"
                        />
                        <Text>{vendor.display_name}</Text>
                      </HStack>
                    </Link>
                  ))}
                </Stack>
                {vendors.length > 5 && (
                  <FormattedMessage
                    id="creditCard.lock.moreVendorCharges"
                    description="Message showing more vendor charges found on credit card"
                    defaultMessage="And {count} more..."
                    values={{ count: vendors.length - 5 }}
                  />
                )}
              </Stack>
            )}
            <FormattedMessage
              id="creditCard.lock.confirmMessage"
              description="Confirmation message for credit card termination"
              defaultMessage="Are you sure you want to lock credit card {displayName}? Future charges will be blocked once locked."
              values={{ displayName }}
            />
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              <FormattedMessage
                id="creditCard.lock.cancel"
                description="Cancel button text for credit card termination"
                defaultMessage="Cancel"
              />
            </Button>
            <Button colorScheme="red" onClick={onLock} ml={3}>
              <FormattedMessage
                id="creditCard.lock.confirm"
                description="Confirm button text for credit card lock"
                defaultMessage="Lock"
              />
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export function LockAllCreditCardsModal({
  onLock,
  isOpen,
  onClose,
  ...props
}: {
  onLock: () => Promise<void>
  isOpen: boolean
  onClose: () => void
  objectType: "Tool" | "Vendor"
}) {
  const cancelRef = useRef<HTMLButtonElement>(null)
  const intl = useIntl()
  const navigate = useNavigate()

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isCentered isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            <FormattedMessage
              id="creditCard.lockAll.confirmHeader"
              description="Header for locking all credit cards confirmation dialog"
              defaultMessage="Lock Credit Cards"
            />
          </AlertDialogHeader>
          <AlertDialogBody>
            <Stack spacing={4}>
              <FormattedMessage
                id="creditCard.lockAll.confirmMessage"
                description="Confirmation message for locking all credit cards associated with a vendor or tool"
                defaultMessage="Would you like to lock all credit cards associated with this {objectType}? Cards with active payments of other vendors will not be affected."
                values={{
                  objectType:
                    props.objectType === "Tool"
                      ? intl.formatMessage({
                          id: "creditCard.lockAll.tool",
                          description: "tool object type",
                          defaultMessage: "tool",
                        })
                      : intl.formatMessage({
                          id: "creditCard.lockAll.vendor",
                          description: "vendor object type",
                          defaultMessage: "vendor",
                        }),
                }}
              />
            </Stack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              <FormattedMessage
                id="creditCard.lockAll.cancel"
                description="Cancel button text for locking all credit cards"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              colorScheme="red"
              onClick={async () => {
                await onLock()
                onClose()
                navigate("../payments")
              }}
              ml={3}
            >
              <FormattedMessage
                id="creditCard.lockAll.confirm"
                description="Confirm button text for locking all credit cards"
                defaultMessage="Lock All"
              />
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
