import type { FunctionComponent } from "react"
import { useParams } from "react-router-dom"
import { isString } from "typed-assert"
import { useGetWorkflowV1DefinitionsByIdQuery } from "../../../app/services/generated-api.js"
import WorkflowDefinitionForm from "./WorkflowDefinitionForm.js"

const WorkflowDefinitionPage: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>()
  isString(id, "Missing workflow definition id")

  const { data: workflowDef, isLoading } = useGetWorkflowV1DefinitionsByIdQuery({ id })

  if (isLoading || !workflowDef) {
    return null
  }

  return <WorkflowDefinitionForm workflowDef={workflowDef} workflowKind={workflowDef.kind} />
}

export default WorkflowDefinitionPage
