import type { FilterField } from "@brm/schema-types/types.js"
import type { Filter } from "@brm/type-helpers/filters.js"
import { Flags } from "@brm/util/flags.js"
import { expandUUID } from "@brm/util/short-uuids.js"
import { Stack } from "@chakra-ui/react"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { type PostTransactionV1ListApiArg } from "../app/services/generated-api.js"
import TransactionList from "../features/transaction/TransactionList.js"
import { PAGE_PADDING_X, PAGE_PADDING_Y } from "../util/constant.js"
import { TABLE_DEFAULT_PARAMS } from "../util/schema-table.js"
import { useObjectSchema } from "../util/use-schema.js"
import CardList from "./CardList.js"
import { useUrlTableParams } from "./DataTable/use-schema-table-params.js"

export const PaymentsPanel = ({ toolId, vendorId }: Pick<PostTransactionV1ListApiArg, "toolId" | "vendorId">) => {
  const createRampCreditCardEnabled = useFeatureFlagEnabled(Flags.CREATE_RAMP_CREDIT_CARD_ENABLED)
  const transactionSchema = useObjectSchema("ReconciledTransaction")
  const { tableParams, updateTableParams } = useUrlTableParams<string>({
    defaultParams: TABLE_DEFAULT_PARAMS,
    objectSchema: transactionSchema,
    savedViews: [],
  })

  if (!tableParams || !updateTableParams) {
    return null
  }

  return (
    <Stack paddingX={PAGE_PADDING_X} paddingY={PAGE_PADDING_Y} gap={6}>
      {createRampCreditCardEnabled && (
        <CardList
          toolId={toolId}
          vendorId={vendorId}
          filterTransactions={(id) => {
            let newValues: string[] = [id]
            const existingFields = tableParams.filterMap.get("card.id")?.fields
            if (existingFields?.comparator === "any") {
              const existingCardIds = new Set(existingFields.values?.map((id) => expandUUID(id)))
              // If card id is already in the filter, remove it
              if (existingCardIds.has(id)) {
                existingCardIds.delete(id)
              } else {
                existingCardIds.add(id)
              }
              newValues = Array.from(existingCardIds)
            }
            updateTableParams?.({
              filterMap: new Map([
                [
                  "card.id",
                  {
                    column: "card.id",
                    fields: { comparator: "any", includeNull: false, values: newValues },
                  } satisfies Filter<string, FilterField>,
                ],
              ]),
            })
          }}
        />
      )}
      <TransactionList
        toolId={toolId}
        vendorId={vendorId}
        tableParams={tableParams}
        updateTableParams={updateTableParams}
      />
    </Stack>
  )
}
