import { serializeRichTextToMarkdown } from "@brm/schema-helpers/rich-text/serialize.js"
import type {
  Conversation,
  Email1,
  EmailRichTextDraft,
  Message,
  PickableEntityFilter,
  ToolCall,
} from "@brm/schema-types/types.js"
import { formatDateTime } from "@brm/util/format-date-time.js"
import { displayPersonName } from "@brm/util/names.js"
import { isEmpty } from "@brm/util/type-guard.js"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Stack,
  StackDivider,
  Text,
  useToast,
} from "@chakra-ui/react"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { type Descendant } from "slate"
import { isPresent } from "ts-is-present"
import braimLogo from "../../assets/braim.svg"
import { useGetUserV1WhoamiQuery, usePostNegotiationV1EmailSendMutation } from "../app/services/generated-api.js"
import type { AgentAction } from "../features/betsy/AgentResponse.js"
import { ChatMessage, type ChatMessageAuthor } from "../features/betsy/ChatMessage.js"
import { defaultSuggestedPrompts } from "../features/betsy/util.js"
import { log } from "../util/logger.js"
import { getPublicImageGcsUrl } from "../util/url.js"
import BetsyDisplayName from "./ContextMenu/BetsyDisplayName.js"
import { IconButtonWithTooltip } from "./IconButtonWithTooltip.js"
import { CopyIcon, SendIcon, ShareIcon } from "./icons/icons.js"
import { EMPTY_EMAIL_DRAFT } from "./negotiation/util.js"
import OverflownText from "./OverflownText.js"
import RichTextEditor from "./RichTextEditor/RichTextEditor.js"
import { DEFAULT_PICKABLE_ENTITIES, trimRichTextWhiteSpace } from "./RichTextEditor/util/common.js"
import SuggestedPromptBadge from "./SuggestedPromptBadge/SuggestedPromptBadge.js"

export default function Chat({
  conversation,
  startNewConversation,
  onError,
  onSuccess,
  defaultMessages,
  initialValue,
  onSubmitStreamingUrl,
  addOns = {},
  additionalRequestParams = {},
}: {
  conversation: Partial<Omit<Conversation, "id">> & { id: string }
  startNewConversation?: () => void
  onError?: () => void
  // Calls onSuccess with the AI response
  onSuccess?: (message?: string) => void
  initialValue?: Descendant[]
  defaultMessages?: Message[]
  onSubmitStreamingUrl: string
  addOns?: {
    suggestedPrompts?: {
      enabled: boolean
      defaultPrompts?: string[]
    }
    emailDraft?: {
      enabled: boolean
      emailThread?: Email1[]
    }
  }
  additionalRequestParams?: Record<string, string>
}) {
  const intl = useIntl()
  const navigate = useNavigate()
  const toast = useToast()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { id: conversationId } = conversation
  const [messages, setMessages] = useState<Message[] | undefined>(conversation?.messages ?? defaultMessages)
  const [status, setStatus] = useState("waiting")
  const [isLoadingAnswer, setIsLoadingAnswer] = useState(false)
  const bottomRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLDivElement>(null)
  const [richTextRenderKey, setRichTextRenderKey] = useState(0)
  const [emailDraft, setEmailDraft] = useState<EmailRichTextDraft>(
    conversation?.negotiation_email_draft ?? EMPTY_EMAIL_DRAFT
  )

  const [suggestedPrompts, setSuggestedPrompts] = useState<string[] | undefined>(defaultSuggestedPrompts)
  const [draft, setDraft] = useState<Descendant[] | undefined>(initialValue)

  // Reset state when conversation Id changes
  useEffect(() => {
    setMessages(conversation.messages ?? defaultMessages)
    setDraft(initialValue)
    setSuggestedPrompts(addOns.suggestedPrompts?.defaultPrompts)
    setIsLoadingAnswer(false)
    setEmailDraft(conversation.negotiation_email_draft ?? EMPTY_EMAIL_DRAFT)
    inputRef.current?.focus()
  }, [
    conversationId,
    conversation.messages,
    defaultMessages,
    initialValue,
    addOns.suggestedPrompts?.defaultPrompts,
    conversation?.negotiation_email_draft,
  ])

  const pickableEntityFilters: Omit<PickableEntityFilter, "name"> = useMemo(
    () => ({
      entities: DEFAULT_PICKABLE_ENTITIES,
    }),
    []
  )

  useEffect(() => {
    if (!isLoadingAnswer && suggestedPrompts && suggestedPrompts.length > 0) {
      // Scroll to bottom when suggested prompts are updated
      bottomRef.current?.scrollIntoView({ behavior: "instant" })
    }

    if (window.innerHeight - bottomRef.current!.getBoundingClientRect().y > 0) {
      // Pin to bottom if the user is already scrolled to the bottom
      bottomRef.current?.scrollIntoView({ behavior: "instant" })
    }

    // Scroll to bottom on new agent replies
    if (messages?.[messages.length - 1]?.content === "") {
      bottomRef.current?.scrollIntoView({ behavior: "instant" })
    }
  }, [messages, suggestedPrompts, isLoadingAnswer])

  const isReadOnly = conversation?.user_id && whoami?.id !== conversation.user_id

  const userAuthor: ChatMessageAuthor = useMemo(
    () =>
      conversation.user_id && conversation?.user_id !== whoami?.id
        ? {
            name:
              conversation?.user?.first_name ||
              (conversation?.user && displayPersonName(conversation.user, intl)) ||
              "User",
            image: getPublicImageGcsUrl(conversation?.user?.profile_image?.gcs_file_name),
            role: "user" as const,
          }
        : {
            name: whoami?.first_name || (whoami && displayPersonName(whoami, intl)) || "User",
            image: getPublicImageGcsUrl(whoami?.profile_image?.gcs_file_name),
            role: "user" as const,
          },
    [conversation, whoami, intl]
  )

  const fetchAnswer = useCallback(
    async (draft: Descendant[], messages: Message[] | undefined) => {
      let dataReceived = false
      const savedDraft = structuredClone(draft)
      await fetchEventSource(onSubmitStreamingUrl, {
        method: "POST",
        credentials: "include",
        openWhenHidden: true,
        body: JSON.stringify({
          query: draft,
          messages,
          conversation_id: conversation.id,
          log_message: true,
          ...(addOns.emailDraft?.enabled && emailDraft ? { email_draft: emailDraft } : {}),
          ...additionalRequestParams,
        }),
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Accept: "text/event-stream",
          ["Content-Type"]: "application/json",
        },
        async onopen(res) {
          if (res.ok && res.status === 200) {
            // do nothing
            return
          }
          if (res.status === 401) {
            navigate("/login")
            return
          }

          throw new Error("braim api failure")
        },
        onmessage(event) {
          if (event.event === "data") {
            dataReceived = true
            const data = JSON.parse(event.data)
            const text = data?.text
            const suggestedPrompts = data?.suggestedPrompts
            const emailDraft = data?.emailDraft
            if (addOns.suggestedPrompts?.enabled && !isEmpty(suggestedPrompts)) {
              setSuggestedPrompts(suggestedPrompts)
            }
            if (addOns.emailDraft?.enabled && !isEmpty(emailDraft)) {
              setEmailDraft(emailDraft)
              setRichTextRenderKey((key) => key + 1)
            }
            if (!isEmpty(text)) {
              setStatus("waiting")
              setMessages((c) => {
                const currentMessage: Message = (c ?? []).at(-1)!
                const ret = c?.slice(0, -1) ?? []
                ret.push({ ...currentMessage, content: `${currentMessage?.content ?? ""}${text}` })
                return ret
              })
            }
          } else if (event.event === "status") {
            const data = JSON.parse(event.data)
            setStatus(data.status)
            log.info("braim api status", data.status)
          }
        },
        onclose() {
          if (!dataReceived) {
            throw new Error("braim api failure")
          }
        },
        onerror(e) {
          log.error("braim api failure", e, { draft, messages })
          // reset the draft back to the saved draft
          setDraft(savedDraft)

          // reset the last two messages
          setMessages((messages) => {
            // remove the last two messages
            return messages?.slice(0, -2) ?? []
          })

          toast({ description: "Something went wrong", status: "error" })
          onError?.()
          setIsLoadingAnswer(false)
          throw e
        },
      })
    },
    [
      onSubmitStreamingUrl,
      conversation.id,
      addOns.emailDraft?.enabled,
      addOns.suggestedPrompts?.enabled,
      emailDraft,
      additionalRequestParams,
      navigate,
      toast,
      onError,
    ]
  )
  const submit = useCallback(async () => {
    const trimmedBody = draft && trimRichTextWhiteSpace(draft)
    if (trimmedBody) {
      const serializedQuery = serializeRichTextToMarkdown(trimmedBody)
      setMessages((currentMessages) => {
        return [
          ...(currentMessages ?? []),
          { content: serializedQuery, role: "user", rich_text: trimmedBody, tool_calls: null },
          { content: "", role: "assistant", tool_calls: null },
        ]
      })
      setDraft(undefined)
      setIsLoadingAnswer(true)

      await fetchAnswer(trimmedBody, messages)

      // handle success
      setIsLoadingAnswer(false)
      setStatus("waiting")
      inputRef.current?.focus()
      onSuccess?.(messages?.[messages.length - 1]?.content ?? "")
    }
  }, [draft, messages, setMessages, fetchAnswer, onSuccess])

  return (
    <HStack width="100%" height="100%" flex={1} minH={0}>
      <Flex p={4} overflow="hidden" height="100%" minWidth="50%" flex={1}>
        <Stack justifyContent="space-between" width="100%">
          <ChatMessages
            messages={messages ?? []}
            status={status}
            isLoadingAnswer={isLoadingAnswer}
            defaultMessages={defaultMessages ?? []}
            conversationId={conversationId}
            userAuthor={userAuthor}
            bottomRef={bottomRef}
          />
          <Box
            pos="relative"
            bottom="0"
            insetX="0"
            bgGradient="linear(to-t, white 80%, rgba(0,0,0,0))"
            paddingY="4"
            marginX="4"
          >
            <Stack maxW="prose" mx="auto">
              {addOns.suggestedPrompts?.enabled && (
                <HStack flexWrap="wrap" gap={2}>
                  {!isLoadingAnswer &&
                    suggestedPrompts?.map((prompt, i) => (
                      <SuggestedPromptBadge
                        key={i}
                        prompt={prompt}
                        onClick={() => {
                          const draft = [
                            {
                              type: "paragraph",
                              children: [
                                {
                                  text: prompt,
                                },
                              ],
                            },
                          ] satisfies Descendant[]
                          setDraft(draft)
                          setMessages((c) =>
                            c === undefined
                              ? undefined
                              : [
                                  ...c,
                                  { content: prompt, role: "user", tool_calls: null },
                                  { content: "", role: "assistant", tool_calls: null },
                                ]
                          )
                          return fetchAnswer(draft, messages)
                        }}
                      />
                    ))}
                </HStack>
              )}
              <Box as="form" pos="relative" my={3}>
                <RichTextEditor
                  forceFocus={true}
                  initialValue={draft}
                  containerProps={{
                    onKeyDown: async (e) => {
                      if (e.key === "Enter" && (e.metaKey || e.ctrlKey) && !isLoadingAnswer && !isReadOnly) {
                        await submit()
                      } else if (e.key === "k" && e.metaKey && e.shiftKey) {
                        startNewConversation?.()
                      }
                    },
                  }}
                  onChange={(e) => {
                    setDraft(e)
                  }}
                  sendButton={
                    <Button
                      isDisabled={isReadOnly || isLoadingAnswer || !draft}
                      isLoading={isLoadingAnswer}
                      size="sm"
                      variant="text"
                      colorScheme="gray"
                      aria-keyshortcuts="Enter"
                      onClick={submit}
                    >
                      <Icon as={SendIcon} />
                    </Button>
                  }
                  pickableEntityFilters={pickableEntityFilters}
                  ref={inputRef}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Flex>
      {addOns.emailDraft?.enabled && (
        <>
          <Divider orientation="vertical" />
          <Flex
            gap={8}
            width="50%"
            textAlign="left"
            padding={4}
            justifyContent="space-between"
            flexDirection="column"
            alignItems="space-between"
            height="100%"
          >
            <Accordion allowToggle height="50%" overflowY="auto">
              {addOns.emailDraft?.emailThread?.map((email, i) => (
                <AccordionItem key={i}>
                  <AccordionButton px={2} py={1} _hover={{ bg: "gray.100" }}>
                    <HStack flex="1" spacing={4}>
                      <AccordionIcon />
                      <Box flex="1">
                        <HStack justify="space-between">
                          <OverflownText fontWeight="medium">{email.subject}</OverflownText>
                          {email.received_at && (
                            <Text color="gray.500" fontSize="sm">
                              {formatDateTime(intl, email.received_at)}
                            </Text>
                          )}
                        </HStack>
                        <Flex flex={1}>
                          <Text color="gray.600" fontSize="sm">
                            {email.from_email_address}
                          </Text>
                        </Flex>
                      </Box>
                    </HStack>
                  </AccordionButton>
                  <AccordionPanel pb={4} px={10}>
                    <Text whiteSpace="pre-wrap">{email.body}</Text>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
            <EmailFields
              emailDraft={emailDraft}
              onChange={(value) => setEmailDraft({ ...emailDraft, ...value })}
              renderKey={richTextRenderKey}
              negotiationId={additionalRequestParams.negotiation_id}
            />
            <Button
              size="md"
              width="fit-content"
              onClick={() => {
                if (emailDraft) {
                  void navigator.clipboard.writeText(serializeRichTextToMarkdown(emailDraft.body as Descendant[]))
                }
              }}
            >
              <FormattedMessage
                id="negotiation.copyToClipboard"
                description="Button text to copy negotiation text to clipboard"
                defaultMessage="Copy to clipboard"
              />
            </Button>
          </Flex>
        </>
      )}
    </HStack>
  )
}

export function ChatMessages({
  messages,
  status,
  isLoadingAnswer,
  defaultMessages,
  conversationId,
  userAuthor,
  bottomRef,
}: {
  messages: Message[]
  status: string
  isLoadingAnswer: boolean
  defaultMessages: Message[]
  conversationId: string
  userAuthor: ChatMessageAuthor
  bottomRef: React.RefObject<HTMLDivElement>
}) {
  const toast = useToast()
  const intl = useIntl()
  // TODO: add betsy tool calls
  const toolCallToAgentAction: Record<ToolCall["function"]["name"], AgentAction> = {
    create_new_email_draft: { displayName: "Drafted email", name: "create_new_email_draft" },
  }

  return (
    <Box overflowY="auto" paddingY={2}>
      <Stack
        maxW="prose"
        mx="auto"
        paddingX={{ base: "1", md: "0" }}
        divider={
          <Box marginLeft="14!">
            <StackDivider />
          </Box>
        }
        spacing="5"
      >
        {messages?.map((m, i) => (
          <ChatMessage
            actions={m.tool_calls?.map((t) => toolCallToAgentAction[t.function.name]).filter(isPresent) ?? undefined}
            richText={(m.rich_text as Descendant[]) ?? undefined}
            status={i === messages.length - 1 && status !== "waiting" ? status : undefined}
            after={
              m !== defaultMessages?.[0] &&
              i === messages?.length - 1 && (
                <HStack py={2}>
                  <IconButtonWithTooltip
                    placement="bottom"
                    label={intl.formatMessage({
                      id: "message.copy.tooltip",
                      description: "Tooltip for the copy button",
                      defaultMessage: "Copy",
                    })}
                    variant="ghost"
                    onClick={async () => {
                      await navigator.clipboard.writeText(m.content)
                      toast({
                        description: intl.formatMessage({
                          id: "conversation.copy.success",
                          description: "Message displayed when the user successfully copies the message",
                          defaultMessage: "Copied to clipboard",
                        }),
                        status: "success",
                      })
                    }}
                    icon={<Icon as={CopyIcon} />}
                    size="sm"
                  />
                  <IconButtonWithTooltip
                    variant="ghost"
                    onClick={async () => {
                      const url = new URL(`braim/${conversationId}`, window.location.origin)
                      await navigator.clipboard.writeText(url.toString())
                      toast({
                        description: intl.formatMessage({
                          id: "conversation.share.success",
                          description: "Message displayed when the user successfully copies the conversation link",
                          defaultMessage: "Link copied to clipboard",
                        }),
                        status: "success",
                      })
                    }}
                    placement="bottom"
                    label={intl.formatMessage({
                      id: "conversation.share.tooltip",
                      description: "Tooltip for the share button",
                      defaultMessage:
                        "Share conversation. Anyone in your organization with the link will be able to view this conversation",
                    })}
                    icon={<Icon as={ShareIcon} />}
                    size="sm"
                  />
                </HStack>
              )
            }
            isLoading={i === messages.length - 1 && isLoadingAnswer}
            key={i}
            author={
              m.role === "user"
                ? userAuthor
                : {
                    name: "Braim",
                    renderedName: (
                      <Text display="inline" fontWeight="medium">
                        <BetsyDisplayName />
                      </Text>
                    ),
                    image: braimLogo,
                    role: m.role,
                  }
            }
            content={m.content}
          />
        ))}
      </Stack>
      <Box height="1px" ref={bottomRef} />
    </Box>
  )
}
const EmailFields = ({
  emailDraft,
  onChange,
  renderKey,
  negotiationId,
}: {
  emailDraft: EmailRichTextDraft
  onChange: (value: Partial<EmailRichTextDraft>) => void
  renderKey: number
  negotiationId: string | undefined
}) => {
  const intl = useIntl()
  const toast = useToast()
  const [sendEmail, { isLoading: isSendingEmail }] = usePostNegotiationV1EmailSendMutation()
  const [hideCc, setHideCc] = useState(emailDraft.cc.length === 0)
  const [hideBcc, setHideBcc] = useState(emailDraft.bcc.length === 0)

  useEffect(() => {
    if (emailDraft.cc.length > 0) {
      setHideCc(false)
    }
    if (emailDraft.bcc.length > 0) {
      setHideBcc(false)
    }
  }, [emailDraft.cc, emailDraft.bcc])

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (emailDraft?.body && negotiationId) {
      try {
        const response = await sendEmail({
          body: {
            email_draft: emailDraft,
            negotiation_id: negotiationId,
          },
        })
        if (response.error && "data" in response.error) {
          const errorData = response.error.data as { message?: string }
          throw new Error(errorData.message ?? "Error")
        }
      } catch {
        toast({
          description: intl.formatMessage({
            id: "email.send.error",
            description: "Error message when sending email fails",
            defaultMessage: "Failed to send email",
          }),
          status: "error",
        })
        throw new Error("Failed to send email")
      }
    }
  }

  return (
    <chakra.form display="flex" flexDirection="column" gap={2} onSubmit={onSubmit}>
      <FormControl isRequired>
        <FormLabel>
          <FormattedMessage id="email.subject" description="Label for email subject field" defaultMessage="Subject" />
        </FormLabel>
        <Input
          value={emailDraft.subject}
          onChange={(e) => onChange({ ...emailDraft, subject: e.target.value })}
          placeholder={intl.formatMessage({
            id: "email.subject.placeholder",
            description: "Placeholder for email subject field",
            defaultMessage: "Enter subject",
          })}
        />
      </FormControl>

      <FormControl isRequired>
        <HStack justify="space-between">
          <FormLabel display="flex" justifyContent="space-between">
            <FormattedMessage id="email.to" description="Label for email to field" defaultMessage="To" />
          </FormLabel>
          <HStack gap={0}>
            {hideCc && (
              <Button onClick={() => setHideCc(false)} variant="link">
                <FormattedMessage
                  id="email.cc.button"
                  description="Button to show CC field in email form"
                  defaultMessage="Cc"
                />
              </Button>
            )}
            {hideBcc && (
              <Button onClick={() => setHideBcc(false)} variant="link">
                <FormattedMessage
                  id="email.bcc.button"
                  description="Button to show BCC field in email form"
                  defaultMessage="Bcc"
                />
              </Button>
            )}
          </HStack>
        </HStack>
        <Input
          value={emailDraft.to.join(", ")}
          onBlur={(e) =>
            onChange({
              ...emailDraft,
              to: e.target.value
                .split(",")
                .map((s) => s.trim())
                .filter(Boolean),
            })
          }
          onChange={(e) =>
            onChange({
              ...emailDraft,
              to: [e.target.value],
            })
          }
          placeholder={intl.formatMessage({
            id: "email.to.placeholder",
            description: "Placeholder for email to field",
            defaultMessage: "Enter recipients (comma separated)",
          })}
        />
      </FormControl>

      {!hideCc && (
        <FormControl>
          <FormLabel>
            <FormattedMessage id="email.cc" description="Label for email cc field" defaultMessage="Cc" />
          </FormLabel>
          <Input
            autoFocus={true}
            value={emailDraft.cc.join(", ")}
            onBlur={(e) =>
              onChange({
                cc: e.target.value
                  .split(",")
                  .map((s) => s.trim())
                  .filter(Boolean),
              })
            }
            onChange={(e) =>
              onChange({
                cc: [e.target.value],
              })
            }
            placeholder={intl.formatMessage({
              id: "email.cc.placeholder",
              description: "Placeholder for email cc field",
              defaultMessage: "Add Cc recipients (comma separated)",
            })}
          />
        </FormControl>
      )}

      {!hideBcc && (
        <FormControl>
          <FormLabel>
            <FormattedMessage id="email.bcc" description="Label for email bcc field" defaultMessage="Bcc" />
          </FormLabel>
          <Input
            autoFocus={true}
            value={emailDraft.bcc.join(", ")}
            onBlur={(e) =>
              onChange({
                bcc: e.target.value
                  .split(",")
                  .map((s) => s.trim())
                  .filter(Boolean),
              })
            }
            onChange={(e) =>
              onChange({
                bcc: [e.target.value],
              })
            }
            placeholder={intl.formatMessage({
              id: "email.bcc.placeholder",
              description: "Placeholder for email bcc field",
              defaultMessage: "Add Bcc recipients (comma separated)",
            })}
          />
        </FormControl>
      )}

      <FormControl>
        <RichTextEditor
          forceFocus={true}
          key={renderKey}
          isReadOnly={false}
          onChange={(value) => {
            onChange({ body: value })
          }}
          initialValue={emailDraft?.body as Descendant[]}
          placeholder={intl.formatMessage({
            id: "email.body.placeholder",
            description: "Placeholder for email body field",
            defaultMessage: "Write your message here",
          })}
          disableMentions={true}
        />
      </FormControl>

      <Button type="submit" size="md" width="fit-content" isLoading={isSendingEmail}>
        <FormattedMessage
          id="negotiation.copyToClipboard"
          description="Button text to copy negotiation text to clipboard"
          defaultMessage="Send"
        />
      </Button>
    </chakra.form>
  )
}
