import { Icon, IconButton, Tooltip, type IconButtonProps } from "@chakra-ui/react"
import type { Except } from "type-fest"
import { useSessionStorage } from "usehooks-ts"
import { CollapseRightDrawerIcon, DrawerIcon } from "./icons/icons.js"

interface Props extends Except<IconButtonProps, "aria-label"> {
  hideStorageKey: string
  expandLabel: string
  collapseLabel: string
  onOpen?: () => void
  onClose?: () => void
}

/**
 * For use with session storage sidebars (eg. timeline). A button that toggles showing/hiding a timeline sidebar through session storage.
 */
export default function ShowHideDrawerButton({
  hideStorageKey,
  expandLabel,
  collapseLabel,
  onOpen,
  onClose,
  ...iconButtonProps
}: Props) {
  const [isHidden, setIsHidden] = useSessionStorage(hideStorageKey, false)
  return (
    <Tooltip closeOnClick={false} closeOnEsc={false} label={isHidden ? expandLabel : collapseLabel}>
      <IconButton
        variant="outline"
        icon={<Icon as={isHidden ? DrawerIcon : CollapseRightDrawerIcon} />}
        aria-label={isHidden ? expandLabel : collapseLabel}
        {...iconButtonProps}
        onClick={() => {
          if (isHidden) {
            onOpen?.()
          } else {
            onClose?.()
          }
          setIsHidden(!isHidden)
        }}
      />
    </Tooltip>
  )
}
