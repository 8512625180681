import type { CreditCardInterval, CreditCardListItem, CreditCardPatch, FieldMetadata } from "@brm/schema-types/types.js"
import { CreditCardIntervalSchema } from "@brm/schemas"
import { getEnumOptions, type EnumTypeSchema } from "@brm/util/schema.js"
import { chakra, Divider, FormControl, FormLabel, Icon, Input, Stack, Text } from "@chakra-ui/react"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { skipToken } from "@reduxjs/toolkit/query"
import { chakraComponents, type ControlProps, type OptionProps } from "chakra-react-select"
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { ReadonlyObjectDeep } from "type-fest/source/readonly-deep.js"
import { useGetCreditCardV1PickerOptionsQuery } from "../../app/services/generated-api.js"
import { CreditCardIcon } from "../../components/icons/icons.js"
import { getDefaultDateShortcuts } from "../../util/form.js"
import { InlineCreditCard } from "../CreditCard.js"
import { DatePickerInput } from "../DynamicForm/DatePicker.js"
import type { ValueWithSource } from "../DynamicForm/types.js"
import Select from "../Select/Select.js"
import { CurrencyAmountInputGroup } from "./CurrencyAmountInput.js"
import { EnumSelect } from "./EnumSelect.js"

interface CreditCardInputGroupProps {
  value: CreditCardPatch | undefined | null
  onChange: (value: CreditCardPatch | undefined | null, fieldSource?: FieldMetadata) => void
  isReadOnly?: boolean
  disabled?: boolean
  vendorId?: string
  defaultValue?: CreditCardPatch
  isInvalid?: boolean
}
interface NewOption {
  type: "new"
}

const Option = ({
  children,
  ...props
}: OptionProps<
  ValueWithSource<(Partial<Omit<CreditCardListItem, "id">> & { id?: string | null }) | null | undefined> | NewOption
>) => {
  if ("type" in props.data) {
    return (
      <chakraComponents.Option {...props}>
        <Icon as={CreditCardIcon} />
        {children}
      </chakraComponents.Option>
    )
  }
  return <chakraComponents.Option {...props}>{children}</chakraComponents.Option>
}

const Control = ({
  children,
  ...props
}: ControlProps<
  ValueWithSource<(Partial<Omit<CreditCardListItem, "id">> & { id?: string | null }) | null | undefined> | NewOption
>) => {
  const data = props.getValue()
  if (data[0] && "value" in data[0] && data[0].value?.id) {
    return <chakraComponents.Control {...props}>{children}</chakraComponents.Control>
  }
  return (
    <chakraComponents.Control {...props}>
      <chakra.span ml={3}>
        <Icon as={CreditCardIcon} />
      </chakra.span>
      {children}
    </chakraComponents.Control>
  )
}

export const CreditCardInputGroup = forwardRef<HTMLDivElement, CreditCardInputGroupProps>(function CreditCardInputGroup(
  { value, onChange, isReadOnly, disabled, vendorId, defaultValue, isInvalid },
  ref
) {
  const intl = useIntl()
  const [uncontrolledComponentResetId, setUncontrolledComponentResetId] = useState(0)
  const incrementUncontrolledComponentResetId = useCallback(() => setUncontrolledComponentResetId((id) => id + 1), [])
  const { data: creditCards, isLoading } = useGetCreditCardV1PickerOptionsQuery(vendorId ? { vendorId } : skipToken)

  const emptyCard = useMemo(
    () =>
      ({
        spend_limit: defaultValue?.spend_limit ?? null,
        spend_limit_interval: defaultValue?.spend_limit_interval ?? null,
        object_type: "CreditCard",
        display_name:
          defaultValue?.display_name ??
          intl.formatMessage({
            id: "creditCardInput.newCard",
            defaultMessage: "New Card",
            description: "Label for the create new card option",
          }),
        id: null,
        auto_lock_date: null,
      }) satisfies CreditCardPatch,
    [defaultValue?.display_name, defaultValue?.spend_limit, defaultValue?.spend_limit_interval, intl]
  )

  useEffect(() => {
    // Update the spend limit to the default value when default value changes
    if (defaultValue?.spend_limit?.amount && value) {
      onChange({ ...value, spend_limit: defaultValue?.spend_limit })
      incrementUncontrolledComponentResetId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incrementUncontrolledComponentResetId, defaultValue?.spend_limit?.amount])

  useEffect(() => {
    // Update the spend interval to the default value when default value changes
    if (defaultValue?.spend_limit_interval) {
      onChange({ ...value, spend_limit_interval: defaultValue?.spend_limit_interval })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue?.spend_limit_interval])

  const options = useMemo(() => {
    const existingOptions =
      creditCards
        // Only active cards can be valid options
        ?.filter((creditCard) => creditCard.status === "active")
        .map((creditCard) => ({
          value: creditCard,
          fieldSources: [],
        })) ?? []
    return [{ value: null, fieldSources: [] }, { type: "new" } satisfies NewOption, ...existingOptions]
  }, [creditCards])

  const matchedCard = useMemo(
    () => creditCards?.find((creditCard) => creditCard.id === value?.id),
    [creditCards, value]
  )

  return (
    <Stack
      ref={(container) => {
        if (ref) {
          if (typeof ref === "function") {
            ref(container ?? null)
          } else {
            ref.current = container ?? null
          }
        }
      }}
      borderRadius="xl"
      border="1px solid"
      borderColor={isInvalid ? "error.500" : "gray.200"}
      padding={4}
      gap={3}
    >
      <Text>
        <FormattedMessage
          id="creditCardInput.label"
          defaultMessage="This card will be automatically issued when the request is fully approved. Configure the card details below:"
          description="Helper text for the credit card input group"
        />
      </Text>
      <Stack gap={0}>
        <FormLabel>
          <FormattedMessage
            id="creditCardInput.label"
            defaultMessage="Select card"
            description="Label for the credit card selector"
          />
        </FormLabel>
        <Select<
          | ValueWithSource<(Partial<Omit<CreditCardListItem, "id">> & { id?: string | null }) | null | undefined>
          | NewOption
        >
          options={options}
          isLoading={isLoading}
          value={{ value: value ? (matchedCard ?? value) : null }}
          isSearchable={false}
          isReadOnly={isReadOnly}
          isDisabled={disabled}
          onChange={(selectedCard) => {
            if (!selectedCard) {
              onChange(null)
              return
            }
            if ("type" in selectedCard) {
              onChange(emptyCard)
            } else {
              const newCard = { ...selectedCard.value }
              onChange(selectedCard.value ? newCard : null)
            }
            incrementUncontrolledComponentResetId()
          }}
          // eslint-disable-next-line @typescript-eslint/naming-convention
          components={{ Option, Control }}
          chakraStyles={{
            valueContainer: (provided) => ({
              ...provided,
              paddingX: 2,
            }),
          }}
          menuPortalTarget={document.body}
          getOptionValue={(option) =>
            "type" in option || (option.value && !option.value.id) ? "new" : (option.value?.id ?? "")
          }
          getOptionLabel={(option) => {
            if (!("type" in option) && !option.value) {
              return intl.formatMessage({
                defaultMessage: "Do not issue card",
                description: "Label for the do not issue a card option",
                id: "creditCardInput.doNotIssue",
              })
            }

            if ("type" in option || !option.value?.id) {
              return intl.formatMessage({
                defaultMessage: "Create New Card",
                description: "Label for the create new card option",
                id: "creditCardInput.newCard",
              })
            }

            return `${option.value?.display_name} ${option.value?.last_four ? `****${option.value.last_four}` : ""}`
          }}
        />
      </Stack>
      {matchedCard && <InlineCreditCard {...matchedCard} />}
      <Divider borderColor="gray.200" />
      {value && (
        <>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="creditCardInput.label"
                defaultMessage="Card Display Name"
                description="Label for the credit card display name input"
              />
            </FormLabel>
            <Input
              isDisabled={disabled}
              isReadOnly={isReadOnly}
              value={value.display_name ?? ""}
              type="text"
              onChange={(e) => onChange({ ...value, display_name: e.target.value })}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="creditCardInput.spendLimit"
                defaultMessage="Spend Limit"
                description="Label for the credit card spend limit input"
              />
            </FormLabel>
            <CurrencyAmountInputGroup
              disabled={disabled}
              isReadOnly={isReadOnly}
              value={value.spend_limit ?? null}
              onChange={(spendLimit) => onChange({ ...value, spend_limit: spendLimit })}
              key={uncontrolledComponentResetId}
              disableCurrencySelect={true}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="creditCardInput.spendInterval"
                defaultMessage="Spend Interval"
                description="Label for the credit card spend interval input"
              />
            </FormLabel>
            <EnumSelect
              isDisabled={disabled}
              isReadOnly={isReadOnly}
              allowNull={true}
              value={value.spend_limit_interval ?? null}
              onChange={(spendInterval) =>
                onChange({
                  ...value,
                  spend_limit_interval: getEnumOptions(CreditCardIntervalSchema).find(
                    (option) => option.const === spendInterval
                  )?.const as CreditCardInterval,
                })
              }
              schema={CreditCardIntervalSchema as unknown as EnumTypeSchema<ReadonlyObjectDeep<JSONSchemaObject>>}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="creditCardInput.autoLockDate"
                defaultMessage="Auto Lock Date"
                description="Label for the credit card auto lock date input"
              />
            </FormLabel>
            <DatePickerInput
              isDisabled={disabled}
              isReadOnly={isReadOnly}
              value={value.auto_lock_date ?? null}
              dateShortcuts={getDefaultDateShortcuts(intl, { sign: 1 })}
              onChange={(autoLockDate) => onChange({ ...value, auto_lock_date: autoLockDate })}
              ariaLabel={intl.formatMessage({
                defaultMessage: "Enter Credit Card Auto Lock Date",
                description: "Aria label for the credit card auto lock date input",
                id: "creditCardInput.autoLockDate",
              })}
            />
          </FormControl>
        </>
      )}
    </Stack>
  )
})
