import type { ObjectValue, TimelineEventType } from "@brm/schema-types/types.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import { displayPersonName } from "@brm/util/names.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Avatar } from "@chakra-ui/react"
import type { ReactElement } from "react"
import type { IntlShape } from "react-intl"
import { FormattedMessage } from "react-intl"
import type { Location } from "react-router-dom"
import { AUTO_SELECT_STEP_HASH } from "../../features/workflows/constants.js"
import { NestedBadge } from "../../features/workflows/run/NestedBadge.js"
import { workflowRunStatusColors } from "../../features/workflows/run/status-colors.js"
import type { GetLogoForOrganizationProps, OrganizationActor } from "../../features/workflows/run/utils.js"
import { displayWorkflowStatus } from "../../features/workflows/util.js"
import { matchesWorkflowRunPath, pathForLegalAgreement, pathForWorkflowRun } from "../../util/json-schema.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { Link, LinkOrSpan } from "../Link.js"
import { LegalAgreementLogo, ToolLogo, VendorLogo } from "../icons/Logo.js"
import { timelineEventPrimaryIconBoxSize } from "./common.js"
import type { TimelineRenderer } from "./types.js"

interface TimelineObjectRendererParams {
  objectValue: ObjectValue | null
  /** Based on the location, a timeline event can be rendered differently */
  location: Location
  intl: IntlShape
  eventType?: TimelineEventType
  // Used if an actor is not provided
  organizationActor?: OrganizationActor
  actorAvatar: ReactElement
  actorText: ReactElement
}

export const timelineObjectRenderer = ({
  actorAvatar,
  actorText,
  objectValue,
  intl,
  location,
  eventType,
}: TimelineObjectRendererParams & GetLogoForOrganizationProps): TimelineRenderer | undefined => {
  if (!objectValue) {
    return
  }

  switch (objectValue.object_type) {
    case "Tool": {
      const matchesPath = location.pathname.startsWith("/tools/")
      const to = `/tools/${objectValue.id}`
      const displayLabel = objectValue.display_name

      return {
        to,
        displayLabel,
        matchesPath,
        renderTimelineEventSections: () => ({
          primaryIcon: (
            <LinkOrSpan to={!matchesPath ? to : null} display="flex">
              <ToolLogo
                logo={getPublicImageGcsUrl(objectValue.image_asset?.gcs_file_name)}
                boxSize={timelineEventPrimaryIconBoxSize}
              />
            </LinkOrSpan>
          ),
          detail: (
            <FormattedMessage
              id="timeline.event.body.newTool"
              description="The message for a timeline event for a new tool"
              defaultMessage="New tool detected: {tool}"
              values={{
                tool: (
                  <LinkOrSpan to={!matchesPath ? to : null} fontWeight="medium">
                    {displayLabel}
                  </LinkOrSpan>
                ),
              }}
            />
          ),
        }),
      }
    }
    case "Vendor": {
      const matchesPath = location.pathname.startsWith("/vendors/")
      const to = `/vendors/${objectValue.id}`
      const displayLabel = objectValue.display_name

      return {
        to,
        displayLabel,
        matchesPath,
        renderTimelineEventSections: () => ({
          primaryIcon: (
            <LinkOrSpan to={!matchesPath ? to : null} display="flex">
              <VendorLogo
                logo={getPublicImageGcsUrl(objectValue.image_asset?.gcs_file_name)}
                boxSize={timelineEventPrimaryIconBoxSize}
              />
            </LinkOrSpan>
          ),
          detail: (
            <FormattedMessage
              id="timeline.event.body.newVendor"
              description="The message for a timeline event for a new vendor"
              defaultMessage="New vendor detected: {vendor}"
              values={{
                vendor: (
                  <LinkOrSpan to={!matchesPath ? to : null} fontWeight="medium">
                    {displayLabel}
                  </LinkOrSpan>
                ),
              }}
            />
          ),
        }),
      }
    }
    case "LegalAgreement": {
      const to = pathForLegalAgreement(objectValue.id)
      const displayLabel = objectValue.display_name

      return {
        to,
        displayLabel,
        // No legal agreement timeline yet
        matchesPath: false,
        renderTimelineEventSections: () => ({
          avatar: actorAvatar,
          primaryIcon: (
            <Link to={to} display="flex" state={{ prevLocation: location }}>
              <LegalAgreementLogo boxSize={timelineEventPrimaryIconBoxSize} />
            </Link>
          ),
          detail: getLegalAgreementDetail({ eventType, actorText, displayLabel, to }),
        }),
      }
    }
    case "ReconciledTransaction": {
      // Stay on the tool page if we're currently there, otherwise go to the vendor page
      const to = location.pathname.startsWith("/tools")
        ? `/tools/${objectValue.tool?.id}/transactions#transaction=${objectValue.id}`
        : `/vendors/${objectValue.vendor.id}/transactions#transaction=${objectValue.id}`
      const displayLabel = formatCurrency(objectValue.currency_amount, intl)

      const amount = (
        <Link to={to} as="strong" fontWeight="medium">
          {displayLabel}
        </Link>
      )

      const vendorLink = `/vendors/${objectValue.vendor.id}`
      const recipient = (
        <LinkOrSpan to={vendorLink} fontWeight="medium">
          {objectValue.vendor?.display_name ?? objectValue.merchant_identification}
        </LinkOrSpan>
      )

      return {
        to,
        displayLabel,
        renderTimelineEventSections: () => ({
          avatar: objectValue.person && (
            <Link to={`/people/${objectValue.person.id}`}>
              <Avatar
                boxSize={timelineEventPrimaryIconBoxSize}
                name={displayPersonName(objectValue.person, intl)}
                src={getPublicImageGcsUrl(objectValue.person.profile_image?.gcs_file_name)}
              />
            </Link>
          ),
          primaryIcon: (
            <LinkOrSpan to={vendorLink} display="flex">
              <VendorLogo
                logo={getPublicImageGcsUrl(objectValue.vendor?.image_asset?.gcs_file_name)}
                boxSize={timelineEventPrimaryIconBoxSize}
              />
            </LinkOrSpan>
          ),
          detail: objectValue.person ? (
            <FormattedMessage
              id="timeline.event.body.newTransaction"
              description="The detail line for a new_payment event with an actor"
              defaultMessage="{actorName} recorded a payment of {amount} to {recipient}"
              values={{
                actorName: (
                  <Link to={`/people/${objectValue.person.id}`} fontWeight="medium">
                    {displayPersonName(objectValue.person, intl)}
                  </Link>
                ),
                amount,
                recipient,
              }}
            />
          ) : (
            <FormattedMessage
              id="timeline.event.body.withoutActor"
              description="The detail line for a new_payment event."
              defaultMessage="A payment of {amount} was recorded to {recipient}"
              values={{ amount, recipient }}
            />
          ),
          integrations: objectValue.integrations,
        }),
      }
    }
    case "Login": {
      // No login drawer today, so we link to the tool's security page
      const to = `/tools/${objectValue.tool.id}/security`
      const displayLabel = objectValue.tool.display_name
      return {
        to,
        displayLabel,
        renderTimelineEventSections: () => ({
          avatar: (
            <Link to={`/people/${objectValue.person.id}`}>
              <Avatar
                boxSize={timelineEventPrimaryIconBoxSize}
                name={displayPersonName(objectValue.person, intl)}
                src={getPublicImageGcsUrl(objectValue.person.profile_image?.gcs_file_name)}
              />
            </Link>
          ),
          primaryIcon: (
            <Link to={to} display="flex">
              <ToolLogo
                logo={getPublicImageGcsUrl(objectValue.tool.image_asset?.gcs_file_name)}
                boxSize={timelineEventPrimaryIconBoxSize}
              />
            </Link>
          ),
          detail: (
            <FormattedMessage
              id="timeline.event.body.newLogin"
              description="The message for a timeline event for a new login"
              defaultMessage="{personName} is a new user of {tool}"
              values={{
                personName: (
                  <Link to={`/people/${objectValue.person.id}`} fontWeight="medium">
                    {displayPersonName(objectValue.person, intl)}
                  </Link>
                ),
                tool: (
                  <Link to={`/tools/${objectValue.tool.id}`} fontWeight="medium">
                    {objectValue.tool.display_name}
                  </Link>
                ),
              }}
            />
          ),
          integrations: [objectValue.integration],
        }),
      }
    }
    case "WorkflowRun": {
      const matchesPath = matchesWorkflowRunPath(location.pathname)
      const to = pathForWorkflowRun(objectValue)
      const displayLabel = objectValue.display_name

      return {
        to,
        displayLabel,
        matchesPath,
        renderTimelineEventSections: () => ({
          avatar: actorAvatar,
          detail:
            objectValue.kind === "software_purchase" || objectValue.kind === "vendor_purchase" ? (
              <FormattedMessage
                id="timeline.event.body.newPurchaseRequest"
                description="The message for a timeline event for a new purchase request"
                defaultMessage="{actorName} started a purchase request: {request}"
                values={{
                  actorName: actorText,
                  request: (
                    <LinkOrSpan to={!matchesPath ? to : null} fontWeight="medium">
                      {displayLabel}
                    </LinkOrSpan>
                  ),
                }}
              />
            ) : objectValue.kind === "software_renewal" || objectValue.kind === "vendor_renewal" ? (
              <FormattedMessage
                id="timeline.event.body.newRenewalRequest"
                description="The message for a timeline event for a new renewal request"
                defaultMessage="{actorName} started a renewal request: {request}"
                values={{
                  actorName: actorText,
                  request: (
                    <LinkOrSpan to={!matchesPath ? to : null} fontWeight="medium">
                      {displayLabel}
                    </LinkOrSpan>
                  ),
                }}
              />
            ) : objectValue.kind === "gather_data" ? (
              <FormattedMessage
                id="timeline.event.body.newGatherDataRequest"
                description="The message for a timeline event for a new data gathering request"
                defaultMessage="{actorName} started a data gathering request: {request}"
                values={{ actorName: actorText, request: displayLabel }}
              />
            ) : (
              unreachable(objectValue.kind)
            ),
          subDetail: (
            <NestedBadge
              as={Link}
              variant="clickable"
              to={{ pathname: pathForWorkflowRun(objectValue), hash: AUTO_SELECT_STEP_HASH }}
              colorScheme={workflowRunStatusColors[objectValue.status]}
              leftBadgeContent={displayWorkflowStatus(objectValue.status)}
              rightContent={objectValue.display_name}
            />
          ),
        }),
      }
    }
    default:
      unreachable(objectValue)
  }
}

const getLegalAgreementDetail = ({
  eventType,
  actorText,
  displayLabel,
  to,
}: {
  eventType?: TimelineEventType
  actorText: ReactElement
  displayLabel: string
  to: string
}) => {
  switch (eventType) {
    case "new_object":
      return (
        <FormattedMessage
          id="timeline.event.body.newAgreementWithActor"
          description="The message for a timeline event for when an actor first associates an agreement with a tool or vendor"
          defaultMessage="{actorName} associated a new agreement: {agreement}"
          values={{
            actorName: actorText,
            agreement: (
              <Link to={to} fontWeight="medium">
                {displayLabel}
              </Link>
            ),
          }}
        />
      )
    case "delete_object":
      return (
        <FormattedMessage
          id="timeline.event.body.deleteAgreementWithActor"
          description="The message for a timeline event for a new agreement with an actor"
          defaultMessage="{actorName} deleted an agreement: {agreement}"
          values={{
            actorName: actorText,
            agreement: displayLabel,
          }}
        />
      )
    default:
      throw new Error(`Unknown legal agreement timeline event type: ${eventType}`)
  }
}
