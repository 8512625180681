import { Flex, Text } from "@chakra-ui/react"
import { useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { TABLE_PAGE_SIZE_OPTIONS } from "../../util/schema-table.js"
import NextIconButton from "../icons/system/NextIconButton.js"
import PreviousIconButton from "../icons/system/PreviousIconButton.js"
import Select from "../Select/Select.js"

export interface PaginationProps {
  page: number
  onPageChange: (page: number) => void

  pageSize: number
  onPageSizeChange: (pageSize: number) => void

  pageSizeOptions?: readonly [number, ...number[]]
  totalListElements: number
}

export default function Pagination({
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS,
  totalListElements,
}: PaginationProps) {
  const intl = useIntl()

  const options = useMemo(
    () =>
      pageSizeOptions.map((pageSize) => ({
        value: pageSize,
        label: intl.formatMessage(
          {
            id: "table.pageSize",
            description: "Option users can select to change the number of rows per page",
            defaultMessage: "Show {pageSize}",
          },
          { pageSize }
        ),
      })),
    [intl, pageSizeOptions]
  )

  const totalNumPages = Math.ceil(totalListElements / pageSize)

  if (totalNumPages === 1 && pageSize <= pageSizeOptions[0] && page === 1) {
    // If there is only one page of content, don't show pagination
    return null
  }

  const canPreviousPage = page > 1
  const canNextPage = page < totalNumPages

  return (
    <Flex justifyContent="center" alignItems="center" gap={4} px={4} py={2}>
      <PreviousIconButton onClick={() => onPageChange(page - 1)} isDisabled={!canPreviousPage} />
      <Flex alignItems="center" gap={4}>
        <Text flexShrink="0">
          <FormattedMessage
            id="table.pagination"
            description="Shows a user which page they are viewing of their table data"
            defaultMessage="Page <b>{pageNum}</b> of <b>{totalPages}</b>"
            values={{
              b: (chunks) => <b>{chunks}</b>,
              pageNum: page,
              totalPages: totalNumPages,
            }}
          />
        </Text>
        <Select<{ value: number; label: string }, false, never>
          value={options.find((o) => o.value === pageSize)!}
          options={options}
          isSearchable={false}
          menuPlacement="top"
          onChange={(option) => {
            if (!option) {
              return
            }
            onPageSizeChange(option.value)
          }}
        />
      </Flex>
      <NextIconButton onClick={() => onPageChange(page + 1)} isDisabled={!canNextPage} />
    </Flex>
  )
}
