import { chakra, type StyleProps } from "@chakra-ui/system"
import React from "react"

export const HighlightPulseWrapper = React.forwardRef<
  HTMLSpanElement,
  {
    children: React.ReactNode
    isHighlighted: boolean
  } & StyleProps
>(({ children, isHighlighted, ...props }, ref) => {
  return (
    <chakra.span
      ref={ref}
      borderRadius="sm"
      animation={isHighlighted ? "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite" : undefined}
      sx={{
        "@keyframes pulse": {
          "0%, 100%": {
            boxShadow: "0 0 3em 1em var(--chakra-colors-brand-200)",
          },
          "50%": {
            boxShadow: "0 0 1em 0.2em var(--chakra-colors-brand-200)",
          },
        },
      }}
      backgroundColor={isHighlighted ? "brand.50" : undefined}
      {...props}
    >
      {children}
    </chakra.span>
  )
})

HighlightPulseWrapper.displayName = "HighlightPulseWrapper"
