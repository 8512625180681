import type { Message } from "@brm/schema-types/types.js"

export const defaultMessages: Message[] = [
  {
    content: "Hi, I will gladly answer your questions about tools, vendors, and more!",
    role: "assistant",
    tool_calls: [],
  },
]
export const defaultSuggestedPrompts = ["What is BRM?", "Who owns BRM?", "Purchase BRM"]
