import { getLegalAgreementVerificationStatusSchema } from "@brm/schema-helpers/legal.js"
import { hasPermission } from "@brm/schema-helpers/role.js"
import type { ToolMinimal } from "@brm/schema-types/types.js"
import { WorkflowSoftwareRenewalKindSchema } from "@brm/schemas"
import { getTitle } from "@brm/util/schema.js"
import type { CardProps } from "@chakra-ui/react"
import { Badge, Box, Button, Card, Flex, HStack, Icon, Spacer, Stack, Tooltip, useDisclosure } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import { useGetUserV1WhoamiQuery, useGetWorkflowV1DefinitionsQuery } from "../../app/services/generated-api.js"
import ToolCell from "../../components/DataTable/CellRenderer/ToolCell.js"
import FeaturedIcon from "../../components/FeaturedIcon/FeaturedIcon.js"
import { AlertIcon, CheckIcon, ChevronRightIcon } from "../../components/icons/icons.js"
import { Link, LinkOrSpan } from "../../components/Link.js"
import { pathForLegalAgreement } from "../../util/json-schema.js"
import StartWorkflowModal from "../workflows/run/start/StartWorkflowModal.js"
import AgreementAutoRenews from "./AgreementAutoRenews.js"
import AgreementSigner from "./AgreementSigner.js"
import AgreementStartEndDate from "./AgreementStartEndDate.js"
import AgreementTcv from "./AgreementTcv.js"
import type { LegalAgreementOption } from "./LegalAgreementPicker.js"
import LegalAgreementStatusBadge from "./LegalAgreementStatusBadge.js"

interface Props extends CardProps {
  legalAgreement: LegalAgreementOption
  isPrimary?: boolean
  hideRenewalOption?: boolean
  isOwner?: boolean
  // Is duplicative subscription agreement, deprecate once we have tasks/inbox
  isDuplicativeSubscription?: boolean
  tool?: ToolMinimal
}

export const LegalAgreementCard = ({
  legalAgreement,
  hideRenewalOption,
  isDuplicativeSubscription: isDuplicate,
  isPrimary,
  isOwner,
  tool,
  ...cardProps
}: Props) => {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  // Fetch renewal workflow definition to determine if user can start a renewal from the table actions menu
  const { data: workflowDefinitions } = useGetWorkflowV1DefinitionsQuery()
  const location = useLocation()
  const intl = useIntl()
  const startWorkflowDisclosure = useDisclosure()

  const verificationStatusSchema = getLegalAgreementVerificationStatusSchema(legalAgreement?.verification_status)
  const userCanUpdate = isOwner || hasPermission(whoami?.roles, "legal:update")
  const missingRenewalDefinition = !workflowDefinitions?.some(
    (def) => def.kind === WorkflowSoftwareRenewalKindSchema.const
  )
  const canRenewAgreement = !missingRenewalDefinition && !hideRenewalOption && legalAgreement.renewal_successor === null

  return (
    <Card variant="outline" px={6} py={4} {...cardProps} borderRadius="xl">
      <Stack>
        <HStack alignItems="center" gap={2} flexWrap="wrap">
          <LinkOrSpan
            state={{ prevLocation: location }}
            to={userCanUpdate ? pathForLegalAgreement(legalAgreement.id) : undefined}
            fontSize="lg"
            fontWeight="medium"
            whiteSpace="nowrap"
            flexShrink={1}
            minW="5ch"
            isTruncated
          >
            {legalAgreement.display_name}
          </LinkOrSpan>
          {isDuplicate && (
            <Tooltip
              label={intl.formatMessage({
                defaultMessage:
                  "BRM has detected duplicative subscription agreements. Consider consolidating to a team plan.",
                description: "Tooltip for duplicate agreement badge",
                id: "agreement.primary.tooltip",
              })}
            >
              <Box>
                <FeaturedIcon
                  icon={<Icon as={AlertIcon} color="warning.700" boxSize={5} />}
                  backgroundColor="warning.200"
                />
              </Box>
            </Tooltip>
          )}

          {isPrimary && (
            <Tooltip
              label={intl.formatMessage({
                defaultMessage: "This is the primary agreement",
                description: "Tooltip for primary agreement badge",
                id: "agreement.primary.tooltip",
              })}
            >
              <HStack alignItems="center" gap={1}>
                <Icon as={CheckIcon} />
              </HStack>
            </Tooltip>
          )}
          {legalAgreement.verification_status !== "verified" && (
            <Badge variant="subtleOutlined" size="md" colorScheme={verificationStatusSchema?.colorScheme}>
              {getTitle(legalAgreement.verification_status, verificationStatusSchema)}
            </Badge>
          )}
          <Spacer />
          <LegalAgreementStatusBadge status={legalAgreement.effective_status} />
        </HStack>
        <HStack paddingTop={2} gap={4} flexWrap="wrap" alignItems="end" flex={1}>
          <Flex columnGap={10} rowGap={4} fontSize="sm" fontWeight="medium" flexWrap="wrap">
            {/* SignerField */}
            {legalAgreement.agreement_type === "enterprise" && (
              <AgreementSigner
                is_signed={legalAgreement.is_signed}
                effective_status={legalAgreement.effective_status}
                buyer_signer_name={legalAgreement.buyer_signer_name}
              />
            )}
            {/* DateField */}
            <AgreementStartEndDate startDate={legalAgreement.start_date} endDate={legalAgreement.end_date} />
            {(isOwner || hasPermission(whoami?.roles, "legal:read")) && (
              <AgreementTcv totalContractValue={legalAgreement.total_contract_value} />
            )}
            {/* RenewalField */}
            {typeof legalAgreement.auto_renews === "boolean" && (
              <AgreementAutoRenews autoRenews={legalAgreement.auto_renews} />
            )}
            {tool && (
              <HStack alignItems="center">
                <ToolCell tool={tool} />
              </HStack>
            )}
          </Flex>
          <Spacer />
          <Flex>
            {legalAgreement.renewal_successor ? (
              <Link to={`/requests/${legalAgreement.renewal_successor.id}`} fontWeight="semibold">
                <FormattedMessage
                  id="agreement.viewRenewalRequest"
                  description="Text on a link to view a renewal request of a legal agreement"
                  defaultMessage="View renewal"
                />
                <Icon as={ChevronRightIcon} />
              </Link>
            ) : (
              canRenewAgreement &&
              userCanUpdate && (
                <Button variant="link" onClick={startWorkflowDisclosure.onOpen}>
                  <FormattedMessage
                    id="agreement.startRenewal"
                    description="Text on a link to start a renewal request of a legal agreement"
                    defaultMessage="Start renewal"
                  />
                  <Icon as={ChevronRightIcon} />
                </Button>
              )
            )}
          </Flex>
        </HStack>
      </Stack>
      {startWorkflowDisclosure.isOpen && (
        <StartWorkflowModal {...startWorkflowDisclosure} initialLegalAgreementId={legalAgreement.id} />
      )}
    </Card>
  )
}
