import type { FieldCounts, WorkflowRunStepStatus, WorkflowRunStepWithContext } from "@brm/schema-types/types.js"
import type { Theme } from "@brm/theme"
import { CircularProgress, Flex, HStack, Icon, Text, useTheme } from "@chakra-ui/react"
import { useSearchParams } from "react-router-dom"
import CardWithHoverActions from "../../../../components/CardWithHoverActions.js"
import { CancelIcon, CheckCircleIcon, ClockIcon, EyeIcon, XCircleIcon } from "../../../../components/icons/icons.js"
import { Link } from "../../../../components/Link.js"
import WorkflowStepIcon from "../../WorkflowStepIcon.js"
import { type WorkflowRunWithExternalFlag } from "../utils.js"
import WorkflowRunStepStatusText from "./status/WorkflowRunStepStatusText.js"

export default function WorkflowRunStepCard({
  step,
  workflowRun,
  onClick,
  selected,
}: {
  step: WorkflowRunStepWithContext
  workflowRun: WorkflowRunWithExternalFlag
  onClick: () => void
  selected?: boolean
}) {
  const [searchParams] = useSearchParams()
  const theme = useTheme<Theme>()
  return (
    <HStack flex={1} maxWidth={300}>
      <CardWithHoverActions
        selected={selected}
        flexGrow={1}
        onClick={onClick}
        overflow="hidden"
        cardBodyProps={{
          as: "div",
          display: "flex",
          gap: 2,
          alignItems: "center",
          backgroundColor: step.status === "pending" || step.status === "pending_submitted" ? "gray.50" : undefined,
        }}
        tooltipProps={{
          label: <WorkflowRunStepStatusText step={step} workflowRun={workflowRun} />,
          placement: "bottom",
        }}
      >
        <Link
          to={{ search: new URLSearchParams({ ...Object.fromEntries(searchParams), step: step.id }).toString() }}
          display="contents"
        >
          <WorkflowStepIcon stepType={step.type} />
          <Flex
            flexDirection="column"
            sx={{
              [`@container workflow-run-overview (width < ${theme.breakpoints["lg"]})`]: {
                display: "none",
              },
            }}
          >
            <Text fontWeight="medium" isTruncated>
              {step.display_name}
            </Text>
          </Flex>
          <WorkflowStepStatusIcon status={step.status} fieldCounts={step.field_counts} />
        </Link>
      </CardWithHoverActions>
    </HStack>
  )
}

const WorkflowStepStatusIcon = ({
  status,
  fieldCounts,
}: {
  status: WorkflowRunStepStatus
  fieldCounts: FieldCounts
}) => {
  switch (status) {
    case "in_progress":
      return (
        <CircularProgress value={(fieldCounts.completed / fieldCounts.total) * 100} color="brand.600" size="1rem" />
      )
    case "pending":
    case "pending_submitted":
      return <Icon as={ClockIcon} color="gray.500" />
    case "approved":
      return <Icon as={CheckCircleIcon} color="brand.600" />
    case "rejected":
      return <Icon as={XCircleIcon} color="red.500" />
    case "submitted":
      return <Icon as={EyeIcon} color="blue.600" />
    case "completed":
      return <Icon as={CheckCircleIcon} color="brand.600" />
    case "cancelled":
      return <Icon as={CancelIcon} color="gray.500" />
    default:
      return null
  }
}
