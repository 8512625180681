import type { WorkflowKind } from "@brm/schema-types/types.js"
import type { FunctionComponent } from "react"
import { useParams } from "react-router-dom"
import { isString } from "typed-assert"
import WorkflowDefinitionForm from "./WorkflowDefinitionForm.js"

const WorkflowDefinitionCreatePage: FunctionComponent = () => {
  const { kind } = useParams<{ kind: WorkflowKind }>()
  isString(kind, "Missing workflow definition kind")

  return <WorkflowDefinitionForm workflowKind={kind} />
}

export default WorkflowDefinitionCreatePage
