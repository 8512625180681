// eslint-disable-next-line no-restricted-imports
import { Select as ChakraSelect, type SelectComponent } from "chakra-react-select"
import { forwardRef } from "react"
import DropdownIndicator from "./DropdownIndicator.js"

const Select = forwardRef(function Select(props, ref) {
  return (
    <ChakraSelect
      {...props}
      ref={ref}
      components={{
        ...props.components,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DropdownIndicator,
      }}
    />
  )
}) as SelectComponent
export default Select
