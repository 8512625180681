import type { LegalAgreementListItem } from "@brm/schema-types/types.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import { formatDate } from "@brm/util/format-date-time.js"
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Spinner,
  Text,
  Textarea,
  useClipboard,
  useDisclosure,
  useToast,
  VStack,
  type StyleProps,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import React, { useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { useLocalStorage } from "usehooks-ts"
import {
  useGetUserV1CalendarKeyQuery,
  useGetUserV1WhoamiQuery,
  usePostLegalV1AgreementsListQuery,
} from "../../app/services/generated-api.js"
import {
  AgreementIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CircleDotIcon,
  CoinStacksIcon,
  CopyIcon,
  RenewalIcon,
} from "../../components/icons/icons.js"
import { VendorLogo } from "../../components/icons/Logo.js"
import { Link } from "../../components/Link.js"
import OnboardingCallout from "../../components/Onboarding/OnboardingCallout.js"
import PageWrapper from "../../components/PageWrapper.js"
import Select from "../../components/Select/Select.js"
import { PAGE_PADDING_X, PAGE_PADDING_Y } from "../../util/constant.js"
import { getLocalStorageKeyForUser, LocalStorageKeys } from "../../util/local-storage.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { HighlightPulseWrapper } from "../onboarding/HighlightPulseWrapper.js"
import { selectCurrentOnboardingStep } from "../onboarding/onboarding-slice.js"
import FullTextSearch from "../search/FullTextSearch.js"
import AgreementCalendarListItem from "./AgreementCalendarListItem.js"
import AgreementOverflow from "./AgreementOverflow.js"

// RenewalCalendar component
const RenewalCalendar: React.FC<{
  currentMonth: Temporal.PlainDate
  agreements: LegalAgreementListItem[]
}> = ({ currentMonth, agreements }) => {
  const today = Temporal.Now.plainDateISO()

  const generateCalendarDays = () => {
    const daysInMonth = currentMonth.daysInMonth
    const firstDayOfMonth = currentMonth.with({ day: 1 }).dayOfWeek % 7 // Sunday is 0, Saturday is 6

    const calendarDays = []

    const dayItemStyle: StyleProps = {
      borderWidth: "0.5px",
      borderColor: "gray.200",
      p: 2,
      display: "flex",
      minWidth: 0,
      flexDirection: "column" as const,
    }

    // Add days from previous month
    for (let i = 0; i < firstDayOfMonth; i++) {
      const day = currentMonth.subtract({ months: 1 }).daysInMonth - firstDayOfMonth + i + 1
      calendarDays.push(
        <GridItem key={`prev-${day}`} {...dayItemStyle} opacity={0.5}>
          <Text fontWeight="bold">{day}</Text>
        </GridItem>
      )
    }

    // Add days of current month
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = currentMonth.with({ day })
      const dayAgreements =
        agreements.filter(
          (agreement) => agreement.decision_date && Temporal.PlainDate.from(agreement.decision_date).equals(currentDate)
        ) || []
      const isToday = currentDate.equals(today)

      const agreementsToShow = dayAgreements.slice(0, 2)
      const remainingAgreements = dayAgreements.slice(2)

      calendarDays.push(
        <GridItem key={day} {...dayItemStyle}>
          <Flex justifyContent="center" alignItems="center" width="24px" height="24px" mb={1}>
            <Text
              fontWeight="bold"
              color={isToday ? "white" : undefined}
              bg={isToday ? "brand.500" : undefined}
              borderRadius="full"
              width="24px"
              height="24px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {day}
            </Text>
          </Flex>
          <VStack align="stretch" spacing={1} overflow="auto" flex={1}>
            {agreementsToShow.map((agreement) => (
              <AgreementCalendarListItem key={agreement.id} agreement={agreement} />
            ))}
            {remainingAgreements.length > 0 && (
              <AgreementOverflow allAgreements={dayAgreements} overflowCount={remainingAgreements.length} />
            )}
          </VStack>
        </GridItem>
      )
    }

    // Add days from next month
    const remainingDays = (7 - ((firstDayOfMonth + daysInMonth) % 7)) % 7
    for (let i = 1; i <= remainingDays; i++) {
      calendarDays.push(
        <GridItem key={`next-${i}`} {...dayItemStyle} opacity={0.5}>
          <Text fontWeight="bold">{i}</Text>
        </GridItem>
      )
    }

    return calendarDays
  }

  const weekdays = [
    <FormattedMessage
      key="sunday"
      id="calendar.sunday"
      defaultMessage="Sun"
      description="Abbreviated name for Sunday in calendar header"
    />,
    <FormattedMessage
      key="monday"
      id="calendar.monday"
      defaultMessage="Mon"
      description="Abbreviated name for Monday in calendar header"
    />,
    <FormattedMessage
      key="tuesday"
      id="calendar.tuesday"
      defaultMessage="Tue"
      description="Abbreviated name for Tuesday in calendar header"
    />,
    <FormattedMessage
      key="wednesday"
      id="calendar.wednesday"
      defaultMessage="Wed"
      description="Abbreviated name for Wednesday in calendar header"
    />,
    <FormattedMessage
      key="thursday"
      id="calendar.thursday"
      defaultMessage="Thu"
      description="Abbreviated name for Thursday in calendar header"
    />,
    <FormattedMessage
      key="friday"
      id="calendar.friday"
      defaultMessage="Fri"
      description="Abbreviated name for Friday in calendar header"
    />,
    <FormattedMessage
      key="saturday"
      id="calendar.saturday"
      defaultMessage="Sat"
      description="Abbreviated name for Saturday in calendar header"
    />,
  ]

  return (
    <Grid
      templateColumns="repeat(7, 1fr)"
      templateRows={`auto repeat(${Math.ceil(((currentMonth.with({ day: 1 }).dayOfWeek % 7) + currentMonth.daysInMonth) / 7)}, 1fr)`}
      height="100%"
      flex={1}
      borderWidth="0.5px"
      borderColor="gray.200"
    >
      {weekdays.map((day, index) => (
        <GridItem key={index} textAlign="center" fontWeight="bold" p={2} borderWidth="0.5px" borderColor="gray.200">
          {day}
        </GridItem>
      ))}
      {generateCalendarDays()}
    </Grid>
  )
}

// RenewalAgenda component
const RenewalAgenda: React.FC<{
  agreements: LegalAgreementListItem[]
  todayRef: React.RefObject<HTMLDivElement>
}> = ({ agreements, todayRef }) => {
  const intl = useIntl()
  let currentDate: string | React.ReactNode | null = null
  let currentMonth: string | null = null
  let currentAgreementDate: Temporal.PlainDate | null = null
  const today = Temporal.Now.plainDateISO()
  const formattedToday = formatDate(intl, today.toString(), {
    weekday: "short",
    month: "short",
    day: "numeric",
  })

  const monthlyStats = agreements.reduce((acc, agreement) => {
    const agreementDate = agreement.decision_date ? Temporal.PlainDate.from(agreement.decision_date) : null
    if (agreementDate) {
      const formattedMonth = formatDate(intl, agreementDate.toString(), {
        month: "short",
        year: "numeric",
      })

      if (!acc.has(formattedMonth)) {
        acc.set(formattedMonth, { agreementCount: 0, tcvTotal: 0 })
      }

      const stats = acc.get(formattedMonth)!
      stats.agreementCount += 1
      if (agreement.usd_total_contract_value) {
        stats.tcvTotal += Number(agreement.usd_total_contract_value)
      }
    }
    return acc
  }, new Map<string, { agreementCount: number; tcvTotal: number }>())

  return (
    <VStack align="stretch" overflowY="auto" gap={0}>
      {agreements.map((agreement, index) => {
        const agreementDate = agreement.decision_date ? Temporal.PlainDate.from(agreement.decision_date) : null
        const isToday = agreementDate?.equals(today)
        const isJustPastToday =
          !isToday &&
          agreementDate &&
          agreementDate.until(today).days < 0 &&
          (index === 0 || (currentAgreementDate && currentAgreementDate.until(today).days > 0))
        const isTodayInCurrentMonth = today.month === agreementDate?.month

        const formattedDate = agreementDate
          ? formatDate(intl, agreementDate.toString(), {
              weekday: "short",
              month: "short",
              day: "numeric",
            })
          : intl.formatMessage({
              id: "renewalCalendar.noDate",
              defaultMessage: "N/A",
              description: "Displayed when no date is available",
            })

        const formattedMonth = agreementDate
          ? formatDate(intl, agreementDate.toString(), {
              month: "short",
              year: "numeric",
            })
          : null
        const isNewDate = formattedDate !== currentDate
        const isNewMonth = formattedMonth !== currentMonth
        currentDate = formattedDate
        currentMonth = formattedMonth
        currentAgreementDate = agreementDate

        return (
          <Box key={agreement.id}>
            {isJustPastToday && !isTodayInCurrentMonth && (
              <DateMarker formattedDate={formattedToday} isToday={true} isNewDate={true} todayRef={todayRef} />
            )}
            {isNewMonth && formattedMonth && (
              <Flex alignItems="center" px={4} gap={2} bg="gray.100" h="2rem">
                <Text fontSize="lg" fontWeight="bold">
                  {formattedMonth}
                </Text>
                <HStack gap={1}>
                  <Icon as={AgreementIcon} color="brand.500" boxSize={4} />
                  <Text fontSize="sm" color="gray.500">
                    <FormattedMessage
                      id={`renewalCalendar.agreements.${formattedMonth}.count`}
                      defaultMessage="{count}"
                      description="Number of agreements in the month"
                      values={{ count: monthlyStats.get(formattedMonth)?.agreementCount || 0 }}
                    />
                  </Text>
                </HStack>
                <HStack gap={1}>
                  <Icon as={CoinStacksIcon} color="warning.400" boxSize={4} />
                  <Text fontSize="sm" color="gray.500">
                    {formatCurrency(
                      { amount: String(monthlyStats.get(formattedMonth)?.tcvTotal || 0), currency_code: "USD" },
                      intl
                    )}
                  </Text>
                </HStack>
              </Flex>
            )}
            {isJustPastToday && isTodayInCurrentMonth && (
              <DateMarker formattedDate={formattedToday} isToday={true} isNewDate={true} todayRef={todayRef} />
            )}
            <Flex
              alignItems="center"
              h="2rem"
              _hover={{ bg: "blue.50" }}
              borderStartRadius={isToday && isNewDate ? ".5rem" : undefined}
            >
              <Box width={44} flexShrink={0}>
                <DateMarker
                  formattedDate={formattedDate}
                  isToday={isToday || false}
                  isNewDate={isNewDate}
                  todayRef={todayRef}
                />
              </Box>
              <Box flex={1}>
                <AgreementCalendarListItem agreement={agreement} />
              </Box>
              <RenewalAgendaItemTags agreement={agreement} />
            </Flex>
          </Box>
        )
      })}
    </VStack>
  )
}

const DateMarker: React.FC<{
  formattedDate: string
  isToday: boolean
  isNewDate: boolean
  todayRef: React.RefObject<HTMLDivElement>
}> = ({ formattedDate, isToday, isNewDate, todayRef }) => {
  return (
    <HStack
      alignItems="center"
      px={4}
      h="2rem"
      boxShadow={isToday && isNewDate ? "inset 0 0 2px 1px var(--chakra-colors-blue-200)" : undefined}
      borderRadius=".5rem"
      ref={isToday && isNewDate ? todayRef : undefined}
      gap={0}
      width="fit-content"
      fontWeight={isToday && isNewDate ? "semibold" : "regular"}
    >
      <Box width={12} alignItems="center" userSelect="none">
        {isToday && isNewDate && (
          <Text>
            <FormattedMessage id="today.label" defaultMessage="Today" description="Today label" />
          </Text>
        )}
      </Box>
      <Flex flexShrink={0}>{isNewDate && <Text>{formattedDate}</Text>}</Flex>
    </HStack>
  )
}

const AgendaPill: React.FC<{
  icon: React.ReactNode
  label: string
  keyString: string
}> = ({ icon, label, keyString }) => {
  return (
    <Flex
      alignItems="center"
      gap={2}
      px={2}
      py={1}
      background="white"
      borderRadius="full"
      borderWidth="1px"
      borderColor="gray.200"
      overflow="hidden"
      whiteSpace="nowrap"
      minWidth={16}
      key={keyString}
      userSelect="none"
    >
      {icon}
      <Text fontSize="sm" color="gray.500">
        {label}
      </Text>
    </Flex>
  )
}

const RenewalAgendaItemTags: React.FC<{
  agreement: LegalAgreementListItem
}> = ({ agreement }) => {
  const { auto_renews, total_contract_value, verification_status, vendor, vendor_spend } = agreement
  const { ltm } = vendor_spend || {}
  const intl = useIntl()

  return (
    <Flex gap={2} justifyContent="flex-end" alignItems="center" overflow="hidden">
      {verification_status === "verified" && (
        <AgendaPill
          keyString={`${agreement.id}-unverified-pill`}
          icon={<Icon as={CircleDotIcon} color="warning.400" boxSize={3} />}
          label={intl.formatMessage({
            id: "renewalCalendar.unverified",
            defaultMessage: "Unverified",
            description: "Label for unverified pill",
          })}
        />
      )}
      {auto_renews === true && (
        <AgendaPill
          keyString={`${agreement.id}-auto-renews-pill`}
          icon={<Icon as={RenewalIcon} color="brand.500" boxSize={3} />}
          label={intl.formatMessage({
            id: "renewalCalendar.autoRenews",
            defaultMessage: "Auto-renews",
            description: "Label for auto-renews pill",
          })}
        />
      )}
      {ltm?.currency_amount && (
        <AgendaPill
          keyString={`${agreement.id}-tcv-pill`}
          icon={
            <Text fontWeight="semibold" color="purple.500">
              <FormattedMessage id="renewalCalendar.tcv.icon" defaultMessage="LTM" description="LTM pill icon" />
            </Text>
          }
          label={formatCurrency(
            { amount: ltm.currency_amount.amount, currency_code: ltm.currency_amount.currency_code },
            intl
          )}
        />
      )}
      {total_contract_value && (
        <AgendaPill
          keyString={`${agreement.id}-tcv-pill`}
          icon={
            <Text fontWeight="semibold" color="brand.500">
              <FormattedMessage id="renewalCalendar.tcv.icon" defaultMessage="TCV" description="TCV pill icon" />
            </Text>
          }
          label={formatCurrency(
            { amount: total_contract_value.amount, currency_code: total_contract_value.currency_code },
            intl
          )}
        />
      )}
      {vendor && (
        <AgendaPill
          keyString={`${agreement.id}-vendor-pill`}
          icon={<VendorLogo boxSize={3.5} logo={getPublicImageGcsUrl(agreement.vendor?.image_asset?.gcs_file_name)} />}
          label={vendor.display_name}
        />
      )}
    </Flex>
  )
}

const RenewalCalendarPage: React.FC = () => {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const [currentMonth, setCurrentMonth] = useState(Temporal.Now.plainDateISO())
  const intl = useIntl()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const calendarKeyQuery = useGetUserV1CalendarKeyQuery()
  const toast = useToast()
  const [view, setView] = useLocalStorage<"calendar" | "agenda">(
    getLocalStorageKeyForUser(LocalStorageKeys.RENEWAL_CALENDAR_VIEW_MODE, whoami?.id || ""),
    "agenda"
  )

  const inOnboardingFlow = useSelector(selectCurrentOnboardingStep) === "overview.view_renewal_agenda"

  const calendarUrl = calendarKeyQuery.data
    ? `${import.meta.env.VITE_API_BASE_URL}/calendar/v1/renewals/${calendarKeyQuery.data?.calendar_key}`
    : ""

  const { onCopy } = useClipboard(calendarUrl)

  const getMonthDates = (date: Temporal.PlainDate) => {
    const startDate = date.with({ day: 1 }).toString()
    const endDate = date.with({ day: date.daysInMonth }).add({ days: 1 }).toString()
    return { startDate, endDate }
  }

  const { startDate, endDate } = getMonthDates(currentMonth)
  const prevMonth = currentMonth.subtract({ months: 1 })
  const nextMonth = currentMonth.add({ months: 1 })

  const { startDate: prevStartDate, endDate: prevEndDate } = getMonthDates(prevMonth)
  const { startDate: nextStartDate, endDate: nextEndDate } = getMonthDates(nextMonth)

  const currentMonthQuery = usePostLegalV1AgreementsListQuery(
    {
      listQueryStringParams: {
        filter: [
          [
            {
              column: "decision_date",
              fields: {
                comparator: "between",
                minValue: startDate,
                maxValue: endDate,
              },
            },
          ],
        ],
      },
    },
    {
      skip: view !== "calendar",
    }
  )

  const agendaQuery = usePostLegalV1AgreementsListQuery(
    {
      listQueryStringParams: {
        sort: {
          by: "decision_date",
          direction: "ASC",
        },
        filter: [
          [
            {
              column: "decision_date",
              fields: {
                comparator: "gte",
                value: currentMonth.subtract({ months: 1 }).with({ day: 1 }).toString(),
              },
            },
          ],
        ],
        offset: 0,
        limit: 500, // for now it's safe to assume nobody renews more than 500 agreements in a month
      },
    },
    {
      skip: view !== "agenda",
    }
  )

  // Prime the cache for previous and next months only in calendar view
  const _prevMonthQuery = usePostLegalV1AgreementsListQuery(
    {
      listQueryStringParams: {
        filter: [
          [
            {
              column: "decision_date",
              fields: {
                comparator: "between",
                minValue: prevStartDate,
                maxValue: prevEndDate,
              },
            },
          ],
        ],
      },
    },
    {
      skip: view !== "calendar",
    }
  )

  const _nextMonthQuery = usePostLegalV1AgreementsListQuery(
    {
      listQueryStringParams: {
        filter: [
          [
            {
              column: "decision_date",
              fields: {
                comparator: "between",
                minValue: nextStartDate,
                maxValue: nextEndDate,
              },
            },
          ],
        ],
      },
    },
    {
      skip: view !== "calendar",
    }
  )

  const isLoading =
    (view === "calendar" && (currentMonthQuery.isLoading || currentMonthQuery.isFetching)) ||
    (view === "agenda" && (agendaQuery.isLoading || agendaQuery.isFetching)) ||
    !whoami

  const error = view === "calendar" ? currentMonthQuery.error : agendaQuery.error
  const agreements = view === "calendar" ? currentMonthQuery.data?.items : agendaQuery.data?.items

  const selectOptions = {
    calendar: {
      value: "calendar" as const,
      label: intl.formatMessage({
        id: "renewalCalendar.calendarView",
        defaultMessage: "Calendar",
        description: "Label for the Calendar view option",
      }),
    },
    agenda: {
      value: "agenda" as const,
      label: intl.formatMessage({
        id: "renewalCalendar.agendaView",
        defaultMessage: "Agenda",
        description: "Label for the Agenda view option",
      }),
    },
  }

  const todayRef = useRef<HTMLDivElement>(null)

  return (
    <PageWrapper>
      <Flex direction="column" height="100%" overflow="hidden" mr={`-${PAGE_PADDING_X}`} mb={`-${PAGE_PADDING_Y}`}>
        <Flex pt={0} pb={3} gap={2} borderBottomWidth="1px" paddingX={PAGE_PADDING_X}>
          <Heading as="h1" size="xs" whiteSpace="nowrap">
            <FormattedMessage
              id="renewalCalendar.title"
              defaultMessage="Renewals"
              description="Title for the Renewal Calendar page"
            />
          </Heading>
          <FullTextSearch order={2} ml="auto" />
        </Flex>
        <Flex direction="column" flex={1} overflow="hidden">
          <Box position="sticky" top={0} bg="white" zIndex={1} pr={PAGE_PADDING_X}>
            <Flex justifyContent="space-between" alignItems="center" mt={4} px={4} py={2}>
              <Flex alignItems="center" minWidth="300px" flex={1} gap={4}>
                <Select<{ value: "calendar" | "agenda"; label: string }>
                  value={selectOptions[view]}
                  onChange={(newValue) => (newValue ? setView(newValue.value) : undefined)}
                  options={[
                    {
                      value: "calendar",
                      label: intl.formatMessage({
                        id: "renewalCalendar.calendarView",
                        defaultMessage: "Calendar",
                        description: "Label for the Calendar view option",
                      }),
                    },
                    {
                      value: "agenda",
                      label: intl.formatMessage({
                        id: "renewalCalendar.agendaView",
                        defaultMessage: "Agenda",
                        description: "Label for the Agenda view option",
                      }),
                    },
                  ]}
                />
                <ButtonGroup>
                  {view === "calendar" && (
                    <IconButton
                      aria-label={intl.formatMessage({
                        id: "renewalCalendar.previousMonth",
                        defaultMessage: "Previous month",
                        description: "Label for the button to go to the previous month",
                      })}
                      icon={<ChevronLeftIcon />}
                      onClick={() => setCurrentMonth((prevMonth) => prevMonth.subtract({ months: 1 }))}
                      variant="ghost"
                    />
                  )}
                  <Button
                    onClick={() => {
                      if (view === "calendar") {
                        setCurrentMonth(Temporal.Now.plainDateISO())
                      } else if (view === "agenda") {
                        todayRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
                      }
                    }}
                  >
                    <FormattedMessage
                      id="renewalCalendar.today"
                      defaultMessage="Today"
                      description="Label for the button to go to today’s date"
                    />
                  </Button>
                  {view === "calendar" && (
                    <IconButton
                      aria-label={intl.formatMessage({
                        id: "renewalCalendar.nextMonth",
                        defaultMessage: "Next month",
                        description: "Label for the button to go to the next month",
                      })}
                      icon={<ChevronRightIcon />}
                      onClick={() => setCurrentMonth((prevMonth) => prevMonth.add({ months: 1 }))}
                      variant="ghost"
                    />
                  )}
                </ButtonGroup>
              </Flex>
              {view === "calendar" && (
                <Flex justifyContent="center" flex={1}>
                  <Text fontSize="xl" fontWeight="bold" textAlign="center">
                    <FormattedMessage
                      id="renewalCalendar.currentMonth"
                      defaultMessage="{month}"
                      description="Current month display in the Renewal Calendar"
                      values={{
                        month: formatDate(intl, currentMonth.toString(), { month: "long", year: "numeric" }),
                      }}
                    />
                  </Text>
                </Flex>
              )}
              <Flex flex={1} justifyContent="flex-end">
                <HighlightPulseWrapper isHighlighted={inOnboardingFlow}>
                  <Button onClick={onOpen}>
                    <FormattedMessage
                      id="renewalCalendar.addToCalendar"
                      defaultMessage="Add to Calendar"
                      description="Button text for adding renewals to a Calendar"
                    />
                  </Button>
                </HighlightPulseWrapper>
              </Flex>
            </Flex>
          </Box>
          <Box flex={1} overflow="auto" pr={PAGE_PADDING_X} pb={PAGE_PADDING_Y}>
            <Flex direction="column" height="100%">
              {isLoading ? (
                <Flex justifyContent="center" alignItems="center" flex={1}>
                  <Spinner />
                </Flex>
              ) : error ? (
                <Flex justifyContent="center" alignItems="center" flex={1}>
                  <Text>
                    <FormattedMessage
                      id="renewalCalendar.errorLoading"
                      defaultMessage="Error loading agreements"
                      description="Error message displayed when agreements fail to load"
                    />
                  </Text>
                </Flex>
              ) : view === "calendar" ? (
                <Box flex={1} display="flex" flexDirection="column">
                  <RenewalCalendar currentMonth={currentMonth} agreements={agreements || []} />
                </Box>
              ) : (
                <Box flex={1} mt={4}>
                  <RenewalAgenda agreements={agreements || []} todayRef={todayRef} />
                </Box>
              )}
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              id="renewalCalendar.modalTitle"
              defaultMessage="Add Renewal Calendar"
              description="Title for the modal to add renewal calendar"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {calendarKeyQuery.isLoading ? (
              <Spinner />
            ) : calendarKeyQuery.isError ? (
              <Text color="red.500">
                <FormattedMessage
                  id="renewalCalendar.errorLoadingCalendarKey"
                  defaultMessage="Error loading calendar key"
                  description="Error message when calendar key fails to load"
                />
              </Text>
            ) : (
              <VStack spacing={6} align="stretch">
                <Box>
                  <Text fontWeight="bold" mb={1}>
                    <FormattedMessage
                      id="renewalCalendar.simpleInstructions"
                      defaultMessage="Default Calendar App"
                      description="Header for simple calendar addition instructions"
                    />
                  </Text>
                  <Text mb={2}>
                    <FormattedMessage
                      id="renewalCalendar.simpleInstructionsText"
                      defaultMessage="Click the button below to add the Renewal Calendar to your default calendar app. This is recommended for most users."
                      description="Instructions for adding calendar URL to default calendar app"
                    />
                  </Text>
                  <Button as="a" href={`webcal://${calendarUrl.replace(/^https?:\/\//u, "")}`} colorScheme="brand">
                    <FormattedMessage
                      id="renewalCalendar.addToCalendarButton"
                      defaultMessage="Add to Calendar"
                      description="Button text for adding calendar using webcal protocol"
                    />
                  </Button>
                </Box>
                <Divider />
                <Box>
                  <Text fontWeight="bold" mb={2}>
                    <FormattedMessage
                      id="renewalCalendar.customInstructions"
                      defaultMessage="Other Calendar Apps"
                      description="Header for custom calendar addition instructions"
                    />
                  </Text>
                  <Text mb={2}>
                    <FormattedMessage
                      id="renewalCalendar.customInstructionsText"
                      defaultMessage="The following URL can be added to any calendar product that supports iCal, such as {googleCalendarLink}, {appleCalendarLink}, {microsoftOutlookLink} and {outlookComLink}."
                      description="Instructions for adding calendar URL to various calendar applications"
                      values={{
                        googleCalendarLink: (
                          <Link color="brand.700" to="https://support.google.com/calendar/answer/37100" isExternal>
                            <FormattedMessage
                              id="renewalCalendar.googleCalendar"
                              defaultMessage="Google Calendar"
                              description="Link text for Google Calendar support page"
                            />
                          </Link>
                        ),
                        appleCalendarLink: (
                          <Link
                            color="brand.700"
                            to="https://support.apple.com/guide/calendar/subscribe-to-calendars-icl1022/mac"
                            isExternal
                          >
                            <FormattedMessage
                              id="renewalCalendar.appleCalendar"
                              defaultMessage="Apple Calendar"
                              description="Link text for Apple Calendar support page"
                            />
                          </Link>
                        ),
                        microsoftOutlookLink: (
                          <Link
                            color="brand.700"
                            to="https://support.microsoft.com/en-us/office/import-calendars-into-outlook-8e8364e1-400e-4c0f-a573-fe76b5a2d379"
                            isExternal
                          >
                            <FormattedMessage
                              id="renewalCalendar.microsoftOutlook"
                              defaultMessage="Microsoft Outlook"
                              description="Link text for Microsoft Outlook support page"
                            />
                          </Link>
                        ),
                        outlookComLink: (
                          <Link
                            color="brand.700"
                            to="https://support.microsoft.com/en-us/office/import-or-subscribe-to-a-calendar-in-outlook-com-or-outlook-on-the-web-cff1429c-5af6-41ec-a5b4-74f2c278e98c"
                            isExternal
                          >
                            <FormattedMessage
                              id="renewalCalendar.outlookCom"
                              defaultMessage="Outlook.com"
                              description="Link text for Outlook.com support page"
                            />
                          </Link>
                        ),
                      }}
                    />
                  </Text>
                  <Box
                    position="relative"
                    width="100%"
                    onClick={() => {
                      onCopy()
                      toast({
                        title: intl.formatMessage({
                          id: "renewalCalendar.urlCopied",
                          defaultMessage: "Calendar URL copied",
                          description: "Toast message shown after copying the calendar URL",
                        }),
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      })
                    }}
                    cursor="pointer"
                  >
                    <Textarea value={calendarUrl} isReadOnly pr="40px" pointerEvents="none" />
                    <Box position="absolute" right="8px" top="50%" transform="translateY(-50%)" pointerEvents="none">
                      <Icon as={CopyIcon} color="gray.500" />
                    </Box>
                  </Box>
                </Box>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>
              <FormattedMessage
                id="renewalCalendar.close"
                defaultMessage="Close"
                description="Button to close the modal"
              />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {inOnboardingFlow && !isOpen && (
        <Portal>
          <Box
            position="fixed"
            top={12}
            left="50%"
            transform="translateX(-50%)"
            borderRadius="md"
            boxShadow="md"
            zIndex="popover"
            bg="white"
          >
            <OnboardingCallout step="overview.view_renewal_agenda" />
          </Box>
        </Portal>
      )}
    </PageWrapper>
  )
}

export default RenewalCalendarPage
