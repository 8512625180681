import { formatFieldPathLabel } from "@brm/schema-helpers/format.js"
import { actionPermission, hasPermission } from "@brm/schema-helpers/role.js"
import type { FilterField, ObjectType } from "@brm/schema-types/types.js"
import { iterateJsonSchema } from "@brm/util/iterate-schema.js"
import { getSchemaAtPath, isNullableSchema, shouldSkipChildrenDisplay, unwrapNullableSchema } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
} from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { useMemo, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { hasPresentKey } from "ts-is-present"
import type { ReadonlyDeep } from "type-fest"
import { isNotUndefined } from "typed-assert"
import { useGetUserV1WhoamiQuery } from "../../../app/services/generated-api.js"
import { getSearchableString } from "../../../util/searchable-string.js"
import { ListBox, ListBoxItem } from "../../ListBox.js"
import { BackIcon, FilterIcon, ThreeStarsIcon } from "../../icons/icons.js"
import { FieldSchemaIcon } from "./FieldSchemaIcon.js"
import FilterConfigPopoverBody from "./FilterConfigPopoverBody.js"
import { FILTER_POPOVER_MAX_HEIGHT_CALC } from "./constants.js"
import { displayPathFromFilterPath, filterPathFromDisplayPath } from "./util.js"

export interface AddFilterPopoverProps {
  // These props are _controlled_ so that when you add a filter for a property that already has a filter, it
  // instead edits the existing filter and starts the UI state with the existing filter config.

  /** The path of the property being filtered. */
  filterPath: string | undefined
  onFilterPathChange: (filterPath: string | undefined) => void

  /** The filter being created. */
  filter: FilterField | undefined
  /** Called when the filter configuration for the filter being added is changed by the user. */
  onFilterChange: (filter: FilterField) => void

  /** The schema of the object for each row. */
  objectSchema: ReadonlyDeep<JSONSchema>

  /** Display paths of selected columns for the table, ordering of the columns is important */
  selectedColumns: readonly string[]

  queryAiFilter?: (query: string) => Promise<void>

  /** If true, the "Show all filters" option will not be shown */
  hideShowAllFilters?: boolean

  onClose: () => void

  /** Pinned filters represented by an array of paths that are always pinned at the top */
  pinnedFilters?: string[]

  /** Pickable filters for the table */
  pickableFilters?: Partial<Record<ObjectType, object>>
}

export interface SchemaListItem {
  displayPath: string[]
  schema: ReadonlyDeep<JSONSchema>
  group: "active-column" | "other"
}

/**
 * Filter Row Header is component that starts with an Add Filter Menu button with a list of fields the user can filter.
 * It supports clearing all filters when one is applied.
 */
export default function AddFilterPopover({
  filterPath,
  filter,
  selectedColumns,
  objectSchema,
  onFilterPathChange,
  onFilterChange,
  onClose,
  queryAiFilter,
  pinnedFilters,
  pickableFilters,
  hideShowAllFilters = false,
}: AddFilterPopoverProps) {
  const intl = useIntl()
  const collator = useMemo(() => new Intl.Collator(intl.locale), [intl.locale])

  const { data: whoami } = useGetUserV1WhoamiQuery()

  const [searchQuery, setSearchQuery] = useState("")
  const [aiFilterSelected, setAiFilterSelected] = useState<boolean>(false)
  const [showAllFilters, setShowAllFilters] = useState(false)
  const [aiIsLoading, setAiIsLoading] = useState(false)
  const [aiSearchInput, setAiSearchInput] = useState("")

  const searchInputRef = useRef<HTMLInputElement>(null)
  // Until the user clicks "Show all", only the currently selected & pinned columns are offered as filters

  const pinnedColumnSchemas: SchemaListItem[] = useMemo(
    () =>
      pinnedFilters
        ?.map((path) => ({
          displayPath: path.split(".").filter(Boolean),
          schema: getSchemaAtPath(objectSchema, path),
          group: "active-column" as const,
        }))
        .filter(hasPresentKey("schema"))
        .filter(({ schema }) => !(isObject(schema) && schema.filterable === false)) ?? [],
    [objectSchema, pinnedFilters]
  )

  const filteredPinnedColumnsSchemas: SchemaListItem[] = useMemo(() => {
    const searchableQuery = getSearchableString(searchQuery, intl)
    return pinnedColumnSchemas.filter(({ displayPath }) =>
      getSearchableString(formatFieldPathLabel(displayPath, objectSchema), intl).includes(searchableQuery)
    )
  }, [intl, objectSchema, searchQuery, pinnedColumnSchemas])

  /** The schemas for currently selected table columns. */
  const selectedColumnSchemas: SchemaListItem[] = useMemo(
    () =>
      selectedColumns
        .filter((path) => !pinnedFilters?.includes(path))
        .map((displayPath) => ({
          displayPath: displayPath.split(".").filter(Boolean),
          schema: getSchemaAtPath(objectSchema, displayPath),
          group: "active-column" as const,
        }))
        .filter(hasPresentKey("schema"))
        .filter(({ schema }) => !(isObject(schema) && schema.filterable === false)),
    [objectSchema, pinnedFilters, selectedColumns]
  )
  const filteredSelectedColumnsSchemas: SchemaListItem[] = useMemo(() => {
    const searchableQuery = getSearchableString(searchQuery, intl)
    return selectedColumnSchemas.filter(({ displayPath }) =>
      getSearchableString(formatFieldPathLabel(displayPath, objectSchema), intl).includes(searchableQuery)
    )
  }, [intl, objectSchema, searchQuery, selectedColumnSchemas])

  /** The schemas for all other possible columns that are currently not selected. */
  const remainingSchemas: SchemaListItem[] = useMemo(() => {
    const selectedColumnsSet = new Set(selectedColumns)
    const pinnedFiltersSet = new Set(pinnedFilters)

    const remainingSchemas: SchemaListItem[] = []
    const iterator = iterateJsonSchema(objectSchema)
    let result = iterator.next()
    while (!result.done) {
      const node = result.value
      if (
        node.keyword === "properties" &&
        !(
          isObject(node.schema) &&
          (node.schema.displayable === false || node.schema.tableDisplay === false || node.schema.filterable === false)
        ) &&
        // Don't offer to index into specific array items (e.g. departments should always display the whole list of
        // departments not only the Nth).
        node.dataPath.every((part) => typeof part === "string") &&
        !selectedColumnsSet.has(node.dataPath.join(".")) &&
        !pinnedFiltersSet.has(node.dataPath.join(".")) &&
        (!isObject(node.schema) ||
          !node.schema.permission ||
          hasPermission(whoami?.roles, actionPermission(node.schema.permission, "read")))
      ) {
        remainingSchemas.push({
          displayPath: node.dataPath as string[],
          schema: node.schema,
          group: "other",
        })
      }
      result = iterator.next(shouldSkipChildrenDisplay(node.schema) ? "skip" : undefined)
    }
    remainingSchemas.sort((a, b) =>
      collator.compare(
        formatFieldPathLabel(a.displayPath, objectSchema),
        formatFieldPathLabel(b.displayPath, objectSchema)
      )
    )
    return remainingSchemas
  }, [collator, objectSchema, pinnedFilters, selectedColumns, whoami?.roles])

  const filteredRemainingSchemas: SchemaListItem[] = useMemo(() => {
    const searchableQuery = getSearchableString(searchQuery, intl)
    return remainingSchemas.filter((node) =>
      getSearchableString(formatFieldPathLabel(node.displayPath, objectSchema), intl).includes(searchableQuery)
    )
  }, [intl, objectSchema, remainingSchemas, searchQuery])

  // Note: Filters are always in a menu so components like ListItems must be used instead of Buttons
  const renderPopoverBody = () => {
    if (filterPath && !aiFilterSelected) {
      const displayPath = displayPathFromFilterPath(filterPath.split("."))
      const fieldSchema = getSchemaAtPath(objectSchema, displayPath, false)
      isNotUndefined(fieldSchema)
      if (!isObject(fieldSchema)) {
        return null
      }
      const isNullable = isNullableSchema(fieldSchema)
      const unwrappedFieldSchema = unwrapNullableSchema(fieldSchema)
      return (
        <FilterConfigPopoverBody
          displayPath={displayPath}
          fieldSchema={unwrappedFieldSchema}
          isNullable={isNullable}
          filter={filter}
          onChange={(filter) => onFilterChange(filter)}
          onBackClick={() => onFilterPathChange(undefined)}
          pickableFilters={pickableFilters}
        />
      )
    }

    // AI Filter
    if (aiFilterSelected && queryAiFilter) {
      return (
        <>
          <Flex gap={2} p={1} alignItems="center" fontWeight="semibold">
            <Button variant="ghost" onClick={() => setAiFilterSelected(false)}>
              <Icon as={BackIcon} />
            </Button>
            <FormattedMessage
              defaultMessage="AI Filter"
              description="Title of the AI Filter menu"
              id="filter.type.selector.aiFilter"
            />
            {aiIsLoading && <Spinner color="purple.700" />}
          </Flex>
          <Flex px={2} mb={2}>
            <Input
              autoFocus
              value={aiSearchInput}
              isReadOnly={aiIsLoading}
              onChange={(e) => {
                setAiSearchInput(e.target.value)
              }}
              onKeyDown={async (e) => {
                if (e.key !== "Enter") {
                  return
                }
                try {
                  setAiIsLoading(true)
                  await queryAiFilter(aiSearchInput)
                } finally {
                  setAiIsLoading(false)
                }
              }}
            />
          </Flex>
        </>
      )
    }

    const aiFilterLabel = intl.formatMessage({
      defaultMessage: "AI Filter",
      description: "AI filter option in the filter selector",
      id: "filter.type.selector.aiFilter",
    })

    const showAllFiltersLabel = intl.formatMessage({
      defaultMessage: "Show all filters",
      description: "Text in the filter selector that allows users to show all filters",
      id: "filter.type.selector.showAllFilters",
    })

    return (
      <>
        <Flex borderBottomWidth="1px" p={2}>
          <Input
            onChange={(event) => setSearchQuery(event.target.value)}
            ref={searchInputRef}
            value={searchQuery}
            placeholder={intl.formatMessage({
              defaultMessage: "Filter by…",
              description: "Text in the filter selector that is above the list of options a user can filter a table by",
              id: "filter.type.selector.text",
            })}
          />
        </Flex>
        <Box maxH={FILTER_POPOVER_MAX_HEIGHT_CALC} overflowY="auto">
          <ListBox
            aria-label={intl.formatMessage({
              defaultMessage: "Add filter menu",
              description: "Aria label for the list of options a user can filter a table by",
              id: "filter.type.selector.label",
            })}
          >
            {queryAiFilter && (
              <ListBoxItem textValue={aiFilterLabel} onAction={() => setAiFilterSelected(true)}>
                <Icon as={ThreeStarsIcon} />
                {aiFilterLabel}
              </ListBoxItem>
            )}
            {[...filteredPinnedColumnsSchemas, ...filteredSelectedColumnsSchemas, ...filteredRemainingSchemas].map(
              ({ displayPath, schema, group }, index) => {
                if (group === "other" && !(showAllFilters || searchQuery)) {
                  return null
                }
                const filterPath = filterPathFromDisplayPath(displayPath, schema)
                const fieldPathLabel = formatFieldPathLabel(displayPath, objectSchema)
                return (
                  <ListBoxItem
                    key={displayPath.join(".")}
                    textValue={fieldPathLabel}
                    onAction={() => onFilterPathChange(filterPath.join("."))}
                    borderTopWidth={index === filteredSelectedColumnsSchemas.length ? 1 : 0}
                  >
                    <Icon as={FieldSchemaIcon} fieldSchema={schema} displayPath={displayPath} />
                    {fieldPathLabel}
                  </ListBoxItem>
                )
              }
            )}
            {!hideShowAllFilters && filteredRemainingSchemas.length > 0 && !showAllFilters && (
              <ListBoxItem textValue={showAllFiltersLabel} onAction={() => setShowAllFilters(true)}>
                {showAllFiltersLabel}
              </ListBoxItem>
            )}
          </ListBox>
        </Box>
      </>
    )
  }

  return (
    <Popover
      onOpen={() => onFilterPathChange(undefined)}
      onClose={onClose}
      isLazy
      initialFocusRef={searchInputRef}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <Button as={Button} variant="outline" flexShrink={0}>
          <HStack>
            <Icon as={FilterIcon} />
            <Box hideBelow="lg">
              <FormattedMessage
                id="tool.list.addFilter.button"
                description="Title of a button that allows users to add filters to a table"
                defaultMessage="Filter"
              />
            </Box>
          </HStack>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent width="sm">{renderPopoverBody()}</PopoverContent>
      </Portal>
    </Popover>
  )
}
