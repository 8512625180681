import { Box, Button, Collapse, Flex, Grid, Icon, useDisclosure } from "@chakra-ui/react"
import { useCallback, type FC } from "react"
import { FormattedMessage } from "react-intl"
import { useSearchParams } from "react-router-dom"
import { ChevronDownIcon, ChevronUpIcon } from "../../components/icons/icons.js"
import FieldTimeline, { type FieldTimelineProps } from "../../components/Timeline/FieldTimeline.js"
import { TimelineCommentInput, type TimelineCommentInputProps } from "../../components/Timeline/TimelineCommentInput.js"
import { useStreaming } from "../../util/hooks/streaming.js"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "../workflows/run/utils.js"

interface CollapsibleTimelineWithInputProps {
  fieldTimelineProps: FieldTimelineProps
  timelineCommentInputProps: TimelineCommentInputProps
  commentCount?: number
  /** Callback with side effects when the timeline is opened */
  onOpen?: () => void
  /** Callback with side effects when the timeline is closed */
  onClose?: () => void
  defaultIsOpen?: boolean
  isReadOnly?: boolean
}

/**
 * A collapsible comment section that displays a timeline of comments and a comment input as well as a collapse/expand button to show/hide the timeline.
 */
export const CollapsibleTimelineWithInput: FC<
  CollapsibleTimelineWithInputProps & GetLogoForOrganizationProps & GetOrganizationActorProps
> = ({
  timelineCommentInputProps,
  fieldTimelineProps,
  onOpen,
  onClose,
  getLogoToShowByOrganizationId,
  getOrganizationActorWhenActorMissing,
  defaultIsOpen,
  isReadOnly,
}) => {
  const timelineEventsExist = fieldTimelineProps.timelineEvents.length > 0
  // since the input is inside the collapse, and we want the input to be visible when there are no events, we need to open the collapse by default if there are no events
  const timelineDisclosure = useDisclosure({ defaultIsOpen: !timelineEventsExist ? true : (defaultIsOpen ?? true) })
  const toggleTimeline = useCallback(() => {
    if (timelineDisclosure.isOpen) {
      timelineDisclosure.onClose()
      onClose?.()
    } else {
      timelineDisclosure.onOpen()
      onOpen?.()
    }
  }, [timelineDisclosure, onOpen, onClose])

  const [streaming, streamingController] = useStreaming()

  const [currentSearchParams] = useSearchParams()

  const currentlySelectedField = currentSearchParams.get("field")

  const currentlySelectedObjectId = currentSearchParams.get("object")

  const eventInTimelineIsSelected =
    timelineCommentInputProps.objectId === currentlySelectedObjectId &&
    timelineCommentInputProps.fieldName === currentlySelectedField

  return (
    <Box>
      {timelineEventsExist && (
        <Button
          display="flex"
          alignItems="center"
          variant="link"
          justifyContent="center"
          onClick={toggleTimeline}
          colorScheme="brand"
          gap={2}
        >
          <Flex boxSize={6} alignItems="center" justifyContent="center">
            <Icon as={timelineDisclosure.isOpen ? ChevronUpIcon : ChevronDownIcon} />
          </Flex>
          {timelineDisclosure.isOpen ? (
            <FormattedMessage
              defaultMessage="Hide activity"
              description="Button ARIA label to hide comments on a step"
              id="workflowRun.form.step.timeline.hide.aria.label"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Show activity"
              description="Button ARIA label to show comments on a step"
              id="workflowRun.form.step.timeline.show.aria.label"
            />
          )}
        </Button>
      )}
      {/* the collapse has a style override to allow focus borders to be visible */}
      <Collapse in={timelineDisclosure.isOpen} style={{ overflow: "visible" }}>
        {timelineEventsExist && (
          <FieldTimeline
            {...fieldTimelineProps}
            isReadOnly={isReadOnly}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
            streaming={streaming}
          />
        )}
        <Grid templateColumns="[avatar] auto [input] 1fr" gap={2} mt={2}>
          <TimelineCommentInput
            autoFocus={eventInTimelineIsSelected}
            {...timelineCommentInputProps}
            onOpen={() => {
              timelineDisclosure.onOpen()
              onOpen?.()
            }}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            streamingController={streamingController}
          />
        </Grid>
      </Collapse>
    </Box>
  )
}
