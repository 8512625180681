import type { WorkflowConditionGroupInput, WorkflowStepObjectInput } from "@brm/schema-types/types.js"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  HStack,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react"
import { forwardRef, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { type Descendant } from "slate"
import {
  usePostWorkflowV1DefinitionsConditionalMutation,
  usePostWorkflowV1FieldsQuery,
} from "../../../app/services/generated-api.js"
import { EMPTY_RICH_TEXT_BODY, isEmptyRichText } from "../../../components/RichTextEditor/util/common.js"
import SuggestedPromptBadge from "../../../components/SuggestedPromptBadge/SuggestedPromptBadge.js"
import { WriteIcon } from "../../../components/icons/icons.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import { log } from "../../../util/logger.js"
import type { ConditionGroupProps } from "./ConditionGroup.js"
import ConditionGroup from "./ConditionGroup.js"
import ConditionalEditor from "./ConditionalEditor.js"
import {
  getSuggestedConditionalPrompts,
  serializeConditionalGenerationInput,
  type SuggestedConditionalPrompt,
} from "./utils.js"

interface ConditionalPromptProps extends ConditionGroupProps {
  getEligibleObjects: () => WorkflowStepObjectInput[]
  onChange: (conditionalSchema: WorkflowConditionGroupInput) => void
}

const ConditionalPrompt = forwardRef<{ focus: () => void }, ConditionalPromptProps>(function ConditionalPrompt(
  { getEligibleObjects, onChange, ...conditionGroupProps },
  ref
) {
  const intl = useIntl()

  // Force a reset of the conditional group rendering when we generate a new full conditional
  const [conditionalResetId, setConditionalResetId] = useState<number>(0)
  const [conditionalPromptResetId, setConditionalPromptResetId] = useState<number>(0)
  const [conditionalPrompt, setConditionalPrompt] = useState<Descendant[]>(EMPTY_RICH_TEXT_BODY)
  const [generateConditional, { isLoading: generateConditionalIsLoading, error: generateConditionalError }] =
    usePostWorkflowV1DefinitionsConditionalMutation()

  const eligibleObjects = useMemo(() => getEligibleObjects(), [getEligibleObjects])

  const { data: fields } = usePostWorkflowV1FieldsQuery({
    body: eligibleObjects,
  })

  const suggestedPrompts = useMemo(() => {
    const eligibleFieldPrompts: SuggestedConditionalPrompt[] = []
    const promptsMap = getSuggestedConditionalPrompts(intl)
    for (const field of eligibleObjects) {
      const promptMap = promptsMap[field.object_type]
      if (promptMap) {
        eligibleFieldPrompts.push(...Object.values(promptMap))
        if (eligibleFieldPrompts.length >= 3) {
          // Return early if we have 3 prompts
          return eligibleFieldPrompts.slice(0, 3)
        }
      }
    }
    // Return any prompts that were found, can be empty
    return eligibleFieldPrompts
  }, [intl, eligibleObjects])

  const handleGenerateConditional = async () => {
    try {
      const promptText = conditionalPrompt.map(serializeConditionalGenerationInput).join("\n")
      const conditionalResponse = await generateConditional({
        body: {
          prompt: promptText,
          eligibleWorkflowObjects: eligibleObjects,
        },
      }).unwrap()
      onChange(structuredClone(conditionalResponse.condition))
      setConditionalResetId((prev) => prev + 1)
    } catch (err) {
      log.error("Failed to generate conditional", err)
    }
  }

  return (
    <Stack>
      <Box>
        <ConditionalEditor
          ref={ref}
          key={conditionalPromptResetId}
          initialValue={conditionalPrompt}
          onChange={(value) => setConditionalPrompt(value)}
          eligibleFields={fields ?? []}
          containerProps={{
            onKeyDown: async (event) => {
              if (event.key === "Enter" && (event.metaKey || event.ctrlKey) && !generateConditionalIsLoading) {
                await handleGenerateConditional()
              }
            },
          }}
        />
      </Box>
      {generateConditionalError && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{getAPIErrorMessage(generateConditionalError)}</AlertTitle>
          <AlertDescription>
            <FormattedMessage
              id="request.config.step.prompt.button.error.description"
              description="Description of the error that occurs when a conditional prompt fails to generate"
              defaultMessage="Try modifying your prompt or contact {contactSupportLink}."
              values={{
                contactSupportLink: (
                  <Button variant="link" onClick={() => window.Pylon?.("show")}>
                    <FormattedMessage
                      id="request.config.step.prompt.button.error.contactSupportLink"
                      description="Link to contact support"
                      defaultMessage="BRM support"
                    />
                  </Button>
                ),
              }}
            />
          </AlertDescription>
        </Alert>
      )}
      {isEmptyRichText(conditionalPrompt) && suggestedPrompts.length > 0 && (
        <HStack flexWrap="wrap">
          {suggestedPrompts.map((prompt) => (
            <SuggestedPromptBadge
              key={prompt.label}
              prompt={prompt.label}
              onClick={() => {
                setConditionalPrompt(prompt.richTextPrompt)
                // Reset the conditional editor to trigger a re-render of the editor
                setConditionalPromptResetId((prev) => prev + 1)
              }}
            />
          ))}
        </HStack>
      )}
      <HStack justify="flex-end">
        {generateConditionalIsLoading ? (
          <HStack>
            <CircularProgress color="brand.500" isIndeterminate={true} size="1.1em" />
            <Text>
              <FormattedMessage
                id="request.config.step.prompt.button.loading"
                description="Button text to add a new conditional prompt to a step"
                defaultMessage="Generating conditional..."
              />
            </Text>
          </HStack>
        ) : (
          <Button colorScheme="brand" leftIcon={<Icon as={WriteIcon} />} onClick={handleGenerateConditional}>
            <FormattedMessage
              id="request.config.step.prompt.button"
              description="Button text to add a new conditional prompt to a step"
              defaultMessage="Generate"
            />
          </Button>
        )}
      </HStack>
      <ConditionGroup key={conditionalResetId} {...conditionGroupProps} />
    </Stack>
  )
})

export default ConditionalPrompt
