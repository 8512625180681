import { useCallback, useEffect, useState } from "react"
import { useGetNotificationV1TasksQuery, type Task } from "../../app/services/generated-api.js"

interface UseInboxTasksResult {
  tasks: Task[]
  removeTask: (index: number) => void
}

// Hook to do state managment for tasks in the inbox to get around the latency of being dependant on RTK query
export const useInboxTasks = (): UseInboxTasksResult => {
  const tasksQuery = useGetNotificationV1TasksQuery()

  const [allTasks, setAllTasks] = useState<Task[]>([])

  useEffect(() => {
    if (tasksQuery.data) {
      setAllTasks(tasksQuery.data.items)
    }
  }, [tasksQuery.data])

  const removeTask = useCallback((index: number) => {
    setAllTasks((current) => current.filter((_, i) => i !== index))
  }, [])

  return {
    tasks: allTasks,
    removeTask,
  }
}
