import { Box, Flex, Icon, IconButton, Image, List, Spinner, Text, Tooltip } from "@chakra-ui/react"
import { Suspense, useCallback, useEffect, useRef, useState, type FunctionComponent } from "react"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { Outlet, useLocation } from "react-router-dom"
import emptyStateGif from "../../../assets/empty_state_clap_one_loop.gif"
import {
  useGetNotificationV1CountQuery,
  useGetUserV1WhoamiQuery,
  type InboxNotification,
  type Task,
} from "../../app/services/generated-api.js"
import Button from "../../components/Button/Button.js"
import { ButtonStyles } from "../../components/Button/types.js"
import { Link } from "../../components/Link.js"
import { OnboardingPopover } from "../../components/Onboarding/OnboardingPopover.js"
import { XIcon } from "../../components/icons/icons.js"
import { SHORT_TOOLTIP_OPEN_DELAY } from "../../util/constant.js"
import { HighlightPulseWrapper } from "../onboarding/HighlightPulseWrapper.js"
import { selectCurrentOnboardingStep } from "../onboarding/onboarding-slice.js"
import { InboxActionsMenu } from "./InboxActionsMenu.js"
import { InboxFiltersMenu } from "./InboxFiltersMenu.js"
import NotificationItem from "./notification/NotificationItem.js"
import TaskItem from "./task/TaskItem.js"
import { useInboxNotifications } from "./use-inbox-notifications.js"
import { useInboxTasks } from "./use-inbox-tasks.js"

export const Inbox: FunctionComponent = () => {
  const intl = useIntl()

  const { data: whoami } = useGetUserV1WhoamiQuery()

  const notificationCountResult = useGetNotificationV1CountQuery()

  const [isTaskSectionExpanded, setIsTaskSectionExpanded] = useState(false)
  const [isTaskSectionVisible, setIsTaskSectionVisible] = useState(true)
  const [taskCloseIconNode, setTaskCloseIconNode] = useState<HTMLDivElement | null>(null)
  const taskCloseIconRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setTaskCloseIconNode(node)
    }
  }, [])

  const { tasks, removeTask } = useInboxTasks()

  const scrollContainerRef = useRef<HTMLUListElement>(null)

  const {
    inboxNotifications,
    loadingMoreNotifications,
    hasMoreNotifications,
    loadMoreDisabled,
    loadMore,
    filterByUnread,
    filterByMentioned,
    toggleFilterByUnread,
    toggleFilterByMentioned,
    selectedNotificationId,
    selectNotification,
    toggleRead,
    deleteNotification,
    markAllAsRead,
    markAllAsUnread,
    deleteRead,
    deleteAll,
  } = useInboxNotifications()

  const [sentryRef, { rootRef: infiniteScrollRootRefCallback }] = useInfiniteScroll({
    loading: loadingMoreNotifications,
    hasNextPage: hasMoreNotifications,
    disabled: loadMoreDisabled,
    onLoadMore: loadMore,
  })

  useEffect(() => {
    infiniteScrollRootRefCallback(scrollContainerRef.current)
  }, [infiniteScrollRootRefCallback])

  useEffect(() => {
    const current = taskCloseIconNode
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry) {
          setIsTaskSectionVisible(entry.isIntersecting)
        }
      },
      { threshold: 0 }
    )

    if (current) {
      observer.observe(current)
    }

    return () => {
      if (current) {
        observer.unobserve(current)
      }
    }
  }, [taskCloseIconNode])

  const baseNumTasksToShow = 3

  if (!notificationCountResult.data) {
    return null
  }

  if (!whoami) {
    return null
  }

  const organizationId = whoami?.organization_id
  if (!organizationId) {
    return null
  }

  return (
    <Flex key="inbox-panel" flexDir="column" width="400px" borderRightWidth={1} flexShrink={0}>
      <Flex flexDir="column" borderBottomWidth={1} height="100%" flex="1 0 auto" overflowY="auto">
        {/* Task Section */}
        <Flex flexDirection="column" alignItems="flex-start" justifyContent="center">
          <Flex
            pt={2}
            pb={1}
            alignItems="center"
            justifyContent="space-between"
            position="sticky"
            top={0}
            bg="white"
            zIndex={1}
            width="100%"
            px={4}
          >
            <Text fontSize="lg" fontWeight="semibold">
              <FormattedMessage defaultMessage="Tasks" description="Title for the inbox tasks" id="inbox.tasks.title" />
            </Text>
            <TaskSectionCloseButton taskCloseIconRef={taskCloseIconRef} />
          </Flex>
          {tasks?.length === 0 ? (
            <Flex flexDirection="column" alignItems="center" justifyContent="center" px={4} width="100%">
              <Image src={emptyStateGif} height="7rem" />
              <Text fontWeight="semibold" size="sm" color="gray.600" textAlign="center">
                <FormattedMessage
                  defaultMessage="Nice work! You‘ve completed all of your tasks."
                  description="Message shown when all tasks are completed"
                  id="inbox.tasks.allCompleted"
                />
              </Text>
            </Flex>
          ) : (
            <List width="100%">
              {tasks?.map((task, index) => {
                if (isTaskSectionExpanded || index < baseNumTasksToShow) {
                  return <InboxTaskItem key={index} task={task} index={index} removeTask={removeTask} />
                }
                return null
              })}
              {tasks && tasks.length > baseNumTasksToShow && !isTaskSectionExpanded && (
                <Flex justifyContent="flex-start" px="3.75rem" pt={4}>
                  <Button
                    buttonStyles={ButtonStyles.LinkBrand}
                    onClick={() => setIsTaskSectionExpanded(true)}
                    size="md"
                    label={intl.formatMessage(
                      {
                        defaultMessage: "View {numberOfTasks} more tasks",
                        description: "Button to show more tasks",
                        id: "inbox.tasks.viewAll",
                      },
                      { numberOfTasks: tasks.length - baseNumTasksToShow }
                    )}
                  />
                </Flex>
              )}
            </List>
          )}
        </Flex>
        {/* Notifications Section */}
        <Flex
          pl={4}
          pr={2}
          pt={2}
          pb={1}
          alignItems="center"
          justifyContent="space-between"
          position="sticky"
          top={0}
          bg="white"
          zIndex={1}
        >
          <Text fontSize="lg" fontWeight="semibold">
            <FormattedMessage
              defaultMessage="Notifications"
              description="Title for the inbox notifications"
              id="inbox.notifications.title"
            />
          </Text>
          <Flex gap={1}>
            <InboxActionsMenu
              markAllAsRead={markAllAsRead}
              markAllAsUnread={markAllAsUnread}
              deleteRead={deleteRead}
              deleteAll={deleteAll}
            />
            <InboxFiltersMenu
              filterByUnread={filterByUnread}
              filterByMentioned={filterByMentioned}
              toggleFilterByUnread={toggleFilterByUnread}
              toggleFilterByMentioned={toggleFilterByMentioned}
            />
            {!isTaskSectionVisible && <NotificationsInboxCloseButton />}
          </Flex>
        </Flex>
        <List>
          {notificationCountResult.data.count === 0 && (
            <Box fontWeight="semibold" textAlign="center" color="gray.600" p={4}>
              <FormattedMessage
                defaultMessage="You don‘t have any notifications."
                description="Message shown when there are no notifications"
                id="inbox.empty"
              />
            </Box>
          )}
          {inboxNotifications.map((notification, index) => (
            <InboxNotificationItem
              key={notification.id}
              organizationId={organizationId}
              notification={notification}
              prevNotification={index > 0 ? inboxNotifications.at(index - 1) : undefined}
              nextNotification={index < inboxNotifications.length - 1 ? inboxNotifications.at(index + 1) : undefined}
              selectedNotificationId={selectedNotificationId}
              toggleRead={toggleRead}
              deleteNotification={deleteNotification}
              selectNotification={selectNotification}
            />
          ))}
          {loadingMoreNotifications && (
            <Flex justifyContent="center" pt={2}>
              <Spinner size="md" />
            </Flex>
          )}
          {/* This box acts as the trigger for the intersection observer */}
          <Box ref={sentryRef} />
        </List>
      </Flex>
    </Flex>
  )
}

export const InboxLayout = () => {
  return (
    <Flex height="100%" flexShrink={0} pt={2}>
      <Inbox />
      <Suspense>
        <Outlet />
      </Suspense>
    </Flex>
  )
}

const getCloseInboxUrl = (pathname: string) => {
  if (pathname === "/inbox") {
    return "/"
  }
  if (pathname.startsWith("/inbox/tasks/")) {
    return pathname.replace(/^\/inbox\/tasks\/.+?\//u, "/")
  }
  if (pathname.startsWith("/inbox/")) {
    return pathname.replace(/^\/inbox\/.+?\//u, "/")
  }
  return pathname
}

const NotificationsInboxCloseButton = () => {
  const { pathname } = useLocation()
  const intl = useIntl()
  return (
    <Tooltip
      label={
        <FormattedMessage
          id="inbox.tasks.close.button"
          description="Tooltip for the button that closes the inbox"
          defaultMessage="Close inbox"
        />
      }
      openDelay={SHORT_TOOLTIP_OPEN_DELAY}
    >
      <IconButton
        aria-label={intl.formatMessage({
          id: "inbox..sidebar.close.ariaLabel",
          description: "Aria label for the button that closes the inbox sidebar",
          defaultMessage: "Close inbox sidebar",
        })}
        variant="ghost"
        flexShrink={0}
        icon={<Icon as={XIcon} />}
        as={Link}
        to={getCloseInboxUrl(pathname)}
      />
    </Tooltip>
  )
}

const TaskSectionCloseButton = ({ taskCloseIconRef }: { taskCloseIconRef: (node: HTMLDivElement | null) => void }) => {
  const intl = useIntl()
  const { pathname, search } = useLocation()
  return (
    <Tooltip
      label={
        <FormattedMessage
          id="inbox.tasks.close.button"
          description="Tooltip for the button that closes the inbox"
          defaultMessage="Close inbox"
        />
      }
      openDelay={SHORT_TOOLTIP_OPEN_DELAY}
    >
      <IconButton
        ref={taskCloseIconRef}
        aria-label={intl.formatMessage({
          id: "inbox.sidebar.close.ariaLabel",
          description: "Aria label for the button that closes the inbox sidebar",
          defaultMessage: "Close inbox sidebar",
        })}
        variant="ghost"
        flexShrink={0}
        icon={<Icon as={XIcon} />}
        as={Link}
        to={getCloseInboxUrl(pathname) + search}
      />
    </Tooltip>
  )
}

const InboxNotificationItem: FunctionComponent<{
  notification: InboxNotification
  prevNotification?: InboxNotification
  nextNotification?: InboxNotification
  organizationId: string
  selectedNotificationId?: string
  selectNotification: (notification: InboxNotification) => void
  toggleRead: (notification: InboxNotification) => void
  deleteNotification: (notification: InboxNotification) => void
}> = ({
  notification,
  prevNotification,
  nextNotification,
  organizationId,
  selectedNotificationId,
  toggleRead,
  deleteNotification,
  selectNotification,
}) => {
  const onToggleRead = useCallback(() => toggleRead(notification), [notification, toggleRead])

  const onDeleteNotification = useCallback(() => {
    deleteNotification(notification)
    const next = nextNotification ?? prevNotification
    if (next) {
      selectNotification(next)
    }
  }, [notification, nextNotification, prevNotification, deleteNotification, selectNotification])

  const onSelectNotification = useCallback(() => selectNotification(notification), [notification, selectNotification])

  const onSelectNextNotification = useCallback(() => {
    if (nextNotification) {
      selectNotification(nextNotification)
    }
  }, [nextNotification, selectNotification])

  const onSelectPreviousNotification = useCallback(() => {
    if (prevNotification) {
      selectNotification(prevNotification)
    }
  }, [prevNotification, selectNotification])

  return (
    <NotificationItem
      key={notification.id}
      organizationId={organizationId}
      notification={notification}
      isSelected={selectedNotificationId === notification.id}
      onToggleRead={onToggleRead}
      onDeleteNotification={onDeleteNotification}
      onSelectNotification={onSelectNotification}
      onSelectNextNotification={onSelectNextNotification}
      onSelectPreviousNotification={onSelectPreviousNotification}
    />
  )
}

const InboxTaskItem: FunctionComponent<{ task: Task; index: number; removeTask: (index: number) => void }> = ({
  task,
  index,
  removeTask,
}) => {
  const currentStep = useSelector(selectCurrentOnboardingStep)
  const onRemoveTask = useCallback(() => removeTask(index), [index, removeTask])
  return currentStep === "inbox.verify_agreements" && task.type === "agreement_verification" ? (
    <OnboardingPopover
      step="inbox.verify_agreements"
      isOpen={currentStep === "inbox.verify_agreements"}
      position="right"
      anchorElement={
        // TODO: Find a better wrapper element or turn on the pulse
        <HighlightPulseWrapper isHighlighted={false}>
          <TaskItem task={task} onRemoveTask={onRemoveTask} />
        </HighlightPulseWrapper>
      }
    />
  ) : (
    <TaskItem task={task} onRemoveTask={onRemoveTask} />
  )
}
