import type { Task } from "@brm/schema-types/types.js"
import { Flags } from "@brm/util/flags.js"
import { unreachable } from "@brm/util/unreachable.js"
import posthog from "posthog-js"
import type { Location } from "react-router-dom"
import { isNotVoid } from "typed-assert"
import { AGREEMENT_VERIFICATION_SELECT_TOP_AGREEMENT_HASH, AUTO_SELECT_STEP_HASH } from "../../workflows/constants.js"

export const getTaskPrimarySrcUrl = (task: Task) => {
  const { pathName, searchParams, hash } = getTaskUrlParts(task)
  if (hash) {
    return `${pathName}#${hash}`
  }
  if (searchParams) {
    return `${pathName}?${searchParams.toString()}`
  }
  return pathName
}

const getTaskUrlParts = (task: Task): { pathName: string; searchParams?: URLSearchParams; hash?: string } => {
  const isNegotiationEnabled = posthog.isFeatureEnabled(Flags.NEGOTIATION_ENABLED)
  const taskBasePath = `/inbox/tasks/${task.type.toLowerCase()}`
  switch (task.type) {
    case "workflow_step_gatherer":
      return {
        pathName: `${taskBasePath}/requests/${task.workflow_run_id}`,
        hash: AUTO_SELECT_STEP_HASH,
      }
    case "workflow_run_step_ready_for_review":
    case "workflow_run_aggregated_steps_ready_for_review_or_approval":
      return {
        pathName: `${taskBasePath}/requests/${task.workflow_run_id}`,
        hash: AUTO_SELECT_STEP_HASH,
      }
    case "workflow_field_gatherer":
      return {
        pathName: `${taskBasePath}/requests/${task.workflow_run_id}`,
        searchParams: new URLSearchParams({
          step: task.workflow_run_step_id,
          task: task.type,
          type: task.object_type,
          object: task.object_id,
          field: task.field_name,
        }),
      }
    case "workflow_run_aggregated_gather_fields":
      return {
        pathName: `${taskBasePath}/requests/${task.workflow_run_id}`,
        hash: AUTO_SELECT_STEP_HASH,
      }
    case "agreement_renewal":
      if (isNegotiationEnabled) {
        if (task.negotiation_id) {
          return {
            pathName: `${taskBasePath}/negotiations/${task.vendor_id}/${task.negotiation_id}`,
          }
        }

        return {
          pathName: `${taskBasePath}/negotiations/${task.vendor_id}`,
        }
      }
      return {
        pathName: task.tool_id ? `${taskBasePath}/tools/${task.tool_id}` : `${taskBasePath}/vendors/${task.vendor_id}`,
      }
    case "agreement_verification":
      return {
        pathName: `${taskBasePath}/agreements`,
        hash: AGREEMENT_VERIFICATION_SELECT_TOP_AGREEMENT_HASH,
      }
    case "reply":
      if (task.workflow_run_id) {
        isNotVoid(task.object_type)
        isNotVoid(task.object_id)

        const searchParams = new URLSearchParams({
          event: task.timeline_event_id,
          type: task.object_type,
          object: task.object_id,
        })
        if (task.workflow_run_step_id) {
          searchParams.set("step", task.workflow_run_step_id)
        }

        if (task.field_name) {
          searchParams.set("field", task.field_name)
        }

        return {
          pathName: `${taskBasePath}/requests/${task.workflow_run_id}`,
          searchParams,
        }
      }
      if (task.vendor_id) {
        return {
          pathName: `${taskBasePath}/vendors/${task.vendor_id}/overview`,
          searchParams: new URLSearchParams({
            event: task.timeline_event_id,
          }),
        }
      }
      if (task.tool_id) {
        return {
          pathName: `${taskBasePath}/tools/${task.tool_id}/overview`,
          searchParams: new URLSearchParams({
            event: task.timeline_event_id,
          }),
        }
      }
      throw new Error("Reply task has no workflow run or vendor or tool")
    case "disconnected_integration":
      return {
        pathName:
          task.integration.provider === "gmail_oauth"
            ? `${taskBasePath}/settings/contract-collector`
            : `${taskBasePath}/settings/integrations`,
      }
    case "tools_inactive_owner_reassignment":
      return {
        pathName: `${taskBasePath}/tools`,
        searchParams: new URLSearchParams({
          "owner.status": "inactive_employee*inactive_user",
        }),
      }
    case "vendors_inactive_owner_reassignment":
      return {
        pathName: `${taskBasePath}/vendors`,
        searchParams: new URLSearchParams({
          "owner.status": "inactive_employee*inactive_user",
        }),
      }
    case "agreement_duplicative_subscriptions":
      return {
        pathName: `${taskBasePath}/${task.object_type.toLowerCase()}s/${task.object_id}/overview`,
        hash: "agreements",
      }
    default:
      unreachable(task)
  }
}

export const compareLocationToTaskUrl = (location: Location, task: Task) => {
  const { pathname } = location
  const taskUrlData = getTaskUrlParts(task)
  return pathname.includes(taskUrlData.pathName)
}
