import type { EnabledOnboardingStep, NullableOnboardingStep } from "@brm/type-helpers/onboarding.js"
import Popover, { PopoverContent, PopoverTrigger, type PopoverProps } from "../Popover.js"
import OnboardingCallout from "./OnboardingCallout.js"
import { isCalloutConfiguredStep } from "./OnboardingCalloutConfigurations.js"

// Callers configure the position of the popover
interface OnboardingPopoverProps {
  step: NullableOnboardingStep
  anchorElement: React.ReactNode
  isOpen: boolean
  hasArrow?: boolean
  arrowSize?: number
  position?: PopoverProps["position"]
  positionMainOffset?: number
  positionCrossOffset?: number
}

export const OnboardingPopover = ({
  step,
  anchorElement,
  isOpen,
  hasArrow = true,
  arrowSize = 16,
  position,
  positionMainOffset = 16,
  positionCrossOffset = 0,
}: OnboardingPopoverProps) => {
  if (!step || !isOpen || !isCalloutConfiguredStep(step)) {
    return anchorElement
  }

  return (
    <Popover
      opened={isOpen}
      styles={{
        dropdown: {
          padding: 0,
          borderRadius: "var(--chakra-radii-md)",
          boxShadow: "var(--chakra-shadows-sm)",
        },
      }}
      position={position}
      withArrow={hasArrow}
      arrowSize={arrowSize}
      offset={{ mainAxis: positionMainOffset, crossAxis: positionCrossOffset }}
    >
      <PopoverTrigger>{anchorElement}</PopoverTrigger>
      <PopoverContent>
        <OnboardingCallout step={step as EnabledOnboardingStep} />
      </PopoverContent>
    </Popover>
  )
}
